import mutations from "./mutations.js";
import actions from "./actions.js";
import getters from "./getters.js";

export default {
    namespaced: true,
    state() {
        return {
            defaultStep: 1,
            cvStep: localStorage.getItem("SAS_CV_STEP"),
            cvId: localStorage.getItem("SAS_CV_ID"),
        };
    },
    mutations,
    actions,
    getters,
};