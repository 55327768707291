<template>
  <!--Start Jobs By Categories-->
  <section class="jobs_by_categories Pad_50">
    <div class="container">
      <div class="row">
        <div class="col-sm-12">
          <!--Top Title-->
          <div class="side_title">
            <h3>{{ $t("categories.jobsByCat") }}</h3>
            <router-link to="/categories" v-if="!show">{{
              $t("showAll")
            }}</router-link>
          </div>
          <!--Top Title-->
        </div>
        <div
          class="col-md-3 category_container"
          v-for="category in categories.slice(0, 8)"
          :key="category.id"
        >
          <!--Job Category-->
          <div class="job_category">
            <!--Categry Title-->
            <h4>
              <span class="icon"><img :src="category.image" /></span
              >{{ category.name }}
            </h4>
            <!--Categry Title-->
            <!--Category List-->
            <ul class="category_list" v-if="!show">
              <li
                v-for="sub in category.subcategories.slice(0, 7)"
                :key="sub.id"
              >
                <router-link :to="`/categoriesJobs/${sub.id}`">{{
                  sub.name
                }}</router-link>
              </li>
            </ul>
            <ul class="category_list" v-if="show">
              <li v-for="sub in category.subcategories" :key="sub.id">
                <router-link :to="`/categoriesJobs/${sub.id}`">{{
                  sub.name
                }}</router-link>
              </li>
            </ul>
            <!--Category List-->
          </div>
          <!--Job Category-->
        </div>
      </div>
    </div>
  </section>
  <!--End Jobs By Categories-->
</template>

<script>
export default {
  props: ["show"],
  data() {
    return {
      // Categories
      categories: [],
      // Categories
    };
  },
  methods: {
    getData() {
      // ======= Categories ========
      this.$axios({
        method: "GET",
        url: "categories",
      }).then((res) => {
        this.categories = res.data.data;
      });
    },
  },
  created() {
    this.getData();
  },
};
</script>

<style></style>
