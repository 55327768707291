import Vue from "vue";
import VueRouter from "vue-router";

// ============================== Start Views ==============================
import Home from "../views/Home.vue"; // Home

// *************** Children
import HomePage from "../views/children/HomePage.vue";
import HomeJobSearch from "../views/children/HomeJobSearch.vue";
import HomeJobOwner from "../views/children/HomeJobOwner.vue";
//Register
import Register from "../views/Auth/Register/Register.vue";

// *************** Start Auth ***************
import Auth from "../views/Auth/Auth.vue";
import Login from "../views/Auth/Login.vue";
// *************** End Auth ***************

// *************** Start Static ***************
import Terms from "../views/children/StaticPages/Terms.vue";
import About from "../views/children/StaticPages/AboutUs.vue";
import ContactUs from "../views/children/StaticPages/ContactUs.vue";
// *************** End Static ***************

// *************** Start Searcher Profile Data ***************
import SearcherProfileData from "../views/children/JobSearcher/ProfileData/ProfileData.vue";
// *************** End Searcher Profile Data ***************

// *************** Start Settings Job Searcher ***************
import SearcherSettings from "../views/children/JobSearcher/EditProfile/Home.vue";
import EditJob from "../views/children/JobSearcher/EditProfile/EditJob.vue";
import EditPersonalData from "../views/children/JobSearcher/EditProfile/EditPersonal.vue";
import EditPassword from "../views/children/JobSearcher/EditProfile/EditPassword.vue";
// *************** End Settings Job Searcher ***************

// *************** Start Owner Profile Data ***************
import OwnerProfileData from "../views/children/JobOwner/ProfileData/ProfileData.vue";
// *************** End Owner Profile Data ***************

// *************** Start Owner Packages ***************
import Packages from "../views/children/JobOwner/Packages/Packages.vue";
import MyPackages from "../views/children/JobOwner/Packages/MyPackage.vue";
// *************** End Owner Packages  ***************

// *************** Start Settings Job Owner ***************
import OwnerSettings from "../views/children/JobOwner/EditProfile/Home.vue";
import EditOwnerPersonalData from "../views/children/JobOwner/EditProfile/EditPersonal.vue";
import EditOwnerPassword from "../views/children/JobOwner/EditProfile/EditPassword.vue";
// *************** End Settings Job Owner ***************

// *************** Start Categories ***************
import Categories from "../views/children/Categories/Categories.vue";
// *************** End Categories***************

// *************** Start Search ***************
import Search from "../views/children/SearchResults/Search.vue";
import SearchResaults from "../views/children/SearchResults/SearchResult.vue";
// *************** End Search***************

// *************** Create Cv ***************
import CreateCv from "../views/children/CreateCv/CreateCv.vue";
import CvSteps from "../views/children/CreateCv/CvSteps.vue";
import EditCv from "../views/children/CreateCv/EditCv.vue";
import CvTemplateOne from "../views/children/CreateCv/TemplatePage1.vue";
import CvTemplateTwo from "../views/children/CreateCv/TemplatePage2.vue";
import CvTemplateThree from "../views/children/CreateCv/TemplatePage3.vue";
import CvTemplateFour from "../views/children/CreateCv/TemplatePage4.vue";
// *************** Create Cv***************

// *************** Rate Cv ***************
import RateCv from "../views/children/RateCv/RateCv.vue";
// *************** Rate Cv***************

// *************** Improve Cv ***************
import ImproveCv from "../views/children/ImproveCv/ImproveCv.vue";
// *************** Improve Cv***************

// *************** Job Details ***************
import JobsByCategories from "../views/children/JobDetails/JobsByCategories.vue";
import JobsByCity from "../views/children/JobDetails/JobsByCity.vue";
import JobDetails from "../views/children/JobDetails/JobDetails.vue";
import AddJob from "../views/children/JobDetails/AddJob.vue";
import EditOnwerJob from "../views/children/JobDetails/EditJob.vue";
//Add Employes
import AddEmployes from "../views/children/JobDetails/AddEmployes.vue";
// *************** Job Details***************

// *************** Job Applies ***************
import JobApplies from "../views/children/JobApplies/JobApplies.vue";
import AppliedData from "../views/children/JobApplies/AppliesData.vue";

// *************** Featured Cv ***************
import FeaturedCv from "../views/children/FeaturedCv/FeaturedCv.vue";

// *************** Job Applies  ***************
import Blogs from "../views/children/Blogs/Blogs.vue";
import BlogDetails from "../views/children/Blogs/BlogDetails.vue";
// *************** Blogs ***************

// *************** Start Forget Password ***************
import EnterEmail from "../views/Auth/ForgetPassword/EnterEmail.vue";
import CheckCode from "../views/Auth/ForgetPassword/CheckCode.vue";
import ResetPassword from "../views/Auth/ForgetPassword/ResetPassword.vue";
// *************** End Forget Password ***************

// *************** NotFound
import NotFound from "../views/NotFound.vue";

// ============================== End Views ==============================

// Vuex store
import store from "../store/index";

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    scrollBehavior() {
        document.getElementById("app").scrollIntoView({ behavior: "smooth" });
    },
    routes: [
        //Main
        {
            path: "/",
            component: Home,
            children: [{
                    path: "/",
                    name: "HomePage",
                    component: HomePage,
                },
                {
                    path: "/jobSearch",
                    name: "HomeJobSearch",
                    component: HomeJobSearch,
                },
                {
                    path: "/jobOwner",
                    name: "HomeJobOwner",
                    component: HomeJobOwner,
                },
                {
                    path: "/register",
                    name: "Register",
                    component: Register,
                },

                //===========Staic=============

                //terms
                {
                    path: "/terms",
                    name: "Terms",
                    component: Terms
                },
                //About
                {
                    path: "/about",
                    name: "About",
                    component: About
                },
                //ContactUs
                {
                    path: "/contactUs",
                    name: "ContactUs",
                    component: ContactUs
                },
                //===========Staic=============

                //=========== Job Searcher=============
                {
                    path: "/jobSearcherProfile",
                    name: "SearcherProfileData",
                    component: SearcherProfileData
                },
                //=========== Job Searcher=============

                // ======= Settings Job Searcher
                {
                    path: "/editJobSearcher",
                    component: SearcherSettings,
                    children: [{
                            path: "",
                            name: "EditJob",
                            component: EditJob
                        },
                        {
                            path: "/editPersonalData",
                            name: "EditPersonalData",
                            component: EditPersonalData
                        },
                        {
                            path: "/editPassword",
                            name: "EditPassword",
                            component: EditPassword
                        },
                    ]
                },
                // ======= Settings Job Searcher

                //=========== Job Owner=============
                {
                    path: "/jobOwnerProfile",
                    name: "OwnerProfileData",
                    component: OwnerProfileData
                },
                //=========== Job Owner=============

                //=========== Job Owner Package=============
                {
                    path: "/packages",
                    name: "Packages",
                    component: Packages
                },
                {
                    path: "/myPackage",
                    name: "MyPackages",
                    component: MyPackages
                },
                //=========== Job Owner Package=============

                // ======= Settings Job Searcher
                {
                    path: "/editJobOwner",
                    component: OwnerSettings,
                    children: [{
                            path: "",
                            name: "EditOwnerPersonalData",
                            component: EditOwnerPersonalData
                        },
                        {
                            path: "/editOwnerPassword",
                            name: "EditOwnerPassword",
                            component: EditOwnerPassword
                        },
                    ]
                },
                // ======= Settings Job Owner

                // ======= Categories
                {
                    path: "/categories",
                    name: "Categories",
                    component: Categories
                },
                // ======= Categories

                // ======= Search
                {
                    path: "/jobs",
                    name: "Search",
                    component: Search
                },
                {
                    path: "/search/:text/:city",
                    name: "SearchResaults",
                    component: SearchResaults,
                    props: true
                },
                // ======= Search

                // ======= Create Cv
                {
                    path: "/createCv",
                    name: "CreateCv",
                    component: CreateCv
                },
                {
                    path: "/cvSteps",
                    name: "CvSteps",
                    component: CvSteps
                },
                {
                    path: "/editCv/:tempLang/:id",
                    name: "EditCv",
                    component: EditCv,
                    props: true
                },
                {
                    path: "/cvSteps/one/:tempLang/:id",
                    name: "CvTemplateOne",
                    component: CvTemplateOne,
                    props: true
                },
                {
                    path: "/cvSteps/two/:tempLang/:id",
                    name: "CvTemplateTwo",
                    component: CvTemplateTwo,
                    props: true
                },
                {
                    path: "/cvSteps/three/:tempLang/:id",
                    name: "CvTemplateThree",
                    component: CvTemplateThree,
                    props: true
                },
                {
                    path: "/cvSteps/four/:tempLang/:id",
                    name: "CvTemplateFour",
                    component: CvTemplateFour,
                    props: true
                },
                // ======= Create Cv

                // ======= Rate Cv
                {
                    path: "/rateCv",
                    name: "RateCv",
                    component: RateCv
                },
                // ======= Rate Cv

                // ======= Improve Cv
                {
                    path: "/improveCv",
                    name: "ImproveCv",
                    component: ImproveCv
                },
                // ======= Rate Cv

                // ======= Job Details
                {
                    path: "/categoriesJobs/:id",
                    name: "JobsByCategories",
                    component: JobsByCategories,
                    props: true,
                    children: []
                },
                {
                    path: "/cityJobs/:id",
                    name: "JobsByCity",
                    component: JobsByCity,
                    props: true,
                    children: []
                },
                {
                    path: "/jobDetails/:id",
                    name: "JobDetails",
                    component: JobDetails,
                    props: true
                },
                {
                    path: "/addJob",
                    name: "AddJob",
                    component: AddJob,
                },
                {
                    path: "/editOwnerJob/:id",
                    name: "EditOnwerJob",
                    component: EditOnwerJob,
                    props: true
                },
                //Add Employes
                {
                    path: "/addEmployes",
                    name: "AddEmployes",
                    component: AddEmployes,
                },
                // ======= Job Details

                // ======= Job Applies ========
                {
                    path: "/jobApplies/:id",
                    name: "JobApplies",
                    component: JobApplies,
                    props: true
                },
                // =======  Job Applies ========

                // ======= Applied Data ========
                {
                    path: "/appliedData/:id",
                    name: "AppliedData",
                    component: AppliedData,
                    props: true
                },
                // =======  Applied Data ========

                // ======= Featured Cv ========
                {
                    path: "/featuredCv",
                    name: "FeaturedCv",
                    component: FeaturedCv
                },
                // =======  Featured Cv ========

                // ======= Blogs ========
                //===Blogs
                {
                    path: "/blogs",
                    name: "Blogs",
                    component: Blogs
                },
                //===Blog Details
                {
                    path: "/blogDetails/:id",
                    name: "BlogDetails",
                    component: BlogDetails,
                    props: true
                },
                // =======  Blogs ========

                //===========Forget Password Cycle=============

                //Forget Password
                {
                    path: "/enterEmail",
                    name: "EnterEmail",
                    component: EnterEmail
                },
                {
                    path: "/checkCode",
                    name: "CheckCode",
                    component: CheckCode
                },
                {
                    path: "/resetPassword",
                    name: "ResetPassword",
                    component: ResetPassword
                }
                //===========Forget Password Cycle=============

            ],
        },

        // Auth
        {
            path: "/login",
            component: Auth,
            children: [{
                path: "/login",
                name: "Login",
                component: Login
            }]
        },

        // NotFound
        { path: "/:notFound(.*)", name: "NotFound", component: NotFound },
    ],
});

router.beforeEach((to, _2, next) => {
//   if (to.name !== "Login" && !store.getters["auth/token"]) {
//     next({ name: "Login" });
//   } else if (to.name == "Login" && store.getters["auth/token"]) {
//     next("/");
//   } else {
//     next();
//   }
  if (to.name == "AddJob" && !store.getters["auth_module/token"]) {
    next({ name: "Login" });
  } 
//   else if(to.name == 'HomePage' && store.getters["auth_module/usertype"] == 'company'){
//     next({path: "/jobOwner"})
//   } else if(to.name == 'HomePage' && store.getters["auth_module/usertype"] == 'user'){
//     next({path: "/jobSearcher"})
//   } 
  else {
    next();
  }
});

Vue.use(VueRouter);
export default router;