<template>
  <!--Start Strong Cv-->
  <section class="strong_cv Pad_50">
    <div class="layer"></div>
    <div class="container">
      <div class="row">
        <div class="col-md-4">
          <!--Image Part-->
          <div class="image">
            <img src="@/assets/images/strong_cv/img.png" />
          </div>
          <!--Image Part-->
        </div>
        <div class="col-md-8">
          <!--Text Part-->
          <div class="text">
            <!--Title-->
            <h4>
              {{ $t("strongCv.title") }}
              <span><img src="@/assets/images/strong_cv/num.png" /></span>
            </h4>
            <!--Title-->
            <!--Description-->
            <div class="desc">
              <p>{{ $t("strongCv.offerYou") }}</p>
              <ul>
                <li>
                  <i class="fas fa-check"></i>
                  <span>{{ $t("strongCv.list1") }}</span>
                </li>
                <li>
                  <i class="fas fa-check"></i>
                  <span>{{ $t("strongCv.list4") }}</span>
                </li>
                <li>
                  <i class="fas fa-check"></i>
                  <span>{{ $t("strongCv.list2") }}</span>
                </li>
                <li>
                  <i class="fas fa-check"></i>
                  <span>{{ $t("strongCv.list3") }}</span>
                </li>
              </ul>
            </div>
            <!--Description-->
            <!--Link-->
            <router-link to="/cvSteps" class="main_button go_now"
              ><span>{{ $t("strongCv.goNow") }}</span></router-link
            >
            <!--Link-->
          </div>
          <!--Text Part-->
        </div>
      </div>
    </div>
  </section>
  <!--End Strong Cv-->
</template>

<script>
export default {};
</script>

<style></style>
