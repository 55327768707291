<template>
  <div class="register_page">
    <!--Top Background-->
    <div class="top_background">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="title">
              <h4>{{ $t("settings.title") }}</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--Top Background-->

    <!--Edit Data-->
    <div class="profile_page">
      <div class="container">
        <div class="row">
          <div class="col-md-3">
            <!--Settings Tabs-->
            <div class="setting_tabs">
              <ul>
                <!--Tabs List-->
                <li class="tab_list">
                  <router-link to="/editJobOwner">{{
                    $t("settings.tabs.editPersonal")
                  }}</router-link>
                </li>
                <!--Tabs List-->

                <!--Tabs List-->
                <li class="tab_list">
                  <router-link to="/editOwnerPassword">{{
                    $t("settings.tabs.editPassword")
                  }}</router-link>
                </li>
                <!--Tabs List-->
              </ul>
            </div>
            <!--Settings Tabs-->
          </div>
          <div class="col-md-9">
            <!--Setting View-->
            <div class="setting_view">
              <transition name="slither" mode="out-in">
                <router-view></router-view>
              </transition>
            </div>
            <!--Setting View-->
          </div>
        </div>
      </div>
    </div>
    <!--Edit Data--->

    <!--Newsleter-->
    <newsleter></newsleter>
    <!--Newsleter-->
  </div>
</template>

<script>
import Newsleter from "@/components/HomePage/Newsleter.vue";
export default {
  components: { Newsleter },
  data() {
    return {};
  },
};
</script>

<style></style>
