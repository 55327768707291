import axios from "axios";
import store from "../../../store/index";

export default {
    // ============ Remove Step
    removeStep(context) {
        context.commit("remove_cv_step", payload.step);
    },

    // ============ Remove Id
    removeId(context) {
        context.commit("remove_cv_id", payload.id);
    },

    // ============ Add Step
    addStep(context, payload) {
        context.commit("set_cv_step", payload.step);
        // location.reload();
    },

    handleStep(context) {
        let cvStep = context.getters.cvStep;
        let defaultStep = context.getters.defaultStep;

        if (!cvStep) {
            cvStep = defaultStep;
            localStorage.setItem("SAS_CV_STEP", defaultStep);
            context.commit("set_cv_step", cvStep);
        } else {
            localStorage.setItem("SAS_CV_STEP", cvStep);
            context.commit("set_cv_step", cvStep);
        }
    },

    // ============ Add Id
    addId(context, payload) {
        context.commit("set_cv_id", payload.id);
        // location.reload();
    },
};