<template>
  <div>
    <!--HEADER-->
    <Header :class="{ head_back: $route.name == 'JobDetails' }"></Header>
    <!--HEADER-->
    <transition name="slither" mode="out-in">
      <router-view></router-view>
    </transition>
    <!--FOOTER-->
    <Footer></Footer>
    <!--FOOTER-->
    <a href="https://wa.me/00966500114568" target="_blank"
      ><div class="floating-button"><i class="fab fa-whatsapp icon wa"></i></div
    ></a>
    <button
      @click="toTop"
      class="scroll_top"
      :class="{ show_scroll: scrollPosition > 50 }"
    >
      <i class="far fa-angle-double-up"></i>
    </button>
  </div>
</template>

<script>
import Header from "../components/Layouts/TheHeader.vue";
import Footer from "../components/Layouts/TheFooter.vue";
export default {
  name: "Home",
  data() {
    return {
      //Sticky
      scrollPosition: null,
    };
  },
  components: {
    Header,
    Footer,
  },
  methods: {
    //Update Scroll
    updateScroll() {
      this.scrollPosition = window.scrollY;
    },
    toTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
  },
  mounted() {
    window.addEventListener("scroll", this.updateScroll);
  },
};
</script>
