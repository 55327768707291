<template>
  <div class="register_page">
    <!--Top Background-->
    <div class="top_background">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="title">
              <h4>{{ $t("blogs.title") }}</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--Top Background-->

    <!--Blogs-->
    <loader v-if="loading"></loader>
    <div class="blog_details" v-else>
      <div class="container">
        <div class="row">
          <div class="col-md-9">
            <!--Article Card-->
            <div class="article_card">
              <!--Image-->
              <div class="image">
                <img :src="allData.image" />
                <!--Text-->
                <div class="text">
                  <!-- <h4>اسرار المقابلات</h4> -->
                </div>
                <!--Text-->
              </div>
              <!--Image-->
              <!--Details-->
              <div class="details">
                <p class="question">
                  {{ allData.name }}
                </p>
                <p class="answer" v-html="allData.desc"></p>
                <span>{{ allData.created_at }}</span>
              </div>
              <!--Details-->
            </div>
            <!--Article Card-->
          </div>
          <div class="col-md-3">
            <!--Elan-->
            <!-- <div class="elan">
              <img src="@/assets/images/icons/elan.png" />
            </div> -->
            <!--Elan-->
          </div>
        </div>
      </div>
    </div>
    <!--Blogs-->

    <!--Newsleter-->
    <newsleter></newsleter>
    <!--Newsleter-->
  </div>
</template>

<script>
import Newsleter from "@/components/HomePage/Newsleter.vue";
export default {
  props: ["id"],
  components: { Newsleter },
  data() {
    return {
      allData: null,
      loading: false,
    };
  },
  methods: {
    //Get Data
    getData() {
      this.loading = true;
      this.$axios({
        method: "GET",
        url: "blog_details",
        params: { blog_id: this.id },
      }).then((res) => {
        this.loading = false;
        this.allData = res.data.data;
      });
    },
  },
  created() {
    this.getData();
  },
};
</script>

<style></style>
