export default {
    methods: {
        helper_checkIfInputIsEmpty(e) {
            let inputElement = e.target;
            if (inputElement.value.length > 0) {
                inputElement.classList.remove("empty");
            } else {
                inputElement.classList.add("empty");
            }
        },

        helper_togglePasswordVisibility(e) {
            let passwordElement = e.currentTarget.parentElement.children[2];
            let passwordTogglerBtn = e.currentTarget;
            if (passwordElement.type == "password") {
                passwordElement.type = "text";
                passwordTogglerBtn.classList.add("password_is_visible");
            } else if (passwordElement.type == "text") {
                passwordElement.type = "password";
                passwordTogglerBtn.classList.remove("password_is_visible");
            }
        },

        helper_validEmail: function(email) {
            var re =
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(email);
        },

        helper_filterSearch(value, search, item) {
            if (search == "all") return true;
            return value != null && search != null && value.indexOf(search) !== -1;
        },

        helper_containsObject(obj, arr, key) {
            console.log(arr);
            for (let i = 0; i < arr.length; i++) {
                if (arr[i].uniqeID == obj.uniqeID) {
                    console.log("the same element");
                } else {
                    console.log("not the same");
                }
                // if (arr[i][key] !== obj[key]) {
                //   return true;
                // }
            }
            // return false;
        },

        //========Input English And Space
        isLetter(e) {
            if (this.lang_global == 'en') {
                let char = String.fromCharCode(e.keyCode);
                if (/^[A-Za-z ]+$/.test(char)) {
                    return true;
                } else {
                    e.preventDefault();
                    this.$iziToast.error({
                        timeout: 2000,
                        message: this.$t("validation.appLang"),
                        position: "bottomRight",
                    });
                }
            } else {
                let char = String.fromCharCode(e.keyCode);
                if (/^[\u0621-\u064A ]+$/.test(char)) { return true; } else {
                    e.preventDefault();
                    this.$iziToast.error({
                        timeout: 2000,
                        message: this.$t("validation.appLang"),
                        position: "bottomRight",
                    });
                }
            }
        },

        isLetter2(e) {
            if (this.lang_global == 'en') {
                let char = String.fromCharCode(e.keyCode);
                if (/^[\u0621-\u064A ]+$/.test(char)) { return true; } else {
                    e.preventDefault();
                    this.$iziToast.error({
                        timeout: 2000,
                        message: this.$t("validation.appLang"),
                        position: "bottomRight",
                    });
                }
            } else {
                let char = String.fromCharCode(e.keyCode);
                if (/^[A-Za-z ]+$/.test(char)) {
                    return true;
                } else {
                    e.preventDefault();
                    this.$iziToast.error({
                        timeout: 2000,
                        message: this.$t("validation.appLang"),
                        position: "bottomRight",
                    });
                }
            }
        },
        //phone Max Length
        maxLength(e) {
            let inputElement = e.target;
            if (inputElement.value.length == 10) {
                return false;
            } else {
                return true
            }
        },
        //Block Char
        blockChar(){
            var inputBox = document.getElementById("inputBox");

var invalidChars = [
  "-",
  "+",
  "e",
];

inputBox.addEventListener("keydown", function(e) {
  if (invalidChars.includes(e.key)) {
    e.preventDefault();
  }
});
        }
    },
    computed: {
        lang_global() {
            return this.$store.getters["lang_module/lang"]
        }
    }
};