<template>
  <loader v-if="loading"></loader>
  <div class="form_inputs" v-else>
    <!--Title-->
    <h4>{{ $t("settings.data.title") }}</h4>
    <!--Title-->

    <!--Form-->
    <form @submit.prevent="saveData">
      <!--Wrapper Flex-->
      <div class="wrapper_flex profile_wrapper">
        <span class="profile_label">{{ $t("settings.data.specialist") }}</span>
        <!--Input Wrapper-->
        <div class="input_wrapper reg_style select">
          <!--Select Specialists-->
          <select
            class="form-control"
            v-model="data.specialist"
            @change="getSub()"
          >
            <option disabled value="">
              {{ $t("settings.data.specialist") }}
            </option>
            <option v-for="spe in specialists" :key="spe.id" :value="spe.id">
              {{ spe.name }}
            </option>
          </select>
          <!--Select Specialists-->
        </div>
        <!--Input Wrapper-->
      </div>
      <!--Wrapper Flex-->

      <!--Wrapper Flex-->
      <div class="wrapper_flex profile_wrapper">
        <span class="profile_label">{{
          $t("settings.data.subSpecialist")
        }}</span>
        <!--Input Wrapper-->
        <div class="input_wrapper reg_style select">
          <!--Select Sub Specialists-->
          <select class="form-control" v-model="data.subSpecialist">
            <option disabled value="">
              {{ $t("settings.data.subSpecialist") }}
            </option>
            <option v-for="sub in subSpecialists" :key="sub.id" :value="sub.id">
              {{ sub.name }}
            </option>
          </select>
          <!--Select Sub Specialists-->
        </div>
        <!--Input Wrapper-->
      </div>
      <!--Wrapper Flex-->

      <!--Wrapper Flex-->
      <div class="wrapper_flex profile_wrapper">
        <span class="profile_label">{{ $t("settings.data.experience") }}</span>
        <!--Input Wrapper-->
        <div class="input_wrapper reg_style">
          <!--Experience-->
          <input
            type="text"
            class="form-control"
            v-model="data.experience"
            :placeholder="$t('settings.data.experience')"
            pattern="/^-?\d+\.?\d*$/"
            onKeyPress="if(this.value.length == 2) {
                        return false;
                      }"
            min="0"
            oninput="
                        this.value =
                          !!this.value && Math.abs(this.value) >= 0
                            ? Math.abs(this.value)
                            : null
                      "
          />
          <!--Experience-->
        </div>
        <!--Input Wrapper-->
      </div>
      <!--Wrapper Flex-->

      <!--Wrapper Flex-->
      <div class="wrapper_flex profile_wrapper">
        <span class="profile_label">{{ $t("settings.data.image") }}</span>
        <!--Input Wrapper-->
        <div class="input_wrapper reg_style file_upload">
          <!--Upload Image-->
          <div class="form-control">
            <span v-if="data.beforeUpload">
              {{ $t("settings.data.image") }}</span
            >
            <span v-if="data.afterUpload && data.fromBegin">{{
              data.image
            }}</span>
            <span v-else>{{ data.image.name }}</span>
          </div>
          <input type="file" name="chooseFile" @change="uploadFile" />
          <!--Upload Image-->
        </div>
        <!--Input Wrapper-->
      </div>
      <!--Wrapper Flex-->

      <!--Wrapper Flex-->
      <div class="wrapper_flex profile_wrapper">
        <span class="profile_label">{{ $t("settings.data.cv") }}</span>
        <!--Input Wrapper-->
        <div class="input_wrapper reg_style file_upload">
          <!--Upload Cv-->
          <div class="form-control">
            <span v-if="data.beforeUpload2"> {{ $t("settings.data.cv") }}</span>
            <span v-if="data.afterUpload2 && data.fromBegin2">{{
              data.cv
            }}</span>
            <span v-else>{{ data.cv.name }}</span>
            <span class="icon"><i class="fal fa-file"></i></span>
          </div>
          <input type="file" name="chooseFile" @change="uploadCv" />
          <!--Upload Cv-->
        </div>
        <!--Input Wrapper-->
      </div>
      <!--Wrapper Flex-->

      <!--Wrapper Flex-->
      <div class="wrapper_flex profile_wrapper">
        <span class="profile_label">{{ $t("settings.data.about") }}</span>
        <!--Input Wrapper-->
        <div class="input_wrapper reg_style">
          <!--About Me-->
          <textarea
            class="form-control"
            v-model="data.about"
            :placeholder="$t('settings.data.about')"
          ></textarea>
          <!--About Me-->
        </div>
        <!--Input Wrapper-->
      </div>
      <!--Wrapper Flex-->

      <div class="Pad_50">
        <button
          type="button"
          class="main_button button"
          ref="save"
          @click="saveData()"
        >
          <i class="fas fa-spinner"></i>
          <span>حفظ التغيرات</span>
        </button>
      </div>
    </form>
    <!--Form-->
  </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";
export default {
  components: { Datepicker },
  data() {
    return {
      //Specialists
      specialists: [],
      //Specialists

      //Sub Specialists
      subSpecialists: [],
      //Sub Specialists

      //Experiences
      Experiences: [],
      //Experiences

      //Loader
      loading: false,
      //Loader

      //My Data
      myData: null,
      //My Data

      //Data
      data: {
        specialist: "",
        subSpecialist: "",
        experience: "",
        image: null,
        beforeUpload: true,
        afterUpload: false,
        fromBegin: false,
        cv: null,
        beforeUpload2: true,
        afterUpload2: false,
        fromBegin2: false,
        about: null,
      },
      //Data
    };
  },
  methods: {
    //Upload Image
    uploadFile(e) {
      this.data.image = e.target.files[0];
      this.data.beforeUpload = false;
      this.data.afterUpload = true;
      this.data.fromBegin = false;
    },
    //Upload Image

    //Upload Cv
    uploadCv(e) {
      this.data.cv = e.target.files[0];
      this.data.beforeUpload2 = false;
      this.data.afterUpload2 = true;
      this.data.fromBegin2 = false;
    },
    //Upload Cv

    //Get Data
    getData() {
      this.loading = true;
      //====Cities=======
      // this.$axios({
      //   method: "GET",
      //   url: "cities",
      // }).then((res) => {
      //   this.cities = res.data.data;
      // });
      //====Profile Data=======
      this.$axios({
        method: "GET",
        url: "profile",
        headers: {
          Authorization: "bearer" + localStorage.getItem("SAS_App_Token"),
        },
      }).then((res) => {
        this.loading = false;
        this.myData = res.data.data;
        this.data.specialist = this.myData.main_category.id;
        this.data.subSpecialist = this.myData.sub_category.id;
        this.data.experience = this.myData.experience_years;
        this.data.cv = this.myData.cv;
        this.data.image = this.myData.image;
        if (this.data.image) {
          this.data.beforeUpload = false;
          this.data.afterUpload = true;
          this.data.fromBegin = true;
        }
        if (this.data.cv != null) {
          this.data.beforeUpload2 = false;
          this.data.afterUpload2 = true;
          this.data.fromBegin2 = true;
        }
        this.data.about = this.myData.bio;
        this.getSub();
      });
      //====Main Category=======
      this.$axios({
        method: "GET",
        url: "categories",
      }).then((res) => {
        this.specialists = res.data.data;
      });
      //====Education Levels=======
      this.$axios({
        method: "GET",
        url: "education_levels",
      }).then((res) => {
        this.educationLevels = res.data.data;
      });
    },

    //====Sub Category=======
    getSub() {
      this.$axios({
        method: "GET",
        url: "subcategories?category_id=" + this.data.specialist,
      }).then((res) => {
        this.subSpecialists = res.data.data;
      });
    },

    //Save Data
    saveData() {
      var submitButton = this.$refs["save"];
      submitButton.classList.add("click");
      if (!this.data.specialist) {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.$iziToast.error({
            message: this.$t("settings.validation.specialist"),
          });
        }, 1000);
      } else if (!this.data.subSpecialist) {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.$iziToast.error({
            message: this.$t("settings.validation.subSpecialist"),
          });
        }, 1000);
      } else if (!this.data.experience) {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.$iziToast.error({
            message: this.$t("settings.validation.experience"),
          });
        }, 1000);
      }
      // else if (!this.data.image) {
      //   setTimeout(() => {
      //     submitButton.classList.remove("click");
      //     this.$iziToast.error({
      //       message: this.$t("settings.validation.image"),
      //     });
      //   }, 1000);
      // }
      else if (!this.data.cv) {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.$iziToast.error({
            message: this.$t("settings.validation.cv"),
          });
        }, 1000);
      } else if (!this.data.about) {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.$iziToast.error({
            message: this.$t("settings.validation.about"),
          });
        }, 1000);
      } else {
        let submit_data = new FormData();
        submit_data.append("categories_id", this.data.specialist);
        submit_data.append("sub_categories_id", this.data.subSpecialist);
        submit_data.append("experience_years", this.data.experience);
        submit_data.append("bio", this.data.about);
        if (!this.data.fromBegin) {
          submit_data.append("image", this.data.image);
        }
        if (!this.data.fromBegin2) {
          submit_data.append("cv", this.data.cv);
        }
        this.$axios({
          method: "post",
          url: "profile",
          data: submit_data,
          headers: {
            Authorization: "bearer" + localStorage.getItem("SAS_App_Token"),
          },
        })
          .then(() => {
            setTimeout(() => {
              submitButton.classList.remove("click");
              this.$iziToast.success({
                message: this.$t("global.editSuccess"),
              });
            }, 1000);
          })
          .catch((err) => {
            setTimeout(() => {
              submitButton.classList.remove("click");
              this.$iziToast.error({
                message: err.response.data.message,
              });
            }, 1000);
          });
      }
    },
  },
  created() {
    this.getData();
  },
};
</script>

<style></style>
