<template>
  <div class="not-found">
    <div class="image">
      <img src="@/assets/images/icons/not_found.svg" />
    </div>

    <h3 v-if="lang_global == 'en'">Data Not Found</h3>
    <h3 v-if="lang_global == 'ar'">لايوجد بيانات</h3>
  </div>
</template>
<script>
export default {};
</script>

<style lang="scss" scoped>
.not-found {
  width: 100%;
  text-align: center;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .image {
    position: relative;
    overflow: hidden;
    margin-bottom: 20px;
    img {
      width: 350px;
    }
  }
  h3 {
    font-size: 27px;
    color: #87ccaf;
    position: relative;
  }
}
</style>
