import axios from "axios";
import store from "../../../store/index";

export default {
    // ============ Remove Step
    removeStep(context) {
        context.commit("remove_rate_step", payload.step);
    },

    // ============ Remove Id
    removeId(context) {
        context.commit("remove_rate_id", payload.id);
    },

    // ============ Add Step
    addStep(context, payload) {
        context.commit("set_rate_step", payload.step);
        // location.reload();
    },

    // ============ Add Id
    addId(context, payload) {
        context.commit("set_rate_id", payload.id);
        // location.reload();
    },

    // ============ Add Id
    rateDetails(context, payload) {
        context.commit("set_rate_details", payload.details);
        context.commit("set_rate_edu", payload.edu);
        context.commit("set_rate_exp", payload.exp);
        // location.reload();
    },
};