<template>
  <div class="register_page">
    <!--Top Background-->
    <div class="top_background">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="title">
              <h4>{{ $t("packages.title") }}</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--Top Background-->

    <!--Packages-->
    <featured-package></featured-package>
    <!--Packages-->

    <!--Cv Steps-->
    <cv-steps></cv-steps>
    <!--Cv Steps-->

    <!--Latest Articles-->
    <latest-articles></latest-articles>
    <!--Latest Articles-->

    <!--Newsleter-->
    <newsleter></newsleter>
    <!--Newsleter-->
  </div>
</template>

<script>
import Newsleter from "@/components/HomePage/Newsleter.vue";
import FeaturedPackage from "../../../../components/HomePage/FeaturedPackage.vue";
import CvSteps from "../../../../components/HomePage/CvSteps.vue";
import LatestArticles from "../../../../components/HomePage/LatestArticles.vue";
export default {
  components: { Newsleter, FeaturedPackage, CvSteps, LatestArticles },
};
</script>

<style></style>
