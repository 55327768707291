<template>
  <div class="register_page">
    <!--Top Background-->
    <div class="top_background">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="title">
              <h4>
                {{ $t("enterCv") }}
                <span class="free">{{ $t("free") }}</span>
              </h4>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--Top Background-->
    <!--Register Wizard Form-->
    <div class="container">
      <div class="register_wizard_form">
        <div class="wizard_title">
          <h3>{{ $t("createCv.title") }}</h3>
        </div>
        <!--Desc-->
        <div class="desc mb-4">
          <p>{{ $t("createCv.desc") }}</p>
        </div>
        <!--Desc-->
        <!--Form Inputs-->
        <div class="form_inputs">
          <form>
            <div class="row align-items-center">
              <div class="col-md-6">
                <!--Input Wrapper-->
                <div class="input_wrapper reg_style">
                  <!--First Name-->
                  <input
                    type="text"
                    class="form-control"
                    v-model="data.firstName"
                    @keypress="isLetter($event)"
                    :placeholder="$t('register.data.firstName')"
                  />
                  <!--First Name-->
                </div>
                <!--Input Wrapper-->
              </div>
              <!-- 
              <div class="col-md-4">
                <!~~Input Wrapper~~>
                <div class="input_wrapper reg_style">
                  <!~~Last Name~~>
                  <input
                    type="text"
                    class="form-control"
                    v-model="data.fatherName"
                    :placeholder="$t('register.data.fatherName')"
                  />
                  <!~~Last Name~~>
                </div>
                <!~~Input Wrapper~~>
              </div> -->
              <div class="col-md-6">
                <!--Input Wrapper-->
                <div class="input_wrapper reg_style">
                  <!--Last Name-->
                  <input
                    type="text"
                    class="form-control"
                    v-model="data.lastName"
                    @keypress="isLetter($event)"
                    :placeholder="$t('register.data.lastName')"
                  />
                  <!--Last Name-->
                </div>
                <!--Input Wrapper-->
              </div>

              <div class="col-md-6">
                <!--Wrapper Flex-->
                <div class="wrapper_flex">
                  <!--Input Wrapper-->
                  <div class="input_wrapper reg_style code">
                    <!--Code-->
                    <span>+966</span>
                    <!--Code-->
                  </div>
                  <!--Input Wrapper-->

                  <!--Input Wrapper-->
                  <div class="input_wrapper reg_style phone">
                    <!--Phone-->
                    <input
                      type="number"
                      id="inputBox"
                      @keydown="blockChar"
                      class="form-control"
                      v-model="data.phone"
                      pattern="/^-?\d+\.?\d*$/"
                      onKeyPress="if(this.value.length == 9) {
                        return false;
                      }"
                      min="0"
                      :placeholder="$t('register.data.phone')"
                    />
                    <!--Phone-->
                  </div>
                  <!--Input Wrapper-->
                </div>
                <!--Wrapper Flex-->
              </div>

              <div class="col-md-6">
                <!--Input Wrapper-->
                <div class="input_wrapper reg_style">
                  <!--Email-->
                  <input
                    type="email"
                    class="form-control"
                    v-model="data.email"
                    :placeholder="$t('register.data.email')"
                  />
                  <!--Email-->
                </div>
                <!--Input Wrapper-->
              </div>

              <div class="col-md-6">
                <!--Input Wrapper-->
                <div class="input_wrapper reg_style select">
                  <!--Nationality-->
                  <select class="form-control" v-model="data.nationality">
                    <option disabled value="">
                      {{ $t("register.data.nationality") }}
                    </option>
                    <option
                      v-for="nation in nationality"
                      :key="nation.id"
                      :value="nation.id"
                    >
                      {{ nation.name }}
                    </option>
                  </select>
                  <!--Nationality-->
                </div>
                <!--Input Wrapper-->
              </div>

              <div class="col-md-6">
                <!--Input Wrapper-->
                <div class="input_wrapper reg_style select">
                  <!--Select Type-->
                  <select class="form-control" v-model="data.type">
                    <option disabled value="">
                      {{ $t("register.data.type") }}
                    </option>
                    <option
                      v-for="type in Types"
                      :key="type.id"
                      :value="type.value"
                    >
                      {{ type.name }}
                    </option>
                  </select>
                  <!--Select Type-->
                </div>
                <!--Input Wrapper-->
              </div>

              <div class="col-md-6">
                <!--Input Wrapper-->
                <div class="input_wrapper reg_style">
                  <!--Birth Date-->
                  <Datepicker
                    class="form-control"
                    :format="formatDate"
                    :disabledDates="disabledDates"
                    v-model="data.birthDate"
                    :placeholder="$t('settings.data.birthDate')"
                  ></Datepicker>
                  <!--Birth Date-->
                </div>
                <!--Input Wrapper-->
              </div>

              <div class="col-md-6">
                <!--Input Wrapper-->
                <div class="input_wrapper reg_style select">
                  <!--Select City-->
                  <select class="form-control" v-model="data.city">
                    <option disabled value="">
                      {{ $t("register.data.city") }}
                    </option>
                    <option
                      v-for="city in cities"
                      :key="city.id"
                      :value="city.id"
                    >
                      {{ city.name }}
                    </option>
                  </select>
                  <!--Select City-->
                </div>
                <!--Input Wrapper-->
              </div>

              <div class="col-md-6">
                <!--Input Wrapper-->
                <div class="input_wrapper reg_style select">
                  <!--Select Bachalor-->
                  <select class="form-control" v-model="data.bachalor">
                    <option disabled value="">
                      {{ $t("register.data.bachalor") }}
                    </option>
                    <option
                      v-for="item in bachalor"
                      :key="item.id"
                      :value="item.id"
                    >
                      {{ item.name }}
                    </option>
                  </select>
                  <!--Select Bachalor-->
                </div>
                <!--Input Wrapper-->
              </div>

              <div class="col-md-6">
                <!--Input Wrapper-->
                <div class="input_wrapper reg_style select">
                  <!--Select Specialists-->
                  <select
                    class="form-control"
                    v-model="data.specialist"
                    @change="getSub(), (data.subSpecialist = '')"
                  >
                    <option disabled value="">
                      {{ $t("register.data.specialist") }}
                    </option>
                    <option
                      v-for="spe in specialists"
                      :key="spe.id"
                      :value="spe.id"
                    >
                      {{ spe.name }}
                    </option>
                  </select>
                  <!--Select Specialists-->
                </div>
                <!--Input Wrapper-->
              </div>

              <div class="col-md-6">
                <!--Input Wrapper-->
                <div class="input_wrapper reg_style select">
                  <!--Select Sub Specialists-->
                  <select class="form-control" v-model="data.subSpecialist">
                    <option disabled value="">
                      {{ $t("register.data.subSpecialist") }}
                    </option>
                    <option
                      v-for="sub in subSpecialists"
                      :key="sub.id"
                      :value="sub.id"
                    >
                      {{ sub.name }}
                    </option>
                  </select>
                  <!--Select Sub Specialists-->
                </div>
                <!--Input Wrapper-->
              </div>

              <div class="col-md-6">
                <!--Input Wrapper-->
                <div class="input_wrapper reg_style">
                  <!--Select Experience-->
                  <input
                    class="form-control"
                    type="number"
                    v-model="data.experience"
                    :placeholder="$t('register.data.experience')"
                    pattern="/^-?\d+\.?\d*$/"
                    onKeyPress="if(this.value.length == 2) {
                        return false;
                      }"
                    min="0"
                    oninput="
                        this.value =
                          !!this.value && Math.abs(this.value) >= 0
                            ? Math.abs(this.value)
                            : null
                      "
                  />
                  <!--Select Experience-->
                </div>
                <!--Input Wrapper-->
              </div>

              <div class="col-md-6">
                <!--Input Wrapper-->
                <div class="input_wrapper reg_style file_upload">
                  <!--Upload Cv-->
                  <div class="form-control">
                    <span v-if="data.beforeUpload">
                      {{ $t("register.data.uploadCv") }}</span
                    >
                    <span v-if="data.afterUpload">{{
                      data.uploadCv.name
                    }}</span>
                    <span class="icon"><i class="fal fa-file"></i></span>
                  </div>
                  <input type="file" name="chooseFile" @change="uploadFile" />
                  <!--Upload Cv-->
                </div>
                <!--Input Wrapper-->
              </div>

              <div class="col-md-6">
                <div class="input_wrapper reg_style">
                  <span style="font-size: 14px">{{
                    $t("validation.cvUpload")
                  }}</span>
                </div>
              </div>

              <div class="col-md-12">
                <!--Input Wrapper-->
                <div class="input_wrapper reg_style">
                  <!--About Me-->
                  <textarea
                    class="form-control"
                    v-model="data.about"
                    :placeholder="$t('register.data.about')"
                    @keypress="isLetter($event)"
                  ></textarea>
                  <!--About Me-->
                </div>
                <!--Input Wrapper-->
              </div>
              <!--Cv Data-->

              <div class="col-sm-12 mt-5">
                <button
                  type="button"
                  class="main_button2 button register_btn"
                  @click="create()"
                  ref="create"
                >
                  <i class="fas fa-spinner"></i>
                  {{ $t("sign") }}
                </button>
              </div>
            </div>
          </form>
        </div>
        <!--Form Inputs-->
      </div>
    </div>
    <!--Register Wizard Form-->
  </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import * as moment from "moment/moment";
export default {
  data() {
    return {
      //Cities
      cities: [],
      //Cities

      //Specialists
      specialists: [],
      //Specialists

      //Sub Specialists
      subSpecialists: [],
      //Sub Specialists

      //Types
      Types: [
        {
          id: 1,
          name: this.$t("male"),
          value: "male",
        },
        {
          id: 2,
          name: this.$t("female"),
          value: "female",
        },
      ],
      //Types

      //Nationality
      nationality: [],
      //Nationality

      //Bachalor
      bachalor: [],
      //Bachalor

      //Experiences
      Experiences: [],
      //Experiences

      //Show Error
      showError: false,
      //Show Error

      //Disable Dates
      disabledDates: {
        from: new Date(Date.now() - 8640000),
        // from: new Date(Date.now() - 500 * 365 * 60 * 60 * 1000),
      },
      //Disable Dates

      //Data
      data: {
        firstName: null,
        fatherName: null,
        lastName: null,
        phone: null,
        nationality: "",
        type: "",
        city: "",
        email: null,
        birthDate: null,
        bachalor: "",
        specialist: "",
        subSpecialist: "",
        experience: "",
        about: null,
        uploadCv: null,
        afterUpload: false,
        beforeUpload: true,
      },
    };
  },
  methods: {
    //Upload Cv
    uploadFile(e) {
      this.data.uploadCv = e.target.files[0];
      this.data.beforeUpload = false;
      this.data.afterUpload = true;
    },
    //Upload Cv

    //Data Formate
    formatDate(date) {
      return moment(date).format("DD-MM-YYYY");
    },
    //Data Formate

    //Get Data
    getData() {
      //====Countries=======
      this.$axios({
        method: "GET",
        url: "countries",
      }).then((res) => {
        this.nationality = res.data.data;
      });
      //====Cities=======
      this.$axios({
        method: "GET",
        url: "cities",
      }).then((res) => {
        this.cities = res.data.data;
      });
      //====Main Category=======
      this.$axios({
        method: "GET",
        url: "categories",
      }).then((res) => {
        this.specialists = res.data.data;
      });
      //====Education Levels=======
      this.$axios({
        method: "GET",
        url: "education_levels",
      }).then((res) => {
        this.bachalor = res.data.data;
      });
    },

    //====Sub Category=======
    getSub() {
      this.$axios({
        method: "GET",
        url: "subcategories?category_id=" + this.data.specialist,
      }).then((res) => {
        this.subSpecialists = res.data.data;
      });
    },

    //Create
    create() {
      var submitButton = this.$refs["create"];
      submitButton.classList.add("click");
      if (!this.data.firstName) {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.$iziToast.error({
            message: this.$t("register.validation.firstName"),
          });
        }, 1000);
      } else if (!this.data.lastName) {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.$iziToast.error({
            message: this.$t("register.validation.lastName"),
          });
        }, 1000);
      } else if (!this.data.phone) {
        this.showError = true;
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.$iziToast.error({
            message: this.$t("register.validation.phone"),
          });
        }, 1000);
      } else if (!this.data.email) {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.$iziToast.error({
            message: this.$t("register.validation.email"),
          });
        }, 1000);
      } else if (!this.data.nationality) {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.$iziToast.error({
            message: this.$t("register.validation.nationality"),
          });
        }, 1000);
      } else if (!this.data.type) {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.$iziToast.error({
            message: this.$t("register.validation.type"),
          });
        }, 1000);
      } else if (!this.data.birthDate) {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.$iziToast.error({
            message: this.$t("register.validation.birthDate"),
          });
        }, 1000);
      } else if (!this.data.city) {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.$iziToast.error({
            message: this.$t("register.validation.city"),
          });
        }, 1000);
      } else if (!this.data.bachalor) {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.$iziToast.error({
            message: this.$t("register.validation.bachalor"),
          });
        }, 1000);
      } else if (!this.data.specialist) {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.$iziToast.error({
            message: this.$t("register.validation.specialist"),
          });
        }, 1000);
      } else if (!this.data.subSpecialist) {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.$iziToast.error({
            message: this.$t("register.validation.subSpecialist"),
          });
        }, 1000);
      } else if (!this.data.experience) {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.$iziToast.error({
            message: this.$t("register.validation.experience"),
          });
        }, 1000);
      } else {
        this.showError = false;
        let submit_data = new FormData();
        submit_data.append("first_name", this.data.firstName);
        submit_data.append("last_name", this.data.lastName);
        submit_data.append("city_id", this.data.city);
        submit_data.append("email", this.data.email);
        submit_data.append("phone", "+966" + this.data.phone);
        submit_data.append("date_birth", this.formatDate(this.data.birthDate));
        submit_data.append("user_type", "user");
        submit_data.append("categories_id", this.data.specialist);
        submit_data.append("sub_categories_id", this.data.subSpecialist);
        submit_data.append("experience_years", this.data.experience);
        submit_data.append("bio", this.data.about);
        submit_data.append("education_level_id", this.data.bachalor);
        submit_data.append("gender", this.data.type);
        submit_data.append("country_id", this.data.nationality);
        if (this.data.uploadCv) {
          submit_data.append("cv", this.data.uploadCv);
        }
        this.$axios({
          method: "post",
          url: "free_cv",
          data: submit_data,
        })
          .then(() => {
            setTimeout(() => {
              submitButton.classList.remove("click");
              this.$iziToast.success({
                message: this.$t("sendSuccess"),
              });
              this.$router.push({ path: "/" });
            }, 1000);
          })
          .catch((err) => {
            setTimeout(() => {
              submitButton.classList.remove("click");
              this.$iziToast.error({
                message: err.response.data.message,
              });
            }, 1000);
          });
      }
    },
  },
  components: {
    Datepicker,
  },
  created() {
    this.getData();
  },
};
</script>

<style></style>
