<template>
  <!--Start Featured Cv-->
  <section class="featured_cv Pad_50" v-if="item">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <!--Center Title-->
          <div class="center_title">
            <h3>{{ $t("featuredCv.homeTitle") }}</h3>
          </div>
          <!--Center Title-->
          <!--Slider-->
          <owl-slider
            :items="4"
            :autoplay="true"
            :loop="true"
            :nav="true"
            :dots="true"
            :margin="10"
            :autoplayHoverPause="true"
            :navText="[
              `<span class='fal fa-angle-right'></span>`,
              `<span class='fal fa-angle-left'></span>`,
            ]"
            :responsive="{
              0: { items: 1, nav: false },
              600: { items: 2, nav: true },
              1000: { items: 4, nav: true },
            }"
            dir="ltr"
            class="owl-cv"
          >
            <div class="item" v-for="item in item.cvs" :key="item.id">
              <cv-card :cv="item"></cv-card>
            </div>
          </owl-slider>
          <!--Slider-->
          <!--Bottom Text-->
          <div class="bottom_text">
            <p>{{ $t("featuredCv.nowFeat") }}</p>
            <router-link to="/featuredCv" class="main_button3"
              ><span>{{ $t("featuredCv.featNow") }}</span></router-link
            >
          </div>
          <!--Bottom Text-->
        </div>
      </div>
    </div>
  </section>
  <!--End Featured Cv-->
</template>

<script>
import CvCard from "../CvCard/CvCard.vue";
export default {
  props: ["item", "loading"],
  components: {
    CvCard,
  },
};
</script>

<style></style>
