export default {
    cvStep(state) {
        return state.cvStep;
    },
    defaultStep(state) {
        return state.defaultStep
    },
    cvId(state) {
        return state.cvId;
    },
};