<template>
  <div class="register_page">
    <!--Top Background-->
    <div class="top_background">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="title">
              <h4>{{ $t("about.title") }}</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--Top Background-->

    <!--About-->
    <div class="container">
      <loader v-if="loading"></loader>
      <!--All Text-->
      <div class="all_text" v-else>
        <div class="row">
          <div class="col-md-3">
            <div class="logo">
              <img src="@/assets/images/icons/foot_logo.png" />
            </div>
          </div>
          <div class="col-md-9">
            <!--Text Content-->
            <div class="text_content">
              <p v-html="allData.about"></p>
            </div>
            <!--Text Content-->
          </div>
        </div>
      </div>
      <!--All Text-->
    </div>
    <!--About-->

    <!--Newsleter-->
    <newsleter></newsleter>
    <!--Newsleter-->
  </div>
</template>

<script>
import Newsleter from "@/components/HomePage/Newsleter.vue";
export default {
  data() {
    return {
      //All Data
      allData: null,
      //Loading
      loading: false,
    };
  },
  methods: {
    //Get Data
    getData() {
      this.loading = true;
      this.$axios({
        method: "GET",
        url: "about",
      }).then((res) => {
        this.loading = false;
        this.allData = res.data.data;
      });
    },
    //Get Data
  },
  created() {
    this.getData();
  },
  components: { Newsleter },
};
</script>

<style></style>
