import mutations from "./mutations.js";
import actions from "./actions.js";
import getters from "./getters.js";

export default {
    namespaced: true,
    state() {
        return {
            token: localStorage.getItem("SAS_App_Token"),
            usertype: localStorage.getItem("SAS_App_User_Type"),
            userName: localStorage.getItem("SAS_App_User_Name"),
            user_id: localStorage.getItem("SAS_App_User_Id"),
            userImage: localStorage.getItem("SAS_App_userImg"),
            userPhone: localStorage.getItem("SAS_App_userPhone"),
            devMessage: localStorage.getItem("SAS_App_devMessage"),
            email: localStorage.getItem("SAS_App_Email"),
            havePackage: localStorage.getItem("SAS_App_Package"),
            expPackage: localStorage.getItem("SAS_App_expPackage"),
        };
    },
    mutations,
    actions,
    getters,
};