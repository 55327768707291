<template>
  <div class="register_page">
    <!--Top Background-->
    <div class="top_background">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="title">
              <h4>{{ $t("blogs.title") }}</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--Top Background-->

    <!--Blogs-->
    <div class="blogs_page">
      <div class="container">
        <div class="row">
          <div class="col-md-4">
            <!--Article Card-->
            <router-link to="/blogDetails/1">
              <div class="article_card">
                <!--Image-->
                <div class="image">
                  <img src="@/assets/images/latest_articles/img1.png" />
                  <!--Text-->
                  <div class="text">
                    <h4>اسرار المقابلات</h4>
                  </div>
                  <!--Text-->
                </div>
                <!--Image-->
                <!--Details-->
                <div class="details">
                  <p class="question">
                    ماهي اسرار المقابلات التي تعمل على خلف بيئة مريحة في
                    المقابلة والتي تعمل على إعطائك جزء كبير من الراحة النفسية
                    وتملك الشخصية؟
                  </p>
                  <p class="answer">
                    تخيّلي أن رئيسك بالعمل أرسل لك ليلا بريدا إلكترونيا بخصوص
                    مهمة غير واضحة، ثم رسا
                  </p>
                  <span>06-Oct-2020</span>
                </div>
                <!--Details-->
              </div>
            </router-link>
            <!--Article Card-->
          </div>
          <div class="col-md-4">
            <!--Article Card-->
            <router-link to="/blogDetails/1">
              <div class="article_card">
                <!--Image-->
                <div class="image">
                  <img src="@/assets/images/latest_articles/img1.png" />
                  <!--Text-->
                  <div class="text">
                    <h4>اسرار المقابلات</h4>
                  </div>
                  <!--Text-->
                </div>
                <!--Image-->
                <!--Details-->
                <div class="details">
                  <p class="question">
                    ماهي اسرار المقابلات التي تعمل على خلف بيئة مريحة في
                    المقابلة والتي تعمل على إعطائك جزء كبير من الراحة النفسية
                    وتملك الشخصية؟
                  </p>
                  <p class="answer">
                    تخيّلي أن رئيسك بالعمل أرسل لك ليلا بريدا إلكترونيا بخصوص
                    مهمة غير واضحة، ثم رسا
                  </p>
                  <span>06-Oct-2020</span>
                </div>
                <!--Details-->
              </div>
            </router-link>
            <!--Article Card-->
          </div>
          <div class="col-md-4">
            <!--Article Card-->
            <router-link to="/blogDetails/1">
              <div class="article_card">
                <!--Image-->
                <div class="image">
                  <img src="@/assets/images/latest_articles/img1.png" />
                  <!--Text-->
                  <div class="text">
                    <h4>اسرار المقابلات</h4>
                  </div>
                  <!--Text-->
                </div>
                <!--Image-->
                <!--Details-->
                <div class="details">
                  <p class="question">
                    ماهي اسرار المقابلات التي تعمل على خلف بيئة مريحة في
                    المقابلة والتي تعمل على إعطائك جزء كبير من الراحة النفسية
                    وتملك الشخصية؟
                  </p>
                  <p class="answer">
                    تخيّلي أن رئيسك بالعمل أرسل لك ليلا بريدا إلكترونيا بخصوص
                    مهمة غير واضحة، ثم رسا
                  </p>
                  <span>06-Oct-2020</span>
                </div>
                <!--Details-->
              </div>
            </router-link>
            <!--Article Card-->
          </div>
          <div class="col-md-4">
            <!--Article Card-->
            <router-link to="/blogDetails/1">
              <div class="article_card">
                <!--Image-->
                <div class="image">
                  <img src="@/assets/images/latest_articles/img1.png" />
                  <!--Text-->
                  <div class="text">
                    <h4>اسرار المقابلات</h4>
                  </div>
                  <!--Text-->
                </div>
                <!--Image-->
                <!--Details-->
                <div class="details">
                  <p class="question">
                    ماهي اسرار المقابلات التي تعمل على خلف بيئة مريحة في
                    المقابلة والتي تعمل على إعطائك جزء كبير من الراحة النفسية
                    وتملك الشخصية؟
                  </p>
                  <p class="answer">
                    تخيّلي أن رئيسك بالعمل أرسل لك ليلا بريدا إلكترونيا بخصوص
                    مهمة غير واضحة، ثم رسا
                  </p>
                  <span>06-Oct-2020</span>
                </div>
                <!--Details-->
              </div>
            </router-link>
            <!--Article Card-->
          </div>
          <div class="col-md-4">
            <!--Article Card-->
            <router-link to="/blogDetails/1">
              <div class="article_card">
                <!--Image-->
                <div class="image">
                  <img src="@/assets/images/latest_articles/img1.png" />
                  <!--Text-->
                  <div class="text">
                    <h4>اسرار المقابلات</h4>
                  </div>
                  <!--Text-->
                </div>
                <!--Image-->
                <!--Details-->
                <div class="details">
                  <p class="question">
                    ماهي اسرار المقابلات التي تعمل على خلف بيئة مريحة في
                    المقابلة والتي تعمل على إعطائك جزء كبير من الراحة النفسية
                    وتملك الشخصية؟
                  </p>
                  <p class="answer">
                    تخيّلي أن رئيسك بالعمل أرسل لك ليلا بريدا إلكترونيا بخصوص
                    مهمة غير واضحة، ثم رسا
                  </p>
                  <span>06-Oct-2020</span>
                </div>
                <!--Details-->
              </div>
            </router-link>
            <!--Article Card-->
          </div>
          <div class="col-md-4">
            <!--Article Card-->
            <router-link to="/blogDetails/1">
              <div class="article_card">
                <!--Image-->
                <div class="image">
                  <img src="@/assets/images/latest_articles/img1.png" />
                  <!--Text-->
                  <div class="text">
                    <h4>اسرار المقابلات</h4>
                  </div>
                  <!--Text-->
                </div>
                <!--Image-->
                <!--Details-->
                <div class="details">
                  <p class="question">
                    ماهي اسرار المقابلات التي تعمل على خلف بيئة مريحة في
                    المقابلة والتي تعمل على إعطائك جزء كبير من الراحة النفسية
                    وتملك الشخصية؟
                  </p>
                  <p class="answer">
                    تخيّلي أن رئيسك بالعمل أرسل لك ليلا بريدا إلكترونيا بخصوص
                    مهمة غير واضحة، ثم رسا
                  </p>
                  <span>06-Oct-2020</span>
                </div>
                <!--Details-->
              </div>
            </router-link>
            <!--Article Card-->
          </div>
        </div>
      </div>
    </div>
    <!--Blogs-->

    <!--Newsleter-->
    <newsleter></newsleter>
    <!--Newsleter-->
  </div>
</template>

<script>
import Newsleter from "@/components/HomePage/Newsleter.vue";
export default {
  components: { Newsleter },
};
</script>

<style></style>
