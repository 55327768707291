<template>
  <div class="row justify-content-center">
    <div class="col-md-12">
      <div
        class="cv_template_1 en"
        v-if="item"
        style="font-family: 'font_regular' !important"
      >
        <!--Right Part-->
        <div class="right_back" :style="{ backgroundColor: `#87ccaf` }">
          <!--Cv User Data-->
          <div class="cv_user_data">
            <!--Image-->
            <div class="user_image">
              <img :src="item.image" />
            </div>
            <!--Image-->

            <!--Name-->
            <div class="user_name">
              <h4>{{ item.name_en }}</h4>
            </div>
            <!--Name-->

            <!--Job Title-->
            <div class="job_title">
              <h4>{{ item.job_title_en }}</h4>
            </div>
            <!--Job Title-->

            <!--User Breif-->
            <div class="user_breif">
              <!--Title-->
              <div class="cv_title1">
                <h4>About Me</h4>
              </div>
              <!--Title-->

              <!--Desc-->
              <div class="desc">
                <p>
                  {{ item.desc_en }}
                </p>
              </div>
              <!--Desc-->
            </div>
            <!--User Breif-->

            <!--User Contact-->
            <div class="user_contact">
              <!--Title-->
              <div class="cv_title1">
                <h4>Contact</h4>
              </div>
              <!--Title-->

              <!--Contact Info-->
              <ul class="contact_info">
                <!--List Item-->
                <li style="list-style: none">
                  <span>Phone</span>
                  <p>{{ item.phone }}</p>
                </li>
                <!--List Item-->

                <!--List Item-->
                <li style="list-style: none">
                  <span>Email Address</span>
                  <p>{{ item.email }}</p>
                </li>
                <!--List Item-->

                <!--List Item-->
                <li style="list-style: none">
                  <span>Address</span>
                  <p>{{ item.address_en }}</p>
                </li>
                <!--List Item-->
              </ul>
              <!--Contact Info-->
            </div>
            <!--User Contact-->

            <!--User Skills-->
            <div class="user_skills">
              <!--Title-->
              <div class="cv_title1">
                <h4>Skills</h4>
              </div>
              <!--Title-->
              <!--Skill List-->
              <ul>
                <li
                  style="list-style: none"
                  v-for="skill in item.skills"
                  :key="skill.id"
                >
                  <span>{{ skill.name_en }}</span>
                </li>
              </ul>
              <!--Skill List-->
            </div>
            <!--User Skills-->
          </div>
          <!--Cv User Data-->
        </div>
        <!--Right Part-->

        <!--Left Part-->
        <div class="left_back">
          <!--Work Experience-->
          <div class="work_experience">
            <!--Title-->
            <div class="cv_title2">
              <h4>Work Experience</h4>
            </div>
            <!--Title-->
            <!--Template Details-->
            <div
              class="template_details"
              v-for="exp in item.experiences"
              :key="exp.id"
            >
              <!--Top Details-->
              <div class="top_details">
                <!--Title-->
                <div class="title">
                  <h4>{{ exp.name_en }}</h4>
                </div>
                <!--Title-->

                <!--Date-->
                <div class="date">
                  <span>{{ exp.start_date.substring(6, 10) }}-</span>
                  <span v-if="Boolean(!exp.is_still)">{{
                    exp.end_date.substring(6, 10)
                  }}</span>
                  <span v-if="Boolean(exp.is_still)">Untill Now</span>
                </div>
                <!--Date-->
              </div>
              <!--Top Details-->

              <!--Bottom Details-->
              <div class="bottom_details">
                <!--Job Title-->
                <div class="job_tit">
                  <h4>{{ exp.job_title_en }}</h4>
                </div>
                <!--Job Title-->

                <!--Desc-->
                <div class="desc">
                  <p>
                    {{ exp.desc_en }}
                  </p>
                </div>
                <!--Desc-->
              </div>
              <!--Bottom Details-->
            </div>
            <!--Template Details-->

            <!--             <!~~Template Details~~>
            <div class="template_details">
              <!~~Top Details~~>
              <div class="top_details">
                <!~~Title~~>
                <div class="title">
                  <h4>اسم الشركة</h4>
                </div>
                <!~~Title~~>

                <!~~Date~~>
                <div class="date">
                  <span>2015-2018</span>
                </div>
                <!~~Date~~>
              </div>
              <!~~Top Details~~>

              <!~~Bottom Details~~>
              <div class="bottom_details">
                <!~~Job Title~~>
                <div class="job_tit">
                  <h4>المسمى الوظيفي</h4>
                </div>
                <!~~Job Title~~>

                <!~~Desc~~>
                <div class="desc">
                  <p>
                    هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم
                    توليد هذا النص من مولد النص العربى، حيث يمكنك أن تولد مثل
                    هذا النص أو العديد من النصوص الأخرى إضافة إلى زيادة عدد
                    الحروف التى يولدها التطبيق. هذا النص هو مثال لنص يمكن أن
                    يستبدل ف
                  </p>
                </div>
                <!~~Desc~~>
              </div>
              <!~~Bottom Details~~>
            </div>
            <!~~Template Details~~>

            <!~~Template Details~~>
            <div class="template_details">
              <!~~Top Details~~>
              <div class="top_details">
                <!~~Title~~>
                <div class="title">
                  <h4>اسم الشركة</h4>
                </div>
                <!~~Title~~>

                <!~~Date~~>
                <div class="date">
                  <span>2015-2018</span>
                </div>
                <!~~Date~~>
              </div>
              <!~~Top Details~~>

              <!~~Bottom Details~~>
              <div class="bottom_details">
                <!~~Job Title~~>
                <div class="job_tit">
                  <h4>المسمى الوظيفي</h4>
                </div>
                <!~~Job Title~~>

                <!~~Desc~~>
                <div class="desc">
                  <p>
                    هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم
                    توليد هذا النص من مولد النص العربى، حيث يمكنك أن تولد مثل
                    هذا النص أو العديد من النصوص الأخرى إضافة إلى زيادة عدد
                    الحروف التى يولدها التطبيق. هذا النص هو مثال لنص يمكن أن
                    يستبدل ف
                  </p>
                </div>
                <!~~Desc~~>
              </div>
              <!~~Bottom Details~~>
            </div>
            <!~~Template Details~~> -->
          </div>
          <!--Work Experience-->

          <!--Education-->
          <div class="education">
            <!--Title-->
            <div class="cv_title2">
              <h4>Education</h4>
            </div>
            <!--Title-->
            <!--Template Details-->
            <div
              class="template_details"
              v-for="edu in item.education"
              :key="edu.id"
            >
              <!--Top Details-->
              <div class="top_details">
                <!--Title-->
                <div class="title">
                  <h4>{{ edu.name_en }}</h4>
                </div>
                <!--Title-->

                <!--Date-->
                <div class="date">
                  <span>{{ edu.start_date.substring(6, 10) }}-</span>
                  <span v-if="Boolean(!edu.is_still)">{{
                    edu.end_date.substring(6, 10)
                  }}</span>
                  <span v-if="Boolean(edu.is_still)">Untill Now</span>
                </div>
                <!--Date-->
              </div>
              <!--Top Details-->

              <!--Bottom Details-->
              <div class="bottom_details">
                <!--Department-->
                <div class="job_tit">
                  <h4>{{ edu.section_title_en }}</h4>
                </div>
                <!--Department-->

                <!--Desc-->
                <div class="desc">
                  <p>
                    {{ edu.desc_en }}
                  </p>
                </div>
                <!--Desc-->
              </div>
              <!--Bottom Details-->
            </div>
            <!--Template Details-->
          </div>
          <!--Education-->
        </div>
        <!--Left Part-->
      </div>

      <!--Change Template Color-->
      <!-- <div class="template_color" id="temco">
        <ul>
          <li v-for="color in colors" :key="color.color">
            <button
              @click="changeColor(color.color)"
              type="button"
              :style="{ background: color.color }"
            ></button>
          </li>
        </ul>
      </div> -->
      <!--Change Template Color-->
    </div>
  </div>
</template>

<script>
export default {
  props: ["item"],
  data() {
    return {
      //Template Color
      templateColor: "",
      //Template Color

      //Colors
      colors: [
        {
          color: "#DFDFDF",
        },
        {
          color: "#0EB6D8",
        },
        {
          color: "#87CCAF",
        },
        {
          color: "#CEFFB1",
        },
        {
          color: "#E4A8F3",
        },
      ],
      //Colors
    };
  },
  methods: {
    //Change Color
    changeColor(color) {
      this.templateColor = color;
    },
  },
  mounted() {
    this.changeColor(this.colors[0].color);
  },
};
</script>

<style></style>
