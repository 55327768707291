<template>
  <div class="register_page">
    <!--Top Background-->
    <div class="top_background">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="title">
              <!-- <h4>{{ $t("search.title") }}</h4> -->
              <h4>{{ $t("search.allJobs") }}</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--Top Background-->

    <!--Categories-->
    <div class="search_page">
      <div class="container">
        <div class="row">
          <div class="col-lg-3">
            <!--Search Sidebar-->
            <div class="search_sidebar">
              <!--Sidebar Item-->
              <div class="sidebar_item">
                <h4>{{ $t("jobFilter.category") }}</h4>
                <ul>
                  <li v-for="cat in categories" :key="cat.id">
                    <input
                      type="radio"
                      name="cat"
                      :id="'c' + cat.id"
                      :value="cat.id"
                      v-model="filterData.category"
                      @change="getJobs()"
                    />
                    <label :for="'c' + cat.id">{{ cat.name }}</label>
                  </li>
                </ul>
              </div>
              <!--Sidebar Item-->

              <!--Sidebar Item-->
              <div class="sidebar_item">
                <h4>{{ $t("jobFilter.region") }}</h4>
                <ul>
                  <li v-for="city in cities" :key="city.id">
                    <input
                      type="radio"
                      name="reg"
                      :id="'r' + city.id"
                      :value="city.id"
                      v-model="filterData.city"
                      @change="getJobs()"
                    />
                    <label :for="'r' + city.id">{{ city.name }}</label>
                  </li>
                </ul>
              </div>
              <!--Sidebar Item-->

              <!--Sidebar Item-->
              <div class="sidebar_item">
                <h4>{{ $t("jobFilter.postDate") }}</h4>
                <ul>
                  <li>
                    <input
                      type="radio"
                      name="date"
                      id="d1"
                      value="1"
                      v-model="filterData.date"
                    />
                    <label for="d1">{{ $t("jobFilter.day") }}</label>
                  </li>
                  <li>
                    <input
                      type="radio"
                      name="date"
                      id="d2"
                      value="7"
                      v-model="filterData.date"
                    />
                    <label for="d2">{{ $t("jobFilter.week") }}</label>
                  </li>
                  <li>
                    <input
                      type="radio"
                      name="date"
                      id="d3"
                      value="30"
                      v-model="filterData.date"
                    />
                    <label for="d3">{{ $t("jobFilter.month") }}</label>
                  </li>
                  <li>
                    <input
                      type="radio"
                      name="date"
                      id="d4"
                      value="60"
                      v-model="filterData.date"
                    />
                    <label for="d4">{{ $t("jobFilter.2months") }}</label>
                  </li>
                </ul>
              </div>
              <!--Sidebar Item-->
            </div>
            <!--Search Sidebar-->
          </div>
          <div class="col-lg-9">
            <loader v-if="loading"></loader>
            <template v-else>
              <not-found v-if="jobs.length == 0"></not-found>
              <div v-else>
                <job-card
                  v-for="job in jobs"
                  :key="job.id"
                  :job="job"
                ></job-card>
              </div>
            </template>
          </div>
        </div>
      </div>
      <strong-cv></strong-cv>
      <newsleter></newsleter>
    </div>
    <!--Categories--->
  </div>
</template>

<script>
import Newsleter from "@/components/HomePage/Newsleter.vue";
import StrongCv from "../../../components/HomePage/StrongCv.vue";
import JobCard from "@/components/JobCard/JobCard2.vue";
export default {
  components: {
    Newsleter,
    StrongCv,
    JobCard,
  },
  data() {
    return {
      //START:: Filter Data
      filterData: {
        category: null,
        city: null,
        date: null,
      },
      //END:: Filter Data

      //START:: LOADING
      loading: false,
      //END:: LOADING

      //START:: CATEGORIES
      categories: [],
      //END:: CATEGORIES

      //START:: CITIES
      cities: [],
      //END:: CITIES

      //START:: JOBS
      jobs: [],
      //END:: JOBS
    };
  },
  methods: {
    getData() {
      //====Cities=======
      this.$axios({
        method: "GET",
        url: "cities",
      }).then((res) => {
        this.cities = res.data.data;
      });
      //====Main Category=======
      this.$axios({
        method: "GET",
        url: "categories",
      }).then((res) => {
        this.categories = res.data.data;
      });
    },
    //=====Get Jobs
    getJobs() {
      this.loading = true;
      this.$axios({
        method: "GET",
        url: "works",
        params: {
          city_id: this.filterData.city,
          category_id: this.filterData.category,
          date: this.filterData.date,
        },
      }).then((res) => {
        this.loading = false;
        this.jobs = res.data.data;
      });
    },
  },
  created() {
    this.getData();
    this.getJobs();
  },
};
</script>

<style></style>
