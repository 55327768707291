<template>
  <!--Job Card Details-->
  <div class="job_card_details" v-if="job">
    <!--Head Details-->
    <div class="head_details">
      <!--Text Details-->
      <div class="text_details">
        <h4>{{ job.name_ar }}</h4>
        <span class="date">{{ job.created_at }}</span>
        <p v-if="job.city">{{ job.city.name }}</p>
        <!--Head Actions-->
        <div class="head_actions">
          <!--Apply To Job-->
          <button
            @click="applayJob()"
            type="button"
            class="main_button"
            v-if="job.is_join == false && userType != 'company'"
          >
            <span>{{ $t("applayJob") }}</span>
          </button>
          <!--Applay To Job-->
          <router-link
            :to="'/jobApplies/' + job.id"
            class="main_button"
            v-if="userId == job.company.id"
          >
            <span>{{ $t("jobDetails.showApplies") }}</span>
          </router-link>
          <!--Listed Buttons-->
          <ul class="list_btns">
            <li v-if="userId == job.company.id">
              <router-link :to="'/editOwnerJob/' + job.id"
                ><i class="fal fa-edit"></i
              ></router-link>
            </li>
            <li v-if="userId == job.company.id">
              <button @click="deleteJob" type="button">
                <i class="fal fa-trash-alt"></i>
              </button>
            </li>
            <li>
              <button type="button" @click="showShare">
                <i class="far fa-share-alt"></i>
              </button>
            </li>
          </ul>
          <ul class="list_btns" v-if="share">
            <li>
              <ShareNetwork
                network="facebook"
                :url="link"
                :title="job.name_ar"
                hashtags="sas"
              >
                <button class="btn"><i class="fab fa-facebook-f"></i></button>
              </ShareNetwork>
            </li>
            <li>
              <ShareNetwork
                network="twitter"
                :url="link"
                :title="job.name_ar"
                hashtags="sas"
              >
                <button class="btn">
                  <i class="fab fa-twitter"></i>
                </button>
              </ShareNetwork>
            </li>
            <li>
              <ShareNetwork
                network="LinkedIn"
                :url="link"
                :title="job.name_ar"
                hashtags="sas"
              >
                <button class="btn">
                  <i class="fab fa-linkedin"></i>
                </button>
              </ShareNetwork>
            </li>
            <li>
              <ShareNetwork
                network="WhatsApp"
                :url="link"
                :title="job.name_ar"
                hashtags="sas"
              >
                <button class="btn">
                  <i class="fab fa-whatsapp"></i>
                </button>
              </ShareNetwork>
            </li>
          </ul>
          <!--Listed Buttons-->
        </div>
        <!--Head Actions-->
      </div>
      <!--Text Details-->
      <!--Image-->
      <div class="image_details" v-if="job.company.image">
        <img :src="job.company.image" />
      </div>
    </div>
    <!--Head Details-->

    <!--Exp Details-->
    <div class="exp_details">
      <h4>{{ $t("jobDetails.desc") }}</h4>
      <!--Exp Desc-->
      <div class="exp_desc">
        <!--Desc Item-->
        <div class="desc_item">
          <div class="desc">
            <h5>{{ $t("jobDetails.category") }}</h5>
            <span v-if="job.category">{{ job.category.name }}</span>
            <span v-else>{{ $t("notFound") }}</span>
          </div>
          <div class="desc">
            <h5>{{ $t("jobDetails.subCategory") }}</h5>
            <span v-if="job.sub_category">{{ job.sub_category.name }}</span>
            <span v-else>{{ $t("notFound") }}</span>
          </div>
          <div class="desc">
            <h5>{{ $t("jobDetails.jobFor") }}</h5>
            <span>{{
              job.work_for == "resident"
                ? $t("jobDetails.resident")
                : job.work_for == "saudi"
                ? $t("jobDetails.saudi")
                : $t("jobDetails.both")
            }}</span>
          </div>
        </div>
        <!--Desc Item-->

        <!--Desc Item-->
        <div class="desc_item">
          <div class="desc">
            <h5>{{ $t("jobDetails.gender") }}</h5>
            <span>{{
              job.gender == "male"
                ? $t("jobDetails.male")
                : job.gender == "female"
                ? $t("jobDetails.female")
                : $t("jobDetails.bothGender")
            }}</span>
          </div>
          <div class="desc">
            <h5>{{ $t("jobDetails.experience") }}</h5>
            <span>{{ job.experience_years + " " + $t("years") }} </span>
          </div>
          <div class="desc">
            <h5>{{ $t("jobDetails.salary") }}</h5>
            <span>{{
              job.has_salary == 1
                ? job.salary + " " + $t("riyal")
                : $t("jobDetails.unLimit")
            }}</span>
          </div>
        </div>
        <!--Desc Item-->
      </div>
      <!--Exp Desc-->
      <!--Text-->
      <div class="text">
        <p>
          {{ job.desc_ar }}
        </p>
      </div>
      <!--Text-->
    </div>
    <!--Exp Details-->
  </div>
  <!--Job Card Details-->
</template>

<script>
export default {
  metaInfo: {
    title: "اعلان وظيفي جديد من ساس الذكيه لتقنية المعلومات",
    titleTemplate: "%s - Sas Smart",
    meta: [
      { charset: "utf-8" },
      {
        name: "description",
        content: "اعلان وظيفي جديد من ساس الذكيه لتقنية المعلومات",
      },
      { name: "viewport", content: "width=device-width, initial-scale=1" },
    ],
  },
  props: ["job"],
  emits: ["getData"],
  computed: {
    userId() {
      return this.$store.getters["auth_module/set_userId"];
    },
    userType() {
      return this.$store.getters["auth_module/usertype"];
    },
    token() {
      return this.$store.getters["auth_module/token"];
    },
    myMeta() {
      return this.job.name_ar;
    },
  },
  data() {
    return {
      link: window.location.href,
      share: false,
    };
  },
  methods: {
    showShare() {
      this.share = !this.share;
    },
    applayJob() {
      if (!this.token) {
        setTimeout(() => {
          this.$iziToast.error({
            message: this.$t("register.validation.loginFrist"),
          });
          this.$router.push("/login");
        }, 1000);
      } else {
        let submit_data = new FormData();
        submit_data.append("work_id", this.job.id);
        this.$axios({
          method: "post",
          url: "client/join_work",
          data: submit_data,
          headers: {
            Authorization: "bearer" + localStorage.getItem("SAS_App_Token"),
          },
        })
          .then(() => {
            setTimeout(() => {
              this.$iziToast.success({
                message: this.$t("excuteSuccess"),
              });
            }, 1000);
            this.$emit("getData");
          })
          .catch((err) => {
            setTimeout(() => {
              this.$iziToast.error({
                message: err.response.data.message,
              });
            }, 1000);
          });
      }
    },
    deleteJob() {
      this.$axios({
        method: "delete",
        url: "company/work/" + this.job.id,
      }).then(() => {
        setTimeout(() => {
          this.$iziToast.success({
            message: this.$t("deleteSuccess"),
          });
          this.$router.push("/jobOwnerProfile");
        }, 1000);
      });
    },
  },
};
</script>

<style></style>
