export default {
    // ============================= SET RATE STEP
    set_rate_step(state, payload) {
        localStorage.setItem("SAS_RATE_STEP", payload);
        state.rateStep = payload;
    },

    // ============================= SET RATE ID
    set_rate_id(state, payload) {
        localStorage.setItem("SAS_RATE_ID", payload);
        state.rateId = payload;
    },

    // ============================= SET RATE DETAILS
    set_rate_details(state, payload) {
        localStorage.setItem("SAS_RATE_DETAILS", payload);
        state.details = payload;
    },

    // ============================= SET RATE EDU
    set_rate_edu(state, payload) {
        localStorage.setItem("SAS_RATE_EDU", payload);
        state.edu = payload;
    },

    // ============================= SET RATE EDU
    set_rate_exp(state, payload) {
        localStorage.setItem("SAS_RATE_EXP", payload);
        state.exp = payload;
    },

    // ============================= Remove Rate Step
    remove_rate_step(state) {
        state.rateStep = "";
        localStorage.removeItem("SAS_RATE_STEP");
    },

    // ============================= Remove Rate Details
    remove_rate_details(state) {
        state.details = "";
        localStorage.removeItem("SAS_RATE_DETAILS");
    },

    // ============================= Remove Rate Edu
    remove_rate_edu(state) {
        state.edu = "";
        localStorage.removeItem("SAS_RATE_EDU");
    },

    // ============================= Remove Rate Exp
    remove_rate_exp(state) {
        state.exp = "";
        localStorage.removeItem("SAS_RATE_EXP");
    },

    // ============================= Remove Rate Id
    remove_rate_id(state) {
        state.rateId = "";
        localStorage.removeItem("SAS_RATE_ID");
    },
};