<template>
  <div class="row justify-content-center">
    <div class="col-md-10">
      <div class="cv_template_4" v-if="item">
        <!--Right Part-->
        <div class="right_back" :style="{ backgroundColor: `#fff` }">
          <!--Cv User Data-->
          <div class="cv_user_data">
            <!--Image-->
            <div class="user_image">
              <img :src="item.image" />
            </div>
            <!--Image-->

            <!--Education-->
            <div class="education">
              <!--Title-->
              <div class="cv_title2">
                <h4>التعليم</h4>
              </div>
              <!--Title-->
              <!--Template Details-->
              <div
                class="template_details"
                v-for="edu in item.education"
                :key="edu.id"
              >
                <!--Top Details-->
                <div class="top_details">
                  <!--Date-->
                  <div class="date">
                    <span>{{ formatDate(edu.start_date) }}-</span>
                    <span v-if="Boolean(!edu.is_still)">{{
                      formatDate(edu.end_date)
                    }}</span>
                    <span v-if="Boolean(edu.is_still)">حتى الان</span>
                  </div>
                  <!--Date-->

                  <!--Title-->
                  <div class="title">
                    <h4>{{ edu.name }}</h4>
                  </div>
                  <!--Title-->
                </div>
                <!--Top Details-->

                <!--Bottom Details-->
                <div class="bottom_details">
                  <!--Department-->
                  <div class="job_tit">
                    <h4>{{ edu.section_title }}</h4>
                  </div>
                  <!--Department-->

                  <!--Desc-->
                  <div class="desc">
                    <p>
                      {{ edu.desc }}
                    </p>
                  </div>
                  <!--Desc-->
                </div>
                <!--Bottom Details-->
              </div>
              <!--Template Details-->
            </div>
            <!--Education-->

            <!--User Skills-->
            <div class="user_skills">
              <!--Title-->
              <div class="cv_title1">
                <h4>المهارات</h4>
              </div>
              <!--Title-->
              <!--Skill List-->
              <ul>
                <li
                  style="list-style: none"
                  v-for="skill in item.skills"
                  :key="skill.id"
                >
                  <span>{{ skill.name }}</span>
                </li>
              </ul>
              <!--Skill List-->
            </div>
            <!--User Skills-->
          </div>
          <!--Cv User Data-->
        </div>
        <!--Right Part-->

        <!--Left Part-->
        <div class="left_back">
          <!--Work Experience-->
          <div class="work_experience">
            <!--Name-->
            <div class="user_name">
              <h4>{{ item.name }}</h4>
            </div>
            <!--Name-->

            <!--Job Title-->
            <div class="job_title">
              <h4>{{ item.job_title }}</h4>
            </div>
            <!--Job Title-->

            <!--User Contact-->
            <div class="user_contact">
              <!--Contact Info-->
              <ul class="contact_info">
                <!--List Item-->
                <li style="list-style: none">
                  <p>{{ item.phone }} <i class="fal-fa-phone"></i></p>
                </li>
                <!--List Item-->

                <!--List Item-->
                <li style="list-style: none">
                  <p>{{ item.email }} <i class="fal-fa-envelope"></i></p>
                </li>
                <!--List Item-->

                <!--List Item-->
                <li style="list-style: none">
                  <p>
                    {{ item.address }} <i class="fal-fa-map-marker-alt"></i>
                  </p>
                </li>
                <!--List Item-->
              </ul>
              <!--Contact Info-->
            </div>
            <!--User Contact-->

            <!--User Breif-->
            <div class="user_breif">
              <!--Title-->
              <div class="cv_title1">
                <h4>المقدمة</h4>
              </div>
              <!--Title-->

              <!--Desc-->
              <div class="desc">
                <p>
                  {{ item.desc }}
                </p>
              </div>
              <!--Desc-->
            </div>
            <!--User Breif-->

            <!--Title-->
            <div class="cv_title1">
              <h4>الخبرات</h4>
            </div>
            <!--Title-->
            <!--Template Details-->
            <div
              class="template_details"
              v-for="exp in item.experiences"
              :key="exp.id"
            >
              <!--Top Details-->
              <div class="top_details">
                <!--Title-->
                <div class="title">
                  <h4>{{ exp.name }}</h4>
                </div>
                <!--Title-->

                <!--Date-->

                <div class="date">
                  <span>{{ formatDate(exp.start_date) }}-</span>
                  <span v-if="Boolean(!exp.is_still)">{{
                    formatDate(exp.end_date)
                  }}</span>
                  <span v-if="Boolean(exp.is_still)">حتى الان</span>
                </div>
                <!--Date-->
              </div>
              <!--Top Details-->

              <!--Bottom Details-->
              <div class="bottom_details">
                <!--Job Title-->
                <div class="job_tit">
                  <h4>{{ exp.job_title }}</h4>
                </div>
                <!--Job Title-->

                <!--Desc-->
                <div class="desc">
                  <p>
                    {{ exp.desc }}
                  </p>
                </div>
                <!--Desc-->
              </div>
              <!--Bottom Details-->
            </div>
          </div>
          <!--Work Experience-->
        </div>
        <!--Left Part-->
      </div>

      <!--Change Template Color-->
      <!-- <div class="template_color" id="temco">
          <ul>
            <li v-for="color in colors" :key="color.color">
              <button
                @click="changeColor(color.color)"
                type="button"
                :style="{ background: color.color }"
              ></button>
            </li>
          </ul>
        </div> -->
      <!--Change Template Color-->
    </div>
  </div>
</template>

<script>
import * as moment from "moment/moment";
export default {
  props: ["item"],
  data() {
    return {
      //Template Color
      templateColor: "",
      //Template Color

      //Colors
      colors: [
        {
          color: "#DFDFDF",
        },
        {
          color: "#0EB6D8",
        },
        {
          color: "#87CCAF",
        },
        {
          color: "#CEFFB1",
        },
        {
          color: "#E4A8F3",
        },
      ],
      //Colors
    };
  },
  methods: {
    //Change Color
    changeColor(color) {
      this.templateColor = color;
    },
    formatDate(date) {
      return moment(date).format("YYYY");
    },
  },
  mounted() {
    this.changeColor(this.colors[0].color);
  },
};
</script>

<style></style>
