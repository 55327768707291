<template>
  <div class="register_page">
    <!--Top Background-->
    <div class="top_background">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="title">
              <h4>{{ $t("profile.applidTitle") }}</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--Top Background-->

    <!--Profile Data-->
    <div class="container">
      <div class="row">
        <div class="col-md-9">
          <!--  Profile Card-->
          <loader v-if="loading"></loader>
          <div class="profile_card" v-else>
            <!--Personal Data-->
            <div class="personal_data">
              <div class="row">
                <div class="col-lg-6">
                  <!--Personal Content-->
                  <div class="personal_content">
                    <!--Data Info-->
                    <div class="data_info">
                      <h4>{{ $t("profile.name") }}</h4>
                      <span>{{
                        myData.first_name + " " + myData.last_name
                      }}</span>
                    </div>
                    <!--Data Info-->

                    <!--Data Info-->
                    <div class="data_info">
                      <h4>{{ $t("profile.email") }}</h4>
                      <span>{{ myData.email }}</span>
                    </div>
                    <!--Data Info-->

                    <!--Data Info-->
                    <div class="data_info">
                      <h4>{{ $t("profile.experience") }}</h4>
                      <span>{{
                        myData.experience_years + " " + $t("years")
                      }}</span>
                    </div>
                    <!--Data Info-->

                    <!--Data Info-->
                    <div class="data_info">
                      <h4>{{ $t("profile.gender") }}</h4>
                      <span>{{
                        myData.gender == "male" ? $t("male") : $t("female")
                      }}</span>
                    </div>
                    <!--Data Info-->

                    <!--Data Info-->
                    <!-- <div class="data_info">
                          <h4>الشخص المفوض</h4>
                          <span>عبد الرحمن محمد</span>
                        </div> -->
                    <!--Data Info-->
                  </div>
                  <!--Personal Content-->
                </div>
                <div class="col-lg-6">
                  <!--Personal Content-->
                  <div class="personal_content">
                    <!--Data Info-->
                    <div class="data_info">
                      <h4>{{ $t("profile.region") }}</h4>
                      <span v-if="myData.city">{{ myData.city.name }}</span>
                    </div>
                    <!--Data Info-->

                    <!--Data Info-->
                    <div class="data_info">
                      <h4>{{ $t("profile.phone") }}</h4>
                      <span>{{ myData.phone }}</span>
                    </div>
                    <!--Data Info-->

                    <!--Data Info-->
                    <div class="data_info">
                      <h4>{{ $t("profile.birthDate") }}</h4>
                      <span>{{ myData.date_birth }}</span>
                    </div>
                    <!--Data Info-->
                  </div>
                  <!--Personal Content-->
                </div>
                <div class="col-lg-12">
                  <!--Personal Content-->
                  <div class="personal_content">
                    <!--Data Info-->
                    <div class="data_info">
                      <h4>{{ $t("profile.cv") }}</h4>
                      <span v-if="lang_global == 'ar'"
                        ><a :href="myData.cv" target="_blank"
                          >مشاهدة السيرة الذاتية</a
                        ></span
                      >
                      <span v-if="lang_global == 'en'"
                        ><a :href="myData.cv" target="_blank">See Cv</a></span
                      >
                    </div>
                    <!--Data Info-->
                    <!--Data Info-->
                    <div class="data_info">
                      <h4>{{ $t("profile.bio") }}</h4>
                      <span>{{ myData.bio }}</span>
                    </div>
                    <!--Data Info-->
                  </div>
                  <!--Personal Content-->
                </div>
              </div>
            </div>
            <!--Personal Data-->
          </div>
          <!--Profile Card-->
        </div>
        <div class="col-md-3">
          <!--Elan-->
          <!-- <div class="elan">
            <img src="@/assets/images/icons/elan.png" />
          </div> -->
          <!--Elan-->
        </div>
      </div>
    </div>
    <!--Profile Data--->

    <!--Newsleter-->
    <newsleter></newsleter>
    <!--Newsleter-->
  </div>
</template>

<script>
import Newsleter from "@/components/HomePage/Newsleter.vue";
export default {
  props: ["id"],
  components: { Newsleter },
  data() {
    return {
      //My Data
      myData: null,
      //My Data

      //Loading
      loading: false,
      //Loading
    };
  },
  methods: {
    getData() {
      this.loading = true;
      //Get Profile
      this.$axios({
        method: "GET",
        url: "company/user_details",
        params: { id: this.id },
        headers: {
          Authorization: "bearer" + localStorage.getItem("SAS_App_Token"),
        },
      }).then((res) => {
        this.loading = false;
        this.myData = res.data.data;
      });
      //Get Profile
    },
  },
  created() {
    this.getData();
    var pdf = new jsPDF();
    var img = new Image();
    img.onload = function () {
      pdf.addImage(this, 10, 10);
      pdf.save("test.pdf");
    };
    img.crossOrigin = "";
    img.src = "@/assets/images/featured_cv/2.png";
  },
};
</script>

<style></style>
