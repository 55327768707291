<template>
  <!-- Start Header -->
  <header class="header" :class="{ is_sticky: scrollPosition > 50 }">
    <!--Navbar-->
    <nav class="navbar navbar-expand-lg">
      <div class="container">
        <!--Logo-->
        <router-link class="navbar-brand" to="/"
          ><img src="@/assets/images/icons/logo.png"
        /></router-link>
        <!--Logo-->
        <div class="test_mob">
          <span>{{ $t("test_mode") }}</span>
        </div>
        <!--Button Collapse Sho In Mobile-->
        <button
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
          @click="showNavbar"
        >
          <span class="navbar-toggler-icon"><i class="fas fa-bars"></i></span>
        </button>
        <!--Button Collapse Sho In Mobile-->
        <!--Navbar Container-->
        <div class="navbar-collapse" :class="{ show: showNav }">
          <!--Navbar Menu-->
          <ul class="navbar-nav">
            <li
              class="nav-item"
              v-if="userType != 'company'"
              @click="HideNavbar"
            >
              <router-link class="nav-link" to="/jobs"
                >{{ $t("navbar.exploreJobs") }}
                <!-- <span class="tooltip">{{ $t("navbar.exploreJobs") }}</span> -->
              </router-link>
            </li>
            <li class="nav-item have_dropdown" v-if="!token">
              <button
                class="nav-link"
                @click="showMenu(), hideMenu2(), hideMenu3()"
              >
                {{ $t("navbar.workServices") }}
                <span class="icon"><i class="fal fa-angle-down"></i></span>
              </button>
              <transition name="fadeIn" mode="in-out">
                <div class="dropdown_menu" v-if="show">
                  <ul>
                    <li
                      @click="
                        (show = false),
                          (show2 = false),
                          (show3 = false),
                          HideNavbar()
                      "
                    >
                      <router-link to="/packages">
                        <span class="text">{{
                          $t("navbar.browsePackage")
                        }}</span>
                        <span class="icon"
                          ><i class="fal fa-angle-left"></i
                        ></span>
                      </router-link>
                    </li>
                    <hr class="line" />
                    <li
                      @click="
                        (show = false),
                          (show2 = false),
                          (show3 = false),
                          HideNavbar()
                      "
                    >
                      <router-link to="/addJob">
                        <span class="text">{{ $t("navbar.addadver") }}</span>
                        <span class="icon"
                          ><i class="fal fa-angle-left"></i
                        ></span>
                      </router-link>
                    </li>
                    <hr class="line" />
                    <li
                      @click="
                        (show = false),
                          (show2 = false),
                          (show3 = false),
                          HideNavbar()
                      "
                    >
                      <router-link to="/addEmployes">
                        <span class="text">{{
                          $t("navbar.requestEmployee")
                        }}</span>
                        <span class="icon"
                          ><i class="fal fa-angle-left"></i
                        ></span>
                      </router-link>
                    </li>
                  </ul>
                </div>
              </transition>
            </li>
            <li
              class="nav-item have_dropdown style2"
              v-if="userType != 'company'"
            >
              <button
                class="nav-link"
                @click="showMenu2(), hideMenu(), hideMenu3()"
              >
                {{ $t("navbar.cvServices") }}
                <span class="icon"><i class="fal fa-angle-down"></i></span>
              </button>
              <transition name="fadeIn" mode="in-out">
                <div class="dropdown_menu" v-if="show2">
                  <ul>
                    <li
                      @click="
                        (show = false),
                          (show2 = false),
                          (show3 = false),
                          HideNavbar()
                      "
                    >
                      <router-link to="/createCv">
                        <span class="text">{{ $t("navbar.addCv") }}</span>
                        <span class="icon"
                          ><i class="fal fa-angle-left"></i
                        ></span>
                      </router-link>
                    </li>
                    <hr class="line" />
                    <li
                      @click="
                        (show = false),
                          (show2 = false),
                          (show3 = false),
                          HideNavbar()
                      "
                    >
                      <router-link to="/cvSteps">
                        <span class="text">{{ $t("navbar.createCv") }}</span>
                        <span class="icon"
                          ><i class="fal fa-angle-left"></i
                        ></span>
                      </router-link>
                    </li>
                    <hr class="line" />
                    <li
                      @click="
                        (show = false),
                          (show2 = false),
                          (show3 = false),
                          HideNavbar()
                      "
                    >
                      <router-link to="/rateCv">
                        <span class="text">{{ $t("navbar.rateCv") }}</span>
                        <span class="icon"
                          ><i class="fal fa-angle-left"></i
                        ></span>
                      </router-link>
                    </li>
                    <hr class="line" />
                    <li
                      @click="
                        (show = false),
                          (show2 = false),
                          (show3 = false),
                          HideNavbar()
                      "
                    >
                      <router-link to="/improveCv">
                        <span class="text">{{ $t("navbar.cvPath") }}</span>
                        <span class="icon"
                          ><i class="fal fa-angle-left"></i
                        ></span>
                      </router-link>
                    </li>
                    <hr class="line" />
                    <li
                      @click="
                        (show = false),
                          (show2 = false),
                          (show3 = false),
                          HideNavbar()
                      "
                    >
                      <router-link to="/featuredCv">
                        <span class="text">{{ $t("navbar.featCv") }}</span>
                        <span class="icon"
                          ><i class="fal fa-angle-left"></i
                        ></span>
                      </router-link>
                    </li>
                  </ul>
                </div>
              </transition>
            </li>
            <li
              class="nav-item"
              v-if="token && userType == 'user'"
              @click="HideNavbar()"
            >
              <router-link class="nav-link" to="/jobSearcherProfile">{{
                $t("navbar.settings")
              }}</router-link>
            </li>
            <template v-if="token && userType == 'company'">
              <li class="nav-item" @click="HideNavbar()">
                <router-link class="nav-link" to="/packages">{{
                  $t("navbar.packages")
                }}</router-link>
              </li>
              <li class="nav-item" @click="HideNavbar()">
                <router-link class="nav-link" to="/addEmployes">{{
                  $t("navbar.requestEmployee")
                }}</router-link>
              </li>
              <li class="nav-item" @click="HideNavbar()">
                <router-link class="nav-link" to="/addJob">{{
                  $t("navbar.addJob")
                }}</router-link>
              </li>
              <!-- <li class="nav-item" v-if="token && userType == 'company'">
              <router-link class="nav-link" to="/">{{
                $t("navbar.myJobs")
              }}</router-link>
            </li> -->
              <li class="nav-item" @click="HideNavbar()">
                <router-link class="nav-link" to="/myPackage">{{
                  $t("navbar.myPackages")
                }}</router-link>
              </li>
              <li class="nav-item" @click="HideNavbar()">
                <router-link class="nav-link" to="/jobOwnerProfile">{{
                  $t("navbar.settings")
                }}</router-link>
              </li>
            </template>
          </ul>
          <!--Navbar Menu-->
          <!--Login And Search Job Part-->
          <div class="login_part">
            <ul class="login_list">
              <template v-if="!token">
                <!--Login Link-->
                <li class="login" @click="HideNavbar">
                  <router-link to="/login"
                    >{{ $t("navbar.login") }}
                    <span class="icon"><i class="fal fa-sign-in"></i></span
                  ></router-link>
                </li>
                <!--Login Link-->
                <!--Search Job Link-->
                <li class="job_serach" @click="HideNavbar">
                  <router-link class="main_button3" to="/jobSearch"
                    ><span>{{ $t("navbar.jobSearch") }}</span></router-link
                  >
                </li>
                <!--Search Job Link-->
                <!--Search Job Owner-->
                <li class="job_owner" @click="HideNavbar">
                  <router-link class="main_button" to="/jobOwner"
                    ><span>{{ $t("navbar.jobOwner") }}</span></router-link
                  >
                </li>
                <!--Search Job Owner-->
              </template>
              <template v-else>
                <!--User Profile Icon-->
                <li class="profile_icon">
                  <div class="img">
                    <img :src="userImage" />
                  </div>
                  <div class="text">
                    <p>
                      <span>{{ $t("welcome") }}</span> {{ userName }}
                    </p>
                  </div>
                </li>
                <!--User Profile Icon-->
              </template>
              <!--Burger Menu-->
              <li class="burger have_dropdown">
                <button type="button">
                  <i
                    class="fas fa-bars"
                    @click="showMenu3(), hideMenu(), hideMenu2()"
                  ></i>
                </button>
                <transition name="fadeIn" mode="in-out">
                  <div class="dropdown_menu" v-if="show3">
                    <ul>
                      <li
                        @click="
                          (show = false),
                            (show2 = false),
                            (show3 = false),
                            HideNavbar()
                        "
                      >
                        <router-link to="/contactUs">
                          <span class="text">{{ $t("navbar.contactUs") }}</span>
                          <span class="icon"
                            ><i class="fal fa-angle-left"></i
                          ></span>
                        </router-link>
                      </li>
                      <hr class="line" />
                      <li
                        @click="
                          (show = false),
                            (show2 = false),
                            (show3 = false),
                            HideNavbar()
                        "
                      >
                        <router-link to="/about">
                          <span class="text">{{ $t("navbar.about") }}</span>
                          <span class="icon"
                            ><i class="fal fa-angle-left"></i
                          ></span>
                        </router-link>
                      </li>
                      <hr class="line" />
                      <li
                        @click="
                          (show = false),
                            (show2 = false),
                            (show3 = false),
                            HideNavbar()
                        "
                      >
                        <router-link to="/blogs">
                          <span class="text">{{ $t("navbar.blogs") }}</span>
                          <span class="icon"
                            ><i class="fal fa-angle-left"></i
                          ></span>
                        </router-link>
                      </li>
                      <hr class="line" />
                      <!-- <li>
                        <router-link to="">
                          <span class="text">{{ $t("navbar.terms") }}</span>
                          <span class="icon"
                            ><i class="fal fa-angle-left"></i
                          ></span>
                        </router-link>
                      </li>
                      <hr class="line" /> -->
                      <li v-if="token">
                        <button @click="logOut">
                          <span class="text">{{ $t("navbar.logout") }}</span>
                          <span class="icon"
                            ><i class="fal fa-angle-left"></i
                          ></span>
                        </button>
                      </li>
                      <hr class="line" />
                      <li>
                        <span class="text">{{ $t("navbar.followOn") }}</span>
                        <ul class="social_media">
                          <li class="snap">
                            <a href=""><i class="fab fa-snapchat-ghost"></i></a>
                          </li>
                          <li class="linkedin">
                            <a href=""><i class="fab fa-linkedin"></i></a>
                          </li>
                          <li class="twitter">
                            <a href=""><i class="fab fa-twitter"></i></a>
                          </li>
                          <li class="facebook">
                            <a href=""><i class="fab fa-facebook-f"></i></a>
                          </li>
                          <li class="instagram">
                            <a href=""><i class="fab fa-instagram"></i></a>
                          </li>
                          <li class="youtube">
                            <a href=""><i class="fab fa-youtube"></i></a>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                </transition>
              </li>
              <!--Burger Menu-->
              <!--Lang-->
              <li class="lang">
                <button
                  v-if="lang_global == 'ar'"
                  type="button"
                  @click="changeLang"
                >
                  En
                </button>
                <button
                  v-if="lang_global == 'en'"
                  type="button"
                  @click="changeLang"
                >
                  العربية
                </button>
              </li>
              <!--Lang-->
              <li class="test">
                <span>{{ $t("test_mode") }}</span>
              </li>
            </ul>
          </div>
          <!--Login And Search Job Part-->
          <!--Hide Navbar-->
          <button class="close_menu" @click="HideNavbar">
            <i class="fas fa-times"></i>
          </button>
          <!--Hide Navbar-->
        </div>
        <!--Navbar Container-->
      </div>
    </nav>
    <!--Start Overlay-->
    <transition name="fadeIn" mode="in-out">
      <div
        class="overlay"
        @click="hideMenu(), hideMenu2(), hideMenu3()"
        v-if="show || show2 || show3"
      ></div>
    </transition>
    <!--End Overlay-->
  </header>
  <!--End Header-->
</template>

<script>
export default {
  data() {
    return {
      //Sticky
      scrollPosition: null,
      //Show Menu
      show: false,
      //Show Menu
      //Show Menu2
      show2: false,
      //Show Menu2
      //Show Menu3
      show3: false,
      //Show Menu3
      //Show Navbar
      showNav: false,
      //Show Navbar
    };
  },
  methods: {
    //Update Scroll
    updateScroll() {
      this.scrollPosition = window.scrollY;
    },
    //Show Menu
    showMenu() {
      this.show = true;
    },
    //Show Menu

    //Show Menu2
    showMenu2() {
      this.show2 = true;
    },
    //Show Menu2

    //Show Menu3
    showMenu3() {
      this.show3 = true;
    },
    //Show Menu3

    //Show Navbar
    showNavbar() {
      this.showNav = true;
    },
    //Show Navbar

    //Hide Menu
    hideMenu() {
      this.show = false;
    },
    //Hide Menu

    //Hide Menu2
    hideMenu2() {
      this.show2 = false;
    },
    //Hide Menu2

    //Hide Menu3
    hideMenu3() {
      this.show3 = false;
    },
    //Hide Menu3
    //Hide Navbar
    HideNavbar() {
      this.showNav = false;
      this.show = false;
      this.show2 = false;
      this.show3 = false;
    },
    //Hide Navbar
    //Close Menu When Body Click
    close(e) {
      if (!this.$el.contains(e.target)) {
        this.show = false;
        this.show2 = false;
        this.show3 = false;
      }
    },
    //Logout
    logOut() {
      this.$store.dispatch("auth_module/logOut");
    },
    changeLang() {
      this.$store.dispatch("lang_module/switchLang");
    },
  },
  mounted() {
    document.addEventListener("click", this.close);
    window.addEventListener("scroll", this.updateScroll);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.close);
  },
  computed: {
    token() {
      return this.$store.getters["auth_module/token"];
    },
    userImage() {
      return this.$store.getters["auth_module/userImage"];
    },
    userName() {
      return this.$store.getters["auth_module/userName"];
    },
    userType() {
      return this.$store.getters["auth_module/usertype"];
    },
  },
};
</script>

<style lang="scss" scoped></style>
