<template>
  <!--Start Our Clients-->
  <loader v-if="loading"></loader>
  <div class="our_clients" v-else>
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-3">
          <div class="container">
            <!--Text-->
            <div class="text">
              <h4>{{ $t("trustedCustomers.title") }}</h4>
            </div>
          </div>
          <!--Text-->
        </div>
        <div class="col-md-9">
          <!--Clients Slider-->
          <owl-slider
            :items="5"
            :autoplay="true"
            :loop="true"
            :nav="false"
            :dots="false"
            :margin="10"
            dir="ltr"
            :responsive="{
              0: { items: 1, nav: false },
              700: { items: 3, nav: false },
              1000: { items: 7, nav: false },
            }"
          >
            <div
              class="item"
              v-for="customer in item.customers"
              :key="customer.id"
            >
              <div class="client_item">
                <img :src="customer.image" />
              </div>
            </div>
            <!-- <div class="item">
              <div class="client_item">
                <img src="@/assets/images/our_clients/img2.png" />
              </div>
            </div>
            <div class="item">
              <div class="client_item">
                <img src="@/assets/images/our_clients/img3.png" />
              </div>
            </div>
            <div class="item">
              <div class="client_item">
                <img src="@/assets/images/our_clients/img1.png" />
              </div>
            </div>
            <div class="item">
              <div class="client_item">
                <img src="@/assets/images/our_clients/img2.png" />
              </div>
            </div> -->
          </owl-slider>
          <!--Clients Slider-->
        </div>
        <div class="col-md-4">
          <div class="container">
            <!--Text-->
            <div class="text">
              <p v-html="item.trusted_customers_desc"></p>
            </div>
            <!--Text-->
          </div>
        </div>
        <div class="col-md-8">
          <!--Clients Slider-->
          <owl-slider
            :items="4"
            :autoplay="true"
            :loop="true"
            :nav="false"
            :dots="false"
            dir="ltr"
            :margin="10"
            :responsive="{
              0: { items: 1, nav: false },
              700: { items: 2, nav: false },
              1000: { items: 4, nav: false },
            }"
          >
            <div
              class="item"
              v-for="customer in item.customers1"
              :key="customer.id"
            >
              <div class="client_item">
                <img :src="customer.image" />
              </div>
            </div>
            <!-- <div class="item">
              <div class="client_item">
                <img src="@/assets/images/our_clients/img2.png" />
              </div>
            </div>
            <div class="item">
              <div class="client_item">
                <img src="@/assets/images/our_clients/img3.png" />
              </div>
            </div>
            <div class="item">
              <div class="client_item">
                <img src="@/assets/images/our_clients/img1.png" />
              </div>
            </div>
            <div class="item">
              <div class="client_item">
                <img src="@/assets/images/our_clients/img2.png" />
              </div>
            </div> -->
          </owl-slider>
          <!--Clients Slider-->
        </div>
      </div>
    </div>
  </div>
  <!--End Our Clients-->
</template>

<script>
export default {
  props: ["item", "loading"],
};
</script>

<style></style>
