export default {
    // ============================= Login
    set_token(state, payload) {
        localStorage.setItem("SAS_App_Token", payload);
        state.token = payload;
    },
    set_usertype(state, payload) {
        localStorage.setItem("SAS_App_User_Type", payload);
        state.usertype = payload;
    },
    set_userName(state, payload) {
        localStorage.setItem("SAS_App_User_Name", payload);
        state.userName = payload;
    },
    set_userId(state, payload) {
        localStorage.setItem("SAS_App_User_Id", payload);
        state.user_id = payload;
    },
    set_userImage(state, payload) {
        localStorage.setItem("SAS_App_userImg", payload);
        state.userImage = payload;
    },
    set_userPhone(state, payload) {
        localStorage.setItem("SAS_App_userPhone", payload);
        state.userPhone = payload;
    },

    set_devMessage(state, payload) {
        localStorage.setItem("SAS_App_devMessage", payload);
        state.devMessage = payload;
    },
    set_email(state, payload) {
        localStorage.setItem("SAS_App_Email", payload);
        state.email = payload;
    },
    set_Package(state, payload) {
        localStorage.setItem("SAS_App_Package", payload);
        state.havePackage = payload;
    },
    set_expPackage(state, payload) {
        localStorage.setItem("SAS_App_expPackage", payload);
        state.expPackage = payload;
    },

    // ============================= Logout
    remove_token(state) {
        state.token = "";
        localStorage.removeItem("SAS_App_Token");
    },
    remove_userType(state) {
        state.usertype = "";
        localStorage.removeItem("SAS_App_User_Type");
    },
    remove_userName(state) {
        state.usertype = "";
        localStorage.removeItem("SAS_App_User_Name");
    },
    remove_userId(state) {
        state.user_id = "";
        localStorage.removeItem("SAS_App_User_Id");
    },
    remove_userImage(state) {
        state.userImage = "";
        localStorage.removeItem("SAS_App_userImg");
    },
    remove_userPhone(state) {
        state.userPhone = "";
        localStorage.removeItem("SAS_App_userPhone");
    },
    remove_DevMessage(state) {
        state.devMessage = "";
        localStorage.removeItem("SAS_App_devMessage");
    },
    remove_Email(state) {
        state.email = "";
        localStorage.removeItem("SAS_App_Email");
    },
    remove_Package(state) {
        state.havePackage = "";
        localStorage.removeItem("SAS_App_Package");
    },
    remove_expPackage(state) {
        state.expPackage = "";
        localStorage.removeItem("SAS_App_expPackage");
    },
};