<template>
  <div class="form_inputs">
    <!--Title-->
    <h4>{{ $t("settings.data.cahngePassword") }}</h4>
    <!--Title-->

    <!--Form-->
    <form @submit.prevent="saveData()">
      <!--Wrapper Flex-->
      <div class="wrapper_flex profile_wrapper">
        <span class="profile_label">{{ $t("settings.data.newPassword") }}</span>
        <!--Input Wrapper-->
        <div class="input_wrapper reg_style with_icon">
          <div class="icon_pass" @click="helper_togglePasswordVisibility">
            <i class="fal fa-eye open_eye"></i>
            <i class="fal fa-eye-slash closed_eye"></i>
          </div>
          <span></span>
          <!--New Password-->
          <input
            type="password"
            class="form-control"
            :placeholder="$t('settings.data.newPassword')"
            v-model="data.newPassword"
          />
          <!--New Password-->
        </div>
        <!--Input Wrapper-->
      </div>
      <!--Wrapper Flex-->

      <!--Wrapper Flex-->
      <div class="wrapper_flex profile_wrapper">
        <span class="profile_label">{{
          $t("settings.data.confirmPassword")
        }}</span>
        <!--Input Wrapper-->
        <div class="input_wrapper reg_style with_icon">
          <div class="icon_pass" @click="helper_togglePasswordVisibility">
            <i class="fal fa-eye open_eye"></i>
            <i class="fal fa-eye-slash closed_eye"></i>
          </div>
          <span></span>
          <!--Confirm Password-->
          <input
            type="password"
            class="form-control"
            :placeholder="$t('settings.data.confirmPassword')"
            v-model="data.confirmPassword"
          />
          <!--Confirm Password-->
        </div>
        <!--Input Wrapper-->
      </div>
      <!--Wrapper Flex-->

      <!--Input Wrapper-->
      <div class="input_wrapper text">
        <h5>{{ $t("enterOld") }}</h5>
      </div>
      <!--Input Wrapper-->

      <!--Wrapper Flex-->
      <div class="wrapper_flex profile_wrapper">
        <span class="profile_label">{{ $t("settings.data.oldPassword") }}</span>
        <!--Input Wrapper-->
        <div class="input_wrapper reg_style with_icon">
          <div class="icon_pass" @click="helper_togglePasswordVisibility">
            <i class="fal fa-eye open_eye"></i>
            <i class="fal fa-eye-slash closed_eye"></i>
          </div>
          <span></span>
          <!--Old Password-->
          <input
            type="password"
            class="form-control"
            :placeholder="$t('settings.data.oldPassword')"
            v-model="data.oldPassword"
          />
          <!--Old Password-->
        </div>
        <!--Input Wrapper-->
      </div>
      <!--Wrapper Flex-->

      <div class="Pad_50">
        <button
          type="button"
          class="main_button button"
          ref="save"
          @click="saveData()"
        >
          <i class="fas fa-spinner"></i>
          <span>{{ $t("profile.saveChanges") }}</span>
        </button>
      </div>
    </form>
    <!--Form-->
  </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";
export default {
  components: { Datepicker },
  data() {
    return {
      //Cities
      cities: [
        {
          id: 1,
          name: "الرياض",
        },
        {
          id: 2,
          name: "جدة",
        },
        {
          id: 3,
          name: "مكة",
        },
      ],
      //Cities

      //Data
      data: {
        newPassword: null,
        confirmPassword: null,
        oldPassword: null,
      },
      //Data
    };
  },
  methods: {
    //Save Data
    saveData() {
      var submitButton = this.$refs["save"];
      submitButton.classList.add("click");
      if (!this.data.newPassword) {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.$iziToast.error({
            message: this.$t("settings.validation.newPassword"),
          });
        }, 1000);
      } else if (this.data.confirmPassword != this.data.newPassword) {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.$iziToast.error({
            message: this.$t("settings.validation.confirmPassword"),
          });
        }, 1000);
      } else if (!this.data.oldPassword) {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.$iziToast.error({
            message: this.$t("settings.validation.oldPassword"),
          });
        }, 1000);
      } else {
        let submit_data = new FormData();
        submit_data.append("old_password", this.data.oldPassword);
        submit_data.append("password", this.data.newPassword);
        submit_data.append("passwrod_confirmation", this.data.confirmPassword);
        this.$axios({
          method: "post",
          url: "edit_password",
          data: submit_data,
          headers: {
            Authorization: "bearer" + localStorage.getItem("SAS_App_Token"),
          },
        })
          .then(() => {
            setTimeout(() => {
              submitButton.classList.remove("click");
              this.$iziToast.success({
                message: this.$t("global.editSuccess"),
              });
            }, 1000);
          })
          .catch((err) => {
            setTimeout(() => {
              submitButton.classList.remove("click");
              this.$iziToast.error({
                message: err.response.data.message,
              });
            }, 1000);
          });
      }
    },
  },
};
</script>

<style></style>
