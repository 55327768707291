<template>
  <section class="featured_packages">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-sm-6">
          <!--Title-->
          <div class="center_title">
            <h3>{{ $t("featuredPackages.title") }}</h3>
            <p>{{ $t("featuredPackages.desc") }}</p>
          </div>
          <!--Title-->
        </div>
      </div>
      <div class="row">
        <div class="col-md-3 feat_con" v-for="pack in packages" :key="pack.id">
          <!--Feat Card-->
          <div
            class="feat_card"
            :class="{ checked: pack == choosenPackage }"
            @click="choosePackage(pack)"
          >
            <!--Title-->
            <div class="title">
              <h4>{{ pack.name }}</h4>
            </div>
            <!--Title-->
            <!--Price-->
            <div class="price">
              <h4>
                {{ pack.price }} <span>{{ $t("riyal") }}</span>
              </h4>
            </div>
            <!--Price-->
            <!--Desc-->
            <div class="desc">
              <p>
                {{ pack.desc }}
              </p>
            </div>
            <!--Desc-->
            <!--Subscribe-->
            <div class="subscribe_package">
              <button
                @click="payAmount(pack.id, pack.price)"
                type="button"
                class="trans_btn"
              >
                {{ $t("subscribe") }}
              </button>
            </div>
            <!--Subscribe-->
          </div>
          <!--Feat Card-->
        </div>

        <!--         <div class="col-md-3">
          <!~~Feat Card~~>
          <div class="feat_card month2">
            <!~~Title~~>
            <div class="title">
              <h4>باقة 3 شهور</h4>
            </div>
            <!~~Title~~>
            <!~~Price~~>
            <div class="price">
              <h4>300 <span>ر.س</span></h4>
            </div>
            <!~~Price~~>
            <!~~Desc~~>
            <div class="desc">
              <p>
                تؤهلك هذه الباقة للحصول على تصفح كامل للسير الذاتية الموجودة
                بالموقع ولجميع التخصصات لمدة 30 يوم مع معلومات كامل المرشحين
                وإمكانية التواصل معهم لجميع التخصصات مع العلم ان السير
              </p>
            </div>
            <!~~Desc~~>
            <!~~Subscribe~~>
            <div class="subscribe_package">
              <router-link to="" class="trans_btn">{{
                $t("subscribe")
              }}</router-link>
            </div>
            <!~~Subscribe~~>
          </div>
          <!~~Feat Card~~>
        </div>

        <div class="col-md-3">
          <!~~Feat Card~~>
          <div class="feat_card month3">
            <!~~Title~~>
            <div class="title">
              <h4>باقة 3 شهور</h4>
            </div>
            <!~~Title~~>
            <!~~Price~~>
            <div class="price">
              <h4>300 <span>ر.س</span></h4>
            </div>
            <!~~Price~~>
            <!~~Desc~~>
            <div class="desc">
              <p>
                تؤهلك هذه الباقة للحصول على تصفح كامل للسير الذاتية الموجودة
                بالموقع ولجميع التخصصات لمدة 30 يوم مع معلومات كامل المرشحين
                وإمكانية التواصل معهم لجميع التخصصات مع العلم ان السير
              </p>
            </div>
            <!~~Desc~~>
            <!~~Subscribe~~>
            <div class="subscribe_package">
              <router-link to="" class="trans_btn">{{
                $t("subscribe")
              }}</router-link>
            </div>
            <!~~Subscribe~~>
          </div>
          <!~~Feat Card~~>
        </div>

        <div class="col-md-3">
          <!~~Feat Card~~>
          <div class="feat_card month4">
            <!~~Title~~>
            <div class="title">
              <h4>باقة 6 شهور</h4>
            </div>
            <!~~Title~~>
            <!~~Price~~>
            <div class="price">
              <h4>600 <span>ر.س</span></h4>
            </div>
            <!~~Price~~>
            <!~~Desc~~>
            <div class="desc">
              <p>
                تؤهلك هذه الباقة للحصول على تصفح كامل للسير الذاتية الموجودة
                بالموقع ولجميع التخصصات لمدة 30 يوم مع معلومات كامل المرشحين
                وإمكانية التواصل معهم لجميع التخصصات مع العلم ان السير
              </p>
            </div>
            <!~~Desc~~>
            <!~~Subscribe~~>
            <div class="subscribe_package">
              <router-link to="" class="trans_btn">{{
                $t("subscribe")
              }}</router-link>
            </div>
            <!~~Subscribe~~>
          </div>
          <!~~Feat Card~~>
        </div> -->

        <div class="col-sm-12">
          <!--Try Package-->
          <div class="try_package">
            <button type="button" @click="openModel">
              {{ $t("packages.tryPack") }}
            </button>
          </div>
          <!--Try Package-->
        </div>
        <!-- <div class="col-sm-12">
          <router-link to="" class="main_button"
            ><span>تصفح الباقات</span></router-link
          >
        </div> -->
      </div>
    </div>

    <!--Payment Model-->
    <base-model :show="show" @closeModel="closeModel">
      <div class="custom_card">
        <form id="form-container" method="post" @submit.prevent="">
          <!-- Tap element will be here -->
          <div id="element-container"></div>
          <div id="error-handler" role="alert"></div>
          <div
            id="success"
            style="display: none; position: relative; float: left"
          >
            <!-- Success! Your token is <span id="token"></span> -->
          </div>
          <button class="main_button button" id="tap-btn">
            <i class="fas fa-spinner"></i>
            <span>{{ $t("pay") }}</span>
          </button>
          <!-- <span>{{ $t("price") }} : {{ price }} {{ $t("riyal") }}</span> -->
        </form>
      </div>
    </base-model>
    <!--Payment Model-->
  </section>
</template>

<script>
export default {
  data() {
    return {
      //Packages
      packages: [],
      //Packages

      //Show Model
      show: false,
      price: null,
      trans_id: this.$route.query.tap_id,
      package_id: this.$route.query.package_id,
      status: this.$route.query.status,
      verfyRes: null,
      choosenPackage: null,
    };
  },
  methods: {
    openModel() {
      if (!this.choosenPackage) {
        setTimeout(() => {
          this.$iziToast.error({
            message: this.$t("choosePackage"),
          });
        }, 1000);
      } else {
        this.show = true;
      }
    },

    //CloseModel
    closeModel() {
      this.show = false;
      localStorage.removeItem("Sas_TransId");
      localStorage.removeItem("Sas_Pack_Id");
    },

    payAmount(id, price) {
      if (!this.token) {
        setTimeout(() => {
          this.$iziToast.error({
            message: this.$t("register.validation.loginFrist"),
          });
          this.$router.push("/login");
        }, 1000);
      } else {
        window.location.href =
          "https://sas-jobs.net/pay?amount=" +
          price +
          "&type=package&user_id=" +
          this.userId +
          "&redirect_url=https://sas-jobs.net/packages?package_id=" +
          id;
      }
    },

    //Pay Method
    payMethod() {
      let _this = this;
      //pass your public key from tap's dashboard
      var tap = Tapjsli("pk_live_8eKYZbCptrBD97zaUkNivxgR");
      // var tap = Tapjsli("pk_test_URm1EW6QAfyho8weX40DBjcO");

      var elements = tap.elements({});

      var style = {
        base: {
          color: "#535353",
          lineHeight: "18px",
          fontFamily: "sans-serif",
          fontSmoothing: "antialiased",
          fontSize: "16px",
          "::placeholder": {
            color: "rgba(0, 0, 0, 0.26)",
            fontSize: "15px",
          },
        },
        invalid: {
          color: "red",
        },
      };
      // input labels/placeholders
      var labels = {
        cardNumber: "Card Number",
        expirationDate: "MM/YY",
        cvv: "CVV",
        cardHolder: "Card Holder Name",
      };
      //payment options
      var paymentOptions = {
        currencyCode: ["KWD", "USD", "SAR"],
        labels: labels,
        TextDirection: "ltr",
      };
      //create element, pass style and payment options
      var card = elements.create("card", { style: style }, paymentOptions);
      //mount element
      card.mount("#element-container");
      //card change event listener
      card.addEventListener("change", function (event) {
        if (event.loaded) {
          console.log("UI loaded :" + event.loaded);
          console.log("current currency is :" + card.getCurrency());
        }
        var displayError = document.getElementById("error-handler");
        if (event.error) {
          displayError.textContent = event.error.message;
        } else {
          displayError.textContent = "";
        }
      });

      // Handle form submission
      var form = document.getElementById("form-container");
      form.addEventListener("submit", function (event) {
        event.preventDefault();

        tap.createToken(card).then(function (result) {
          console.log(result);
          if (result.error) {
            // Inform the user if there was an error
            var errorElement = document.getElementById("error-handler");
            errorElement.textContent = result.error.message;
          } else {
            // Send the token to your server
            var errorElement = document.getElementById("success");
            errorElement.style.display = "block";
            var tokenElement = document.getElementById("token");
            tokenElement.textContent = result.id;
            _this.subscription(result.id);
            tapTokenHandler(token);
          }
        });
      });

      function tapTokenHandler(token) {
        // Insert the token ID into the form so it gets submitted to the server
        var form = document.getElementById("payment-form");
        var hiddenInput = document.createElement("input");
        hiddenInput.setAttribute("type", "hidden");
        hiddenInput.setAttribute("name", "tapToken");
        hiddenInput.setAttribute("value", token.id);
        form.appendChild(hiddenInput);
        // Submit the form
        form.submit();
      }
      card.addEventListener("change", function (event) {
        if (event.BIN) {
          console.log(event.BIN);
        }
        var displayError = document.getElementById("card-errors");
        if (event.error) {
          displayError.textContent = event.error.message;
        } else {
          displayError.textContent = "";
        }
      });
    },

    //Subscription Steps
    choosePackage(item) {
      this.choosenPackage = item;
    },

    subscription(tok) {
      let submit_data = new FormData();
      submit_data.append("token", tok);
      submit_data.append("amount", this.choosenPackage.price);
      submit_data.append("package_id", this.choosenPackage.id);
      this.$axios({
        method: "POST",
        url: "company/subscribe",
        data: submit_data,
      })
        .then(() => {
          setTimeout(() => {
            this.$iziToast.success({
              message: this.$t("subscribeSuccess"),
            });
            this.show = false;
          }, 1000);
        })
        .catch((err) => {
          setTimeout(() => {
            this.$iziToast.error({
              message: err.response.data.message,
            });
          }, 1000);
        });
    },

    //Get Data
    getData() {
      this.$axios({
        method: "GET",
        url: "packages",
      }).then((res) => {
        this.packages = res.data.data;
      });
    },
    //Get Data

    //Join Package
    joinPackage() {
      setTimeout(() => {
        let submit_data = new FormData();
        submit_data.append("package_id", this.package_id);
        submit_data.append("transaction_id", this.trans_id);
        submit_data.append("payment_type", "visa");
        this.$axios({
          method: "post",
          url: "company/join_package",
          data: submit_data,
          headers: {
            Authorization: "bearer" + localStorage.getItem("SAS_App_Token"),
          },
        })
          .then((res) => {
            this.closeModel();
            this.$store.commit(
              "auth_module/set_expPackage",
              res.data.data.package_expire_date
            );
            setTimeout(() => {
              this.$iziToast.success({
                message: this.$t("subscribeSuccess"),
              });
            }, 1000);
          })
          .catch((err) => {
            setTimeout(() => {
              this.$iziToast.error({
                message: err.response.data.message,
              });
            }, 1000);
          });
      }, 900);
    },
    //Join Package
  },
  mounted() {
    this.payMethod();
    if (this.status == "success") {
      this.joinPackage();
    } else if (this.status == "fail") {
      this.$iziToast.error({
        message: "ghhhhhhh",
      });
    }
  },
  created() {
    this.getData();
  },
  computed: {
    token() {
      return this.$store.getters["auth_module/token"];
    },
    userId() {
      return this.$store.getters["auth_module/set_userId"];
    },
    userEmail() {
      return this.$store.getters["auth_module/set_email"];
    },
    userName() {
      return this.$store.getters["auth_module/userName"];
    },
  },
};
</script>

<style></style>
