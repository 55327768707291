<template>
  <div class="row justify-content-center">
    <div class="col-md-12">
      <div
        class="cv_template_3"
        v-if="item"
        style="font-family: 'font_regular' !important"
      >
        <!--Top Back-->
        <div class="top_back">
          <!--Cv User Data-->
          <div class="cv_user_data">
            <!--User Job Title-->
            <div class="user_job_title">
              <h4>{{ item.name }}</h4>
              <h5>{{ item.job_title }}</h5>
            </div>
            <!--User Job Title-->
            <!--Image-->
            <div class="user_image">
              <img :src="item.image" />
              <h4>المقدمة</h4>
            </div>
            <!--Image-->
            <!--Personal Info-->
            <div class="personal_info">
              <!--Info List-->
              <ul class="info_list">
                <li style="list-style: none">
                  <i class="fal fa-envelope"></i>
                  <span>{{ item.email }}</span>
                </li>
                <li style="list-style: none">
                  <i class="fal fa-phone"></i>
                  <span>{{ item.phone }}</span>
                </li>
                <li style="list-style: none">
                  <i class="fal fa-map-marker-alt"></i>
                  <span>{{ item.address }}</span>
                </li>
                <li style="list-style: none">
                  <i class="fab fa-twitter"></i>
                  <span>{{ item.twitter_user }}</span>
                </li>
                <li style="list-style: none">
                  <i class="fab fa-linkedin"></i>
                  <span>{{ item.linkedin_user }}</span>
                </li>
              </ul>
              <!--Info List-->
            </div>
            <!--Personal Info-->
          </div>
          <!--Cv User Data-->
        </div>
        <!--Top Back-->
        <!--User Breif-->
        <div class="user_breif">
          <p>{{ item.desc }}</p>
        </div>
        <!--User Breif-->
        <div class="row">
          <div class="col-sm-6">
            <!--Work Experience-->
            <div class="work_experience">
              <div class="top_title">
                <h4>الخبرات العلمية</h4>
                <i class="fas fa-briefcase"></i>
              </div>
              <div
                class="work_card"
                v-for="exp in item.experiences"
                :key="exp.id"
              >
                <div class="work">
                  <div class="top_work">
                    <div class="work_det">
                      <span>{{ exp.job_title }}</span>
                      <h4>{{ exp.name }}</h4>
                    </div>
                    <div class="date">
                      <span>{{ exp.start_date.substring(6, 10) }}-</span>
                      <span v-if="Boolean(!exp.is_still)">{{
                        exp.end_date.substring(6, 10)
                      }}</span>
                      <span v-if="Boolean(exp.is_still)">حتى الان</span>
                    </div>
                  </div>
                  <p>{{ exp.desc }}</p>
                </div>
              </div>
            </div>
            <!--Work Experience-->

            <!--Work Experience-->
            <div class="work_experience">
              <div class="top_title">
                <h4>التعليم الاكاديمي</h4>
                <i class="fas fa-university"></i>
              </div>
              <div
                class="work_card"
                v-for="edu in item.education"
                :key="edu.id"
              >
                <div class="work">
                  <div class="top_work">
                    <div class="work_det">
                      <span>{{ edu.section_title }}</span>
                      <h4>{{ edu.name }}</h4>
                    </div>
                    <div class="date">
                      <span>{{ edu.start_date.substring(6, 10) }}-</span>
                      <span v-if="Boolean(!edu.is_still)">{{
                        edu.end_date.substring(6, 10)
                      }}</span>
                      <span v-if="Boolean(edu.is_still)">حتى الان</span>
                    </div>
                  </div>
                  <p>{{ edu.desc }}</p>
                </div>
              </div>
            </div>
            <!--Work Experience-->

            <!--Langs-->
            <div class="langs">
              <div class="top_title">
                <h4>اللغات</h4>
                <i class="fas fa-tv"></i>
              </div>
              <div class="lang_item">
                <span>اللغة العربية</span>
                <ul class="lang_points">
                  <li
                    style="list-style: none"
                    class="fill"
                    v-for="lel in Number(item.arabic_level)"
                    :key="lel.id"
                  ></li>
                  <li
                    style="list-style: none"
                    class="no_fill"
                    v-for="lel in 4 - Number(item.arabic_level)"
                    :key="lel.id"
                  ></li>
                </ul>
              </div>
              <div class="lang_item">
                <span>{{ $t("cvsteps.wizard.langs.lang2") }}</span>
                <ul class="lang_points">
                  <li
                    style="list-style: none"
                    class="fill"
                    v-for="lel in Number(item.english_level)"
                    :key="lel.id"
                  ></li>
                  <li
                    style="list-style: none"
                    class="no_fill"
                    v-for="lel in 4 - Number(item.english_level)"
                    :key="lel.id"
                  ></li>
                </ul>
              </div>
            </div>
            <!--Langs-->
          </div>

          <div class="col-sm-6 borderd">
            <!--Personal Skills-->
            <div class="work_experience">
              <div class="top_title">
                <h4>اللغات والمهارات الشخصية</h4>
                <i class="fas fa-briefcase"></i>
              </div>
              <div class="progress_container">
                <h5>مهارات التواصل</h5>
                <div class="progress">
                  <div
                    class="progress-bar"
                    role="progressbar"
                    :style="{ width: item.contact_level + '%' }"
                    aria-valuenow="25"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  >
                    {{ item.contact_level }}%
                  </div>
                </div>
              </div>
              <div class="progress_container">
                <h5>ادارة الوقت</h5>
                <div class="progress">
                  <div
                    class="progress-bar"
                    role="progressbar"
                    :style="{ width: item.leader_level + '%' }"
                    aria-valuenow="25"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  >
                    {{ item.leader_level }}%
                  </div>
                </div>
              </div>
              <div class="progress_container">
                <h5>الترفيه</h5>
                <div class="progress">
                  <div
                    class="progress-bar"
                    role="progressbar"
                    :style="{ width: item.play_level + '%' }"
                    aria-valuenow="25"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  >
                    {{ item.play_level }}%
                  </div>
                </div>
              </div>
            </div>
            <!--Personal Skills-->

            <!--Skills-->
            <div class="skills">
              <div class="top_title">
                <h4>المهارات</h4>
                <i class="fas fa-tv"></i>
              </div>
              <ul class="skills_list">
                <li
                  style="list-style: none"
                  v-for="skill in item.interests"
                  :key="skill.id"
                >
                  <span>{{ skill.name }}</span>
                </li>
              </ul>
            </div>
            <!--Skills-->
            <!--Time Mangement-->
            <div class="work_experience">
              <div class="top_title">
                <h4>ادارة الوقت</h4>
                <i class="fas fa-briefcase"></i>
              </div>
              <apexchart
                width="290"
                height="250"
                type="donut"
                :options="options"
                :series="newSer"
              ></apexchart>
            </div>
            <!--Time Mangement-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["item"],
  data() {
    return {
      //Template Color
      templateColor: "",
      //Template Color

      options: {
        chart: {
          type: "radialBar",
        },

        legend: {
          show: true,
          horizontalAlign: "center",
          floating: false,
          fontSize: "16px",
          markers: {
            width: 20,
            height: 20,
            strokeWidth: 0,
            radius: 0,
          },
          itemMargin: {
            horizontal: 5,
            vertical: 0,
          },
        },
        dataLabels: {
          enabled: false,
        },
        plotOptions: {
          pie: {
            donut: {
              size: "85%",
              labels: {
                show: true,
                name: {
                  show: true,
                },
                value: {
                  show: false,
                },
                total: {
                  show: true,
                  label: this.$t("cvsteps.wizard.timeMangement.title"),
                  color: "#29479A",
                  // formatter: function (w) {
                  //   return w.globals.seriesTotals.reduce((a, b) => {
                  //     return a + b;
                  //   }, 0);
                  // },
                },
              },
            },
          },
        },
        labels: ["العمل", "العائلة", "الترفيه"],
        colors: ["#7D5EE6", "#CAB6FB", "#9D9AEC"],
      },
      series: [44, 55, 41],

      //Colors
      colors: [
        {
          color: "#DFDFDF",
        },
        {
          color: "#0EB6D8",
        },
        {
          color: "#87CCAF",
        },
        {
          color: "#CEFFB1",
        },
        {
          color: "#E4A8F3",
        },
      ],
      //Colors
    };
  },
  methods: {
    //Change Color
    changeColor(color) {
      this.templateColor = color;
    },
  },
  mounted() {
    this.changeColor(this.colors[0].color);
  },
  computed: {
    newSer() {
      return [
        Number(this.item.work_level),
        Number(this.item.family_level),
        Number(this.item.play_level),
      ];
    },
  },
};
</script>

<style></style>
