<template>
  <div class="register_page">
    <!--Top Background-->
    <div class="top_background">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="title">
              <h4>{{ $t("terms.title") }}</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--Top Background-->

    <!--Terms-->
    <div class="container">
      <div class="row">
        <loader v-if="loading"></loader>
        <div class="col-sm-12" v-else>
          <!--All Text-->
          <div class="all_text" v-html="allData.terms">
            <!--             <!~~Text Content~~>
            <div class="text_content">
              <span>شروط ساس للتوظيف</span>
              <p>
                باستخدام و / أو زيارة موقع الويب SAS-JOBS.NET ، فإنك توافق على
                الالتزام بالشروط والأحكام التالية ("شروط الاستخدام").
              </p>
            </div>
            <!~~Text Content~~>

            <!~~Text Content~~>
            <div class="text_content">
              <span>موقع ويب ساس للتوظيف</span>
              <p>
                تنطبق هذه الشروط على جميع مستخدمي وزوار موقع ساس للتوظيف ، بما
                في ذلك المستخدمين الذين يساهمون أيضًا في الصور ومحتوى الفيديو
                والمعلومات والمواد أو الخدمات الأخرى على الموقع. يحتوي موقع ويب
                ساس للتوظيف على روابط لمواقع ويب تابعة لجهات خارجية ليست مملوكة
                أو خاضعة لسيطرة ساس للتوظيف ، وبالتالي لا تتحكم شركة ساس للتوظيف
                فيها ، ولا تتحمل أي مسؤولية عن المحتوى أو سياسات الخصوصية أو
                ممارسات أي مواقع ويب خاصة بأطراف أخرى . باستخدام موقع الويب ،
                فإنك تعفي ساس للتوظيف صراحةً من أي وجميع المسؤوليات الناشئة عن
                استخدامك لأي موقع ويب تابع لجهة خارجية.
              </p>
            </div>
            <!~~Text Content~~>

            <!~~Text Content~~>
            <div class="text_content">
              <span>الشروط الأساسية </span>
              <p>
                أنت مسؤول عن أي نشاط يحدث باسم المستخدم الخاص بك. أنت مسؤول عن
                حفظ كلمة المرور الخاصة بك آمنة. يجب عليك إخطار ساس للتوظيف على
                الفور بأي خرق للأمن أو استخدام غير مصرح به لحسابك. على الرغم من
                أن شركة ساس للتوظيف لن تكون مسؤولة عن خسائرك الناتجة عن أي
                استخدام غير مصرح به لحسابك ، فقد تكون مسؤولاً عن خسائر ساس
                للتوظيف أو غيرها بسبب هذا الاستخدام غير المصرح به. يجب عليك عدم
                الإساءة إلى مستخدمي ساس للتوظيف الآخرين أو مضايقتهم أو تهديدهم
                أو انتحال صفتهم أو تخويفهم. لا يجوز لك استخدام خدمات
                SAS-JOBS.NET لأي غرض غير قانوني أو غير مصرح به. يوافق المستخدمون
                الدوليون على الامتثال لجميع القوانين المحلية المتعلقة بالسلوك
                عبر الإنترنت والمحتوى المقبول.
              </p>
              <p>
                أنت وحدك المسؤول عن سلوكك وأي بيانات أو نصوص أو معلومات أو أسماء
                مستخدمين أو رسومات أو صور أو ملفات تعريف أو مقاطع صوت وفيديو أو
                روابط ("محتوى") ترسلها أو تنشرها أو تعرضها على خدمة SAS-JOBS.NET
                . لا يجوز لك تعديل موقع SAS-JOBS.NET أو تكييفه أو اختراقه أو
                تعديل موقع ويب آخر للإشارة ضمنيًا إلى أنه مرتبط بـ SAS-JOBS.NET.
                يجب عدم إنشاء أو إرسال تعليقات غير مرغوب فيها أو إرسال بريد
                إلكتروني إلى أي من أعضاء ساس للتوظيف ("البريد العشوائي") يجب ألا
                تنقل أي فيروسات متنقلة أو فيروسات أو أي رمز ذي طبيعة مدمرة. يجب
                ألا تنتهك ، عند استخدام وظائف ساس للتوظيف ، أي قوانين في نطاق
                سلطتك القضائية (بما في ذلك على سبيل المثال لا الحصر قوانين حقوق
                النشر). سيؤدي انتهاك أي من هذه الاتفاقيات إلى إنهاء حساب
                SAS-JOBS.NET الخاص بك. أنت تدرك وتوافق على أن ساس للتوظيف لا
                يمكن أن تكون مسؤولة عن جميع المحتويات المنشورة على موقع الويب
                الخاص بها وتوافق على استخدام خدمة SAS-JOBS.NET على مسؤوليتك
                الخاصة.
              </p>
            </div>
            <!~~Text Content~~>

            <!~~Text Content~~>
            <div class="text_content">
              <span>شروط عامة </span>
              <p>
                نحتفظ بالحق في تعديل أو إنهاء خدمة SAS-JOBS.NET لأي سبب من
                الأسباب ، دون سابق إنذار في أي وقت. بصفتنا شركة توظيف مسجلة ،
                فإننا نحتفظ بالحق في بيع المعلومات التي يتم نشرها عن طيب خاطر من
                قبل المستخدمين على الموقع لعملاء الجهات الخارجية الذين يبحثون عن
                خدمات ساس للتوظيف غير مسؤولة عن أي إجراءات يتخذها أي عميل تابع
                لطرف ثالث فيما يتعلق بالمعلومات التي ينشرها المستخدمون. نحن غير
                مسؤولين عن أي تفاعل أو اتصال أو اتفاقيات وما يترتب عليها من آثار
                تحدث بين مستخدمينا وعملائنا من الأطراف الثالثة ، سواء كانت نقطة
                التقديم الأولية من خلال SAS-JOBS.NET أم لا. نحن لسنا مسؤولين عن
                صحة ودقة أي محتوى ينشره عملاء الطرف الثالث على الموقع.
              </p>
              <p>
                نحتفظ بالحق في تغيير شروط الاستخدام هذه في أي وقت. إذا كانت
                التعديلات تشكل تغييرًا جوهريًا في شروط الاستخدام ، فسنخطرك عبر
                البريد الإلكتروني. سيتم تحديد ما يشكل "تغييرًا ماديًا" وفقًا
                لتقديرنا الخاص ، وبحسن نية وباستخدام الفطرة السليمة والحكم
                المعقول.
              </p>
              <p>نحتفظ بالحق في رفض الخدمة لأي شخص لأي سبب وفي أي وقت.</p>
              <p>
                يجوز لنا ، ولكن ليس لدينا أي التزام ، إزالة المحتوى والحسابات
                التي تحتوي على محتوى نحدد (وفقًا لتقديرنا الخاص) أنها غير
                قانونية أو مسيئة أو مهددة أو تشهيرية أو تشهيرية أو فاحشة أو
                مرفوضة أو تنتهك الملكية الفكرية لأي طرف أو هذه الشروط من
                الاستخدام.
              </p>
              <p>
                تتيح خدمة ساس للتوظيف إمكانية نشر مقاطع الفيديو والصور المستضافة
                على موقع ساس للتوظيف أو مواقع الويب الخاصة بأطراف أخرى إلى مواقع
                الويب الخارجية. هذا الاستخدام مقبول. ومع ذلك ، يجب أن توفر
                الصفحات الموجودة على مواقع الويب الأخرى التي تعرض مقاطع فيديو أو
                صورًا مستضافة على SAS-JOBS.NET رابطًا للعودة إلى صفحات ساس
                للتوظيف الأصلية. تحتفظ شركة ساس للتوظيف بالحق في تغيير محتوى
                إعلان الوظيفة ليتناسب مع متطلباته ومعاييره.
              </p>
              <p>حقوق الملكية الفكرية</p>
              <p>
                المحتوى الموجود على موقع ويب SAS-JOBS ، باستثناء جميع عمليات
                إرسال المستخدم (كما هو محدد أدناه) ، بما في ذلك على سبيل المثال
                لا الحصر ، النصوص والبرامج والبرامج النصية والرسومات والصور
                والأصوات والموسيقى ومقاطع الفيديو والميزات التفاعلية ("المحتوى")
                والعلامات التجارية ، وعلامات الخدمة والشعارات الواردة فيه
                ("العلامات") ، مملوكة أو مرخصة لوظائف SAS ، تخضع لحقوق النشر
                وحقوق الملكية الفكرية الأخرى. يتم توفير المحتوى الموجود على موقع
                الويب لك كما هو لمعلوماتك وللاستخدام الشخصي فقط ولا يجوز
                استخدامه أو نسخه أو إعادة إنتاجه أو توزيعه أو نقله أو بثه أو
                عرضه أو بيعه أو ترخيصه أو استغلاله بأي شكل آخر لأي أغراض أخرى
                على الإطلاق دون سابق موافقة خطية من أصحابها. تحتفظ ساس للتوظيف
                بجميع الحقوق غير الممنوحة صراحةً في وإلى موقع الويب والمحتوى.
                أنت توافق على عدم المشاركة في استخدام أو نسخ أو توزيع أي محتوى
                بخلاف ما هو مسموح به صراحةً هنا ، بما في ذلك أي استخدام أو نسخ
                أو توزيع لمواد إرسال المستخدم. أنت توافق على عدم التحايل أو
                تعطيل أو التدخل بأي شكل آخر في الميزات المتعلقة بالأمان في موقع
                وظائف SAS أو الميزات التي تمنع أو تقيد استخدام أو نسخ أي محتوى
                أو تفرض قيودًا على استخدام موقع ساس للتوظيف أو المحتوى الموجود
                فيه.
              </p>
            </div>
            <!~~Text Content~~>

            <!~~Text Content~~>
            <div class="text_content">
              <span>تقديمات المستخدم </span>
              <p>
                قد يسمح موقع ساس للتوظيف الآن أو في المستقبل بإرسال مقاطع
                الفيديو أو الصور أو الاتصالات الأخرى التي تقدمها أنت والمستخدمون
                الآخرون ("تقديمات المستخدم") واستضافة و / أو مشاركة و / أو نشر
                تقديمات المستخدم هذه. لا تضمن ساس للتوظيف أي سرية فيما يتعلق بأي
                عمليات إرسال. فيما يتعلق بتقديمات المستخدم ، فإنك توافق أيضًا
                على أنك لن تقوم بما يلي:
              </p>
            </div>
            <!~~Text Content~~>

            <!~~Text Content~~>
            <div class="text_content">
              <p>
                إرسال المواد المحمية بحقوق الطبع والنشر أو المحمية بالأسرار
                التجارية أو الخاضعة بخلاف ذلك لحقوق الملكية الخاصة بطرف ثالث ،
                بما في ذلك حقوق الخصوصية والدعاية ، ما لم تكن مالك هذه الحقوق أو
                لديك إذن من مالكها الشرعي لنشر المواد ومنح ساس للتوظيف جميعًا من
                حقوق الترخيص الممنوحة هنا ؛ نشر الأكاذيب أو التحريفات التي قد
                تلحق الضرر بساس للتوظيف أو أي طرف ثالث ؛ إرسال مواد غير قانونية
                ، أو فاحشة ، أو تشهيرية ، أو تشهيرية ، أو تهديدية ، أو إباحية ،
                أو مضايقة ، أو بغيضة ، أو مسيئة عنصريًا أو عرقيًا ، أو تشجع على
                سلوك يمكن اعتباره جريمة جنائية ، أو يؤدي إلى مسؤولية مدنية ، أو
                ينتهك أي قانون ، أو غير مناسب بأي شكل آخر ؛انتحال شخصية شخص آخر.
                لا تصادق ساس للتوظيف على أي من مراسلات المستخدم أو أي رأي أو
                توصية أو نصيحة معبر عنها فيه ، وتتنصل ساس للتوظيف صراحةً عن أي
                وكل مسؤولية فيما يتعلق بتقديمات المستخدم. لا تسمح ساس للتوظيف
                بأنشطة انتهاك حقوق النشر وانتهاك حقوق الملكية الفكرية على موقع
                الويب الخاص بها ، وستقوم ساس للتوظيف بإزالة جميع المحتويات
                وتقارير المستخدم إذا تم إخطارها بشكل صحيح بأن هذا المحتوى أو
                إرسال المستخدم ينتهك حقوق الملكية الفكرية للآخرين. تحتفظ ساس
                للتوظيف بالحق في إزالة المحتوى وتقديمات المستخدم دون إشعار مسبق.
                ستنهي ساس للتوظيف أيضًا وصول المستخدم إلى موقعه على الويب ، إذا
                تم تحديده على أنه منتهك متكرر. تحتفظ ساس للتوظيف أيضًا بالحق في
                تحديد ما إذا كان المحتوى أو أحد عناصر إرسال المستخدم مناسبًا
                ويتوافق مع شروط الخدمة هذه بالنسبة للانتهاكات بخلاف انتهاك حقوق
                النشر وانتهاكات قانون الملكية الفكرية ، مثل ، على سبيل المثال لا
                الحصر ، المواد الإباحية أو العري أو الفاحشة أو مواد تشهيرية أو
                مفرطة الطول. يجوز لـ SAS JOBS إزالة تقديمات المستخدم هذه و / أو
                إنهاء وصول المستخدم لتحميل هذه المواد في انتهاك لشروط الخدمة هذه
                في أي وقت ، دون إشعار مسبق ووفقًا لتقديرها الخاص.
              </p>
            </div>
            <!~~Text Content~~>

            <!~~Text Content~~>
            <div class="text_content">
              <p>
                إذا كنت تعتقد أن عملك قد تم نسخه بطريقة تشكل انتهاكًا لحقوق
                الطبع والنشر ، أو تم انتهاك حقوق الملكية الفكرية الخاصة بك
                بطريقة أخرى ، فيرجى إرسال المعلومات التالية إلينا على العنوان
                الموضح أدناه:
              </p>
            </div>
            <!~~Text Content~~>

            <!~~Text Content~~>
            <div class="text_content">
              <p>
                توقيع إلكتروني أو مادي للشخص المخول بالتصرف نيابة عن مالك حقوق
                النشر أو غيرها من حقوق الملكية الفكرية ؛ وصف للعمل المحمي بحقوق
                الطبع والنشر أو حقوق الملكية الفكرية الأخرى التي تدعي أنه تم
                انتهاكها ؛ وصف لمكان وجود المادة التي تدعي انتهاكها على الموقع ؛
                عنوانك ورقم هاتفك وعنوان بريدك الإلكتروني إفادة من جانبك بأن
                لديك اعتقاد حسن النية بأن الاستخدام المتنازع عليه غير مصرح به من
                قبل مالك حقوق النشر أو وكيله أو القانون ؛ تصريح من جانبك ، تحت
                طائلة عقوبة الحنث باليمين ، بأن المعلومات الواردة أعلاه في
                إشعارك دقيقة وأنك مالك حقوق النشر أو الملكية الفكرية أو مخول
                بالتصرف نيابة عن مالك حقوق النشر أو الملكية الفكرية.
              </p>
              <p>
                . يرجى إرسال جميع هذه الإشعارات عبر البريد الإلكتروني:
                INFO@SAS-JOBS.NET أنت تدرك أنه عند استخدام موقع ويب ساس للتوظيف
                ، فإن ساس للتوظيف ليست مسؤولة عن دقة أو فائدة أو أمان أو حقوق
                الملكية الفكرية أو المتعلقة بتقديمات المستخدم. أنت تفهم أيضًا
                وتقر بأنك قد تتعرض لتقديمات المستخدم التي تكون غير دقيقة أو
                مسيئة أو غير لائقة أو مرفوضة ، وتوافق على التنازل والتنازل بموجب
                هذا عن أي حقوق أو تعويضات قانونية أو منصفة لديك أو قد تكون لديك
                ضد ساس للت
              </p>
            </div>
            <!~~Text Content~~> -->
          </div>
          <!--All Text-->
        </div>
      </div>
    </div>
    <!--Terms-->

    <!--Newsleter-->
    <newsleter></newsleter>
    <!--Newsleter-->
  </div>
</template>

<script>
import Newsleter from "@/components/HomePage/Newsleter.vue";
export default {
  data() {
    return {
      //All Data
      allData: null,
      //Loading
      loading: false,
    };
  },
  methods: {
    //Get Data
    getData() {
      this.loading = true;
      this.$axios({
        method: "GET",
        url: "about",
      }).then((res) => {
        this.loading = false;
        this.allData = res.data.data;
      });
    },
    //Get Data
  },
  created() {
    this.getData();
  },
  components: { Newsleter },
};
</script>

<style></style>
