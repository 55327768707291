<template>
  <div class="register_page">
    <!--Top Background-->
    <div class="top_background">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="title">
              <h4>{{ $t("jobAds.edit") }}</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--Top Background-->
    <!--Register Wizard Form-->
    <div class="container">
      <div class="register_wizard_form">
        <!--Form Inputs-->
        <div class="form_inputs">
          <form>
            <div class="row">
              <div class="col-md-8">
                <!--Input Wrapper-->
                <div class="input_wrapper reg_style">
                  <!--Job Title-->
                  <input
                    type="text"
                    class="form-control"
                    v-model="data.jobTitle"
                    @keypress="isLetter($event)"
                    :placeholder="$t('jobAds.data.title')"
                  />
                  <!--Job Title-->
                </div>
                <!--Input Wrapper-->

                <!--Input Wrapper-->
                <div class="input_wrapper reg_style">
                  <!--Job Title-->
                  <input
                    type="text"
                    class="form-control"
                    v-model="data.jobTitleEn"
                    :placeholder="$t('jobAds.data.titleEn')"
                  />
                  <!--Job Title-->
                </div>
                <!--Input Wrapper-->
              </div>

              <div class="col-md-4">
                <!--Input Wrapper-->
                <div class="input_wrapper reg_style">
                  <!-- Experience-->
                  <input
                    type="text"
                    class="form-control"
                    v-model="data.experience"
                    pattern="/^-?\d+\.?\d*$/"
                    onKeyPress="if(this.value.length == 2) {
                        return false;
                      }"
                    min="0"
                    oninput="
                        this.value =
                          !!this.value && Math.abs(this.value) >= 0
                            ? Math.abs(this.value)
                            : null
                      "
                    :placeholder="$t('jobAds.data.experience')"
                  />
                  <!-- Experience-->
                </div>
                <!--Input Wrapper-->
              </div>

              <div class="col-md-6">
                <!--Input Wrapper-->
                <div class="input_wrapper reg_style select">
                  <!--Select Specialists-->
                  <select
                    class="form-control"
                    v-model="data.specialist"
                    @change="getSub(), (data.subSpecialist = '')"
                  >
                    <option disabled value="">
                      {{ $t("jobAds.data.specialist") }}
                    </option>
                    <option
                      v-for="spe in specialists"
                      :key="spe.id"
                      :value="spe.id"
                    >
                      {{ spe.name }}
                    </option>
                  </select>
                  <!--Select Specialists-->
                </div>
                <!--Input Wrapper-->
              </div>

              <div class="col-md-6">
                <!--Input Wrapper-->
                <div class="input_wrapper reg_style select">
                  <!--Select Sub Specialists-->
                  <select class="form-control" v-model="data.subSpecialist">
                    <option disabled value="">
                      {{ $t("jobAds.data.subSpecialist") }}
                    </option>
                    <option
                      v-for="sub in subSpecialists"
                      :key="sub.id"
                      :value="sub.id"
                    >
                      {{ sub.name }}
                    </option>
                  </select>
                  <!--Select Sub Specialists-->
                </div>
                <!--Input Wrapper-->
              </div>

              <div class="col-md-6">
                <!--Input Wrapper-->
                <div class="input_wrapper reg_style select">
                  <!--Select City-->
                  <select class="form-control" v-model="data.city">
                    <option disabled value="">
                      {{ $t("jobAds.data.city") }}
                    </option>
                    <option
                      v-for="city in cities"
                      :key="city.id"
                      :value="city.id"
                    >
                      {{ city.name }}
                    </option>
                  </select>
                  <!--Select City-->
                </div>
                <!--Input Wrapper-->
              </div>

              <div class="col-md-10">
                <div class="wrapper_flex profile_wrapper">
                  <span class="profile_label">{{
                    $t("jobAds.data.salary")
                  }}</span>
                  <!--Input Wrapper-->
                  <div class="input_wrapper reg_style">
                    <!--Last Name-->
                    <input
                      type="text"
                      class="form-control"
                      id="inputBox"
                      @keydown="blockChar"
                      :class="{ disabled: data.unlimited }"
                      v-model="data.salary"
                      :placeholder="$t('jobAds.data.salary')"
                    />
                    <!--Last Name-->
                  </div>
                  <!--Input Wrapper-->
                  <span class="profile_label">{{ $t("riyal") }}</span>
                  <!--Unlimited Salary-->
                  <div class="check_box">
                    <input type="checkbox" id="sal" v-model="data.unlimited" />
                    <label for="sal">{{
                      $t("jobAds.data.unlimitedSalary")
                    }}</label>
                  </div>
                  <!--Unlimited Salary-->
                </div>
              </div>

              <div class="col-md-7">
                <!--Sex-->
                <div class="wrapper_flex profile_wrapper mb-10">
                  <span class="profile_label">{{
                    $t("jobAds.data.gender")
                  }}</span>
                  <div class="choice_list">
                    <div class="check_box" v-for="type in Types" :key="type.id">
                      <input
                        type="radio"
                        :id="'type' + type.id"
                        name="sex"
                        :value="type.value"
                        v-model="data.gender"
                      />
                      <label :for="'type' + type.id">{{ type.name }}</label>
                    </div>
                    <!-- <div class="check_box">
                      <input
                        type="checkbox"
                        id="female"
                        name="sex"
                        value="female"
                        v-model="data.gender"
                      />
                      <label for="female">{{ $t("jobAds.data.female") }}</label>
                    </div>
                    <div class="check_box">
                      <input
                        type="checkbox"
                        id="both"
                        name="sex"
                        value="both"
                        v-model="data.gender"
                      />
                      <label for="both">{{ $t("jobAds.data.both") }}</label>
                    </div> -->
                  </div>
                </div>
                <!--Sex-->
              </div>

              <div class="col-md-7">
                <!--Job For-->
                <div class="wrapper_flex profile_wrapper mb-10">
                  <span class="profile_label">{{
                    $t("jobAds.data.jobFor")
                  }}</span>
                  <div class="choice_list">
                    <div class="check_box">
                      <input
                        type="radio"
                        id="saudi"
                        name="j"
                        v-model="data.nationality"
                        value="saudi"
                      />
                      <label for="saudi">{{ $t("jobAds.data.saudi") }}</label>
                    </div>
                    <div class="check_box">
                      <input
                        type="radio"
                        id="resident"
                        name="j"
                        v-model="data.nationality"
                        value="resident"
                      />
                      <label for="resident">{{
                        $t("jobAds.data.resident")
                      }}</label>
                    </div>
                    <div class="check_box">
                      <input
                        type="radio"
                        id="all"
                        name="j"
                        value="both"
                        v-model="data.nationality"
                      />
                      <label for="all">{{ $t("jobAds.data.saudiRes") }}</label>
                    </div>
                  </div>
                </div>
                <!--Job For-->
              </div>

              <div class="col-md-6">
                <!--Input Wrapper-->
                <div class="input_wrapper reg_style">
                  <!--About Me-->
                  <textarea
                    class="form-control"
                    v-model="data.desc"
                    :placeholder="$t('jobAds.data.desc')"
                  ></textarea>
                  <!--About Me-->
                </div>
                <!--Input Wrapper-->
              </div>

              <div class="col-md-6">
                <!--Input Wrapper-->
                <div class="input_wrapper reg_style">
                  <!--About Me-->
                  <textarea
                    class="form-control"
                    v-model="data.descEn"
                    :placeholder="$t('jobAds.data.descEn')"
                  ></textarea>
                  <!--About Me-->
                </div>
                <!--Input Wrapper-->
              </div>

              <div class="col-md-12">
                <div class="row justify-content-center">
                  <div class="col-md-10">
                    <!--More Features-->
                    <div class="more_features">
                      <!--More Feature Item-->
                      <div class="more_feat_item">
                        <h4>{{ $t("jobAds.data.cost") }}</h4>
                        <span>{{ data.price }} {{ $t("riyal") }}</span>
                      </div>
                      <!--More Feature Item-->

                      <!--More Option-->
                      <div class="more_option">
                        <h4>{{ $t("jobAds.data.moreOption") }}</h4>
                      </div>
                      <!--More Option-->

                      <!--More Feature Item-->
                      <div class="more_feat_item">
                        <!--More Check-->
                        <div class="more_check">
                          <input
                            type="checkbox"
                            name="feat"
                            v-model="data.trend_price"
                          />
                          <div class="more_check_text">
                            <span>{{ $t("jobAds.data.featAds1") }}</span>
                            <span>{{ $t("jobAds.data.featAds2") }}</span>
                          </div>
                        </div>
                        <!--More Check-->
                        <span>{{ data.trendPrice }} {{ $t("riyal") }}</span>
                      </div>
                      <!--More Feature Item-->

                      <!--More Feature Item-->
                      <div class="more_feat_item">
                        <!--More Check-->
                        <div class="more_check">
                          <input
                            type="checkbox"
                            name="social"
                            v-model="data.social_price"
                          />
                          <div class="more_check_text">
                            <span>{{ $t("jobAds.data.socialPost") }}</span>
                          </div>
                        </div>
                        <!--More Check-->
                        <span>{{ data.socialPrice }} {{ $t("riyal") }}</span>
                      </div>
                      <!--More Feature Item-->

                      <!--More Feature Item-->
                      <div class="more_feat_item">
                        <!--More Check-->
                        <div class="more_check">
                          <input
                            type="checkbox"
                            name="social"
                            v-model="data.hide_name_price"
                          />
                          <div class="more_check_text">
                            <span>{{ $t("jobAds.data.hideCompany") }}</span>
                          </div>
                        </div>
                        <!--More Check-->
                        <span>{{ data.hideNamePrice }} {{ $t("riyal") }}</span>
                      </div>
                      <!--More Feature Item-->

                      <!--Divider-->
                      <div class="divider">
                        <hr class="line" />
                      </div>
                      <!--Divider-->

                      <!--More Feature Item-->
                      <div class="more_feat_item">
                        <h4>{{ $t("jobAds.data.total") }}</h4>
                        <span>{{ total }} {{ $t("riyal") }}</span>
                      </div>
                      <!--More Feature Item-->
                    </div>
                    <!--More Features-->
                  </div>
                </div>
              </div>

              <div class="col-sm-12 mt-5">
                <button
                  type="button"
                  class="main_button2 button register_btn"
                  ref="job"
                  @click="editJob"
                >
                  <i class="fas fa-spinner"></i>
                  {{ $t("jobAds.data.payPost") }}
                </button>
              </div>
            </div>
          </form>
        </div>
        <!--Form Inputs-->
      </div>
    </div>
    <!--Register Wizard Form-->
  </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";
export default {
  props: ["id"],
  data() {
    return {
      //Cities
      cities: [],
      //Cities

      //Specialists
      specialists: [],
      //Specialists

      //Sub Specialists
      subSpecialists: [],
      //Sub Specialists

      //Prices
      prices: [],
      //Prices

      //Types
      Types: [
        {
          id: 1,
          name: this.$t("jobAds.data.male"),
          value: "male",
        },
        {
          id: 2,
          name: this.$t("jobAds.data.female"),
          value: "female",
        },
        {
          id: 3,
          name: this.$t("jobAds.data.both"),
          value: "both",
        },
      ],
      //Types

      //Nationality
      nationality: [
        {
          id: 1,
          name: "مصري",
        },
        {
          id: 2,
          name: "سعودي",
        },
      ],
      //Nationality

      userType: "",

      //Data
      data: {
        jobTitle: null,
        jobTitleEn: null,
        nationality: null,
        unlimited: false,
        salary: null,
        gender: null,
        city: "",
        specialist: "",
        subSpecialist: "",
        experience: "",
        desc: null,
        descEn: null,
        price: null,
        trend_price: false,
        trendPrice: null,
        social_price: false,
        socialPrice: null,
        hide_name_price: false,
        hideNamePrice: null,
      },
    };
  },
  methods: {
    //Upload Cv
    uploadFile(e) {
      this.data.uploadCv = e.target.files[0];
      this.data.beforeUpload = false;
      this.data.afterUpload = true;
    },
    //Upload Cv

    //Get Data
    getData() {
      //====Cities=======
      this.$axios({
        method: "GET",
        url: "cities",
      }).then((res) => {
        this.cities = res.data.data;
      });
      //====Main Category=======
      this.$axios({
        method: "GET",
        url: "categories",
      }).then((res) => {
        this.specialists = res.data.data;
      });
      //====Education Levels=======
      this.$axios({
        method: "GET",
        url: "education_levels",
      }).then((res) => {
        this.educationLevels = res.data.data;
      });
      //====Prices=======
      this.$axios({
        method: "GET",
        url: "prices",
      }).then((res) => {
        this.prices = res.data.data;
      });
      //====Job Data=======
      this.$axios({
        method: "GET",
        url: "company/work/" + this.id,
        headers: {
          Authorization: "bearer" + localStorage.getItem("SAS_App_Token"),
        },
      }).then((res) => {
        let jobData = res.data.data;
        this.data.jobTitle = jobData.name_ar;
        this.data.jobTitleEn = jobData.name_en;
        this.data.experience = jobData.experience_years;
        this.data.specialist = jobData.category.id;
        this.data.subSpecialist = jobData.sub_category.id;
        this.data.city = jobData.city.id;
        this.data.unlimited = !!!jobData.has_salary;
        this.data.salary = jobData.salary;
        this.data.gender = jobData.gender;
        this.data.nationality = jobData.work_for;
        this.data.desc = jobData.desc_ar;
        this.data.descEn = jobData.desc_en;
        this.data.trend_price = !!jobData.is_trend;
        this.data.trendPrice = jobData.is_trend_price;
        this.data.social_price = !!jobData.is_social;
        this.data.socialPrice = jobData.is_social_price;
        this.data.hide_name_price = !!jobData.is_hide_name_price;
        this.data.hideNamePrice = jobData.is_hide_name_price;
        this.data.price = jobData.price;
        // this.data.total = !!jobData.total;
        this.getSub();
      });
    },

    //====Sub Category=======
    getSub() {
      this.$axios({
        method: "GET",
        url: "subcategories?category_id=" + this.data.specialist,
      }).then((res) => {
        this.subSpecialists = res.data.data;
      });
    },

    //Edit Job
    editJob() {
      var submitButton = this.$refs["job"];
      submitButton.classList.add("click");
      if (!this.data.jobTitle) {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.$iziToast.error({
            message: this.$t("jobAds.validation.title"),
          });
        }, 1000);
      } else if (!this.data.jobTitleEn) {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.$iziToast.error({
            message: this.$t("jobAds.validation.titleEn"),
          });
        }, 1000);
      } else if (!this.data.experience) {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.$iziToast.error({
            message: this.$t("jobAds.validation.experience"),
          });
        }, 1000);
      } else if (!this.data.specialist) {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.$iziToast.error({
            message: this.$t("jobAds.validation.specialist"),
          });
        }, 1000);
      } else if (!this.data.subSpecialist) {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.$iziToast.error({
            message: this.$t("jobAds.validation.subSpecialist"),
          });
        }, 1000);
      } else if (!this.data.city) {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.$iziToast.error({
            message: this.$t("jobAds.validation.city"),
          });
        }, 1000);
      } else if (!this.data.salary && this.data.unlimited) {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.$iziToast.error({
            message: this.$t("jobAds.validation.salary"),
          });
        }, 1000);
      } else if (!this.data.gender) {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.$iziToast.error({
            message: this.$t("jobAds.validation.gender"),
          });
        }, 1000);
      } else if (!this.data.nationality) {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.$iziToast.error({
            message: this.$t("jobAds.validation.nationality"),
          });
        }, 1000);
      } else if (!this.data.desc) {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.$iziToast.error({
            message: this.$t("register.validation.desc"),
          });
        }, 1000);
      } else if (!this.data.descEn) {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.$iziToast.error({
            message: this.$t("register.validation.descEn"),
          });
        }, 1000);
      } else {
        let submit_data = {
          ar: {
            name: this.data.jobTitle,
            desc: this.data.desc,
          },
          en: {
            name: this.data.jobTitleEn,
            desc: this.data.descEn,
          },
          city_id: this.data.city,
          categories_id: this.data.specialist,
          sub_categories_id: this.data.subSpecialist,
          experience_years: this.data.experience,
          has_salary: +!this.data.unlimited,
          salary: this.data.salary,
          gender: this.data.gender,
          work_for: this.data.nationality,
          price: this.data.price,
          is_trend: +this.data.trend_price,
          is_trend_price: this.data.trendPrice,
          is_social: +this.data.social_price,
          is_social_price: this.data.socialPrice,
          is_hide_name: +this.data.hide_name_price,
          is_hide_name_price: this.data.hideNamePrice,
          total: this.total,
        };
        if (this.data.unlimited != 0) {
          delete submit_data.salary;
        }
        if (this.data.trend_price == 0) {
          delete submit_data.is_trend_price;
        }
        if (this.data.social_price == 0) {
          delete submit_data.is_social_price;
        }
        if (this.data.hide_name_price == 0) {
          delete submit_data.is_hide_name_price;
        }
        this.$axios({
          method: "PUT",
          url: "company/work/" + this.id,
          data: submit_data,
          headers: {
            Authorization: "bearer" + localStorage.getItem("SAS_App_Token"),
          },
        })
          .then(() => {
            setTimeout(() => {
              submitButton.classList.remove("click");
              this.$iziToast.success({
                message: this.$t("global.editSuccess"),
              });
            }, 1000);
          })
          .catch((err) => {
            setTimeout(() => {
              submitButton.classList.remove("click");
              this.$iziToast.error({
                message: err.response.data.message,
              });
            }, 1000);
          });
      }
    },
  },
  components: {
    Datepicker,
  },
  computed: {
    total() {
      return (
        Number(this.data.price) +
        (!this.data.trend_price ? 0 : Number(this.data.trendPrice)) +
        (!this.data.social_price ? 0 : Number(this.data.socialPrice)) +
        (!this.data.hide_name_price ? 0 : Number(this.data.hideNamePrice))
      );
    },
  },
  created() {
    this.getData();
  },
};
</script>

<style></style>
