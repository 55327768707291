<template>
  <div class="register_page">
    <!--Top Background-->
    <div class="top_background">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="title">
              <h4>{{ $t("forgetPassword.title") }}</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--Top Background-->

    <!--About-->
    <div class="container">
      <!--All Text-->
      <div class="all_text forget_password">
        <div class="row justify-content-center">
          <div class="col-md-8">
            <div class="form_inputs">
              <h4>{{ $t("forgetPassword.sorryLose") }}</h4>
              <p>
                {{ $t("forgetPassword.sendCode") }}
                <span class="dif_mail">{{ email }}</span>
              </p>
              <form>
                <!--Start Input Wrapper Email-->
                <div class="input_wrapper reg_style forget_style">
                  <span>{{ $t("forgetPassword.enterCode") }}</span>
                  <input
                    type="number"
                    class="form-control"
                    v-model="code"
                    :placeholder="$t('forgetPassword.code')"
                  />
                </div>
                <!--End Input Wrapper Email-->

                <!--Login Button-->
                <button
                  class="main_button2 button register_btn"
                  ref="forget"
                  type="button"
                  @click="checkCode"
                >
                  <i class="fas fa-spinner"></i>
                  <span>{{ $t("next") }}</span>
                </button>
                <!--Login Button-->
              </form>
            </div>
          </div>
        </div>
      </div>
      <!--All Text-->
    </div>
    <!--About-->
  </div>
</template>

<script>
export default {
  data() {
    return {
      //code
      code: null,
    };
  },
  methods: {
    checkCode() {
      var submitButton = this.$refs["forget"];
      submitButton.classList.add("click");
      if (!this.code) {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.$iziToast.error({
            message: this.$t("forgetPassword.validation.code"),
          });
        }, 1000);
      } else if (this.code != this.devMessage) {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.$iziToast.error({
            message: this.$t("forgetPassword.validation.codeWrong"),
          });
        }, 1000);
      } else {
        let submit_data = new FormData();
        submit_data.append("email", this.email);
        submit_data.append("code", this.code);
        this.$axios({
          method: "post",
          url: "check_code",
          data: submit_data,
        })
          .then(() => {
            setTimeout(() => {
              submitButton.classList.remove("click");
              this.$iziToast.success({
                message: this.$t("auth.validation.codeTrue"),
              });
              this.$router.push({ path: "/resetPassword" });
              const data = {
                email: this.email,
                devMessage: this.code,
              };

              this.$store.dispatch("auth_module/register", data);
            }, 1000);
          })
          .catch((err) => {
            setTimeout(() => {
              submitButton.classList.remove("click");
              this.$iziToast.error({
                message: err.response.data.message,
              });
            }, 1000);
          });
      }
    },
  },
  computed: {
    devMessage() {
      return this.$store.getters["auth_module/devMessage"];
    },
    email() {
      return this.$store.getters["auth_module/email"];
    },
  },
};
</script>

<style></style>
