<template>
  <!--Employes Card-->
  <div class="employes_card">
    <!--Employee Info-->
    <div class="emp_info diff_emp">
      <div>
        <span>{{ $t("employee.data.name") }}</span>
        <span>{{ employee.first_name + " " + employee.last_name }}</span>
      </div>
      <button class="fav_btn" type="button" @click="doLike(employee.id)">
        <i class="fal fa-star" v-if="!isLiked"></i>
        <i class="fas fa-star" v-else></i>
      </button>
    </div>
    <!--Employee Info-->

    <!--Employee Info-->
    <div class="emp_info">
      <span>{{ $t("employee.data.phone") }}</span>
      <span>{{ employee.phone }}</span>
    </div>
    <!--Employee Info-->

    <!--Employee Info-->
    <!-- <div class="emp_info">
      <span>{{ $t("employee.data.accounts") }}</span>
      <span>{{ employee.accounts }}</span>
    </div> -->
    <!--Employee Info-->

    <!--Employee Info-->
    <div class="emp_info">
      <span>{{ $t("employee.data.experience") }}</span>
      <span>{{ employee.experience_years }}</span>
    </div>
    <!--Employee Info-->

    <!--Employee Actions-->
    <div class="emp_actions">
      <a :href="employee.cv" download target="_blank">{{
        $t("employee.action.downloadCv")
      }}</a>
      <router-link :to="'/appliedData/' + employee.id">{{
        $t("employee.action.showApplies")
      }}</router-link>
    </div>
    <!--Employee Actions-->
  </div>
  <!--Employes Card-->
</template>

<script>
export default {
  props: ["employee", "isLiked"],
  methods: {
    //Do Like
    doLike(id) {
      let submit_data = new FormData();
      submit_data.append("user_id", id);
      this.$axios({
        method: "POST",
        url: "company/like_cv",
        data: submit_data,
      })
        .then(() => {
          this.isLiked = !this.isLiked;
          // this.$iziToast.success({
          //   message: this.$t("addFavourite"),
          // });
        })
        .catch((err) => {
          this.$iziToast.error({
            message: err.respone.data.message,
          });
        });
    },
  },
};
</script>

<style></style>
