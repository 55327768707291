<template>
  <!--Start Latest Jobs-->
  <section class="latest_jobs Pad_50" v-if="item">
    <div class="container">
      <div class="row">
        <div class="col-sm-9">
          <!--Top Title-->
          <div class="side_title">
            <h3>{{ $t("latestJobs.title") }}</h3>
            <!-- <a href="" v-if="!show">{{ $t("showAll") }}</a> -->
          </div>
          <!--Top Title-->
        </div>
      </div>
      <div class="row">
        <div class="col-md-9">
          <not-found v-if="item.new_works.length == 0"></not-found>
          <div class="row" v-else>
            <div class="col-md-6" v-for="job in item.new_works" :key="job.id">
              <job-card :job="job"></job-card>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <!--Image-->
          <!-- <div class="image">
            <img src="@/assets/images/icons/ads.png" />
          </div> -->
          <!--Image-->
        </div>
      </div>
    </div>
  </section>
  <!--End Latest Jobs-->
</template>

<script>
import JobCard from "../JobCard/JobCard.vue";
export default {
  props: ["item", "show"],
  components: { JobCard },
  data() {
    return {
      //START:: JOBS
      jobs: [
        {
          id: Math.random()
            .toString(36)
            .replace(/[^a-z]+/g, "")
            .substr(0, 5),
          title: "مهندس الات",
          company: "ارامكو السعودية",
          address: "الرياض",
          desc: "لوريم ايبسوم هو نموذج افتراضي يوضع في التصاميم لتعرض علىالعميل ليتصور طريقه وضع النصوص بالتصاميم ...",
        },
        {
          id: Math.random()
            .toString(36)
            .replace(/[^a-z]+/g, "")
            .substr(0, 5),
          title: "مهندس الات",
          company: "ارامكو السعودية",
          address: "الرياض",
          desc: "لوريم ايبسوم هو نموذج افتراضي يوضع في التصاميم لتعرض علىالعميل ليتصور طريقه وضع النصوص بالتصاميم ...",
        },
        {
          id: Math.random()
            .toString(36)
            .replace(/[^a-z]+/g, "")
            .substr(0, 5),
          title: "مهندس الات",
          company: "ارامكو السعودية",
          address: "الرياض",
          desc: "لوريم ايبسوم هو نموذج افتراضي يوضع في التصاميم لتعرض علىالعميل ليتصور طريقه وضع النصوص بالتصاميم ...",
        },
        {
          id: Math.random()
            .toString(36)
            .replace(/[^a-z]+/g, "")
            .substr(0, 5),
          title: "مهندس الات",
          company: "ارامكو السعودية",
          address: "الرياض",
          desc: "لوريم ايبسوم هو نموذج افتراضي يوضع في التصاميم لتعرض علىالعميل ليتصور طريقه وضع النصوص بالتصاميم ...",
        },
        {
          id: Math.random()
            .toString(36)
            .replace(/[^a-z]+/g, "")
            .substr(0, 5),
          title: "مهندس الات",
          company: "ارامكو السعودية",
          address: "الرياض",
          desc: "لوريم ايبسوم هو نموذج افتراضي يوضع في التصاميم لتعرض علىالعميل ليتصور طريقه وضع النصوص بالتصاميم ...",
        },
        {
          id: Math.random()
            .toString(36)
            .replace(/[^a-z]+/g, "")
            .substr(0, 5),
          title: "مهندس الات",
          company: "ارامكو السعودية",
          address: "الرياض",
          desc: "لوريم ايبسوم هو نموذج افتراضي يوضع في التصاميم لتعرض علىالعميل ليتصور طريقه وضع النصوص بالتصاميم ...",
        },
      ],
      //END:: JOBS
    };
  },
};
</script>

<style></style>
