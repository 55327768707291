<template>
  <div class="register_page">
    <!--Top Background-->
    <div class="top_background">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="title">
              <h4>{{ $t("jobAds.add") }}</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--Top Background-->
    <!--Register Wizard Form-->
    <div class="container">
      <p class="mt-2">
        {{ $t("jobAds.desc") }}
      </p>
      <div class="register_wizard_form">
        <div class="wizard_title">
          <h3>{{ $t("jobAds.title") }}</h3>
        </div>
        <!--Form Inputs-->
        <div class="form_inputs">
          <form>
            <div class="row">
              <div class="col-md-8">
                <!--Input Wrapper-->
                <div class="input_wrapper reg_style" v-if="lang_global == 'ar'">
                  <!--Job Title-->
                  <input
                    type="text"
                    class="form-control"
                    v-model="data.jobTitle"
                    @keypress="isLetter($event)"
                    :placeholder="$t('jobAds.data.title')"
                  />
                  <!--Job Title-->
                </div>
                <!--Input Wrapper-->

                <!--Input Wrapper-->
                <div class="input_wrapper reg_style" v-if="lang_global == 'en'">
                  <!--Job Title-->
                  <input
                    type="text"
                    class="form-control"
                    v-model="data.jobTitle"
                    @keypress="isLetter2($event)"
                    :placeholder="$t('jobAds.data.title')"
                  />
                  <!--Job Title-->
                </div>
                <!--Input Wrapper-->

                <!--Input Wrapper-->
                <div class="input_wrapper reg_style" v-if="lang_global == 'ar'">
                  <!--Job Title-->
                  <input
                    type="text"
                    class="form-control"
                    v-model="data.jobTitleEn"
                    @keypress="isLetter2($event)"
                    :placeholder="$t('jobAds.data.titleEn')"
                  />
                  <!--Job Title-->
                </div>
                <!--Input Wrapper-->

                <!--Input Wrapper-->
                <div class="input_wrapper reg_style" v-if="lang_global == 'en'">
                  <!--Job Title-->
                  <input
                    type="text"
                    class="form-control"
                    v-model="data.jobTitleEn"
                    @keypress="isLetter($event)"
                    :placeholder="$t('jobAds.data.titleEn')"
                  />
                  <!--Job Title-->
                </div>
                <!--Input Wrapper-->
              </div>

              <div class="col-md-4">
                <!--Input Wrapper-->
                <div class="input_wrapper reg_style">
                  <!-- Experience-->
                  <input
                    type="text"
                    class="form-control"
                    v-model="data.experience"
                    :placeholder="$t('jobAds.data.experience')"
                    pattern="/^-?\d+\.?\d*$/"
                    onKeyPress="if(this.value.length == 2) {
                        return false;
                      }"
                    min="0"
                    oninput="
                        this.value =
                          !!this.value && Math.abs(this.value) >= 0
                            ? Math.abs(this.value)
                            : null
                      "
                  />
                  <!-- Experience-->
                </div>
                <!--Input Wrapper-->
              </div>

              <div class="col-md-6">
                <!--Input Wrapper-->
                <div class="input_wrapper reg_style select">
                  <!--Select Specialists-->
                  <select
                    class="form-control"
                    v-model="data.specialist"
                    @change="getSub(), (data.subSpecialist = '')"
                  >
                    <option disabled value="">
                      {{ $t("jobAds.data.specialist") }}
                    </option>
                    <option
                      v-for="spe in specialists"
                      :key="spe.id"
                      :value="spe.id"
                    >
                      {{ spe.name }}
                    </option>
                  </select>
                  <!--Select Specialists-->
                </div>
                <!--Input Wrapper-->
              </div>

              <div class="col-md-6">
                <!--Input Wrapper-->
                <div class="input_wrapper reg_style select">
                  <!--Select Sub Specialists-->
                  <select class="form-control" v-model="data.subSpecialist">
                    <option disabled value="">
                      {{ $t("jobAds.data.subSpecialist") }}
                    </option>
                    <option
                      v-for="sub in subSpecialists"
                      :key="sub.id"
                      :value="sub.id"
                    >
                      {{ sub.name }}
                    </option>
                  </select>
                  <!--Select Sub Specialists-->
                </div>
                <!--Input Wrapper-->
              </div>

              <div class="col-md-6">
                <!--Input Wrapper-->
                <div class="input_wrapper reg_style select">
                  <!--Select City-->
                  <select class="form-control" v-model="data.city">
                    <option disabled value="">
                      {{ $t("jobAds.data.city") }}
                    </option>
                    <option
                      v-for="city in cities"
                      :key="city.id"
                      :value="city.id"
                    >
                      {{ city.name }}
                    </option>
                  </select>
                  <!--Select City-->
                </div>
                <!--Input Wrapper-->
              </div>

              <div class="col-md-10">
                <div class="wrapper_flex profile_wrapper">
                  <span class="profile_label">{{
                    $t("jobAds.data.salary")
                  }}</span>
                  <!--Input Wrapper-->
                  <div class="input_wrapper reg_style">
                    <!--Last Name-->
                    <input
                      type="number"
                      class="form-control"
                      id="inputBox"
                      @keydown="blockChar"
                      :class="{ disabled: data.unlimited }"
                      v-model="data.salary"
                      :placeholder="$t('jobAds.data.salary')"
                    />
                    <!--Last Name-->
                  </div>
                  <!--Input Wrapper-->
                  <span class="profile_label">{{ $t("riyal") }}</span>
                  <!--Unlimited Salary-->
                  <div class="check_box">
                    <input type="checkbox" id="sal" v-model="data.unlimited" />
                    <label for="sal">{{
                      $t("jobAds.data.unlimitedSalary")
                    }}</label>
                  </div>
                  <!--Unlimited Salary-->
                </div>
              </div>

              <div class="col-md-7">
                <!--Sex-->
                <div class="wrapper_flex profile_wrapper mb-10">
                  <span class="profile_label">{{
                    $t("jobAds.data.gender")
                  }}</span>
                  <div class="choice_list">
                    <div class="check_box" v-for="type in Types" :key="type.id">
                      <input
                        type="radio"
                        :id="'type' + type.id"
                        name="sex"
                        :value="type.value"
                        v-model="data.gender"
                      />
                      <label :for="'type' + type.id">{{ type.name }}</label>
                    </div>
                    <!-- <div class="check_box">
                      <input
                        type="checkbox"
                        id="female"
                        name="sex"
                        value="female"
                        v-model="data.gender"
                      />
                      <label for="female">{{ $t("jobAds.data.female") }}</label>
                    </div>
                    <div class="check_box">
                      <input
                        type="checkbox"
                        id="both"
                        name="sex"
                        value="both"
                        v-model="data.gender"
                      />
                      <label for="both">{{ $t("jobAds.data.both") }}</label>
                    </div> -->
                  </div>
                </div>
                <!--Sex-->
              </div>

              <div class="col-md-7">
                <!--Job For-->
                <div class="wrapper_flex profile_wrapper mb-10">
                  <span class="profile_label">{{
                    $t("jobAds.data.jobFor")
                  }}</span>
                  <div class="choice_list">
                    <div class="check_box">
                      <input
                        type="radio"
                        id="saudi"
                        name="j"
                        v-model="data.nationality"
                        value="saudi"
                      />
                      <label for="saudi">{{ $t("jobAds.data.saudi") }}</label>
                    </div>
                    <div class="check_box">
                      <input
                        type="radio"
                        id="resident"
                        name="j"
                        v-model="data.nationality"
                        value="resident"
                      />
                      <label for="resident">{{
                        $t("jobAds.data.resident")
                      }}</label>
                    </div>
                    <div class="check_box">
                      <input
                        type="radio"
                        id="all"
                        name="j"
                        value="both"
                        v-model="data.nationality"
                      />
                      <label for="all">{{ $t("jobAds.data.saudiRes") }}</label>
                    </div>
                  </div>
                </div>
                <!--Job For-->
              </div>

              <div class="col-md-6">
                <!--Input Wrapper-->
                <div class="input_wrapper reg_style" v-if="lang_global == 'ar'">
                  <!--About Me-->
                  <textarea
                    class="form-control"
                    v-model="data.desc"
                    @keypress="isLetter($event)"
                    :placeholder="$t('jobAds.data.desc')"
                  ></textarea>
                  <!--About Me-->
                </div>
                <!--Input Wrapper-->

                <!--Input Wrapper-->
                <div class="input_wrapper reg_style" v-if="lang_global == 'en'">
                  <!--About Me-->
                  <textarea
                    class="form-control"
                    v-model="data.desc"
                    @keypress="isLetter2($event)"
                    :placeholder="$t('jobAds.data.desc')"
                  ></textarea>
                  <!--About Me-->
                </div>
                <!--Input Wrapper-->
              </div>

              <div class="col-md-6">
                <!--Input Wrapper-->
                <div class="input_wrapper reg_style" v-if="lang_global == 'ar'">
                  <!--About Me-->
                  <textarea
                    class="form-control"
                    v-model="data.descEn"
                    @keypress="isLetter2($event)"
                    :placeholder="$t('jobAds.data.descEn')"
                  ></textarea>
                  <!--About Me-->
                </div>
                <!--Input Wrapper-->

                <!--Input Wrapper-->
                <div class="input_wrapper reg_style" v-if="lang_global == 'en'">
                  <!--About Me-->
                  <textarea
                    class="form-control"
                    v-model="data.descEn"
                    @keypress="isLetter($event)"
                    :placeholder="$t('jobAds.data.descEn')"
                  ></textarea>
                  <!--About Me-->
                </div>
                <!--Input Wrapper-->
              </div>

              <div class="col-md-12">
                <div class="row justify-content-center">
                  <div class="col-md-10">
                    <!--More Features-->
                    <div class="more_features">
                      <!--More Feature Item-->
                      <div class="more_feat_item">
                        <h4>{{ $t("jobAds.data.cost") }}</h4>
                        <span>{{ prices.price }} {{ $t("riyal") }}</span>
                      </div>
                      <!--More Feature Item-->

                      <!--More Option-->
                      <div class="more_option">
                        <h4>{{ $t("jobAds.data.moreOption") }}</h4>
                      </div>
                      <!--More Option-->

                      <!--More Feature Item-->
                      <div class="more_feat_item">
                        <!--More Check-->
                        <div class="more_check">
                          <input
                            type="checkbox"
                            name="feat"
                            v-model="data.trend_price"
                          />
                          <div class="more_check_text">
                            <span>{{ $t("jobAds.data.featAds1") }}</span>
                            <span>{{ $t("jobAds.data.featAds2") }}</span>
                          </div>
                        </div>
                        <!--More Check-->
                        <span
                          >{{ prices.is_trend_price }} {{ $t("riyal") }}</span
                        >
                      </div>
                      <!--More Feature Item-->

                      <!--More Feature Item-->
                      <div class="more_feat_item">
                        <!--More Check-->
                        <div class="more_check">
                          <input
                            type="checkbox"
                            name="social"
                            v-model="data.social_price"
                          />
                          <div class="more_check_text">
                            <span>{{ $t("jobAds.data.socialPost") }}</span>
                          </div>
                        </div>
                        <!--More Check-->
                        <span
                          >{{ prices.is_social_price }} {{ $t("riyal") }}</span
                        >
                      </div>
                      <!--More Feature Item-->

                      <!--More Feature Item-->
                      <div class="more_feat_item">
                        <!--More Check-->
                        <div class="more_check">
                          <input
                            type="checkbox"
                            name="social"
                            v-model="data.hide_name_price"
                          />
                          <div class="more_check_text">
                            <span>{{ $t("jobAds.data.hideCompany") }}</span>
                          </div>
                        </div>
                        <!--More Check-->
                        <span
                          >{{ prices.is_hide_name_price }}
                          {{ $t("riyal") }}</span
                        >
                      </div>
                      <!--More Feature Item-->

                      <!--Divider-->
                      <div class="divider">
                        <hr class="line" />
                      </div>
                      <!--Divider-->

                      <!--More Feature Item-->
                      <div class="more_feat_item">
                        <h4>{{ $t("jobAds.data.total") }}</h4>
                        <span>{{ total }} {{ $t("riyal") }}</span>
                      </div>
                      <!--More Feature Item-->
                    </div>
                    <!--More Features-->
                  </div>
                </div>
              </div>

              <div class="col-sm-12 mt-5">
                <button
                  type="button"
                  class="main_button2 button register_btn"
                  ref="job"
                  @click="addJob"
                >
                  <i class="fas fa-spinner"></i>
                  {{ $t("jobAds.data.payPost") }}
                </button>
              </div>
            </div>
          </form>
        </div>
        <!--Form Inputs-->
      </div>
    </div>
    <!--Register Wizard Form-->

    <!--Payment Model-->
    <base-model :show="show" @closeModel="closeModel">
      <div class="custom_card">
        <form id="form-container" method="post" action="/charge">
          <!-- Tap element will be here -->
          <div id="element-container"></div>
          <div id="error-handler" role="alert"></div>
          <!-- <div
            id="success"
            style="display: none; position: relative; float: left"
          >
            Success! Your token is <span id="token"></span>
          </div> -->
          <!-- Tap pay button -->
          <!-- <button id="tap-btn" @click="upload">{{ $t("pay") }}</button> -->
          <button class="main_button button" id="tap-btn" ref="job2">
            <i class="fas fa-spinner"></i>
            <span>{{ $t("pay") }}</span>
          </button>
        </form>
      </div>
    </base-model>
    <!--Payment Model-->
  </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";
export default {
  data() {
    return {
      //Cities
      cities: [],
      //Cities

      trans_id: this.$route.query.tap_id,
      status: this.$route.query.status,
      id: this.$route.query.work_id,
      type_pay: this.$route.query.type,
      price: this.$route.query.price,

      //Specialists
      specialists: [],
      //Specialists

      //Sub Specialists
      subSpecialists: [],
      //Sub Specialists

      //Prices
      prices: [],
      //Prices

      //Show Pay
      show: false,

      //Types
      Types: [
        {
          id: 1,
          name: this.$t("jobAds.data.male"),
          value: "male",
        },
        {
          id: 2,
          name: this.$t("jobAds.data.female"),
          value: "female",
        },
        {
          id: 3,
          name: this.$t("jobAds.data.both"),
          value: "both",
        },
      ],
      //Types

      //Nationality
      nationality: [
        {
          id: 1,
          name: "مصري",
        },
        {
          id: 2,
          name: "سعودي",
        },
      ],
      //Nationality

      userType: "",

      //Data
      data: {
        jobTitle: null,
        jobTitleEn: null,
        nationality: null,
        unlimited: false,
        salary: null,
        gender: null,
        city: "",
        specialist: "",
        subSpecialist: "",
        experience: "",
        desc: null,
        descEn: null,
        trend_price: false,
        social_price: false,
        hide_name_price: false,
      },
    };
  },
  methods: {
    //Upload Cv
    uploadFile(e) {
      this.data.uploadCv = e.target.files[0];
      this.data.beforeUpload = false;
      this.data.afterUpload = true;
    },
    //Upload Cv

    openModel() {
      localStorage.removeItem("Sas_TransId");
      var submitButton = this.$refs["job"];
      submitButton.classList.add("click");
      if (!this.data.jobTitle) {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.$iziToast.error({
            message: this.$t("jobAds.validation.title"),
          });
        }, 1000);
      } else if (!this.data.jobTitleEn) {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.$iziToast.error({
            message: this.$t("jobAds.validation.titleEn"),
          });
        }, 1000);
      } else if (!this.data.experience) {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.$iziToast.error({
            message: this.$t("jobAds.validation.experience"),
          });
        }, 1000);
      } else if (!this.data.specialist) {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.$iziToast.error({
            message: this.$t("jobAds.validation.specialist"),
          });
        }, 1000);
      } else if (!this.data.subSpecialist) {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.$iziToast.error({
            message: this.$t("jobAds.validation.subSpecialist"),
          });
        }, 1000);
      } else if (!this.data.city) {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.$iziToast.error({
            message: this.$t("jobAds.validation.city"),
          });
        }, 1000);
      } else if (!this.data.salary && !this.data.unlimited) {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.$iziToast.error({
            message: this.$t("jobAds.validation.salary"),
          });
        }, 1000);
      } else if (!this.data.gender) {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.$iziToast.error({
            message: this.$t("jobAds.validation.gender"),
          });
        }, 1000);
      } else if (!this.data.nationality) {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.$iziToast.error({
            message: this.$t("jobAds.validation.nationality"),
          });
        }, 1000);
      } else if (!this.data.desc) {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.$iziToast.error({
            message: this.$t("register.validation.desc"),
          });
        }, 1000);
      } else if (!this.data.descEn) {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.$iziToast.error({
            message: this.$t("register.validation.descEn"),
          });
        }, 1000);
      } else {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.show = true;
        }, 1000);
      }
    },

    //CloseModel
    closeModel() {
      this.show = false;
      localStorage.removeItem("Sas_TransId");
    },

    payAmount(id) {
      window.location.href =
        "https://sas-jobs.net/pay?amount=" +
        this.total +
        "&redirect_url=" +
        encodeURIComponent(
          "https://sas-jobs.net/addJob?" +
            "&type=add_work&user_id=" +
            this.userId +
            "&price=" +
            this.total +
            "&work_id=" +
            id
        );
    },

    //Pay Method
    payMethod() {
      //pass your public key from tap's dashboard
      var tap = Tapjsli("pk_live_8eKYZbCptrBD97zaUkNivxgR");

      var elements = tap.elements({});

      var style = {
        base: {
          color: "#535353",
          lineHeight: "18px",
          fontFamily: "sans-serif",
          fontSmoothing: "antialiased",
          fontSize: "16px",
          "::placeholder": {
            color: "rgba(0, 0, 0, 0.26)",
            fontSize: "15px",
          },
        },
        invalid: {
          color: "red",
        },
      };
      // input labels/placeholders
      var labels = {
        cardNumber: "Card Number",
        expirationDate: "MM/YY",
        cvv: "CVV",
        cardHolder: "Card Holder Name",
      };
      //payment options
      var paymentOptions = {
        currencyCode: ["KWD", "USD", "SAR"],
        labels: labels,
        TextDirection: "ltr",
      };
      //create element, pass style and payment options
      var card = elements.create("card", { style: style }, paymentOptions);
      //mount element
      card.mount("#element-container");
      //card change event listener
      card.addEventListener("change", function (event) {
        if (event.loaded) {
          console.log("UI loaded :" + event.loaded);
          console.log("current currency is :" + card.getCurrency());
        }
        var displayError = document.getElementById("error-handler");
        if (event.error) {
          displayError.textContent = event.error.message;
        } else {
          displayError.textContent = "";
        }
      });

      // Handle form submission
      var form = document.getElementById("form-container");
      form.addEventListener("submit", function (event) {
        event.preventDefault();

        tap.createToken(card).then(function (result) {
          console.log(result);
          if (result.error) {
            // Inform the user if there was an error
            console.log("");
            // var errorElement = document.getElementById("error-handler");
            // errorElement.textContent = result.error.message;
          } else {
            // Send the token to your server
            // var errorElement = document.getElementById("success");
            // errorElement.style.display = "block";
            // var tokenElement = document.getElementById("token");
            // tokenElement.textContent = result.id;
            localStorage.setItem("Sas_TransId", result.id);
            tapTokenHandler(result);
          }
        });
      });

      function tapTokenHandler(token) {
        // Insert the token ID into the form so it gets submitted to the server
        var form = document.getElementById("payment-form");
        var hiddenInput = document.createElement("input");
        hiddenInput.setAttribute("type", "hidden");
        hiddenInput.setAttribute("name", "tapToken");
        hiddenInput.setAttribute("value", token.id);
        form.appendChild(hiddenInput);

        // Submit the form
        form.submit();
      }

      card.addEventListener("change", function (event) {
        if (event.BIN) {
          console.log(event.BIN);
        }
        // var displayError = document.getElementById("card-errors");
        // if (event.error) {
        //   displayError.textContent = event.error.message;
        // } else {
        //   displayError.textContent = "";
        // }
      });
    },

    //Get Data
    getData() {
      //====Cities=======
      this.$axios({
        method: "GET",
        url: "cities",
      }).then((res) => {
        this.cities = res.data.data;
      });
      //====Main Category=======
      this.$axios({
        method: "GET",
        url: "categories",
      }).then((res) => {
        this.specialists = res.data.data;
      });
      //====Education Levels=======
      this.$axios({
        method: "GET",
        url: "education_levels",
      }).then((res) => {
        this.educationLevels = res.data.data;
      });
      //====Prices=======
      this.$axios({
        method: "GET",
        url: "prices",
      }).then((res) => {
        this.prices = res.data.data;
      });
    },

    //====Sub Category=======
    getSub() {
      this.$axios({
        method: "GET",
        url: "subcategories?category_id=" + this.data.specialist,
      }).then((res) => {
        this.subSpecialists = res.data.data;
      });
    },

    //Add Job
    addJob() {
      if (!this.token) {
        setTimeout(() => {
          this.$iziToast.error({
            message: this.$t("register.validation.loginFrist"),
          });
          this.$router.push("/login");
        }, 1000);
      } else {
        var submitButton = this.$refs["job"];
        submitButton.classList.add("click");
        if (!this.data.jobTitle) {
          setTimeout(() => {
            submitButton.classList.remove("click");
            this.$iziToast.error({
              message: this.$t("jobAds.validation.title"),
            });
          }, 1000);
        } else if (!this.data.jobTitleEn) {
          setTimeout(() => {
            submitButton.classList.remove("click");
            this.$iziToast.error({
              message: this.$t("jobAds.validation.titleEn"),
            });
          }, 1000);
        } else if (!this.data.experience) {
          setTimeout(() => {
            submitButton.classList.remove("click");
            this.$iziToast.error({
              message: this.$t("jobAds.validation.experience"),
            });
          }, 1000);
        } else if (!this.data.specialist) {
          setTimeout(() => {
            submitButton.classList.remove("click");
            this.$iziToast.error({
              message: this.$t("jobAds.validation.specialist"),
            });
          }, 1000);
        } else if (!this.data.subSpecialist) {
          setTimeout(() => {
            submitButton.classList.remove("click");
            this.$iziToast.error({
              message: this.$t("jobAds.validation.subSpecialist"),
            });
          }, 1000);
        } else if (!this.data.city) {
          setTimeout(() => {
            submitButton.classList.remove("click");
            this.$iziToast.error({
              message: this.$t("jobAds.validation.city"),
            });
          }, 1000);
        } else if (!this.data.salary && !this.data.unlimited) {
          setTimeout(() => {
            submitButton.classList.remove("click");
            this.$iziToast.error({
              message: this.$t("jobAds.validation.salary"),
            });
          }, 1000);
        } else if (!this.data.gender) {
          setTimeout(() => {
            submitButton.classList.remove("click");
            this.$iziToast.error({
              message: this.$t("jobAds.validation.gender"),
            });
          }, 1000);
        } else if (!this.data.nationality) {
          setTimeout(() => {
            submitButton.classList.remove("click");
            this.$iziToast.error({
              message: this.$t("jobAds.validation.nationality"),
            });
          }, 1000);
        } else if (!this.data.desc) {
          setTimeout(() => {
            submitButton.classList.remove("click");
            this.$iziToast.error({
              message: this.$t("register.validation.desc"),
            });
          }, 1000);
        } else if (!this.data.descEn) {
          setTimeout(() => {
            submitButton.classList.remove("click");
            this.$iziToast.error({
              message: this.$t("register.validation.descEn"),
            });
          }, 1000);
        } else {
          setTimeout(() => {
            let submit_data = new FormData();
            submit_data.append("ar[name]", this.data.jobTitle);
            submit_data.append("en[name]", this.data.jobTitleEn);
            submit_data.append("ar[desc]", this.data.desc);
            submit_data.append("en[desc]", this.data.descEn);
            submit_data.append("city_id", this.data.city);
            submit_data.append("categories_id", this.data.specialist);
            submit_data.append("sub_categories_id", this.data.subSpecialist);
            submit_data.append("experience_years", this.data.experience);
            submit_data.append("has_salary", +!this.data.unlimited);
            if (!this.data.unlimited) {
              submit_data.append("salary", this.data.salary);
            }
            submit_data.append("gender", this.data.gender);
            submit_data.append("work_for", this.data.nationality);
            submit_data.append("price", this.prices.price);
            submit_data.append("is_trend", +this.data.trend_price);
            if (this.data.trend_price) {
              submit_data.append("is_trend_price", this.prices.is_trend_price);
            }
            submit_data.append("is_social", +this.data.social_price);
            if (this.data.social_price) {
              submit_data.append(
                "is_social_price",
                this.prices.is_social_price
              );
            }
            submit_data.append("is_hide_name", +this.data.hide_name_price);
            if (this.data.hide_name_price) {
              submit_data.append(
                "is_hide_name_price",
                this.prices.is_hide_name_price
              );
            }
            submit_data.append("transaction_id", "jjjjj");
            submit_data.append("payment_type", "visa");
            submit_data.append("total", this.total);
            this.$axios({
              method: "post",
              url: "company/work",
              data: submit_data,
              headers: {
                Authorization: "bearer" + localStorage.getItem("SAS_App_Token"),
              },
            })
              .then((res) => {
                // this.closeModel();
                setTimeout(() => {
                  submitButton.classList.remove("click");
                  this.$iziToast.success({
                    message: this.$t("global.addJob"),
                  });
                  this.payAmount(res.data.data.id);
                }, 1000);
              })
              .catch((err) => {
                setTimeout(() => {
                  submitButton.classList.remove("click");
                  this.$iziToast.error({
                    message: err.response.data.message,
                  });
                }, 1000);
              });
          }, 900);
        }
      }
    },
    confirmPay() {
      let submit_data = new FormData();
      submit_data.append("type", this.type_pay);
      submit_data.append("id", this.id);
      submit_data.append("transaction_id", this.trans_id);
      submit_data.append("user_id", this.userId);
      submit_data.append("value", this.price);
      this.$axios({
        method: "post",
        url: "set_pay",
        data: submit_data,
      })
        .then(() => {
          this.$iziToast.success({
            message: this.$t("sendSuccess"),
          });
          this.$router.replace("/");
        })
        .catch((err) => {
          setTimeout(() => {
            this.$iziToast.error({
              message: err.response.data.message,
            });
          }, 1000);
        });
    },
  },
  components: {
    Datepicker,
  },
  created() {
    this.getData();
  },
  mounted() {
    // this.payMethod();
    if (this.status == "success") {
      this.confirmPay();
    } else if (this.status == "fail") {
      this.$iziToast.error({
        message: "ghhhhhhh",
      });
    }
  },
  computed: {
    total() {
      return (
        Number(this.prices.price) +
        (!this.data.trend_price ? 0 : Number(this.prices.is_trend_price)) +
        (!this.data.social_price ? 0 : Number(this.prices.is_social_price)) +
        (!this.data.hide_name_price
          ? 0
          : Number(this.prices.is_hide_name_price))
      );
    },
    token() {
      return this.$store.getters["auth_module/token"];
    },
    userId() {
      return this.$store.getters["auth_module/set_userId"];
    },
  },
};
</script>

<style></style>
