<template>
  <!--Start Main Section-->
  <section class="main_section job_search" v-if="item">
    <div class="container">
      <div class="row">
        <div class="col-md-8">
          <!--Section Details-->
          <div class="details">
            <!--Top Part-->
            <div class="top_part">
              <!--Titles-->
              <div class="titles">
                <h4>{{ item.jobs_video_title }}</h4>
              </div>
              <!--Titles-->
              <!--Description-->
              <div class="description">
                <p>
                  {{ item.jobs_video_desc }}
                </p>
              </div>
              <!--Description-->
            </div>
            <!--Top Part-->
            <!--Button Actions-->
            <div class="button_actions">
              <router-link to="/addJob" class="add_cv main_button3">
                <span>{{ $t("navbar.addadver") }}</span>
              </router-link>
              <router-link to="/packages" class="join_package main_button">
                <span>{{ $t("mainSection.joinPackage") }}</span>
              </router-link>
            </div>
            <!--Button Actions-->
          </div>
          <!--Section Details-->
        </div>
        <div class="col-md-4">
          <!--Image-->
          <div class="image">
            <img src="@/assets/images/main_section/video.jpeg" />
            <div class="overlay" @click="openModel(item.jobs_video)">
              <img src="@/assets/images/main_section/youtube-fill.png" />
            </div>
          </div>
          <!--Image-->
        </div>
      </div>
    </div>
    <!-- Dialog -->
    <base-model @closeModel="closeModel" :show="model.show_model">
      <div class="video" v-if="videoSrc">
        <video class="preview_video preview_media" controls>
          <source :src="videoSrc" type="video/mp4" />
        </video>
      </div>
    </base-model>
  </section>
  <!--End Main Section-->
</template>

<script>
import Typewriter from "typewriter-vue";
// import video from "@/assets/videos/test_video.mp4";
export default {
  props: ["item"],
  data() {
    return {
      //Word Animation
      whois: ["<span>مجانا</span>", "<span>مجانا</span>", "<span>مجانا</span>"],
      //Word Animation

      //Model
      model: {
        show_model: false,
      },
      //Model
      videoSrc: null,
    };
  },
  methods: {
    //Start Word Animation
    pollPerson() {
      const first = this.whois.shift();
      this.whois = this.whois.concat(first);
    },
    //End Word Animation

    //Start Model Actions
    openModel(video) {
      this.model.show_model = true;
      this.videoSrc = video;
    },

    closeModel() {
      this.model.show_model = false;
    },
    //End Model Actions
  },
  mounted() {
    window.setInterval(() => {
      this.pollPerson();
    }, 4000);
  },
  components: {
    Typewriter,
  },
};
</script>

<style></style>
