export default {
    // ============================= SET CV STEP
    set_cv_step(state, payload) {
        localStorage.setItem("SAS_CV_STEP", payload);
        state.cvStep = payload;
    },

    // ============================= SET CV ID
    set_cv_id(state, payload) {
        localStorage.setItem("SAS_CV_ID", payload);
        state.cvId = payload;
    },

    // ============================= Remove CV Step
    remove_cv_step(state) {
        state.cvStep = "";
        localStorage.removeItem("SAS_CV_STEP");
    },
    // ============================= Remove CV ID
    remove_cv_id(state) {
        state.cvId = "";
        localStorage.removeItem("SAS_CV_ID");
    },
};