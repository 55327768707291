<template>
  <loader v-if="loading"></loader>
  <div class="form_inputs" v-else>
    <!--Title-->
    <h4>{{ $t("settings.data.title2") }}</h4>
    <!--Title-->

    <!--Form-->
    <form @submit.prevent="saveData()">
      <!--Wrapper Flex-->
      <div class="wrapper_flex profile_wrapper">
        <span class="profile_label">{{ $t("settings.data.name") }}</span>
        <div class="inflex">
          <!--Input Wrapper-->
          <div class="input_wrapper reg_style">
            <!--First Name-->
            <input
              type="text"
              class="form-control"
              :placeholder="$t('settings.data.firstName')"
              v-model="data.firstName"
              @keypress="isLetter($event)"
            />
            <!--First Name-->
          </div>
          <!--Input Wrapper-->

          <!--Input Wrapper-->
          <div class="input_wrapper reg_style">
            <!--Last Name-->
            <input
              type="text"
              class="form-control"
              :placeholder="$t('settings.data.lastName')"
              v-model="data.lastName"
              @keypress="isLetter($event)"
            />
            <!--Last Name-->
          </div>
          <!--Input Wrapper-->
        </div>
      </div>
      <!--Wrapper Flex-->

      <!--Wrapper Flex-->
      <div class="wrapper_flex profile_wrapper">
        <span class="profile_label">{{ $t("settings.data.region") }}</span>
        <!--Input Wrapper-->
        <div class="input_wrapper reg_style select">
          <!--Select City-->
          <select class="form-control" v-model="data.city">
            <option disabled value="">
              {{ $t("settings.data.region") }}
            </option>
            <option v-for="city in cities" :key="city.id" :value="city.id">
              {{ city.name }}
            </option>
          </select>
          <!--Select City-->
        </div>
        <!--Input Wrapper-->
      </div>
      <!--Wrapper Flex-->

      <!--Wrapper Flex-->
      <div class="wrapper_flex profile_wrapper">
        <span class="profile_label">{{ $t("settings.data.email") }}</span>
        <!--Input Wrapper-->
        <div class="input_wrapper reg_style">
          <!--Last Name-->
          <input
            type="text"
            class="form-control"
            :placeholder="$t('settings.data.email')"
            v-model="data.email"
          />
          <!--Last Name-->
        </div>
        <!--Input Wrapper-->
      </div>
      <!--Wrapper Flex-->

      <!--Wrapper Flex-->
      <div class="wrapper_flex profile_wrapper">
        <span class="profile_label">{{ $t("settings.data.phone") }}</span>
        <!--Input Wrapper-->
        <div class="input_wrapper reg_style">
          <!--Last Name-->
          <input
            type="text"
            class="form-control"
            id="inputBox"
            @keydown="blockChar"
            :placeholder="$t('settings.data.phone')"
            v-model="data.phone"
            pattern="/^-?\d+\.?\d*$/"
            onKeyPress="if(this.value.length == 9) {
                        return false;
                      }"
            min="0"
          />
          <!--Last Name-->
        </div>
        <!--Input Wrapper-->
      </div>
      <!--Wrapper Flex-->

      <!--Wrapper Flex-->
      <div class="wrapper_flex profile_wrapper">
        <span class="profile_label">{{ $t("settings.data.birthDate") }}</span>
        <!--Input Wrapper-->
        <div class="input_wrapper reg_style">
          <!--Birth Date-->
          <Datepicker
            :format="formatDate"
            class="form-control"
            v-model="data.birthDate"
            :placeholder="$t('settings.data.birthDate')"
          ></Datepicker>
          <!--Birth Date-->
        </div>
        <!--Input Wrapper-->
      </div>
      <!--Wrapper Flex-->
      <div class="Pad_50">
        <button
          type="button"
          class="main_button button"
          ref="save"
          @click="saveData()"
        >
          <i class="fas fa-spinner"></i>
          <span>حفظ التغيرات</span>
        </button>
      </div>
    </form>
    <!--Form-->
  </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import * as moment from "moment/moment";
export default {
  components: { Datepicker },
  data() {
    return {
      loading: false,
      //Cities
      cities: [],
      //Cities

      //My Data
      myData: null,
      //My Data

      //Data
      data: {
        firstName: null,
        lastName: null,
        phone: null,
        city: "",
        email: null,
        birthDate: null,
      },
      //Data
    };
  },
  methods: {
    //Get Data
    getData() {
      this.loading = true;
      //====Cities=======
      // this.$axios({
      //   method: "GET",
      //   url: "cities",
      // }).then((res) => {
      //   this.cities = res.data.data;
      // });
      //====Profile Data=======
      this.$axios({
        method: "GET",
        url: "profile",
        headers: {
          Authorization: "bearer" + localStorage.getItem("SAS_App_Token"),
        },
      }).then((res) => {
        this.loading = false;
        this.myData = res.data.data;
        this.data.firstName = this.myData.first_name;
        this.data.lastName = this.myData.last_name;
        this.data.city = this.myData.city.id;
        this.data.email = this.myData.email;
        this.data.phone = this.myData.phone;
        this.data.birthDate = this.myData.date_birth;
      });
      //====Cities=======
      this.$axios({
        method: "GET",
        url: "cities",
      }).then((res) => {
        this.cities = res.data.data;
      });
    },

    //Data Formate
    formatDate(date) {
      return moment(date).format("DD-MM-YYYY");
    },
    //Data Formate

    //Save Data
    saveData() {
      var submitButton = this.$refs["save"];
      submitButton.classList.add("click");
      if (!this.data.firstName) {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.$iziToast.error({
            message: this.$t("settings.validation.firstName"),
          });
        }, 1000);
      } else if (!this.data.city) {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.$iziToast.error({
            message: this.$t("settings.validation.region"),
          });
        }, 1000);
      } else if (!this.data.email) {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.$iziToast.error({
            message: this.$t("settings.validation.email"),
          });
        }, 1000);
      } else if (!this.data.phone) {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.$iziToast.error({
            message: this.$t("settings.validation.phone"),
          });
        }, 1000);
      } else if (!this.data.birthDate) {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.$iziToast.error({
            message: this.$t("settings.validation.birthDate"),
          });
        }, 1000);
      } else {
        let submit_data = new FormData();
        submit_data.append("first_name", this.data.firstName);
        submit_data.append("last_name", this.data.lastName);
        submit_data.append("city_id", this.data.city);
        submit_data.append("email", this.data.email);
        submit_data.append("phone", this.data.phone);
        submit_data.append("date_birth", this.formatDate(this.data.birthDate));
        this.$axios({
          method: "post",
          url: "profile",
          data: submit_data,
          headers: {
            Authorization: "bearer" + localStorage.getItem("SAS_App_Token"),
          },
        })
          .then(() => {
            setTimeout(() => {
              submitButton.classList.remove("click");
              this.$iziToast.success({
                message: this.$t("global.editSuccess"),
              });
            }, 1000);
          })
          .catch((err) => {
            setTimeout(() => {
              submitButton.classList.remove("click");
              this.$iziToast.error({
                message: err.response.data.message,
              });
            }, 1000);
          });
      }
    },
  },
  created() {
    this.getData();
  },
};
</script>

<style></style>
