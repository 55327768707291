<template>
  <div class="register_page">
    <!--Top Background-->
    <div class="top_background">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="title">
              <h4>{{ $t("cvsteps.title") }}</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--Top Background-->
    <!--Cv Steps Page-->
    <div class="cv_steps_page">
      <div class="container">
        <div class="register_wizard_form">
          <div class="wizard_title">
            <h4>{{ $t("cvsteps.wizard_title") }}</h4>
            <p>{{ $t("cvsteps.wizard.desc") }}</p>
          </div>
          <!--Progress Bar-->
          <div class="progress_bar">
            <ul>
              <li :class="{ active: step == 1 }">
                <h4>01 {{ $t("cvsteps.wizard.chooseTemplate") }}</h4>
                <p>
                  <span></span>
                </p>
              </li>
              <li :class="{ active: step == 2 }">
                <h4>02 {{ $t("cvsteps.wizard.pay") }}</h4>
                <p>
                  <span></span>
                </p>
              </li>
              <li :class="{ active: step == 3 || step == 4 }">
                <h4>03 {{ $t("cvsteps.wizard.fillData") }}</h4>
                <p>
                  <span></span>
                </p>
              </li>
            </ul>
          </div>
          <!--Progress Bar-->

          <!--Cv Data-->
          <template v-if="step == 4">
            <vue-html2pdf
              :show-layout="false"
              :float-layout="true"
              :enable-download="true"
              :preview-modal="true"
              :paginate-elements-by-height="1400"
              filename="cv"
              :pdf-quality="2"
              :manual-pagination="false"
              pdf-format="a4"
              :pdf-margin="10"
              pdf-orientation="portrait"
              pdf-content-width="800px"
              @progress="onProgress($event)"
              ref="html2Pdf"
            >
              <section slot="pdf-content">
                <template-four
                  :item="cvData"
                  v-if="tempLang == 'ar'"
                ></template-four>

                <template-four-en
                  :item="cvData"
                  v-if="tempLang == 'en'"
                ></template-four-en>
              </section>
            </vue-html2pdf>

            <template-four
              :item="cvData"
              v-if="tempLang == 'ar'"
            ></template-four>
            <button
              type="button"
              class="main_button button mt-3"
              @click="generatePDF()"
              v-if="tempLang == 'ar'"
            >
              <span>{{ $t("download") }}</span>
            </button>

            <template-four-en
              :item="cvData"
              v-if="tempLang == 'en'"
            ></template-four-en>
            <button
              type="button"
              class="main_button button mt-3"
              @click="generatePDF()"
              v-if="tempLang == 'en'"
            >
              <span>{{ $t("download") }}</span>
            </button>

            <!--Both-->
            <vue-html2pdf
              :show-layout="false"
              :float-layout="true"
              :enable-download="true"
              :preview-modal="true"
              :paginate-elements-by-height="1400"
              filename="cv"
              :pdf-quality="2"
              :manual-pagination="false"
              pdf-format="a4"
              :pdf-margin="10"
              pdf-orientation="portrait"
              pdf-content-width="800px"
              @progress="onProgress_ar($event)"
              ref="html2Pdf_ar"
            >
              <section slot="pdf-content">
                <template-four
                  :item="cvData"
                  v-if="tempLang == 'both'"
                ></template-four>
              </section>
            </vue-html2pdf>
            <vue-html2pdf
              :show-layout="false"
              :float-layout="true"
              :enable-download="true"
              :preview-modal="true"
              :paginate-elements-by-height="1400"
              filename="cv"
              :pdf-quality="2"
              :manual-pagination="false"
              pdf-format="a4"
              :pdf-margin="10"
              pdf-orientation="portrait"
              pdf-content-width="800px"
              @progress="onProgress_en($event)"
              ref="html2Pdf_en"
            >
              <section slot="pdf-content">
                <template-four-en
                  :item="cvData"
                  v-if="tempLang == 'both'"
                ></template-four-en>
              </section>
            </vue-html2pdf>

            <template-four
              :item="cvData"
              v-if="tempLang == 'both'"
            ></template-four>
            <button
              type="button"
              class="main_button button mt-3"
              @click="generatePDF_ar()"
              v-if="tempLang == 'both'"
            >
              <span>{{ $t("download") }}</span>
            </button>

            <template-four-en
              :item="cvData"
              v-if="tempLang == 'both'"
            ></template-four-en>
            <div class="actions_btns">
              <button
                type="button"
                class="main_button button mt-3"
                @click="generatePDF_en()"
                v-if="tempLang == 'both'"
              >
                <span>{{ $t("download") }}</span>
              </button>
              <router-link
                :to="`/editCv/${tempLang}/${id}`"
                class="main_button button mt-3"
              >
                <span>{{ $t("edit") }}</span>
              </router-link>
            </div>
          </template>
          <!--Cv Data-->
        </div>
      </div>
    </div>
    <!--Cv Steps Page-->
  </div>
</template>

<script>
import TemplateFour from "../../../components/CvTemplate/TemplateFour.vue";
import TemplateFourEn from "../../../components/CvTemplate/TemplateFourEn.vue";
import html2canvas from "html2canvas";
import VueHtml2pdf from "vue-html2pdf";
import * as moment from "moment/moment";
export default {
  props: ["tempLang", "id"],
  components: {
    TemplateFour,
    TemplateFourEn,
    VueHtml2pdf,
  },
  data() {
    return {
      //Progress Step
      // step: 1,
      //Progress Step

      cvData: null,

      //Data
      allData: null,

      //loading
      loading: false,

      //   tempNum: null,
      //   tempPrice: null,
      //   tempLang: null,

      //Show Model
      show: false,
    };
  },
  methods: {
    onProgress(event) {
      console.log(`Processed: ${event} / 100`);
    },
    onProgress_ar(event) {
      console.log(`Processed: ${event} / 100`);
    },
    onProgress_en(event) {
      console.log(`Processed: ${event} / 100`);
    },
    hasGenerated() {
      alert("PDF generated successfully!");
    },
    generatePDF() {
      // document.getElementById("temco").style.display = "none";
      this.$refs.html2Pdf.generatePdf();
    },
    generatePDF_ar() {
      // document.getElementById("temco").style.display = "none";
      this.$refs.html2Pdf_ar.generatePdf();
    },
    generatePDF_en() {
      // document.getElementById("temco").style.display = "none";
      this.$refs.html2Pdf_en.generatePdf();
    },

    //Cv Details
    getCv() {
      this.$axios({
        method: "GET",
        url: "cv_design_details?cv_id=" + this.id,
      }).then((res) => {
        this.cvData = res.data.data;
      });
    },

    //Country Code
    onSelect({ name, iso2, dialCode }) {
      console.log(name, iso2, dialCode);
      this.data.code = dialCode;
    },
    takeshot() {
      let div = document.getElementById("one_ar");

      // Use the html2canvas
      // function to take a screenshot
      // and append it
      // to the output div
      html2canvas(div).then(function (canvas) {
        document.getElementById("output").appendChild(canvas);
      });
    },
  },
  mounted() {
    this.getCv();
  },
  created() {
    this.$store.dispatch("cv_module/addStep", {
      step: 4,
    });
  },
  computed: {
    step() {
      return this.$store.getters["cv_module/cvStep"];
    },
    cv_id() {
      return this.$store.getters["cv_module/cvId"];
    },
  },
};
</script>

<style>
.skill_item {
  margin-bottom: 15px;
}

.skill_item span {
  font-size: 15px;
  display: flex;
  margin-bottom: 4px;
}

.range {
  width: 400px;
  height: 15px;
  -webkit-appearance: none;
  background: #cecece;
  direction: ltr;
  outline: none;
  border-radius: 0px;
  overflow: hidden;
  /* box-shadow: inset 0 0 5px rgba(0, 0, 0, 1); */
}
.range::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #0eb6d8;
  cursor: pointer;
  border: 4px solid #fff;
  box-shadow: -407px 0 0 400px #0eb6d8;
}

.vue-country-select {
  margin-bottom: 15px;
  margin-inline-end: 5px;
}
</style>
