<template>
  <!--Cv Card-->
  <div class="cv_card">
    <!--Image-->
    <div class="image">
      <img :src="cv.image" />
      <!--Overlay-->
      <div class="overlay"></div>
      <!--Overlay-->
    </div>
    <!--Image-->
    <!--Icon Action-->
    <div class="icon_action">
      <ul>
        <li>
          <a target="_blank" :href="cv.cv" title="مشاهدة السيرة الذاتية"
            ><i class="fas fa-eye"></i
          ></a>
        </li>
        <li>
          <a :href="cv.cv" download title="تحميل السيرة الذاتية"
            ><i class="fas fa-download"></i
          ></a>
        </li>
      </ul>
    </div>
    <!--Icon Action-->
  </div>
  <!--Cv Card-->
</template>

<script>
export default {
  props: ["cv"],
};
</script>

<style></style>
