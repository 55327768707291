<template>
  <div class="homePage">
    <main-section :item="allData"></main-section>
    <our-clients :item="allData" :loading="loading"></our-clients>
    <job-owner-package v-if="userType != 'user'"></job-owner-package>
    <strong-cv></strong-cv>
    <featured-jobs :item="allData2" :loading="loading"></featured-jobs>
    <featured-cv :item="allData2"></featured-cv>
    <jobs-by-categories :show="false"></jobs-by-categories>
    <jobs-by-cities :item="allData"></jobs-by-cities>
    <latest-jobs :item="allData2"></latest-jobs>
    <latest-articles :item="allData2"></latest-articles>
    <newsleter></newsleter>
  </div>
</template>

<script>
import MainSection from "@/components/HomePage/MainSection.vue";
import OurClients from "@/components/HomePage/OurClients.vue";
import JobOwnerPackage from "@/components/HomePage/JobOwnerPackages.vue";
import StrongCv from "@/components/HomePage/StrongCv";
import FeaturedJobs from "@/components/HomePage/FeaturedJobs";
import FeaturedCv from "@/components/HomePage/FeaturedCv.vue";
import JobsByCategories from "@/components/HomePage/JobsByCategories.vue";
import JobsByCities from "@/components/HomePage/JobsByCities.vue";
import LatestJobs from "@/components/HomePage/LatestJobs.vue";
import LatestArticles from "@/components/HomePage/LatestArticles.vue";
import Newsleter from "@/components/HomePage/Newsleter.vue";
export default {
  data() {
    return {
      allData: null,
      allData2: null,
      loading: false,
    };
  },
  methods: {
    changeTheme() {
      this.$store.dispatch("theme_module/changeTheme");
      if (this.$store.getters["theme_module/current_theme"] == "light") {
        this.$vuetify.theme.dark = false;
      } else {
        this.$vuetify.theme.dark = true;
      }
    },

    changeLang() {
      this.$store.dispatch("lang_module/switchLang");
    },

    //Get Data
    getData() {
      this.loading = true;
      this.$axios({
        method: "GET",
        url: "home",
      }).then((res) => {
        this.loading = false;
        this.allData = res.data.data;
      });
    },
    //Get Data
    //Get Data
    getData2() {
      this.loading = true;
      this.$axios({
        method: "GET",
        url: "section2",
      }).then((res) => {
        this.loading = false;
        this.allData2 = res.data.data;
      });
    },
    //Get Data
  },
  computed: {
    userType() {
      return this.$store.getters["auth_module/usertype"];
    },
  },
  components: {
    MainSection,
    OurClients,
    JobOwnerPackage,
    StrongCv,
    FeaturedJobs,
    FeaturedCv,
    JobsByCategories,
    JobsByCities,
    LatestJobs,
    LatestArticles,
    Newsleter,
  },
  created() {
    this.getData();
    this.getData2();
    if (this.userType == "company") {
      this.$router.push({ path: "/jobOwner" });
    } else if (this.userType == "user") {
      this.$router.push({ path: "/jobSearch" });
    }
  },
};
</script>
