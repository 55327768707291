<template>
  <!--Form Inputs-->
  <div class="my_form">
    <!--Head Part-->
    <div class="head_part">
      <div class="icon">
        <i class="fal fa-mobile"></i>
      </div>
      <div class="text">
        <h5>{{ $t("auth.inputs.joinUs") }}</h5>
        <p>{{ $t("auth.inputs.desc") }}</p>
      </div>
    </div>
    <!--Head Part-->
    <form>
      <!--Start Input Wrapper Email-->
      <div class="input_wrapper with_icon">
        <div class="icon">
          <i class="fal fa-envelope"></i>
        </div>
        <input
          type="email"
          class="form-control"
          v-model="loginData.email"
          :placeholder="$t('auth.inputs.email')"
        />
      </div>
      <!--End Input Wrapper Email-->
      <!--Start Input Wrapper Password-->
      <div class="input_wrapper with_icon">
        <div class="icon_pass" @click="helper_togglePasswordVisibility">
          <i class="fal fa-eye open_eye"></i>
          <i class="fal fa-eye-slash closed_eye"></i>
        </div>
        <div class="icon">
          <i class="fal fa-lock"></i>
        </div>
        <input
          type="password"
          class="form-control"
          v-model="loginData.password"
          :placeholder="$t('auth.inputs.password')"
        />
      </div>
      <!--End Input Wrapper Password-->
      <!--Remmber me-->
      <div class="remmber_me">
        <input type="checkbox" name="rem" id="c1" />
        <label for="c1">{{ $t("auth.inputs.remmberMe") }}</label>
      </div>
      <!--Remmber me-->
      <!--Login Button-->
      <button
        class="main_button button login_btn"
        type="button"
        ref="login"
        @click="login"
      >
        <i class="fas fa-spinner"></i>
        <span>{{ $t("auth.login") }}</span>
      </button>
      <!--Login Button-->
      <!--Forget Password-->
      <div class="forget_password">
        <router-link to="/enterEmail"
          ><span>{{ $t("auth.forgetPassword") }}</span></router-link
        >
      </div>
      <!--Forget Password-->
      <!--Register Button-->
      <router-link to="/register" class="main_button4 register_btn">
        {{ $t("auth.register") }}
      </router-link>
      <!--Register Button-->
    </form>
  </div>
  <!--Form Inputs-->
</template>

<script>
export default {
  data() {
    return {
      //Start Login Data
      loginData: {
        email: null,
        password: null,
      },
      //End Login Data
    };
  },
  methods: {
    //Login
    login() {
      var submitButton = this.$refs["login"];
      submitButton.classList.add("click");
      if (!this.loginData.email) {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.$iziToast.error({
            message: this.$t("auth.validation.email"),
          });
        }, 1000);
      } else if (!this.loginData.password) {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.$iziToast.error({
            message: this.$t("auth.validation.password"),
          });
        }, 1000);
      } else {
        const data = new FormData();
        data.append("username", this.loginData.email);
        data.append("password", this.loginData.password);
        this.$axios({
          method: "post",
          url: "login",
          data: data,
        })
          .then((res) => {
            setTimeout(() => {
              submitButton.classList.remove("click");
              this.$iziToast.success({
                message: this.$t("auth.validation.loginSuccess"),
              });
              // this.$router.push({ path: "/" });
              const data = {
                token: res.data.data.token,
                userType: res.data.data.type,
                userName:
                  res.data.data.type == "user"
                    ? res.data.data.first_name
                    : res.data.data.fullname,
                userId: res.data.data.id,
                email: res.data.data.email,
                userImage: res.data.data.image,
                userPhone: res.data.data.phone,
                package: res.data.data.is_valid_package,
                expPackage: res.data.data.package_expire_date,
              };

              this.$store.dispatch("auth_module/logIn", data);
              if (res.data.data.type == "company") {
                this.$router.push({ path: "/jobOwner" });
              } else if (res.data.data.type == "user") {
                this.$router.push({ path: "/jobSearch" });
              }
            }, 1000);
          })
          .catch((err) => {
            setTimeout(() => {
              submitButton.classList.remove("click");
              this.$iziToast.error({
                timeout: 2000,
                message: err.response.data.message,
                position: "bottomRight",
              });
            }, 1000);
          });
      }
    },
  },
  computed: {
    token() {
      return this.$store.getters["auth_module/token"];
    },
  },
  created() {
    if (this.token) {
      this.$router.push("/");
    }
  },
};
</script>

<style></style>
