<template>
  <div class="register_page">
    <!--Top Background-->
    <div class="top_background">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="title">
              <h4>{{ $t("jobAds.addEmployes") }}</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--Top Background-->
    <!--Register Wizard Form-->
    <div class="container">
      <div class="register_wizard_form">
        <!--Form Inputs-->
        <div class="form_inputs">
          <div class="wizard_title2">
            <h4>{{ $t("jobAds.wizard.title") }}</h4>
            <p>{{ $t("jobAds.wizard.desc") }}</p>
          </div>
          <form>
            <div class="row">
              <div class="col-md-6">
                <!--Input Wrapper-->
                <div class="input_wrapper reg_style select">
                  <!--Select Specialists-->
                  <select
                    class="form-control"
                    v-model="data.specialist"
                    @change="getSub(), (data.subSpecialist = '')"
                  >
                    <option disabled value="">
                      {{ $t("jobAds.data.specialist") }}
                    </option>
                    <option
                      v-for="spe in specialists"
                      :key="spe.id"
                      :value="spe.id"
                    >
                      {{ spe.name }}
                    </option>
                  </select>
                  <!--Select Specialists-->
                </div>
                <!--Input Wrapper-->
              </div>

              <div class="col-md-6">
                <!--Input Wrapper-->
                <div class="input_wrapper reg_style select">
                  <!--Select Sub Specialists-->
                  <select class="form-control" v-model="data.subSpecialist">
                    <option disabled value="">
                      {{ $t("jobAds.data.subSpecialist") }}
                    </option>
                    <option
                      v-for="sub in subSpecialists"
                      :key="sub.id"
                      :value="sub.id"
                    >
                      {{ sub.name }}
                    </option>
                  </select>
                  <!--Select Sub Specialists-->
                </div>
                <!--Input Wrapper-->
              </div>

              <div class="col-md-6">
                <!--Input Wrapper-->
                <div class="input_wrapper reg_style">
                  <!-- Experience-->
                  <input
                    type="number"
                    class="form-control"
                    v-model="data.experience"
                    :placeholder="$t('jobAds.data.experience')"
                    pattern="/^-?\d+\.?\d*$/"
                    onKeyPress="if(this.value.length == 2) {
                        return false;
                      }"
                    min="0"
                    oninput="
                        this.value =
                          !!this.value && Math.abs(this.value) >= 0
                            ? Math.abs(this.value)
                            : null
                      "
                  />
                  <!-- Experience-->
                </div>
                <!--Input Wrapper-->
              </div>

              <div class="col-md-6">
                <!--Input Wrapper-->
                <div class="input_wrapper reg_style">
                  <!--Employe Number-->
                  <input
                    type="number"
                    class="form-control"
                    v-model="data.employeeCount"
                    min="0"
                    oninput="
                        this.value =
                          !!this.value && Math.abs(this.value) >= 0
                            ? Math.abs(this.value)
                            : null
                      "
                    :placeholder="$t('jobAds.data.employesNum')"
                  />
                  <!--Employe Number-->
                </div>
                <!--Input Wrapper-->
              </div>

              <div class="col-md-6">
                <!--Input Wrapper-->
                <div class="input_wrapper reg_style">
                  <!--Company Name-->
                  <input
                    type="text"
                    class="form-control"
                    v-model="data.companyName"
                    @keypress="isLetter($event)"
                    :placeholder="$t('jobAds.data.companyName')"
                  />
                  <!--Company Name-->
                </div>
                <!--Input Wrapper-->
              </div>

              <div class="col-md-6">
                <div class="wrapper_flex">
                  <!--Input Wrapper-->
                  <div class="input_wrapper reg_style code">
                    <!--Code-->
                    <span>+966</span>
                    <!--Code-->
                  </div>
                  <!--Input Wrapper-->
                  <!--Input Wrapper-->
                  <div class="input_wrapper reg_style phone">
                    <!--Phone-->
                    <input
                      type="number"
                      class="form-control"
                      id="inputBox"
                      @keydown="blockChar"
                      v-model="data.phone"
                      :placeholder="$t('jobAds.data.phone')"
                      pattern="/^-?\d+\.?\d*$/"
                      onKeyPress="if(this.value.length == 9) {
                        return false;
                      }"
                      min="0"
                    />
                    <!--Phone-->
                  </div>
                  <!--Input Wrapper-->
                </div>
              </div>

              <div class="col-md-12">
                <!--Input Wrapper-->
                <div class="input_wrapper reg_style">
                  <!--About Me-->
                  <textarea
                    class="form-control"
                    v-model="data.extraInfo"
                    @keypress="isLetter($event)"
                    :placeholder="$t('jobAds.data.extraData')"
                  ></textarea>
                  <!--About Me-->
                </div>
                <!--Input Wrapper-->
              </div>

              <div class="col-sm-12 mt-5">
                <button
                  type="button"
                  class="main_button2 button register_btn"
                  ref="add"
                  @click="addEmployee()"
                >
                  <i class="fas fa-spinner"></i>
                  {{ $t("sendRequest") }}
                </button>
              </div>
            </div>
          </form>
        </div>
        <!--Form Inputs-->
      </div>
    </div>
    <!--Register Wizard Form-->
  </div>
</template>

<script>
export default {
  data() {
    return {
      //Specialists
      specialists: [],
      //Specialists

      //Sub Specialists
      subSpecialists: [],
      //Sub Specialists

      //Data
      data: {
        specialist: "",
        subSpecialist: "",
        experience: null,
        phone: null,
        employeeCount: null,
        companyName: null,
        extraInfo: null,
      },
    };
  },
  methods: {
    //Get Data
    getData() {
      //====Main Category=======
      this.$axios({
        method: "GET",
        url: "categories",
      }).then((res) => {
        this.specialists = res.data.data;
      });
    },

    //====Sub Category=======
    getSub() {
      this.$axios({
        method: "GET",
        url: "subcategories?category_id=" + this.data.specialist,
      }).then((res) => {
        this.subSpecialists = res.data.data;
      });
    },

    //Register
    addEmployee() {
      var submitButton = this.$refs["add"];
      submitButton.classList.add("click");
      if (!this.data.specialist) {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.$iziToast.error({
            message: this.$t("register.validation.specialist"),
          });
        }, 1000);
      } else if (!this.data.subSpecialist) {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.$iziToast.error({
            message: this.$t("register.validation.subSpecialist"),
          });
        }, 1000);
      } else if (!this.data.experience) {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.$iziToast.error({
            message: this.$t("register.validation.experience"),
          });
        }, 1000);
      } else if (!this.data.employeeCount) {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.$iziToast.error({
            message: this.$t("register.validation.employeeCount"),
          });
        }, 1000);
      } else if (!this.data.companyName) {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.$iziToast.error({
            message: this.$t("register.validation.companyName"),
          });
        }, 1000);
      } else if (!this.data.phone) {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.$iziToast.error({
            message: this.$t("register.validation.phone"),
          });
        }, 1000);
      } else {
        let submit_data = new FormData();
        submit_data.append("categories_id", this.data.specialist);
        submit_data.append("sub_categories_id", this.data.subSpecialist);
        submit_data.append("experience_years", this.data.experience);
        submit_data.append("employee_count", this.data.employeeCount);
        submit_data.append("company_name", this.data.companyName);
        submit_data.append("phone", "+966" + this.data.phone);
        submit_data.append("desc", this.data.extraInfo);
        this.$axios({
          method: "post",
          url: "company/employee_request",
          data: submit_data,
        })
          .then(() => {
            setTimeout(() => {
              submitButton.classList.remove("click");
              this.$iziToast.success({
                message: this.$t("sendSuccess"),
              });
              this.$router.push("/");
            }, 1000);
          })
          .catch((err) => {
            setTimeout(() => {
              submitButton.classList.remove("click");
              this.$iziToast.error({
                message: err.response.data.message,
              });
            }, 1000);
          });
      }
    },
  },
  created() {
    this.getData();
  },
};
</script>

<style></style>
