<template>
  <router-link
    v-if="userType == 'user' || userId == job.company.id || !token"
    :to="!route ? `jobDetails/${job.id}` : route"
    title="اضغط لمشاهدة تفاصيل الوظيفة"
  >
    <div class="job_card">
      <!--padge-->
      <div class="padge" v-if="job.is_trend == 1">
        <img src="@/assets/images/job_card/padge.png" />
      </div>
      <!--padge-->
      <!--Job Image-->
      <div class="image">
        <img :src="job.company.image" />
      </div>
      <!--Job Image-->
      <!--Job Description-->
      <div class="job_description">
        <!--Job Name-->
        <h4>{{ job.name.substring(0, 30) + "...." }}</h4>
        <!--Job Name-->
        <!--Job Location-->
        <div class="job_location">
          <span v-if="job.company" class="job_company">{{
            job.company.fullname
          }}</span>
          <span v-if="job.city" class="job_place">{{ job.city.name }}</span>
        </div>
        <!--Job Location-->
        <!--Job Details-->
        <div class="job_details">
          <p>
            {{ job.desc.substring(0, 50) + "...." }}
          </p>
        </div>
        <!--Job Details-->
      </div>
      <!--Job Description-->
    </div>
  </router-link>
  <!----Not Link-->
  <div
    v-else
    :to="!route ? `jobDetails/${job.id}` : route"
    title="اضغط لمشاهدة تفاصيل الوظيفة"
  >
    <div class="job_card">
      <!--padge-->
      <div class="padge" v-if="job.is_trend == 1">
        <img src="@/assets/images/job_card/padge.png" />
      </div>
      <!--padge-->
      <!--Job Image-->
      <div class="image">
        <img :src="job.company.image" />
      </div>
      <!--Job Image-->
      <!--Job Description-->
      <div class="job_description">
        <!--Job Name-->
        <h4>{{ job.name }}</h4>
        <!--Job Name-->
        <!--Job Location-->
        <div class="job_location">
          <span v-if="job.company" class="job_company">{{
            job.company.fullname
          }}</span>
          <span v-if="job.city" class="job_place">{{ job.city.name }}</span>
        </div>
        <!--Job Location-->
        <!--Job Details-->
        <div class="job_details">
          <p>
            {{ job.desc.substring(0, 50) + "...." }}
          </p>
        </div>
        <!--Job Details-->
      </div>
      <!--Job Description-->
    </div>
  </div>
</template>

<script>
export default {
  props: ["job", "route"],
  computed: {
    userType() {
      return this.$store.getters["auth_module/usertype"];
    },
    userId() {
      return this.$store.getters["auth_module/set_userId"];
    },
    token() {
      return this.$store.getters["auth_module/token"];
    },
  },
};
</script>

<style></style>
