<template>
  <div class="register_page">
    <!--Top Background-->
    <div class="top_background">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="title">
              <h4>
                {{ $t("rateCv.title") }}
              </h4>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--Top Background-->
    <!--Cv Steps Page-->
    <div class="cv_steps_page">
      <div class="container">
        <div class="register_wizard_form rate_one">
          <div class="wizard_title">
            <h4>{{ $t("rateCv.wizard_title") }}</h4>
            <p>{{ $t("rateCv.wizard.desc") }}</p>
          </div>
          <!--Progress Bar-->
          <div class="progress_bar">
            <ul>
              <li :class="{ active: step == 1 }">
                <h4>01 {{ $t("rateCv.uploadCv") }}</h4>
                <p>
                  <span></span>
                </p>
              </li>
              <li :class="{ active: step == 2 }">
                <h4>02 {{ $t("cvsteps.wizard.pay") }}</h4>
                <p>
                  <span></span>
                </p>
              </li>
              <li :class="{ active: step == 3 }">
                <h4>03 {{ $t("cvsteps.wizard.getResults") }}</h4>
                <p>
                  <span></span>
                </p>
              </li>
            </ul>
          </div>
          <!--Progress Bar-->

          <div class="form_inputs">
            <!-- <p class="text-center" v-if="step == 1">{{ $t("rateCv.desc") }}</p> -->
            <!--Fill Data-->
            <!--             <template v-if="step == 1">
              <div class="form_inputs">
                <!~~Block Inputs Personal~~>
                <div class="block_inputs">
                  <!~~Title~~>
                  <div class="block_title">
                    <h4>{{ $t("cvsteps.wizard.personalData") }} . 1</h4>
                  </div>
                  <!~~Title~~>
                  <!~~Inputs~~>
                  <div class="row">
                    <div class="col-md-6">
                      <!~~Input Wrapper~~>
                      <div class="input_wrapper reg_style">
                        <!~~First Name~~>
                        <input
                          type="text"
                          class="form-control"
                          v-model="data.firstName"
                          @keypress="isLetter($event)"
                          :placeholder="$t('register.data.firstName')"
                        />
                        <!~~First Name~~>
                      </div>
                      <!~~Input Wrapper~~>
                    </div>

                    <div class="col-md-6">
                      <!~~Input Wrapper~~>
                      <div class="input_wrapper reg_style">
                        <!~~Last Name~~>
                        <input
                          type="text"
                          class="form-control"
                          v-model="data.lastName"
                          @keypress="isLetter($event)"
                          :placeholder="$t('register.data.lastName')"
                        />
                        <!~~Last Name~~>
                      </div>
                      <!~~Input Wrapper~~>
                    </div>

                    <div class="col-md-6">
                      <!~~Input Wrapper~~>
                      <div class="input_wrapper reg_style">
                        <!~~Phone~~>
                        <input
                          type="number"
                          class="form-control"
                          v-model="data.phone"
                          :placeholder="$t('register.data.phone')"
                          pattern="/^-?\d+\.?\d*$/"
                          onKeyPress="if(this.value.length == 10) {
                        return false;
                      }"
                          min="0"
                          oninput="
                        this.value =
                          !!this.value && Math.abs(this.value) >= 0
                            ? Math.abs(this.value)
                            : null
                      "
                        />
                        <!~~Phone~~>
                      </div>
                      <!~~Input Wrapper~~>
                    </div>

                    <div class="col-md-6">
                      <!~~Input Wrapper~~>
                      <div class="input_wrapper reg_style">
                        <!~~Email~~>
                        <input
                          type="email"
                          class="form-control"
                          v-model="data.email"
                          :placeholder="$t('register.data.email')"
                        />
                        <!~~Email~~>
                      </div>
                      <!~~Input Wrapper~~>
                    </div>

                    <div class="col-md-6">
                      <!~~Input Wrapper~~>
                      <div class="input_wrapper reg_style">
                        <!~~Job Title~~>
                        <input
                          type="text"
                          class="form-control"
                          v-model="data.jobTitle"
                          @keypress="isLetter($event)"
                          :placeholder="$t('register.data.jobTitle')"
                        />
                        <!~~Job Title~~>
                      </div>
                      <!~~Input Wrapper~~>
                    </div>

                    <div class="col-md-6">
                      <!~~Input Wrapper~~>
                      <div class="input_wrapper reg_style">
                        <!~~Address~~>
                        <input
                          type="text"
                          class="form-control"
                          v-model="data.address"
                          @keypress="isLetter($event)"
                          :placeholder="$t('register.data.address')"
                        />
                        <!~~Address~~>
                      </div>
                      <!~~Input Wrapper~~>
                    </div>
                  </div>
                  <!~~Inputs~~>
                </div>
                <!~~Block Inputs Personal~~>

                <!~~Block Inputs Berif~~>
                <div class="block_inputs">
                  <!~~Title~~>
                  <div class="block_title">
                    <h4>{{ $t("cvsteps.wizard.breif") }} . 2</h4>
                  </div>
                  <!~~Title~~>
                  <!~~Inputs~~>
                  <div class="row">
                    <div class="col-sm-12">
                      <!~~Input Wrapper~~>
                      <div class="input_wrapper reg_style">
                        <!~~About Me~~>
                        <textarea
                          class="form-control"
                          v-model="data.about"
                          @keypress="isLetter($event)"
                          :placeholder="$t('register.data.breif')"
                        ></textarea>
                        <!~~About Me~~>
                      </div>
                      <!~~Input Wrapper~~>
                    </div>
                  </div>
                  <!~~Inputs~~>
                </div>
                <!~~Block Inputs Berif~~>

                <!~~Block Inputs Experience~~>
                <div class="block_inputs">
                  <!~~Title~~>
                  <div class="block_title">
                    <h4>{{ $t("cvsteps.wizard.experience") }} . 3</h4>
                  </div>
                  <!~~Title~~>
                  <!~~Inputs~~>
                  <div
                    class="row"
                    v-for="(exp, index) in Experiences"
                    :key="exp.id"
                    :id="`exp_${exp.id}`"
                  >
                    <div class="col-md-12">
                      <h4>{{ `${index + 1}` }}</h4>
                    </div>
                    <div class="col-md-6">
                      <!~~Input Wrapper~~>
                      <div class="input_wrapper reg_style">
                        <!~~Job Title~~>
                        <input
                          type="text"
                          class="form-control"
                          v-model="Experiences[index].companyName"
                          @keypress="isLetter($event)"
                          :placeholder="$t('register.data.companyName')"
                        />
                        <!~~Job Title~~>
                      </div>
                      <!~~Input Wrapper~~>
                    </div>

                    <div class="col-md-6">
                      <!~~Input Wrapper~~>
                      <div class="input_wrapper reg_style">
                        <!~~Job Title~~>
                        <input
                          type="text"
                          class="form-control"
                          v-model="Experiences[index].jobTitle"
                          @keypress="isLetter($event)"
                          :placeholder="$t('register.data.jobTitle')"
                        />
                        <!~~Job Title~~>
                      </div>
                      <!~~Input Wrapper~~>
                    </div>

                    <div class="col-md-12">
                      <!~~Input Wrapper~~>
                      <div class="wrapper_flex profile_wrapper mb-10">
                        <span class="profile_label">{{
                          $t("rateCv.data.stillThere")
                        }}</span>
                        <div class="choice_list">
                          <div class="check_box">
                            <input
                              type="radio"
                              :id="'still' + index"
                              name="jj"
                              v-model="Experiences[index].is_still"
                              value="true"
                            />
                            <label :for="'still' + index">{{
                              $t("yes")
                            }}</label>
                          </div>
                          <div class="check_box">
                            <input
                              type="radio"
                              :id="'nostill' + index"
                              name="jj"
                              v-model="Experiences[index].is_still"
                              value="false"
                            />
                            <label :for="'nostill' + index">{{
                              $t("no")
                            }}</label>
                          </div>
                        </div>
                      </div>
                      <!~~Input Wrapper~~>
                    </div>

                    <div class="col-md-6">
                      <!~~Input Wrapper~~>
                      <div class="input_wrapper reg_style">
                        <!~~Start Date~~>
                        <Datepicker
                          :format="formatDate"
                          class="form-control"
                          v-model="Experiences[index].startDate"
                          :placeholder="$t('register.data.startDate')"
                        ></Datepicker>
                        <!~~Start Date~~>
                      </div>
                      <!~~Input Wrapper~~>
                    </div>

                    <div class="col-md-6">
                      <!~~Input Wrapper~~>
                      <div class="input_wrapper reg_style">
                        <!~~End Date~~>
                        <Datepicker
                          :format="formatDate"
                          class="form-control"
                          v-model="Experiences[index].endDate"
                          :disabledDates="{
                            to: new Date(
                              Experiences[index].startDate - 8640000
                            ),
                          }"
                          :placeholder="$t('register.data.endDate')"
                        ></Datepicker>
                        <!~~End Date~~>
                      </div>
                      <!~~Input Wrapper~~>
                    </div>

                    <div class="col-md-12">
                      <!~~Input Wrapper~~>
                      <div class="input_wrapper reg_style">
                        <!~~Breif Job~~>
                        <input
                          type="text"
                          class="form-control"
                          v-model="Experiences[index].breifJob"
                          @keypress="isLetter($event)"
                          :placeholder="$t('register.data.breifJob')"
                        />
                        <!~~Breif Job~~>
                      </div>
                      <!~~Input Wrapper~~>
                    </div>
                  </div>

                  <!~~Add New Button~~>
                  <button class="trans_btn" @click="addExperience">
                    <i class="fal fa-plus"></i>
                    <span>{{ $t("cvsteps.data.addNewExp") }}</span>
                  </button>
                  <!~~Add New Button~~>
                  <!~~Inputs~~>
                </div>
                <!~~Block Experience~~>

                <!~~Block Inputs Education~~>
                <div class="block_inputs">
                  <!~~Title~~>
                  <div class="block_title">
                    <h4>{{ $t("cvsteps.wizard.education") }} . 4</h4>
                  </div>
                  <!~~Title~~>
                  <!~~Inputs~~>
                  <div
                    class="row"
                    v-for="(edu, index) in Education"
                    :key="edu.id"
                    :id="`edu_${edu.id}`"
                  >
                    <div class="col-md-12">
                      <h4>{{ `${index + 1}` }}</h4>
                    </div>
                    <div class="col-md-6">
                      <!~~Input Wrapper~~>
                      <div class="input_wrapper reg_style">
                        <!~~Job Title~~>
                        <input
                          type="text"
                          class="form-control"
                          v-model="Education[index].universityName"
                          @keypress="isLetter($event)"
                          :placeholder="$t('register.data.universityName')"
                        />
                        <!~~Job Title~~>
                      </div>
                      <!~~Input Wrapper~~>
                    </div>

                    <div class="col-md-6">
                      <!~~Input Wrapper~~>
                      <div class="input_wrapper reg_style">
                        <!~~Job Title~~>
                        <input
                          type="text"
                          class="form-control"
                          v-model="Education[index].departmentName"
                          @keypress="isLetter($event)"
                          :placeholder="$t('register.data.departmentName')"
                        />
                        <!~~Job Title~~>
                      </div>
                      <!~~Input Wrapper~~>
                    </div>

                    <div class="col-md-12">
                      <!~~Input Wrapper~~>
                      <div class="wrapper_flex profile_wrapper mb-10">
                        <span class="profile_label">{{
                          $t("rateCv.data.stillThere")
                        }}</span>
                        <div class="choice_list">
                          <div class="check_box">
                            <input
                              type="radio"
                              :id="'edstill' + index"
                              name="j"
                              v-model="Education[index].is_still"
                              value="true"
                            />
                            <label :for="'edstill' + index">{{
                              $t("yes")
                            }}</label>
                          </div>
                          <div class="check_box">
                            <input
                              type="radio"
                              :id="'ednostill' + index"
                              name="j"
                              v-model="Education[index].is_still"
                              value="false"
                            />
                            <label :for="'ednostill' + index">{{
                              $t("no")
                            }}</label>
                          </div>
                        </div>
                      </div>
                      <!~~Input Wrapper~~>
                    </div>

                    <div class="col-md-6">
                      <!~~Input Wrapper~~>
                      <div class="input_wrapper reg_style">
                        <!~~Start Date~~>
                        <Datepicker
                          :format="formatDate"
                          class="form-control"
                          v-model="Education[index].startDate"
                          :placeholder="$t('register.data.startDate')"
                        ></Datepicker>
                        <!~~Start Date~~>
                      </div>
                      <!~~Input Wrapper~~>
                    </div>

                    <div class="col-md-6">
                      <!~~Input Wrapper~~>
                      <div class="input_wrapper reg_style">
                        <!~~End Date~~>
                        <Datepicker
                          class="form-control"
                          :format="formatDate"
                          v-model="Education[index].endDate"
                          :disabledDates="{
                            to: new Date(Education[index].startDate - 8640000),
                          }"
                          :placeholder="$t('register.data.endDate')"
                        ></Datepicker>
                        <!~~End Date~~>
                      </div>
                      <!~~Input Wrapper~~>
                    </div>

                    <div class="col-md-12">
                      <!~~Input Wrapper~~>
                      <div class="input_wrapper reg_style">
                        <!~~Breif Job~~>
                        <input
                          type="text"
                          class="form-control"
                          v-model="Education[index].desc"
                          @keypress="isLetter($event)"
                          :placeholder="$t('register.data.desc')"
                        />
                        <!~~Breif Job~~>
                      </div>
                      <!~~Input Wrapper~~>
                    </div>
                  </div>

                  <!~~Add New Button~~>
                  <button class="trans_btn" @click="addEducation">
                    <i class="fal fa-plus"></i>
                    <span>{{ $t("cvsteps.data.addNewExp") }}</span>
                  </button>
                  <!~~Add New Button~~>
                  <!~~Inputs~~>
                </div>
                <!~~Block Education~~>

                <!~~Next Button~~>
                <button
                  type="button"
                  class="main_button button"
                  @click="nextStep()"
                  ref="next"
                >
                  <i class="fas fa-spinner"></i>
                  <span> {{ $t("next") }}</span>
                </button>
                <!~~Next Button~~>
              </div>
            </template> -->
            <!--Fill Data-->

            <!--Upload Cv Image-->
            <template v-if="step == 1">
              <div class="row align-items-center up_cv">
                <div class="col-md-5">
                  <!--Input Wrapper-->
                  <div class="label_text rate">
                    <h4>{{ $t("rateCv.uploadCvText1") }}</h4>
                    <p>{{ $t("rateCv.uploadCvText2") }}</p>
                  </div>
                  <div class="input_wrapper reg_style file_upload">
                    <!--Upload Cv-->
                    <div class="form-control">
                      <span v-if="uploadCv.beforeUploadFile">
                        {{ $t("rateCv.uploadCv") }}</span
                      >
                      <span v-if="uploadCv.afterUploadFile">{{
                        uploadCv.uploadCv.name
                      }}</span>
                      <span class="icon"><i class="fal fa-file"></i></span>
                    </div>
                    <input
                      type="file"
                      name="chooseFile"
                      @change="uploadCvFile"
                      accept=".doc, .docx, .pdf"
                    />
                    <!--Upload Cv-->
                  </div>
                  <!--Input Wrapper-->

                  <!--Input Wrapper-->
                  <div class="label_text rate">
                    <h4>{{ $t("rateCv.enterEmailText1") }}</h4>
                    <p>{{ $t("rateCv.enterEmailText2") }}</p>
                  </div>
                  <div class="input_wrapper reg_style">
                    <!--Email-->
                    <input
                      type="email"
                      class="form-control"
                      v-model="data.email"
                      :placeholder="$t('register.data.email')"
                    />
                    <!--Email-->
                  </div>
                  <!--Input Wrapper-->
                </div>

                <div class="col-sm-12">
                  <button
                    type="button"
                    class="main_button button"
                    @click="nextStep()"
                    ref="next"
                  >
                    <i class="fas fa-spinner"></i>
                    <span>{{ $t("next") }}</span>
                  </button>
                </div>
              </div>
            </template>
            <!--Upload Cv Image-->

            <!--Pay Step-->
            <template v-if="step == 2">
              <div class="pay_step">
                <span class="icon"><i class="fas fa-star"></i></span>
                <h4>{{ $t("rateCv.pay.congratulation") }}</h4>
                <p>{{ $t("rateCv.pay.review") }}</p>
                <span class="text">{{ $t("rateCv.pay.sendYou") }}</span>
                <span class="text2">{{ $t("rateCv.pay.thankYou") }}</span>
                <!--Next Button-->
                <router-link class="main_button button" to="/">
                  <span> {{ $t("navbar.home") }}</span>
                </router-link>
                <!--Next Button-->
              </div>
            </template>
            <!--Pay Step-->

            <!--Rate Results-->
            <template v-if="step == 3">
              <div class="rate_results">
                <!--Top Shape-->
                <div class="top_shape">
                  <img src="@/assets/images/icons/shape.png" />
                </div>
                <!--Top Shape-->

                <!--Result Section-->
                <div class="result_section">
                  <!--Title-->
                  <h4>{{ $t("rateCv.result") }}</h4>
                  <!--Title-->

                  <!--Result Container-->
                  <div class="result_container">
                    <!--Result Items-->
                    <div class="result_items">
                      <ul>
                        <li>{{ details }}% {{ $t("rateCv.data.summury") }}</li>
                        <li>{{ edu }}% {{ $t("rateCv.data.education") }}</li>
                        <!-- <li>25% {{ $t("rateCv.data.certificate") }}</li> -->
                        <li>{{ exp }}% {{ $t("rateCv.data.experience") }}</li>
                      </ul>
                    </div>
                    <!--Result Items-->

                    <!--Result Total-->
                    <div class="result_total">
                      <h4>{{ $t("rateCv.data.strongCv") }}</h4>
                      <span
                        :class="[
                          total < '50'
                            ? 'red_rate'
                            : total > '50' && total < '80'
                            ? 'blue_rate'
                            : 'green_rate',
                        ]"
                        >{{ total }} %</span
                      >
                    </div>
                    <!--Result Total-->
                  </div>
                  <!--Result Container-->
                </div>
                <!--Result Section-->
              </div>
              <!--Develope Advice-->
              <div class="develope_advice">
                <h4>{{ $t("rateCv.developeAdvice.title") }}</h4>
                <ul>
                  <li>
                    <!--Text-->
                    <p>1 - {{ $t("rateCv.developeAdvice.advice1") }}</p>
                    <!--Text-->
                    <!--Button-->
                    <router-link class="main_button" to=""
                      ><span>{{
                        $t("rateCv.developeAdvice.carerPath")
                      }}</span></router-link
                    >
                    <!--Button-->
                  </li>

                  <li>
                    <!--Text-->
                    <p>2 - {{ $t("rateCv.developeAdvice.advice2") }}</p>
                    <!--Text-->
                    <!--Button-->
                    <router-link class="main_button" to=""
                      ><span>{{
                        $t("rateCv.developeAdvice.createCv")
                      }}</span></router-link
                    >
                    <!--Button-->
                  </li>
                </ul>
              </div>
              <!--Develope Advice-->
            </template>
            <!--Rate Results-->
          </div>
        </div>
      </div>
    </div>
    <!--Cv Steps Page-->

    <!--Strong Cv-->
    <strong-cv></strong-cv>
    <!--Strong Cv-->

    <!--Newsleter-->
    <newsleter></newsleter>
    <!--Newsleter-->

    <!--Payment Model-->
    <base-model :show="show" @closeModel="closeModel">
      <div class="custom_card">
        <form id="form-container" method="post" action="/charge">
          <!-- Tap element will be here -->
          <div id="element-container"></div>
          <div id="error-handler" role="alert"></div>
          <!-- <div
            id="success"
            style="display: none; position: relative; float: left"
          >
            Success! Your token is <span id="token"></span>
          </div> -->
          <!-- Tap pay button -->
          <!-- <button id="tap-btn" @click="upload">{{ $t("pay") }}</button> -->
          <button class="main_button button" id="tap-btn" ref="join">
            <i class="fas fa-spinner"></i>
            <span>{{ $t("pay") }}</span>
          </button>
          <span
            >{{ $t("price") }} : {{ prices ? prices.rate_cv_price : "" }}
            {{ $t("riyal") }}</span
          >
        </form>
      </div>
    </base-model>
    <!--Payment Model-->
  </div>
</template>

<script src="https://cdnjs.cloudflare.com/ajax/libs/bluebird/3.3.4/bluebird.min.js"></script>
<script src="https://secure.gosell.io/js/sdk/tap.min.js"></script>
<script>
import CvTemplate from "@/components/CvCard/CvTemplate.vue";
import Datepicker from "vuejs-datepicker";
import StrongCv from "../../../components/HomePage/StrongCv.vue";
import Newsleter from "../../../components/HomePage/Newsleter.vue";
import * as moment from "moment/moment";
export default {
  components: {
    CvTemplate,
    Datepicker,
    StrongCv,
    Newsleter,
  },
  data() {
    return {
      //Progress Step
      // step: 1,
      //Progress Step
      trans_id: this.$route.query.tap_id,
      status: this.$route.query.status,
      id: this.$route.query.rate_id,
      type_pay: this.$route.query.type,
      price: this.$route.query.price,
      //Experiences
      Experiences: [
        {
          id: (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1),
          companyName: null,
          jobTitle: null,
          startDate: null,
          is_still: false,
          endDate: null,
          breifJob: null,
        },
      ],
      //Experiences

      //Education
      Education: [
        {
          id: (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1),
          universityName: null,
          departmentName: null,
          is_still: false,
          startDate: null,
          endDate: null,
          desc: null,
        },
      ],
      //Education

      //Data
      data: {
        firstName: null,
        lastName: null,
        phone: null,
        email: null,
        jobTitle: null,
        address: null,
        about: null,
        company: null,
      },
      uploadCv: {
        uploadCv: null,
        afterUploadFile: false,
        beforeUploadFile: true,
      },

      prices: null,

      //Show Model
      show: false,
    };
  },
  methods: {
    openModel() {
      this.show = true;
      localStorage.removeItem("Sas_TransId");
    },

    //CloseModel
    closeModel() {
      this.show = false;
      localStorage.removeItem("Sas_TransId");
    },

    payAmount(id) {
      window.location.href =
        "https://sas-jobs.net/pay?amount=" +
        this.prices +
        "&redirect_url=" +
        encodeURIComponent(
          "https://sas-jobs.net/rateCv?" +
            "&type=rate&user_id=" +
            this.userId +
            "&price=" +
            this.prices +
            "&rate_id=" +
            id
        );
    },

    //Pay Method
    payMethod() {
      //pass your public key from tap's dashboard
      var tap = Tapjsli("pk_live_8eKYZbCptrBD97zaUkNivxgR");

      var elements = tap.elements({});

      var style = {
        base: {
          color: "#535353",
          lineHeight: "18px",
          fontFamily: "sans-serif",
          fontSmoothing: "antialiased",
          fontSize: "16px",
          "::placeholder": {
            color: "rgba(0, 0, 0, 0.26)",
            fontSize: "15px",
          },
        },
        invalid: {
          color: "red",
        },
      };
      // input labels/placeholders
      var labels = {
        cardNumber: "Card Number",
        expirationDate: "MM/YY",
        cvv: "CVV",
        cardHolder: "Card Holder Name",
      };
      //payment options
      var paymentOptions = {
        currencyCode: ["KWD", "USD", "SAR"],
        labels: labels,
        TextDirection: "ltr",
      };
      //create element, pass style and payment options
      var card = elements.create("card", { style: style }, paymentOptions);
      //mount element
      card.mount("#element-container");
      //card change event listener
      card.addEventListener("change", function (event) {
        if (event.loaded) {
          console.log("UI loaded :" + event.loaded);
          console.log("current currency is :" + card.getCurrency());
        }
        var displayError = document.getElementById("error-handler");
        if (event.error) {
          displayError.textContent = event.error.message;
        } else {
          displayError.textContent = "";
        }
      });

      // Handle form submission
      var form = document.getElementById("form-container");
      form.addEventListener("submit", function (event) {
        event.preventDefault();

        tap.createToken(card).then(function (result) {
          console.log(result);
          if (result.error) {
            // Inform the user if there was an error
            console.log("");
            // var errorElement = document.getElementById("error-handler");
            // errorElement.textContent = result.error.message;
          } else {
            // Send the token to your server
            // var errorElement = document.getElementById("success");
            // errorElement.style.display = "block";
            // var tokenElement = document.getElementById("token");
            // tokenElement.textContent = result.id;
            localStorage.setItem("Sas_TransId", result.id);
            tapTokenHandler(result);
          }
        });
      });

      function tapTokenHandler(token) {
        // Insert the token ID into the form so it gets submitted to the server
        var form = document.getElementById("payment-form");
        var hiddenInput = document.createElement("input");
        hiddenInput.setAttribute("type", "hidden");
        hiddenInput.setAttribute("name", "tapToken");
        hiddenInput.setAttribute("value", token.id);
        form.appendChild(hiddenInput);

        // Submit the form
        form.submit();
      }

      card.addEventListener("change", function (event) {
        if (event.BIN) {
          console.log(event.BIN);
        }
        // var displayError = document.getElementById("card-errors");
        // if (event.error) {
        //   displayError.textContent = event.error.message;
        // } else {
        //   displayError.textContent = "";
        // }
      });
    },
    //Upload Logo
    uploadCvFile(e) {
      this.uploadCv.uploadCv = e.target.files[0];
      this.uploadCv.beforeUploadFile = false;
      this.uploadCv.afterUploadFile = true;
    },
    //Upload Logo
    //Add Experience
    addExperience() {
      this.Experiences.push({
        id: (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1),
        companyName: null,
        jobTitle: null,
        startDate: null,
        endDate: null,
        breifJob: null,
        is_still: false,
      });
    },
    //Add Experience

    //Add Education
    addEducation() {
      this.Education.push({
        id: (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1),
        universityName: null,
        departmentName: null,
        startDate: null,
        endDate: null,
        desc: null,
        is_still: false,
      });
    },
    //Add Education

    //Data Formate
    formatDate(date) {
      return moment(date).format("YYYY-MM-DD");
    },
    //Data Formate

    nextStep() {
      var submitButton = this.$refs["next"];
      submitButton.classList.add("click");
      if (this.step == 1) {
        if (!this.uploadCv.uploadCv) {
          setTimeout(() => {
            submitButton.classList.remove("click");
            this.$iziToast.error({
              message: this.$t("validation.uploadCv"),
            });
          }, 1000);
        } else if (!this.data.email) {
          setTimeout(() => {
            submitButton.classList.remove("click");
            this.$iziToast.error({
              message: this.$t("register.validation.email"),
            });
          }, 1000);
        } else {
          let submit_data = new FormData();
          if (this.uploadCv.uploadCv) {
            submit_data.append("cv", this.uploadCv.uploadCv);
          }
          submit_data.append("email", this.data.email);
          submit_data.append("transaction_id", "jjjj");
          submit_data.append("payment_type", "visa");
          submit_data.append("value", this.prices);
          this.$axios({
            method: "post",
            url: "rate_cv",
            data: submit_data,
          })
            .then((res) => {
              // this.$store.dispatch("rate_module/addStep", {
              //   step: 2,
              // });
              // this.$store.dispatch("rate_module/rateDetails", {
              //   details: res.data.data.details,
              //   edu: res.data.data.edu,
              //   exp: res.data.data.exp,
              // });
              submitButton.classList.remove("click");
              this.$iziToast.success({
                message: this.$t("sendSuccess"),
              });
              this.payAmount(res.data.data.id);
            })
            .catch((err) => {
              setTimeout(() => {
                submitButton.classList.remove("click");
                this.$iziToast.error({
                  message: err.response.data.message,
                });
              }, 1000);
            });
        }
      }
    },

    confirmPay() {
      let submit_data = new FormData();
      submit_data.append("type", this.type_pay);
      submit_data.append("id", this.id);
      submit_data.append("transaction_id", this.trans_id);
      submit_data.append("user_id", this.userId);
      submit_data.append("value", this.price);
      this.$axios({
        method: "post",
        url: "set_pay",
        data: submit_data,
      })
        .then(() => {
          this.$store.dispatch("rate_module/addStep", {
            step: 2,
          });
          this.$iziToast.success({
            message: this.$t("sendSuccess"),
          });
        })
        .catch((err) => {
          setTimeout(() => {
            this.$iziToast.error({
              message: err.response.data.message,
            });
          }, 1000);
        });
    },

    //Rate
    // rate() {
    //   var submitButton = this.$refs["join"];
    //   submitButton.classList.add("click");
    //   if (!this.uploadCv.uploadCv) {
    //     setTimeout(() => {
    //       submitButton.classList.remove("click");
    //       this.$iziToast.error({
    //         message: this.$t("register.validation.uploadCv"),
    //       });
    //     }, 1000);
    //   } else if (!this.data.email) {
    //     setTimeout(() => {
    //       submitButton.classList.remove("click");
    //       this.$iziToast.error({
    //         message: this.$t("register.validation.lastName"),
    //       });
    //       email;
    //     }, 1000);
    //   } else {
    //     setTimeout(() => {
    //       let submit_data = new FormData();
    //       submit_data.append("cv", this.uploadCv.uploadCv);
    //       submit_data.append("email", this.data.email);
    //       submit_data.append("transaction_id", "jjjj");
    //       submit_data.append("payment_type", "visa");
    //       submit_data.append("value", this.prices);
    //       this.$axios({
    //         method: "post",
    //         url: "rate_cv",
    //         data: submit_data,
    //       })
    //         .then((res) => {
    //           setTimeout(() => {
    //             this.$store.dispatch("rate_module/addStep", {
    //               step: 2,
    //             });
    //             this.$store.dispatch("rate_module/rateDetails", {
    //               details: res.data.data.details,
    //               edu: res.data.data.edu,
    //               exp: res.data.data.exp,
    //             });
    //             submitButton.classList.remove("click");
    //             this.$iziToast.success({
    //               message: this.$t("sendSuccess"),
    //             });
    //           }, 1000);
    //         })
    //         .catch((err) => {
    //           setTimeout(() => {
    //             submitButton.classList.remove("click");
    //             this.$iziToast.error({
    //               message: err.response.data.message,
    //             });
    //           }, 1000);
    //         });
    //     }, 800);
    //   }
    // },
    // rate() {
    //   var submitButton = this.$refs["rate"];
    //   submitButton.classList.add("click");
    //   if (!this.data.firstName) {
    //     setTimeout(() => {
    //       submitButton.classList.remove("click");
    //       this.$iziToast.error({
    //         message: this.$t("register.validation.firstName"),
    //       });
    //     }, 1000);
    //   } else if (!this.data.lastName) {
    //     setTimeout(() => {
    //       submitButton.classList.remove("click");
    //       this.$iziToast.error({
    //         message: this.$t("register.validation.lastName"),
    //       });
    //     }, 1000);
    //   } else if (!this.data.phone) {
    //     setTimeout(() => {
    //       submitButton.classList.remove("click");
    //       this.$iziToast.error({
    //         message: this.$t("register.validation.phone"),
    //       });
    //     }, 1000);
    //   } else if (!this.data.email) {
    //     setTimeout(() => {
    //       submitButton.classList.remove("click");
    //       this.$iziToast.error({
    //         message: this.$t("register.validation.email"),
    //       });
    //     }, 1000);
    //   } else if (!this.data.jobTitle) {
    //     setTimeout(() => {
    //       submitButton.classList.remove("click");
    //       this.$iziToast.error({
    //         message: this.$t("register.validation.jobTitle"),
    //       });
    //     }, 1000);
    //   } else if (!this.data.address) {
    //     setTimeout(() => {
    //       submitButton.classList.remove("click");
    //       this.$iziToast.error({
    //         message: this.$t("register.validation.address"),
    //       });
    //     }, 1000);
    //   } else if (!this.data.about) {
    //     setTimeout(() => {
    //       submitButton.classList.remove("click");
    //       this.$iziToast.error({
    //         message: this.$t("register.validation.about"),
    //       });
    //     }, 1000);
    //   } else if (!this.Experiences[0].companyName) {
    //     setTimeout(() => {
    //       submitButton.classList.remove("click");
    //       this.$iziToast.error({
    //         message: this.$t("register.validation.companyName"),
    //       });
    //     }, 1000);
    //   } else if (!this.Experiences[0].jobTitle) {
    //     setTimeout(() => {
    //       submitButton.classList.remove("click");
    //       this.$iziToast.error({
    //         message: this.$t("register.validation.jobTitle"),
    //       });
    //     }, 1000);
    //   } else if (!this.Experiences[0].startDate) {
    //     setTimeout(() => {
    //       submitButton.classList.remove("click");
    //       this.$iziToast.error({
    //         message: this.$t("register.validation.startDate"),
    //       });
    //     }, 1000);
    //   } else if (!this.Experiences[0].endDate) {
    //     setTimeout(() => {
    //       submitButton.classList.remove("click");
    //       this.$iziToast.error({
    //         message: this.$t("register.validation.endDate"),
    //       });
    //     }, 1000);
    //   } else if (!this.Experiences[0].breifJob) {
    //     setTimeout(() => {
    //       submitButton.classList.remove("click");
    //       this.$iziToast.error({
    //         message: this.$t("register.validation.breifJob"),
    //       });
    //     }, 1000);
    //   } else if (!this.Education[0].universityName) {
    //     setTimeout(() => {
    //       submitButton.classList.remove("click");
    //       this.$iziToast.error({
    //         message: this.$t("register.validation.universityName"),
    //       });
    //     }, 1000);
    //   } else if (!this.Education[0].departmentName) {
    //     setTimeout(() => {
    //       submitButton.classList.remove("click");
    //       this.$iziToast.error({
    //         message: this.$t("register.validation.departmentName"),
    //       });
    //     }, 1000);
    //   } else if (!this.Education[0].startDate) {
    //     setTimeout(() => {
    //       submitButton.classList.remove("click");
    //       this.$iziToast.error({
    //         message: this.$t("register.validation.startDate"),
    //       });
    //     }, 1000);
    //   } else if (!this.Education[0].endDate) {
    //     setTimeout(() => {
    //       submitButton.classList.remove("click");
    //       this.$iziToast.error({
    //         message: this.$t("register.validation.endDate"),
    //       });
    //     }, 1000);
    //   } else {
    //     let submit_data = new FormData();
    //     submit_data.append(
    //       "ar[name]",
    //       this.data.firstName + " " + this.data.lastName
    //     );
    //     submit_data.append(
    //       "en[name]",
    //       this.data.firstName + " " + this.data.lastName
    //     );
    //     submit_data.append("email", this.data.email);
    //     submit_data.append("ar[job_title]", this.data.jobTitle);
    //     submit_data.append("en[job_title]", this.data.jobTitle);
    //     submit_data.append("ar[desc]", this.data.about);
    //     submit_data.append("en[desc]", this.data.about);
    //     console.log(this.Experiences);
    //     this.Experiences.forEach((item, index) => {
    //       console.log(item);
    //       submit_data.append(
    //         `experiences[${index}][ar][name]`,
    //         item.companyName
    //       );
    //       submit_data.append(
    //         `experiences[${index}][en][name]`,
    //         item.companyName
    //       );
    //       submit_data.append(
    //         `experiences[${index}][start_date]`,
    //         this.formatDate(item.startDate)
    //       );
    //       submit_data.append(
    //         `experiences[${index}][end_date]`,
    //         this.formatDate(item.endDate)
    //       );
    //       submit_data.append(`experiences[${index}][ar][desc]]`, item.breifJob);
    //       submit_data.append(`experiences[${index}][en][desc]]`, item.breifJob);
    //       submit_data.append(
    //         `experiences[${index}][ar][job_title]]`,
    //         item.jobTitle
    //       );
    //       submit_data.append(
    //         `experiences[${index}][en][job_title]]`,
    //         item.jobTitle
    //       );
    //       submit_data.append(`experiences[${index}][is_still]]`, item.is_still);
    //     });
    //     this.Education.forEach((item, index) => {
    //       submit_data.append(
    //         `education[${index}][ar][name]`,
    //         item.universityName
    //       );
    //       submit_data.append(
    //         `education[${index}][en][name]`,
    //         item.universityName
    //       );
    //       submit_data.append(
    //         `education[${index}][start_date]`,
    //         this.formatDate(item.startDate)
    //       );
    //       submit_data.append(
    //         `education[${index}][end_date]`,
    //         this.formatDate(item.endDate)
    //       );
    //       submit_data.append(`education[${index}][ar][desc]]`, item.desc);
    //       submit_data.append(`education[${index}][en][desc]]`, item.desc);
    //       submit_data.append(
    //         `education[${index}][ar][section_title]]`,
    //         item.departmentName
    //       );
    //       submit_data.append(
    //         `education[${index}][en][section_title]]`,
    //         item.departmentName
    //       );
    //       submit_data.append(`education[${index}][is_still]]`, item.is_still);
    //     });
    //     submit_data.append("transaction_id", "qwdaasd1231231231");
    //     submit_data.append("payment_type", "visa");
    //     submit_data.append("value", "100");
    //     this.$axios({
    //       method: "post",
    //       url: "rate_cv",
    //       data: submit_data,
    //     })
    //       .then((res) => {
    //         setTimeout(() => {
    //           // this.$store.dispatch("rate_module/addId", {
    //           //   id
    //           // });
    //           this.$store.dispatch("rate_module/addStep", {
    //             step: 3,
    //           });
    //           this.$store.dispatch("rate_module/rateDetails", {
    //             details: res.data.data.details,
    //             edu: res.data.data.edu,
    //             exp: res.data.data.exp,
    //           });
    //           submitButton.classList.remove("click");
    //           this.$iziToast.success({
    //             message: this.$t("sendSuccess"),
    //           });
    //         }, 1000);
    //       })
    //       .catch((err) => {
    //         setTimeout(() => {
    //           submitButton.classList.remove("click");
    //           this.$iziToast.error({
    //             message: err.response.data.message,
    //           });
    //         }, 1000);
    //       });
    //   }
    // },
  },
  created() {
    this.$store.dispatch("rate_module/addStep", {
      step: 1,
    });
  },
  mounted() {
    // this.payMethod();
    //====Prices=======
    this.$axios({
      method: "GET",
      url: "prices",
    }).then((res) => {
      this.prices = res.data.data.rate_cv_price;
    });

    if (this.status == "success") {
      this.confirmPay();
    } else if (this.status == "fail") {
      this.$iziToast.error({
        message: "ghhhhhhh",
      });
    }
  },
  computed: {
    step() {
      return this.$store.getters["rate_module/rateStep"];
    },
    details() {
      return this.$store.getters["rate_module/details"];
    },
    edu() {
      return this.$store.getters["rate_module/edu"];
    },
    exp() {
      return this.$store.getters["rate_module/exp"];
    },
    total() {
      return Number(this.details) + Number(this.edu) + Number(this.exp);
    },
    rateId() {
      return this.$store.getters["rate_module/rateId"];
    },
    userId() {
      return this.$store.getters["auth_module/set_userId"];
    },
  },
};
</script>

<style>
.up_cv {
  padding: 25px 40px;
}
</style>
