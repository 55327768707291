<template>
  <div class="register_page">
    <!--Top Background-->
    <div class="top_background">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="title">
              <h4>{{ $t("forgetPassword.title") }}</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--Top Background-->

    <!--About-->
    <div class="container">
      <!--All Text-->
      <div class="all_text forget_password">
        <div class="row justify-content-center">
          <div class="col-md-8">
            <div class="form_inputs">
              <h4>{{ $t("forgetPassword.sorryLose") }}</h4>
              <p>{{ $t("forgetPassword.restoreIt") }}</p>
              <form>
                <!--Start Input Wrapper Email-->
                <div class="input_wrapper reg_style forget_style">
                  <span>{{ $t("forgetPassword.enterEmail") }}</span>
                  <input
                    type="email"
                    class="form-control"
                    v-model="email"
                    :placeholder="$t('auth.inputs.email')"
                  />
                </div>
                <!--End Input Wrapper Email-->

                <!--Login Button-->
                <button
                  class="main_button2 button register_btn"
                  ref="forget"
                  type="button"
                  @click="enterEmail"
                >
                  <i class="fas fa-spinner"></i>
                  <span>{{ $t("next") }}</span>
                </button>
                <!--Login Button-->
              </form>
            </div>
          </div>
        </div>
      </div>
      <!--All Text-->
    </div>
    <!--About-->
  </div>
</template>

<script>
export default {
  data() {
    return {
      //email
      email: null,
    };
  },
  methods: {
    enterEmail() {
      var submitButton = this.$refs["forget"];
      submitButton.classList.add("click");
      if (!this.email) {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.$iziToast.error({
            message: this.$t("auth.validation.email"),
          });
        }, 1000);
      } else {
        let submit_data = new FormData();
        submit_data.append("email", this.email);
        this.$axios({
          method: "post",
          url: "send_code",
          data: submit_data,
        })
          .then((res) => {
            setTimeout(() => {
              submitButton.classList.remove("click");
              this.$iziToast.success({
                message: this.$t("sendSuccess"),
              });
              this.$router.push({ path: "/checkCode" });
              const data = {
                email: this.email,
                devMessage: res.data.dev_message,
              };

              this.$store.dispatch("auth_module/register", data);
              console.log(data);
            }, 1000);
          })
          .catch((err) => {
            setTimeout(() => {
              submitButton.classList.remove("click");
              this.$iziToast.error({
                message: err.response.data.message,
              });
            }, 1000);
          });
      }
    },
  },
};
</script>

<style></style>
