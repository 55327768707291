<template>
  <!--Start Newsleter-->
  <section class="newsleter">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-8">
          <!--Newsleter Content-->
          <div class="newslet">
            <!--Title-->
            <h4>{{ $t("newsleter.title") }}</h4>
            <!--Title-->
            <!--Enter Email-->
            <div class="enter_mail">
              <input
                class="form-control"
                :placeholder="$t('newsleter.email')"
                v-model="email"
              />
              <button class="btn main_button3" @click="newsleter" type="button">
                <span>{{ $t("newsleter.subscribe") }}</span>
              </button>
            </div>
            <!--Enter Email-->
          </div>
          <!--Newsleter Content-->
        </div>
      </div>
    </div>
  </section>
  <!--End Newsleter-->
</template>

<script>
export default {
  data() {
    return {
      //Email
      email: null,
      //Email
    };
  },
  methods: {
    //Newslter
    newsleter() {
      if (!this.email) {
        this.$iziToast.error({
          message: this.$t("newsleter.emailVal"),
        });
      } else {
        let submit_data = new FormData();
        submit_data.append("email", this.email);
        this.$axios({
          method: "post",
          url: "contact",
          data: submit_data,
        })
          .then(() => {
            this.$iziToast.success({
              message: this.$t("newsleter.subSuccess"),
            });
            this.email = null;
          })
          .catch((err) => {
            this.$iziToast.error({
              message: err.response.data.error,
            });
          });
      }
    },
    //Newslter
  },
};
</script>

<style></style>
