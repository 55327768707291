<template>
  <!--Start Main Section-->
  <section class="main_section" v-if="item">
    <div class="container">
      <div class="row">
        <div class="col-md-8">
          <!--Section Details-->
          <div class="details">
            <!--Top Part-->
            <div class="top_part">
              <!--Titles-->
              <div class="titles">
                <h4 v-html="item.video_title"></h4>
                <!-- <h4>{{ $t("mainSection.getJob") }}</h4>
                <h4>{{ $t("mainSection.buildCompany") }}</h4>
                <h4>{{ $t("mainSection.startFuture") }}</h4> -->
              </div>
              <!--Titles-->
              <!--Description-->
              <div class="description">
                <p v-html="item.main_title"></p>
              </div>
              <!--Description-->
            </div>
            <!--Top Part-->
            <!--Button Actions-->
            <div class="button_actions">
              <router-link to="/createCv" class="add_cv main_button3">
                <span>{{ $t("mainSection.recordCv") }}</span>
                <span class="diff">{{ $t("free") }}</span>
              </router-link>
              <router-link to="/packages" class="join_package main_button">
                <span>{{ $t("mainSection.joinPackage") }}</span>
              </router-link>
            </div>
            <!--Button Actions-->
            <!--Job Search-->
            <div class="job_search">
              <h4>{{ $t("quickSearch") }}</h4>
              <input
                type="text"
                class="form-control"
                v-model="search"
                :placeholder="$t('serchJobTit')"
              />
              <select class="custom_select" v-model="city">
                <option value="" disabled>
                  {{ $t("chooseCity") }}
                </option>
                <option v-for="city in cities" :key="city.id" :value="city.id">
                  {{ city.name }}
                </option>
              </select>
              <button
                @click="searchMethod"
                type="button"
                class="btn main_button"
              >
                <span>{{ $t("search.text") }}</span>
              </button>
            </div>
            <!--Job Search-->
          </div>
          <!--Section Details-->
        </div>
        <div class="col-md-4">
          <!--Image-->
          <div class="image">
            <img src="@/assets/images/main_section/video.jpeg" />
            <div class="overlay" @click="openModel(item.video)">
              <img src="@/assets/images/main_section/youtube-fill.png" />
            </div>
          </div>
          <!--Image-->
        </div>
      </div>
    </div>
    <!-- Dialog -->
    <base-model @closeModel="closeModel" :show="model.show_model">
      <div class="video" v-if="videoSrc">
        <video class="preview_video preview_media" controls>
          <source :src="videoSrc" type="video/mp4" />
        </video>
      </div>
    </base-model>
  </section>
  <!--End Main Section-->
</template>

<script>
import Typewriter from "typewriter-vue";
// import video from "@/assets/videos/test_video.mp4";
export default {
  props: ["item"],
  data() {
    return {
      //Word Animation
      whois: ["<span>مجانا</span>", "<span>مجانا</span>", "<span>مجانا</span>"],
      //Word Animation

      //Search
      search: null,

      //City
      city: "",

      //Cities
      cities: null,

      //Model
      model: {
        show_model: false,
      },
      //Model
      videoSrc: null,
    };
  },
  methods: {
    //Start Word Animation
    pollPerson() {
      const first = this.whois.shift();
      this.whois = this.whois.concat(first);
    },
    //End Word Animation

    //Start Serch Method
    searchMethod() {
      if (!this.search) {
        setTimeout(() => {
          this.$iziToast.error({
            message: this.$t("search.word"),
          });
        }, 1000);
      } else {
        this.$router.push({
          name: "SearchResaults",
          params: { text: this.search, city: this.city },
        });
      }
    },
    //End Serch Method

    //Start Model Actions
    openModel(video) {
      this.model.show_model = true;
      this.videoSrc = video;
    },

    closeModel() {
      this.model.show_model = false;
    },
    //End Model Actions

    //====Cities=======
    getCities() {
      this.$axios({
        method: "GET",
        url: "cities",
      }).then((res) => {
        this.cities = res.data.data;
      });
    },
  },
  mounted() {
    window.setInterval(() => {
      this.pollPerson();
    }, 4000);
    this.getCities();
  },
  components: {
    Typewriter,
  },
};
</script>

<style></style>
