export default {
    token(state) {
        return state.token;
    },
    usertype(state) {
        return state.usertype;
    },
    userName(state) {
        return state.userName;
    },
    set_userId(state) {
        return state.user_id;
    },
    userImage(state) {
        return state.userImage;
    },
    userPhone(state) {
        return state.userPhone;
    },
    devMessage(state) {
        return state.devMessage;
    },
    email(state) {
        return state.email;
    },
    havePackage(state) {
        return state.havePackage
    },
    expPackage(state) {
        return state.expPackage
    },
};