vt
<template>
  <div class="register_page">
    <!--Top Background-->
    <div class="top_background">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="title">
              <h4>
                {{ $t("ImproveCv.title") }}
              </h4>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--Top Background-->
    <!--Improve Cv Page-->
    <div class="featured_cv_page">
      <div class="container">
        <div class="register_wizard_form">
          <template v-if="step == 11">
            <div class="wizard_title">
              <h4>{{ $t("ImproveCv.wizard_title") }}</h4>
              <p>{{ $t("ImproveCv.wiazrd.desc") }}</p>
            </div>

            <!--Welcome Content-->
            <div class="welcome_content">
              <span
                >{{ $t("welcome") }}
                <h4>{{ userName }}</h4></span
              >
              <p>{{ $t("ImproveCv.enterPath") }}</p>
            </div>
            <!--Welcome Content-->

            <!--Form Inputs-->
            <div class="form_inputs">
              <form>
                <div class="row align-items-center">
                  <!--Choose Path-->
                  <div class="col-md-3">
                    <div class="label_text">
                      <h4>{{ $t("ImproveCv.wiazrd.choosePath") }}</h4>
                    </div>
                  </div>

                  <div class="col-md-5">
                    <!--Input Wrapper-->
                    <div class="input_wrapper reg_style select">
                      <!--Select Specialists-->
                      <select class="form-control" v-model="data.subSpecialist">
                        <option disabled value="">
                          {{ $t("ImproveCv.wiazrd.choosePath") }}
                        </option>
                        <option
                          v-for="sub in subSpecialists"
                          :key="sub.id"
                          :value="sub.id"
                        >
                          {{ sub.name }}
                        </option>
                      </select>
                      <!--Select Specialists-->
                    </div>
                    <!--Input Wrapper-->
                  </div>

                  <div class="col-sm-4">
                    <button
                      type="button"
                      class="main_button button"
                      @click="nextStep()"
                      ref="register"
                    >
                      <i class="fas fa-spinner"></i>
                      <span>{{ $t("next") }}</span>
                    </button>
                  </div>
                  <!--Choose Path-->
                </div>
              </form>
            </div>
            <!--Form Inputs-->
          </template>

          <!--Improve Choice-->
          <div class="improve_choice">
            <!--Improve Item-->
            <!-- <div class="imp_item imp_flex">
              <p>لقد اخترت مسار وظيفي في</p>
              <h4>تصميم تجربة المستخدم</h4>
            </div> -->
            <!--Improve Item-->

            <!--Improve Item-->
            <div class="imp_item">
              <p v-if="lang_global == 'ar'">
                الشهادات التي تقدمها المراكز التدريبية المعتمدة بالتعاون مع ساس
                الذكية للتوظيف
              </p>
              <p v-else>
                Certificates offered by accredited training centers in
                cooperation with SAS Smart Recruitment
              </p>
            </div>
            <!--Improve Item-->

            <div class="imp_item">
              <p v-if="lang_global == 'ar'">
                اضغط على الكورس المطلوب لتوجيهك لاحد المراكز المعتمدة
              </p>
              <p v-else>
                Click on the required course to direct you to one of the
                accredited centers
              </p>
            </div>
            <!--Improve Item-->
          </div>
          <!--Improve Choice-->

          <!--Courses Center-->
          <div class="courses_center" v-if="token">
            <not-found v-if="careers.length == 0"></not-found>
            <div class="row" v-else>
              <div class="col-md-4" v-for="career in careers" :key="career.id">
                <a
                  :href="career.link"
                  target="_blank"
                  v-if="token"
                  @click="vistors(career.id)"
                >
                  <div class="course_center_card">
                    <p class="mb-1">
                      <i class="fal fa-eye"></i>{{ career.visit_count }}
                    </p>
                    <span>{{ career.level }}</span>
                    <h4>{{ career.name }}</h4>
                    <p>{{ career.desc }}</p>
                  </div>
                </a>
                <div class="course_center_card" v-else>
                  <span>{{ career.level }}</span>
                  <h4>{{ career.name }}</h4>
                </div>
              </div>
              <!-- <div class="col-md-4">
                  <div class="course_center_card">
                    <span>مبتدئ</span>
                    <h4>تعليم تطوير واجهة المستخدم</h4>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="course_center_card">
                    <span>مبتدئ</span>
                    <h4>اساسيات التصميم</h4>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="course_center_card">
                    <span>محترف</span>
                    <h4>ماهي تجربة المستخدم</h4>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="course_center_card">
                    <span>محترف</span>
                    <h4>ماهي تجربة المستخدم</h4>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="course_center_card">
                    <span>محترف</span>
                    <h4>ماهي تجربة المستخدم</h4>
                  </div>
                </div> -->
            </div>
          </div>
          <div class="courses_center" v-else>
            <p v-if="lang_global == 'ar'">يرجى تسجيل الدخول لمشاهدة الروابط</p>
            <p v-else>Please Sing Up To See Links</p>
          </div>
          <!--Courses Center-->
        </div>
      </div>
    </div>
    <!--Improve Cv Page-->

    <!--Newsleter-->
    <newsleter></newsleter>
    <!--Newsleter-->
  </div>
</template>

<script>
import Newsleter from "../../../components/HomePage/Newsleter.vue";
export default {
  components: { Newsleter },
  data() {
    return {
      //Progress Step
      step: 5,
      //Progress Step

      //Specialists
      specialists: [],
      //Specialists

      //Sub Specialists
      subSpecialists: [],
      //Sub Specialists

      //Careers
      careers: [],
      //Careers

      //Data
      data: {
        specialist: "",
        subSpecialist: "",
      },
    };
  },
  methods: {
    //Next Step
    nextStep() {
      // this.step++;
      this.getCareers();
    },
    //Next Step

    //====Sub Category=======
    getSub() {
      this.$axios({
        method: "GET",
        url: "subcategories",
      }).then((res) => {
        this.subSpecialists = res.data.data;
      });
    },

    //=======Get Careers====
    getCareers() {
      this.$axios({
        method: "GET",
        url: "careers",
        // params: { sub_categories_id: this.data.subSpecialist },
      }).then((res) => {
        this.careers = res.data.data;
      });
    },

    vistors(id) {
      if (this.token) {
        this.$axios({
          method: "GET",
          url: "client/career",
          params: { id: id },
        })
          .then(() => {
            this.getCareers();
          })
          .catch((err) => {
            setTimeout(() => {
              this.$iziToast.success({
                message: err.response.data.message,
              });
            }, 1000);
          });
      } else {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.$iziToast.success({
            message: this.$t("loginFrist"),
          });
        }, 1000);
      }
    },
  },
  mounted() {
    this.getCareers();
  },
  computed: {
    userName() {
      return this.$store.getters["auth_module/userName"];
    },
    token() {
      return this.$store.getters["auth_module/token"];
    },
  },
};
</script>

<style>
.course_center_card p {
  color: #fff;
}
.course_center_card p i {
  margin-inline-end: 5px;
}
</style>
