var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.loading)?_c('loader'):_c('div',{staticClass:"our_clients"},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-3"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"text"},[_c('h4',[_vm._v(_vm._s(_vm.$t("trustedCustomers.title")))])])])]),_c('div',{staticClass:"col-md-9"},[_c('owl-slider',{attrs:{"items":5,"autoplay":true,"loop":true,"nav":false,"dots":false,"margin":10,"dir":"ltr","responsive":{
            0: { items: 1, nav: false },
            700: { items: 3, nav: false },
            1000: { items: 7, nav: false },
          }}},_vm._l((_vm.item.customers),function(customer){return _c('div',{key:customer.id,staticClass:"item"},[_c('div',{staticClass:"client_item"},[_c('img',{attrs:{"src":customer.image}})])])}),0)],1),_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"text"},[_c('p',{domProps:{"innerHTML":_vm._s(_vm.item.trusted_customers_desc)}})])])]),_c('div',{staticClass:"col-md-8"},[_c('owl-slider',{attrs:{"items":4,"autoplay":true,"loop":true,"nav":false,"dots":false,"dir":"ltr","margin":10,"responsive":{
            0: { items: 1, nav: false },
            700: { items: 2, nav: false },
            1000: { items: 4, nav: false },
          }}},_vm._l((_vm.item.customers1),function(customer){return _c('div',{key:customer.id,staticClass:"item"},[_c('div',{staticClass:"client_item"},[_c('img',{attrs:{"src":customer.image}})])])}),0)],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }