vt
<template>
  <div class="register_page">
    <!--Top Background-->
    <div class="top_background">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="title">
              <h4>
                {{ $t("featuredCv.title") }}
              </h4>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--Top Background-->
    <!--Featured Cv Page-->
    <div class="featured_cv_page">
      <div class="container">
        <div class="register_wizard_form">
          <div class="wizard_title">
            <h4>{{ $t("featuredCv.wizard_title") }}</h4>
            <p>
              {{ $t("featuredCv.wiazrd.desc") }}
              {{ prices }}
              {{ $t("riyal") }}
            </p>
          </div>
          <!--Progress Bar-->
          <div class="progress_bar">
            <ul>
              <li :class="{ active: step == 1 }">
                <h4>01 {{ $t("featuredCv.wiazrd.uploadCvPhoto") }}</h4>
                <p>
                  <span></span>
                </p>
              </li>
              <li :class="{ active: step == 2 }">
                <h4>02 {{ $t("featuredCv.wiazrd.uploadCvFile") }}</h4>
                <p>
                  <span></span>
                </p>
              </li>
              <li :class="{ active: step == 3 }">
                <h4>03 {{ $t("featuredCv.wiazrd.pay") }}</h4>
                <p>
                  <span></span>
                </p>
              </li>
            </ul>
          </div>
          <!--Progress Bar-->

          <!--Form Inputs-->
          <div class="form_inputs">
            <p v-if="step == 1">{{ $t("featuredCv.wiazrd.advice1") }}</p>
            <p v-if="step == 2">{{ $t("featuredCv.wiazrd.advice2") }}</p>
            <form>
              <div class="row align-items-center">
                <!--Upload Cv Image-->
                <template v-if="step == 1">
                  <div class="col-md-3">
                    <div class="label_text">
                      <h4>{{ $t("featuredCv.wiazrd.uploadCvPhoto2") }}</h4>
                    </div>
                  </div>

                  <div class="col-md-5">
                    <!--Input Wrapper-->
                    <div class="input_wrapper reg_style file_upload">
                      <!--Upload Cv-->
                      <div class="form-control mb-3">
                        <span v-if="data.beforeUploadImage">
                          {{ $t("featuredCv.wiazrd.uploadImage") }}</span
                        >
                        <span v-if="data.afterUploadImage">{{
                          data.uploadImage.name
                        }}</span>
                        <span class="icon"><i class="fal fa-file"></i></span>
                      </div>
                      <input
                        type="file"
                        name="chooseFile"
                        @change="uploadCvImage"
                        accept="image/*"
                      />
                      <!--Upload Cv-->
                    </div>
                    <!--Input Wrapper-->
                    <span>{{ $t("featuredCv.wiazrd.uploadType1") }}</span>
                  </div>

                  <div class="col-sm-4">
                    <button
                      type="button"
                      class="main_button button"
                      @click="next()"
                    >
                      <i class="fas fa-spinner"></i>
                      <span>{{ $t("next") }}</span>
                    </button>
                  </div>
                </template>
                <!--Upload Cv Image-->

                <!--Upload Cv File-->
                <template v-if="step == 2">
                  <div class="col-md-3">
                    <div class="label_text">
                      <h4>{{ $t("featuredCv.wiazrd.uploadCvFile") }}</h4>
                    </div>
                  </div>

                  <div class="col-md-5">
                    <!--Input Wrapper-->
                    <div class="input_wrapper reg_style file_upload">
                      <!--Upload Cv-->
                      <div class="form-control mb-3">
                        <span v-if="data.beforeUploadFile">
                          {{ $t("featuredCv.wiazrd.uploadFile") }}</span
                        >
                        <span v-if="data.afterUploadFile">{{
                          data.uploadCv.name
                        }}</span>
                        <span class="icon"><i class="fal fa-file"></i></span>
                      </div>
                      <input
                        type="file"
                        name="chooseFile"
                        @change="uploadCvFile"
                        accept=".doc, .docx,.pdf"
                      />
                      <!--Upload Cv-->
                    </div>
                    <!--Input Wrapper-->
                    <span>{{ $t("featuredCv.wiazrd.uploadType2") }}</span>
                  </div>

                  <div class="col-sm-4">
                    <div class="buttons_group">
                      <button
                        type="button"
                        class="main_button button"
                        @click="next()"
                      >
                        <i class="fas fa-spinner"></i>
                        <span>{{ $t("next") }}</span>
                      </button>
                      <button
                        type="button"
                        class="main_button button"
                        @click="step = 1"
                      >
                        <i class="fas fa-spinner"></i>
                        <span>{{ $t("back") }}</span>
                      </button>
                    </div>
                  </div>
                </template>
                <!--Upload Cv File-->

                <!--Upload Cv File-->
                <template v-if="step == 3">
                  <div class="col-md-12">
                    <div class="pay_step" v-if="status">
                      <h4>{{ $t("featuredCv.paySuccess") }}</h4>
                      <router-link class="main_button button" to="/">
                        <span> {{ $t("navbar.home") }}</span>
                      </router-link>
                      <!--Next Button-->
                    </div>
                    <div class="label_text" v-else>
                      <h4>{{ $t("featuredCv.pay") }}</h4>
                      <button
                        type="button"
                        class="main_button button"
                        @click="sendData()"
                      >
                        <i class="fas fa-spinner"></i>
                        <span>{{ $t("send") }}</span>
                      </button>
                    </div>
                  </div>

                  <!-- <div class="col-sm-12">
                    <button
                      type="button"
                      class="main_button button"
                      @click="sendData()"
                    >
                      <i class="fas fa-spinner"></i>
                      <span>{{ $t("send") }}</span>
                    </button>
                  </div> -->
                </template>
                <!--Upload Cv File-->
              </div>
            </form>
          </div>
          <!--Form Inputs-->
        </div>
      </div>
    </div>
    <!--Featured Cv Page-->

    <!--Strong Cv-->
    <!-- <strong-cv></strong-cv> -->
    <!--Strong Cv-->

    <!--Newsleter-->
    <newsleter></newsleter>
    <!--Newsleter-->

    <!--Payment Model-->
    <base-model :show="show" @closeModel="closeModel">
      <div class="custom_card">
        {{ transId }}
        <form id="form-container" method="post" action="/charge">
          <!-- Tap element will be here -->
          <div id="element-container"></div>
          <div id="error-handler" role="alert"></div>
          <!-- <div
            id="success"
            style="display: none; position: relative; float: left"
          >
            Success! Your token is <span id="token"></span>
          </div> -->
          <!-- Tap pay button -->
          <!-- <button id="tap-btn" @click="upload">{{ $t("pay") }}</button> -->
          <button class="main_button button" id="tap-btn" ref="upload">
            <i class="fas fa-spinner"></i>
            <span>{{ $t("pay") }}</span>
          </button>
          <span
            >{{ $t("price") }} : {{ prices ? prices.trend_cv_price : "" }}
            {{ $t("riyal") }}</span
          >
        </form>
      </div>
    </base-model>
    <!--Payment Model-->
  </div>
</template>

<script src="https://cdnjs.cloudflare.com/ajax/libs/bluebird/3.3.4/bluebird.min.js"></script>
<script src="https://secure.gosell.io/js/sdk/tap.min.js"></script>
<script>
import Newsleter from "../../../components/HomePage/Newsleter.vue";
// import StrongCv from "../../../components/HomePage/StrongCv.vue";
export default {
  components: { Newsleter },
  data() {
    return {
      //Progress Step
      step: 1,
      //Progress Step

      trans_id: this.$route.query.tap_id,
      status: this.$route.query.status,
      id: this.$route.query.resume_id,
      type_pay: this.$route.query.type,
      price: this.$route.query.price,

      //Show Pay
      show: false,

      //Trans Id
      transId: null,

      //Prices
      prices: null,

      //Data
      data: {
        uploadImage: null,
        afterUploadImage: false,
        beforeUploadImage: true,
        uploadCv: null,
        afterUploadFile: false,
        beforeUploadFile: true,
      },
    };
  },
  methods: {
    //Upload Cv
    uploadCvImage(e) {
      this.data.uploadImage = e.target.files[0];
      this.data.beforeUploadImage = false;
      this.data.afterUploadImage = true;
    },
    //Upload Cv

    //Upload Logo
    uploadCvFile(e) {
      this.data.uploadCv = e.target.files[0];
      this.data.beforeUploadFile = false;
      this.data.afterUploadFile = true;
    },
    //Upload Logo

    openModel() {
      this.show = true;
      localStorage.removeItem("Sas_TransId");
    },

    //CloseModel
    closeModel() {
      this.show = false;
      localStorage.removeItem("Sas_TransId");
    },

    //Pay Method
    payMethod() {
      //pass your public key from tap's dashboard
      var tap = Tapjsli("pk_live_8eKYZbCptrBD97zaUkNivxgR");

      var elements = tap.elements({});

      var style = {
        base: {
          color: "#535353",
          lineHeight: "18px",
          fontFamily: "sans-serif",
          fontSmoothing: "antialiased",
          fontSize: "16px",
          "::placeholder": {
            color: "rgba(0, 0, 0, 0.26)",
            fontSize: "15px",
          },
        },
        invalid: {
          color: "red",
        },
      };
      // input labels/placeholders
      var labels = {
        cardNumber: "Card Number",
        expirationDate: "MM/YY",
        cvv: "CVV",
        cardHolder: "Card Holder Name",
      };
      //payment options
      var paymentOptions = {
        currencyCode: ["KWD", "USD", "SAR"],
        labels: labels,
        TextDirection: "ltr",
      };
      //create element, pass style and payment options
      var card = elements.create("card", { style: style }, paymentOptions);
      //mount element
      card.mount("#element-container");
      //card change event listener
      card.addEventListener("change", function (event) {
        if (event.loaded) {
          console.log("UI loaded :" + event.loaded);
          console.log("current currency is :" + card.getCurrency());
        }
        var displayError = document.getElementById("error-handler");
        if (event.error) {
          displayError.textContent = event.error.message;
        } else {
          displayError.textContent = "";
        }
      });

      // Handle form submission
      var form = document.getElementById("form-container");
      form.addEventListener("submit", function (event) {
        event.preventDefault();

        tap.createToken(card).then(function (result) {
          console.log(result);
          if (result.error) {
            // Inform the user if there was an error
            console.log("");
            // var errorElement = document.getElementById("error-handler");
            // errorElement.textContent = result.error.message;
          } else {
            // Send the token to your server
            // var errorElement = document.getElementById("success");
            // errorElement.style.display = "block";
            // var tokenElement = document.getElementById("token");
            // tokenElement.textContent = result.id;
            localStorage.setItem("Sas_TransId", result.id);
            tapTokenHandler(token);
          }
        });
      });

      function tapTokenHandler(token) {
        // Insert the token ID into the form so it gets submitted to the server
        var form = document.getElementById("payment-form");
        var hiddenInput = document.createElement("input");
        hiddenInput.setAttribute("type", "hidden");
        hiddenInput.setAttribute("name", "tapToken");
        hiddenInput.setAttribute("value", token.id);
        form.appendChild(hiddenInput);

        // Submit the form
        form.submit();
      }

      card.addEventListener("change", function (event) {
        if (event.BIN) {
          console.log(event.BIN);
        }
        // var displayError = document.getElementById("card-errors");
        // if (event.error) {
        //   displayError.textContent = event.error.message;
        // } else {
        //   displayError.textContent = "";
        // }
      });
    },

    payAmount(id) {
      window.location.href =
        "https://sas-jobs.net/pay?amount=" +
        this.prices +
        "&redirect_url=" +
        encodeURIComponent(
          "https://sas-jobs.net/featuredCv?" +
            "&type=resume&user_id=" +
            this.userId +
            "&price=" +
            this.prices +
            "&resume_id=" +
            id
        );
    },

    //Next Step
    next() {
      if (!this.data.uploadImage && this.step == 1) {
        setTimeout(() => {
          this.$iziToast.error({
            message: this.$t("featuredCv.validation.uploadImage"),
          });
        }, 1000);
      } else if (!this.data.uploadCv && this.step == 2) {
        setTimeout(() => {
          this.$iziToast.error({
            message: this.$t("featuredCv.validation.uploadFile"),
          });
        }, 1000);
      } else {
        this.step++;
      }
    },
    //Send Data
    sendData() {
      if (!this.data.uploadImage) {
        setTimeout(() => {
          this.$iziToast.error({
            message: this.$t("featuredCv.validation.uploadImage"),
          });
        }, 1000);
      } else if (!this.data.uploadCv) {
        setTimeout(() => {
          this.$iziToast.error({
            message: this.$t("featuredCv.validation.uploadFile"),
          });
        }, 1000);
      } else {
        let submit_data = new FormData();
        submit_data.append("image", this.data.uploadImage);
        if (this.data.uploadCv) {
          submit_data.append("cv", this.data.uploadCv);
        }
        submit_data.append("transaction_id", "hhhh");
        submit_data.append("payment_type", "visa");
        submit_data.append("value", this.prices);
        this.$axios({
          method: "post",
          url: "resume",
          data: submit_data,
        })
          .then((res) => {
            setTimeout(() => {
              this.payAmount(res.data.data.id);
              this.$iziToast.success({
                message: this.$t("sendSuccess"),
              });
            }, 1000);
          })
          .catch((err) => {
            setTimeout(() => {
              this.$iziToast.error({
                message: err.response.data.message,
              });
            }, 1000);
          });
      }
    },

    confirmPay() {
      let submit_data = new FormData();
      submit_data.append("type", this.type_pay);
      submit_data.append("id", this.id);
      submit_data.append("transaction_id", this.trans_id);
      submit_data.append("user_id", this.userId);
      submit_data.append("value", this.price);
      this.$axios({
        method: "post",
        url: "set_pay",
        data: submit_data,
      })
        .then(() => {
          this.step = 3;
          this.$iziToast.success({
            message: this.$t("sendSuccess"),
          });
        })
        .catch((err) => {
          setTimeout(() => {
            this.$iziToast.error({
              message: err.response.data.message,
            });
          }, 1000);
        });
    },

    //Register
    // upload() {
    //   setTimeout(() => {
    //     var submitButton = this.$refs["upload"];
    //     submitButton.classList.add("click");
    //     if (!this.data.uploadImage) {
    //       setTimeout(() => {
    //         submitButton.classList.remove("click");
    //         this.$iziToast.error({
    //           message: this.$t("featuredCv.validation.uploadImage"),
    //         });
    //       }, 1000);
    //     } else if (!this.data.uploadCv) {
    //       setTimeout(() => {
    //         submitButton.classList.remove("click");
    //         this.$iziToast.error({
    //           message: this.$t("featuredCv.validation.uploadFile"),
    //         });
    //       }, 1000);
    //     } else {
    //       let submit_data = new FormData();
    //       submit_data.append("image", this.data.uploadImage);
    //       submit_data.append("cv", this.data.uploadCv);
    //       submit_data.append(
    //         "transaction_id",
    //         localStorage.getItem("Sas_TransId")
    //       );
    //       submit_data.append("payment_type", "visa");
    //       submit_data.append("value", this.prices.trend_cv_price);
    //       this.$axios({
    //         method: "post",
    //         url: "resume",
    //         data: submit_data,
    //       })
    //         .then(() => {
    //           setTimeout(() => {
    //             submitButton.classList.remove("click");
    //             localStorage.removeItem("Sas_TransId");
    //             this.step = 1;
    //             this.closeModel();
    //             this.$iziToast.success({
    //               message: this.$t("global.global"),
    //             });
    //           }, 1000);
    //         })
    //         .catch((err) => {
    //           setTimeout(() => {
    //             submitButton.classList.remove("click");
    //             this.$iziToast.error({
    //               message: err.response.data.message,
    //             });
    //           }, 1000);
    //         });
    //     }
    //   }, 800);
    // },
  },
  computed: {
    userId() {
      return this.$store.getters["auth_module/set_userId"];
    },
  },
  mounted() {
    //====Prices=======
    this.$axios({
      method: "GET",
      url: "prices",
    }).then((res) => {
      this.prices = res.data.data.trend_cv_price;
    });

    if (this.status == "success") {
      this.confirmPay();
    } else if (this.status == "fail") {
      this.$iziToast.error({
        message: "ghhhhhhh",
      });
    }
  },
};
</script>

<style>
.buttons_group {
  display: flex;
  align-items: center;
}
</style>
