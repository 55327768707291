<template>
  <div class="register_page">
    <!--Top Background-->
    <div class="top_background">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="title">
              <h4>{{ $t("profile.title") }}</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--Top Background-->

    <!--Profile Data-->
    <div class="container">
      <div class="row">
        <div class="col-md-9">
          <!--  Profile Card-->
          <div class="profile_card">
            <Tabs :tabsContent="tabsContent">
              <template v-slot:personal_data>
                <!--Personal Data-->
                <div class="personal_data" v-if="myData">
                  <div class="row">
                    <div class="col-lg-6">
                      <!--Personal Content-->
                      <div class="personal_content">
                        <!--Data Info-->
                        <div class="data_info">
                          <h4>{{ $t("profile.name") }}</h4>
                          <span>{{
                            myData.first_name + " " + myData.last_name
                          }}</span>
                        </div>
                        <!--Data Info-->

                        <!--Data Info-->
                        <div class="data_info">
                          <h4>{{ $t("profile.email") }}</h4>
                          <span>{{ myData.email }}</span>
                        </div>
                        <!--Data Info-->

                        <!--Data Info-->
                        <div class="data_info">
                          <h4>{{ $t("profile.gender") }}</h4>
                          <span>{{
                            myData.gender == "male" ? $t("male") : $t("female")
                          }}</span>
                        </div>
                        <!--Data Info-->

                        <!--Data Info-->
                        <div class="data_info" v-if="myData.main_category">
                          <h4>{{ $t("profile.specialist") }}</h4>
                          <span>{{ myData.main_category.name }}</span>
                        </div>
                        <!--Data Info-->

                        <!--Data Info-->
                        <div class="data_info" v-if="myData.education_level">
                          <h4>{{ $t("profile.education_level") }}</h4>
                          <span>{{ myData.education_level.name }}</span>
                        </div>
                        <!--Data Info-->
                      </div>
                      <!--Personal Content-->
                    </div>
                    <div class="col-lg-6" v-if="myData.city">
                      <!--Personal Content-->
                      <div class="personal_content">
                        <!--Data Info-->
                        <div class="data_info">
                          <h4>{{ $t("profile.region") }}</h4>
                          <span>{{ myData.city.name }}</span>
                        </div>
                        <!--Data Info-->

                        <!--Data Info-->
                        <div class="data_info">
                          <h4>{{ $t("profile.phone") }}</h4>
                          <span>{{ myData.phone }}</span>
                        </div>
                        <!--Data Info-->

                        <!--Data Info-->
                        <div class="data_info">
                          <h4>{{ $t("profile.birthDate") }}</h4>
                          <span>{{ myData.date_birth }}</span>
                        </div>
                        <!--Data Info-->

                        <!--Data Info-->
                        <div class="data_info">
                          <h4>{{ $t("profile.experience") }}</h4>
                          <span>{{ myData.experience_years }}</span>
                        </div>
                        <!--Data Info-->

                        <!--Data Info-->
                        <div class="data_info">
                          <h4>{{ $t("profile.cv") }}</h4>
                          <span
                            ><a :href="myData.cv" target="_blank">{{
                              $t("profile.show_cv")
                            }}</a></span
                          >
                        </div>
                        <!--Data Info-->
                      </div>
                      <!--Personal Content-->
                    </div>
                    <div class="col-lg-12">
                      <!--Personal Content-->
                      <div class="personal_content">
                        <div class="data_info">
                          <h4>{{ $t("profile.bio") }}</h4>
                          <span>{{ myData.bio }} </span>
                        </div>
                      </div>
                      <!--Personal Content-->
                    </div>
                    <div class="col-lg-12">
                      <router-link
                        to="/editJobSearcher"
                        class="main_button mb-3"
                        ><span>{{ $t("editData") }}</span></router-link
                      >
                    </div>
                  </div>
                </div>
                <!--Personal Data-->
              </template>

              <template v-slot:jobs>
                <!--Applied Jobs-->
                <div class="applied_jobs" v-for="job in jobData" :key="job.id">
                  <!--Job Applied Card-->
                  <div class="job_applied_card">
                    <!--Details-->
                    <div class="details">
                      <router-link
                        :to="`jobDetails/${job.id}`"
                        title="اضغط لمشاهدة تفاصيل الوظيفة"
                      >
                        <!--Title-->
                        <div class="title">
                          <h4>{{ job.name }}</h4>
                        </div>
                        <!--Title-->

                        <!--City-->
                        <div class="city">
                          <h4 v-if="job.city">{{ job.city.name }}</h4>
                          <span>{{ job.created_at }}</span>
                        </div>
                        <!--City-->

                        <!--Desc-->
                        <div class="desc">
                          <p>
                            {{ job.desc }}
                          </p>
                        </div>
                        <!--Desc-->
                      </router-link>
                      <!--Status-->
                      <div class="status">
                        <span>{{ $t("appliedDone") }}</span>
                      </div>
                      <!--Status-->
                    </div>
                    <!--Details-->

                    <!--Image-->
                    <div class="image">
                      <img :src="job.company.image" />
                      <h4>{{ job.company.fullname }}</h4>
                    </div>
                    <!--Image-->
                  </div>
                  <!--Job Applied Card-->
                </div>
                <!--Applied Jobs-->
              </template>
            </Tabs>
          </div>
          <!--Profile Card-->
        </div>
        <div class="col-md-3">
          <!--Elan-->
          <!-- <div class="elan">
            <img src="@/assets/images/icons/elan.png" />
          </div> -->
          <!--Elan-->
        </div>
      </div>
    </div>
    <!--Profile Data--->

    <!--Newsleter-->
    <newsleter></newsleter>
    <!--Newsleter-->
  </div>
</template>

<script>
import Newsleter from "@/components/HomePage/Newsleter.vue";
import Tabs from "@/components/Global/Tabs.vue";
export default {
  components: { Newsleter, Tabs },
  data() {
    return {
      //My Data
      myData: null,
      //My Data

      //Jobs Data
      jobData: null,
      //Jobs Data

      // START:: TABS DATA
      tabsContent: [
        {
          tab: this.$t("profile.profileData"),
          key_name: "personal_data",
        },
        {
          tab: this.$t("profile.jobsApplid"),
          key_name: "jobs",
        },
      ],
      // END:: TABS DATA
    };
  },
  methods: {
    getData() {
      //Get Profile
      this.$axios({
        method: "GET",
        url: "profile",
        headers: {
          Authorization: "bearer" + localStorage.getItem("SAS_App_Token"),
        },
      }).then((res) => {
        this.myData = res.data.data;
      });
      //Get Profile

      //Get Applied Jobs
      this.$axios({
        method: "GET",
        url: "client/jobs",
        headers: {
          Authorization: "bearer" + localStorage.getItem("SAS_App_Token"),
        },
      }).then((res) => {
        this.jobData = res.data.data;
      });
      //Get Applied Jobs
    },
  },
  created() {
    this.getData();
  },
};
</script>

<style></style>
