<template>
  <div class="register_page">
    <!--Top Background-->
    <div class="top_background">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="title my_pack">
              <!-- <h4>{{ $t("search.title") }}</h4> -->
              <!--Company Info-->
              <div class="company_info">
                <img :src="userImage" />
                <h4>{{ userName }}</h4>
              </div>
              <!--Company Info-->

              <!--Package Info-->
              <div class="package_info">
                <!-- <p>{{ $t("packages.yourPackage") }} : <span></span></p> -->
                <p>
                  {{ $t("packages.expDate") }} : <span>{{ expPackage }}</span>
                </p>
              </div>
              <!--Package Info-->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--Top Background-->

    <!--Categories-->
    <div class="search_page">
      <div class="container">
        <div class="row">
          <div class="col-lg-3">
            <!--Search Sidebar-->
            <div class="search_sidebar">
              <!--Sidebar Item-->
              <div class="sidebar_item">
                <h4>{{ $t("jobFilter.category") }}</h4>
                <ul>
                  <li v-for="cat in categories" :key="cat.id">
                    <input
                      type="radio"
                      name="cat"
                      :id="'c' + cat.id"
                      :value="cat.id"
                      v-model="filterData.category"
                      @change="getJobs()"
                    />
                    <label :for="'c' + cat.id">{{ cat.name }}</label>
                  </li>
                </ul>
              </div>
              <!--Sidebar Item-->

              <!--Sidebar Item-->
              <div class="sidebar_item">
                <h4>{{ $t("jobFilter.region") }}</h4>
                <ul>
                  <li v-for="city in cities" :key="city.id">
                    <input
                      type="radio"
                      name="reg"
                      :id="'r' + city.id"
                      :value="city.id"
                      v-model="filterData.city"
                      @change="getJobs()"
                    />
                    <label :for="'r' + city.id">{{ city.name }}</label>
                  </li>
                </ul>
              </div>
              <!--Sidebar Item-->

              <!--Sidebar Item-->
              <div class="sidebar_item">
                <h4>{{ $t("jobFilter.experience") }}</h4>
                <ul>
                  <li>
                    <input
                      type="radio"
                      name="experience"
                      id="dd1"
                      value="1"
                      v-model="filterData.experience"
                    />
                    <label for="dd1">{{ $t("jobFilter.year") }}</label>
                  </li>
                  <li>
                    <input
                      type="radio"
                      name="experience"
                      id="dd2"
                      value="2"
                      v-model="filterData.experience"
                    />
                    <label for="dd2">{{ $t("jobFilter.2year") }}</label>
                  </li>
                  <li>
                    <input
                      type="radio"
                      name="date"
                      id="dd3"
                      value="5"
                      v-model="filterData.experience"
                    />
                    <label for="dd3">{{ $t("jobFilter.5year") }}</label>
                  </li>
                  <li>
                    <input
                      type="radio"
                      name="date"
                      id="dd4"
                      value="10"
                      v-model="filterData.experience"
                    />
                    <label for="dd4">{{ $t("jobFilter.10years") }}</label>
                  </li>
                </ul>
              </div>
              <!--Sidebar Item-->

              <!--Sidebar Item-->
              <div class="sidebar_item">
                <h4>{{ $t("jobFilter.type") }}</h4>
                <ul>
                  <li>
                    <input
                      type="radio"
                      name="type"
                      id="ddd1"
                      value="male"
                      v-model="filterData.type"
                    />
                    <label for="ddd1">{{ $t("male") }}</label>
                  </li>
                  <li>
                    <input
                      type="radio"
                      name="type"
                      id="ddd2"
                      value="2"
                      v-model="filterData.type"
                    />
                    <label for="ddd2">{{ $t("female") }}</label>
                  </li>
                </ul>
              </div>
              <!--Sidebar Item-->
              <!--Button-->
              <button class="main_button" type="button" @click="getUsers">
                <span>{{ $t("applay") }}</span>
              </button>
              <!--Button-->
            </div>
            <!--Search Sidebar-->
          </div>
          <div class="col-lg-9">
            <loader v-if="loading"></loader>
            <div v-else>
              <template v-if="users.length == 0">
                <not-found></not-found>
                <router-link
                  class="main_button"
                  to="/packages"
                  @click="getUsers"
                >
                  <span>{{ $t("navbar.packages") }}</span>
                </router-link>
              </template>
              <div class="row" v-else>
                <div class="col-lg-4" v-for="user in users" :key="user.id">
                  <EmployesCard
                    :employee="user"
                    :isLiked="user.IsLike"
                  ></EmployesCard>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <strong-cv></strong-cv>
      <newsleter></newsleter>
    </div>
    <!--Categories--->
  </div>
</template>

<script>
import Newsleter from "@/components/HomePage/Newsleter.vue";
import StrongCv from "@/components/HomePage/StrongCv.vue";
import EmployesCard from "@/components/Employes/Employes.vue";
export default {
  components: {
    Newsleter,
    StrongCv,
    EmployesCard,
  },
  data() {
    return {
      //START:: Filter Data
      filterData: {
        category: null,
        city: null,
        experience: null,
        type: null,
      },
      //END:: Filter Data

      //Loading
      loading: false,
      //Loading

      //START:: CATEGORIES
      categories: [],
      //END:: CATEGORIES

      //START:: CITIES
      cities: [],
      //END:: CITIES

      //START:: JOBS
      users: [],
      //END:: JOBS
    };
  },
  methods: {
    getData() {
      //====Cities=======
      this.$axios({
        method: "GET",
        url: "cities",
      }).then((res) => {
        this.cities = res.data.data;
      });
      //====Main Category=======
      this.$axios({
        method: "GET",
        url: "categories",
      }).then((res) => {
        this.categories = res.data.data;
      });
    },
    //=====Get Users
    getUsers() {
      this.loading = true;
      this.$axios({
        method: "GET",
        url: "company/search",
        params: {
          gender: this.filterData.type,
          category_id: this.filterData.category,
          city_id: this.filterData.city,
          experience_years: this.filterData.experience,
        },
      })
        .then((res) => {
          this.loading = false;
          this.users = res.data.data;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
  },
  created() {
    this.getData();
    this.getUsers();
  },
  computed: {
    userImage() {
      return this.$store.getters["auth_module/userImage"];
    },
    userName() {
      return this.$store.getters["auth_module/userName"];
    },
    expPackage() {
      return this.$store.getters["auth_module/expPackage"];
    },
  },
};
</script>

<style></style>
