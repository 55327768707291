<template>
  <div class="register_page">
    <!--Top Background-->
    <div class="top_background">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="title">
              <h4>{{ $t("forgetPassword.title") }}</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--Top Background-->

    <!--About-->
    <div class="container">
      <!--All Text-->
      <div class="all_text forget_password">
        <div class="row justify-content-center">
          <div class="col-md-8">
            <div class="form_inputs">
              <h4>{{ $t("forgetPassword.restoreDone") }}</h4>
              <p>{{ $t("forgetPassword.newPass") }}</p>
              <form>
                <!--Start Input Wrapper Password-->
                <div class="input_wrapper reg_style forget_style">
                  <span>{{ $t("forgetPassword.newPassword") }}</span>
                  <input
                    type="password"
                    class="form-control"
                    v-model="password"
                    :placeholder="$t('forgetPassword.newPassword')"
                  />
                </div>
                <!--End Input Wrapper Password-->

                <!--Start Input Wrapper Password-->
                <!-- <div class="input_wrapper reg_style forget_style">
                  <span>تأكيد كلمة المرور الجديدة</span>
                  <input
                    type="password"
                    class="form-control"
                    v-model="password_confirmation"
                    placeholder="تأكيد كلمة المرور الجديدة"
                  />
                </div> -->
                <!--End Input Wrapper Password-->

                <!--Login Button-->
                <button
                  class="main_button2 button register_btn"
                  ref="forget"
                  type="button"
                  @click="resetPassword"
                >
                  <i class="fas fa-spinner"></i>
                  <span>{{ $t("next") }}</span>
                </button>
                <!--Login Button-->
              </form>
            </div>
          </div>
        </div>
      </div>
      <!--All Text-->
    </div>
    <!--About-->
  </div>
</template>

<script>
export default {
  data() {
    return {
      //password data
      password: null,
      password_confirmation: null,
    };
  },
  methods: {
    resetPassword() {
      var submitButton = this.$refs["forget"];
      submitButton.classList.add("click");
      if (!this.password) {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.$iziToast.error({
            message: this.$t("forgetPassword.validation.newPassword"),
          });
        }, 1000);
      }
      //  else if (this.password_confirmation != this.password) {
      //   setTimeout(() => {
      //     submitButton.classList.remove("click");
      //     this.$iziToast.error({
      //       message: "كلمة المرور الجديدة وتأكيد كلمة المرور ليت متشابهة",
      //     });
      //   }, 1000);
      // }
      else {
        let submit_data = new FormData();
        submit_data.append("email", this.email);
        submit_data.append("password", this.password);
        submit_data.append("code", this.devMessage);
        this.$axios({
          method: "post",
          url: "reset_password",
          data: submit_data,
        })
          .then(() => {
            setTimeout(() => {
              submitButton.classList.remove("click");
              this.$iziToast.success({
                message: this.$t("forgetPassword.validation.changeDone"),
              });
              this.$router.push({ path: "/login" });
            }, 1000);
          })
          .catch((err) => {
            setTimeout(() => {
              submitButton.classList.remove("click");
              this.$iziToast.error({
                message: err.response.data.message,
              });
            }, 1000);
          });
      }
    },
  },
  computed: {
    devMessage() {
      return this.$store.getters["auth_module/devMessage"];
    },
    email() {
      return this.$store.getters["auth_module/email"];
    },
  },
};
</script>

<style></style>
