<template>
  <!--Cv Tempalte-->
  <div class="cv_template_card">
    <!--Image-->
    <div class="image">
      <img :src="template.img" />
      <!--Overlay-->
      <div class="overlay"></div>
      <!--Overlay-->
    </div>
    <!--Image-->
    <!--Text Details-->
    <div class="text_details">
      <!--Title-->
      <div class="title">
        <h4>{{ template.title }}</h4>
      </div>
      <!--Title-->
      <!--Price Details-->
      <div class="price_details">
        <!--Price Item-->
        <div class="price_item">
          <h4>{{ $t("cvsteps.template.arLang") }}</h4>
          <span @click="chooseTemplate(template.priceAr, 'ar')"
            >{{ template.priceAr }} {{ $t("riyal") }}</span
          >
        </div>
        <!--Price Item-->
        <!--Price Item-->
        <div class="price_item">
          <h4>{{ $t("cvsteps.template.enLang") }}</h4>
          <span @click="chooseTemplate(template.priceEn, 'en')"
            >{{ template.priceEn }} {{ $t("riyal") }}</span
          >
        </div>
        <!--Price Item-->
        <!--Price Item-->
        <div class="price_item">
          <h4>{{ $t("cvsteps.template.bothLang") }}</h4>
          <span @click="chooseTemplate(template.priceBoth, 'both')"
            >{{ template.priceBoth }} {{ $t("riyal") }}</span
          >
        </div>
        <!--Price Item-->
      </div>
      <!--Price Details-->
    </div>
    <!--Text Details-->
  </div>
  <!--Cv Tempalte-->
</template>

<script>
export default {
  props: ["template", "route"],
  emits: ["chooseTemplate"],
  data() {
    return {
      priceVal: null,
    };
  },
  methods: {
    chooseTemplate(val, lang) {
      this.priceVal = val;
      this.$emit("chooseTemplate", {
        price: val,
        tempNum: this.template.num,
        tempLang: lang,
      });
    },
  },
};
</script>

<style></style>
