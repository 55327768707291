<template>
  <div class="register_page">
    <!--Top Background-->
    <div class="top_background">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="title">
              <h4>{{ $t("contact.title") }}</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--Top Background-->

    <!--Contact Us-->
    <div class="container">
      <!--All Text-->
      <loader v-if="loading"></loader>
      <div class="all_text" v-else>
        <div class="row justify-content-center">
          <div class="col-md-8">
            <!--Center Logo-->
            <div class="cen_logo">
              <img src="@/assets/images/icons/foot_logo.png" />
            </div>
            <!--Center Logo-->

            <!--Contact Info-->
            <div class="contact_info">
              <ul>
                <li>
                  <!--Icon-->
                  <div class="icon">
                    <i class="fal fa-map-marker-alt"></i>
                    <span>موقعنا</span>
                  </div>
                  <!--Icon-->
                  <!--Desc-->
                  <div class="desc">
                    <p>{{ allData.address }}</p>
                  </div>
                  <!--Desc-->
                </li>
                <li>
                  <!--Icon-->
                  <div class="icon">
                    <i class="fal fa-envelope"></i>
                    <span>الايميل</span>
                  </div>
                  <!--Icon-->
                  <!--Desc-->
                  <div class="desc">
                    <p>{{ allData.email }}</p>
                  </div>
                  <!--Desc-->
                </li>
                <li>
                  <!--Icon-->
                  <div class="icon">
                    <i class="fab fa-snapchat-ghost"></i>
                    <span>سناب شات</span>
                  </div>
                  <!--Icon-->
                  <!--Desc-->
                  <div class="desc">
                    <p>{{ allData.snapchat }}</p>
                  </div>
                  <!--Desc-->
                </li>
                <li>
                  <!--Icon-->
                  <div class="icon">
                    <i class="fab fa-instagram"></i>
                    <span>انستجرام</span>
                  </div>
                  <!--Icon-->
                  <!--Desc-->
                  <div class="desc">
                    <p>{{ allData.instagram }}</p>
                  </div>
                  <!--Desc-->
                </li>
                <li>
                  <!--Icon-->
                  <div class="icon">
                    <i class="fab fa-twitter"></i>
                    <span>تويتر</span>
                  </div>
                  <!--Icon-->
                  <!--Desc-->
                  <div class="desc">
                    <p>{{ allData.twitter }}</p>
                  </div>
                  <!--Desc-->
                </li>
                <li>
                  <!--Icon-->
                  <div class="icon">
                    <i class="fab fa-facebook-f"></i>
                    <span>فيسبوك</span>
                  </div>
                  <!--Icon-->
                  <!--Desc-->
                  <div class="desc">
                    <p>{{ allData.facebook.substring(0, 50) }}</p>
                  </div>
                  <!--Desc-->
                </li>
              </ul>
            </div>
            <!--Contact Info-->
          </div>
        </div>
      </div>
      <!--All Text-->
    </div>
    <!--Contact Us-->
  </div>
</template>

<script>
export default {
  data() {
    return {
      allData: null,
      loading: false,
    };
  },
  methods: {
    //Get Data
    getData() {
      this.loading = true;
      this.$axios({
        method: "GET",
        url: "home",
      }).then((res) => {
        this.loading = false;
        this.allData = res.data.data;
      });
    },
    //Get Data
  },
  created() {
    this.getData();
  },
};
</script>

<style></style>
