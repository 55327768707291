<template>
  <!--Start job by City-->
  <section class="jobs_by_city Pad_50" v-if="item">
    <div class="container">
      <div class="row">
        <div class="col-sm-12">
          <!--Top Title-->
          <div class="side_title">
            <h3>{{ $t("categories.jobsByCity") }}</h3>
          </div>
          <!--Top Title-->
        </div>
        <template v-for="city in cities">
          <div class="col-md-3" :key="city.id" v-if="city.is_active == 1">
            <!--Job City-->
            <div class="job_city">
              <!--Image-->
              <div class="image">
                <img @error="replaceImg" :src="city.image" />
                <!--City Name-->
                <router-link :to="`cityJobs/${city.id}`">
                  <div class="city_name">
                    <h4>{{ city.name }}</h4>
                  </div>
                </router-link>
                <!--City Name-->
              </div>
              <!--Image-->
            </div>
            <!--Job City-->
          </div>
        </template>
        <template v-for="city in cities">
          <div class="col-md-3" :key="city.id" v-if="city.is_active == 0">
            <!--Job City-->
            <div class="job_city">
              <!--City List-->
              <ul class="city_list">
                <li>
                  <router-link :to="`cityJobs/${city.id}`">{{
                    city.name
                  }}</router-link>
                </li>
              </ul>
              <!--City List-->
            </div>
            <!--Job City-->
          </div>
        </template>
        <!--         <div class="col-md-3">
          <!~~Job City~~>
          <div class="job_city">
            <!~~Image~~>
            <div class="image">
              <img src="@/assets/images/jobs_by_city/dmam.png" />
              <!~~City Name~~>
              <router-link to="">
                <div class="city_name">
                  <h4>الدمام</h4>
                </div>
              </router-link>
              <!~~City Name~~>
            </div>
            <!~~Image~~>
            <!~~City List~~>
            <ul class="city_list">
              <li><a href="">الخفجي</a></li>
              <li><a href="">الدوادمي</a></li>
              <li><a href="">بريدة</a></li>
              <li>
                <a href="">عنيزة</a>
              </li>
            </ul>
            <!~~City List~~>
          </div>
          <!~~Job City~~>
        </div>
        <div class="col-md-3">
          <!~~Job City~~>
          <div class="job_city">
            <!~~Image~~>
            <div class="image">
              <img src="@/assets/images/jobs_by_city/jeddah.png" />
              <!~~City Name~~>
              <router-link to="">
                <div class="city_name">
                  <h4>جدة</h4>
                </div>
              </router-link>
              <!~~City Name~~>
            </div>
            <!~~Image~~>
            <!~~City List~~>
            <ul class="city_list">
              <li><a href="">ابها</a></li>
              <li><a href="">حائل</a></li>
              <li><a href="">تبوك</a></li>
              <li>
                <a href="">الطائف</a>
              </li>
            </ul>
            <!~~City List~~>
          </div>
          <!~~Job City~~>
        </div>
        <div class="col-md-3">
          <!~~Job City~~>
          <div class="job_city">
            <!~~Image~~>
            <div class="image">
              <img src="@/assets/images/jobs_by_city/mecca.png" />
              <!~~City Name~~>
              <router-link to="">
                <div class="city_name">
                  <h4>مكة المكرمة</h4>
                </div>
              </router-link>
              <!~~City Name~~>
            </div>
            <!~~Image~~>
            <!~~City List~~>
            <ul class="city_list">
              <li><a href="">المدينة المنورة</a></li>
              <li><a href="">الاحساء</a></li>
              <li><a href="">القصيم</a></li>
              <li>
                <a href="">الطائف</a>
              </li>
            </ul>
            <!~~City List~~>
          </div>
          <!~~Job City~~>
        </div> -->
      </div>
    </div>
  </section>
  <!--End job by City-->
</template>

<script>
import Terms from "../../views/children/StaticPages/Terms.vue";
import imgFalse from "../../assets/images/jobs_by_city/mecca.png";
export default {
  components: { Terms },
  props: ["item"],
  data() {
    return {
      cities: null,
    };
  },
  methods: {
    //====Cities=======
    getCities() {
      this.$axios({
        method: "GET",
        url: "cities",
      }).then((res) => {
        this.cities = res.data.data;
      });
    },
    replaceImg(e) {
      e.target.src = imgFalse;
    },
  },
  mounted() {
    this.getCities();
  },
};
</script>

<style></style>
