<template>
  <loader v-if="loading"></loader>
  <div class="job_details_page" v-else>
    <!--Elan-->
    <!-- <div class="top_elan">
      <img src="@/assets/images/icons/horzn_elan.png" />
    </div> -->
    <!--Elan-->
    <div class="container" v-if="jobDetails">
      <div class="row">
        <div class="col-md-9">
          <job-card-details
            @getData="getData"
            :job="jobDetails"
          ></job-card-details>
        </div>
        <div class="col-md-3">
          <!--Elan-->
          <!-- <div class="elan">
            <img src="@/assets/images/icons/elan.png" />
          </div> -->
          <!--Elan-->
        </div>
        <div class="col-sm-12">
          <!--Similar Jobs-->
          <div class="similar_jobs">
            <div class="side_title">
              <h3>{{ $t("similarJobs.title") }}</h3>
            </div>
            <!--Job Card-->
            <not-found v-if="jobs.length == 0"></not-found>
            <div class="row" v-else>
              <div class="col-md-4" v-for="job in jobs" :key="job.id">
                <job-card
                  :route="`/jobDetails/${job.id}`"
                  :job="job"
                ></job-card>
              </div>
            </div>
            <!--Job Card-->
          </div>
          <!--Similar Jobs-->
        </div>
      </div>
    </div>
    <!--Packages-->
    <!-- <featured-package></featured-package> -->
    <!--Packages-->
    <!--EmployeeRequests-->
    <!-- <employee-requests></employee-requests> -->
    <!--EmployeeRequests-->
    <!--Newsleter-->
    <newsleter></newsleter>
    <!--Newsleter-->
  </div>
</template>

<script>
import FeaturedPackage from "../../../components/HomePage/FeaturedPackage.vue";
import JobCard from "../../../components/JobCard/JobCard.vue";
import JobCardDetails from "../../../components/JobCard/JobCardDetails.vue";
import EmployeeRequests from "../../../components/HomePage/EmployeeRequests.vue";
import Newsleter from "../../../components/HomePage/Newsleter.vue";
export default {
  props: ["id"],
  components: {
    JobCardDetails,
    JobCard,
    FeaturedPackage,
    EmployeeRequests,
    Newsleter,
  },
  data() {
    return {
      loading: false,
      //START:: JOBS
      jobs: [
        {
          id: Math.random()
            .toString(36)
            .replace(/[^a-z]+/g, "")
            .substr(0, 5),
          title: "مهندس الات",
          company: "ارامكو السعودية",
          address: "الرياض",
          featured: false,
          desc: "لوريم ايبسوم هو نموذج افتراضي يوضع في التصاميم لتعرض علىالعميل ليتصور طريقه وضع النصوص بالتصاميم ...",
        },
        {
          id: Math.random()
            .toString(36)
            .replace(/[^a-z]+/g, "")
            .substr(0, 5),
          title: "مهندس الات",
          company: "ارامكو السعودية",
          address: "الرياض",
          featured: false,
          desc: "لوريم ايبسوم هو نموذج افتراضي يوضع في التصاميم لتعرض علىالعميل ليتصور طريقه وضع النصوص بالتصاميم ...",
        },
        {
          id: Math.random()
            .toString(36)
            .replace(/[^a-z]+/g, "")
            .substr(0, 5),
          title: "مهندس الات",
          company: "ارامكو السعودية",
          address: "الرياض",
          featured: false,
          desc: "لوريم ايبسوم هو نموذج افتراضي يوضع في التصاميم لتعرض علىالعميل ليتصور طريقه وضع النصوص بالتصاميم ...",
        },
      ],
      //END:: JOBS

      //START:: JOB DETAILS
      jobDetails: null,
      //END:: JOB DETAILS
    };
  },
  methods: {
    getData() {
      this.loading = true;
      this.jobs = [];
      //======Job Details========
      if (this.userType == "company") {
        this.$axios({
          method: "GET",
          url: "company/work/" + this.id,
          headers: {
            Authorization: "bearer" + localStorage.getItem("SAS_App_Token"),
          },
        }).then((res) => {
          this.loading = false;
          this.jobDetails = res.data.data;
        });
      } else {
        this.$axios({
          method: "GET",
          url: "work/Details?work_id=" + this.id,
          headers: {
            Authorization: "bearer" + localStorage.getItem("SAS_App_Token"),
          },
        }).then((res) => {
          this.jobDetails = res.data.data;
          this.loading = false;
        });
      }

      this.$axios({
        method: "GET",
        url: "other_works",
        params: { work_id: this.id },
        headers: {
          Authorization: "bearer" + localStorage.getItem("SAS_App_Token"),
        },
      }).then((res) => {
        this.jobs = res.data.data;
        this.loading = false;
      });
    },
  },
  computed: {
    userType() {
      return this.$store.getters["auth_module/usertype"];
    },
    lang() {
      return this.$store.getters["lang_module/lang"];
    },
  },
  created() {
    this.getData();
  },
};
</script>

<style></style>
