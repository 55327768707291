<template>
  <div class="register_page">
    <!--Top Background-->
    <div class="top_background">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="title">
              <h4>
                {{ $t("employee.title") }}
                <span class="free">: {{ employes.name }}</span>
              </h4>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--Top Background-->

    <!--Categories-->
    <div class="job_applies_page" v-if="employes">
      <div class="container">
        <div class="row">
          <div class="col-sm-12">
            <!--Start Applies Title-->
            <div class="applies_title">
              <!--Title-->
              <h4>
                {{ $t("employee.appliesNum") }} : {{ employes.users_count }}
              </h4>
              <!--Filter Select-->
              <div class="filter_select input_wrapper select">
                <select class="form-control" v-model="show" @change="getData">
                  <option value="all">{{ $t("employee.filter.all") }}</option>
                  <option value="fav">
                    {{ $t("employee.filter.favourite") }}
                  </option>
                </select>
              </div>
            </div>
            <!--End Applies Title-->
          </div>
          <template v-if="employes.users">
            <not-found v-if="employes.users.length == 0"></not-found>
          </template>
          <template v-if="show == 'all'">
            <div
              class="col-lg-3"
              v-for="employee in employes.users"
              :key="employee.id"
            >
              <EmployesCard
                :employee="employee"
                :isLiked="employee.IsLike"
              ></EmployesCard>
            </div>
          </template>
          <template v-if="show == 'fav'">
            <div
              class="col-lg-3"
              v-for="employee in employes.users.filter((x) => x.IsLike)"
              :key="employee.id"
            >
              <EmployesCard
                :employee="employee"
                :isLiked="employee.IsLike"
              ></EmployesCard>
            </div>
          </template>
        </div>
      </div>
    </div>
    <!--Categories--->
  </div>
</template>

<script>
import EmployesCard from "@/components/Employes/Employes.vue";
export default {
  props: ["id"],
  components: {
    EmployesCard,
  },
  data() {
    return {
      //START:: JOBS
      employes: [
        {
          id: Math.random()
            .toString(36)
            .replace(/[^a-z]+/g, "")
            .substr(0, 5),
          name: "عبد الرحمن محمد",
          phone: "0254566665",
          accounts: "محاسبة التكاليف",
          experience: "5",
        },
        {
          id: Math.random()
            .toString(36)
            .replace(/[^a-z]+/g, "")
            .substr(0, 5),
          name: "عبد الرحمن محمد",
          phone: "0254566665",
          accounts: "محاسبة التكاليف",
          experience: "5",
        },
        {
          id: Math.random()
            .toString(36)
            .replace(/[^a-z]+/g, "")
            .substr(0, 5),
          name: "عبد الرحمن محمد",
          phone: "0254566665",
          accounts: "محاسبة التكاليف",
          experience: "5",
        },
        {
          id: Math.random()
            .toString(36)
            .replace(/[^a-z]+/g, "")
            .substr(0, 5),
          name: "عبد الرحمن محمد",
          phone: "0254566665",
          accounts: "محاسبة التكاليف",
          experience: "5",
        },
        {
          id: Math.random()
            .toString(36)
            .replace(/[^a-z]+/g, "")
            .substr(0, 5),
          name: "عبد الرحمن محمد",
          phone: "0254566665",
          accounts: "محاسبة التكاليف",
          experience: "5",
        },
        {
          id: Math.random()
            .toString(36)
            .replace(/[^a-z]+/g, "")
            .substr(0, 5),
          name: "عبد الرحمن محمد",
          phone: "0254566665",
          accounts: "محاسبة التكاليف",
          experience: "5",
        },
        {
          id: Math.random()
            .toString(36)
            .replace(/[^a-z]+/g, "")
            .substr(0, 5),
          name: "عبد الرحمن محمد",
          phone: "0254566665",
          accounts: "محاسبة التكاليف",
          experience: "5",
        },
        {
          id: Math.random()
            .toString(36)
            .replace(/[^a-z]+/g, "")
            .substr(0, 5),
          name: "عبد الرحمن محمد",
          phone: "0254566665",
          accounts: "محاسبة التكاليف",
          experience: "5",
        },
        {
          id: Math.random()
            .toString(36)
            .replace(/[^a-z]+/g, "")
            .substr(0, 5),
          name: "عبد الرحمن محمد",
          phone: "0254566665",
          accounts: "محاسبة التكاليف",
          experience: "5",
        },
        {
          id: Math.random()
            .toString(36)
            .replace(/[^a-z]+/g, "")
            .substr(0, 5),
          name: "عبد الرحمن محمد",
          phone: "0254566665",
          accounts: "محاسبة التكاليف",
          experience: "5",
        },
        {
          id: Math.random()
            .toString(36)
            .replace(/[^a-z]+/g, "")
            .substr(0, 5),
          name: "عبد الرحمن محمد",
          phone: "0254566665",
          accounts: "محاسبة التكاليف",
          experience: "5",
        },
        {
          id: Math.random()
            .toString(36)
            .replace(/[^a-z]+/g, "")
            .substr(0, 5),
          name: "عبد الرحمن محمد",
          phone: "0254566665",
          accounts: "محاسبة التكاليف",
          experience: "5",
        },
      ],
      //END:: JOBS
      //Filter
      show: "all",
      //Filter
      //Loading
      loading: false,
    };
  },
  methods: {
    //Get Data
    getData() {
      (this.employes = []), (this.loading = true);
      this.$axios({
        method: "GET",
        url: "/company/work/details",
        params: { id: this.id },
      }).then((res) => {
        this.loading = false;
        this.employes = res.data.data;
      });
    },
  },
  created() {
    this.getData();
  },
};
</script>

<style></style>
