<template>
  <section class="employee_requests">
    <div class="container">
      <div class="row">
        <div class="col-md-3 offset-md-1">
          <!--Image-->
          <div class="image">
            <img src="@/assets/images/employee_reqursts/emp_req.png" />
          </div>
          <!--Image-->
        </div>
        <div class="col-md-7">
          <!--Text-->
          <div class="text">
            <h4>خدمة طلب الموظفين من ساس الذكية للتوظيف</h4>
            <p>خليها كلها علينا</p>
            <ul>
              <li><i class="fal fa-check"></i>نفلتر السير الذاتية</li>
              <li><i class="fal fa-check"></i>نقابل ونقيم المرشحين</li>
              <li><i class="fal fa-check"></i>نضمنلك افضل الكفاءات</li>
            </ul>
            <router-link to="/addEmployes" class="main_button"
              ><span>اطلب موظفين</span></router-link
            >
          </div>
          <!--Text-->
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style></style>
