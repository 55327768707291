<template>
  <div class="register_page">
    <!--Top Background-->
    <div class="top_background">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="title">
              <h4>{{ $t("profile.personalPage") }}</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--Top Background-->

    <!--Profile Data-->
    <div class="container">
      <div class="row">
        <div class="col-md-9">
          <!--  Profile Card-->
          <loader v-if="loading"></loader>
          <div class="profile_card" v-else>
            <Tabs :tabsContent="tabsContent">
              <template v-slot:personal_data>
                <!--Personal Data-->
                <div class="personal_data">
                  <div class="row">
                    <div class="col-lg-6">
                      <!--Personal Content-->
                      <div class="personal_content">
                        <!--Data Info-->
                        <div class="data_info">
                          <h4>{{ $t("profile.name") }}</h4>
                          <span>{{ myData.fullname }}</span>
                        </div>
                        <!--Data Info-->

                        <!--Data Info-->
                        <div class="data_info">
                          <h4>{{ $t("profile.email") }}</h4>
                          <span>{{ myData.email }}</span>
                        </div>
                        <!--Data Info-->

                        <!--Data Info-->
                        <!-- <div class="data_info">
                          <h4>الشخص المفوض</h4>
                          <span>عبد الرحمن محمد</span>
                        </div> -->
                        <!--Data Info-->
                      </div>
                      <!--Personal Content-->
                    </div>
                    <div class="col-lg-6">
                      <!--Personal Content-->
                      <div class="personal_content">
                        <!--Data Info-->
                        <div class="data_info">
                          <h4>{{ $t("profile.region") }}</h4>
                          <span v-if="myData.city">{{ myData.city.name }}</span>
                        </div>
                        <!--Data Info-->

                        <!--Data Info-->
                        <div class="data_info">
                          <h4>{{ $t("profile.phone") }}</h4>
                          <span>{{ myData.phone }}</span>
                        </div>
                        <!--Data Info-->
                      </div>
                      <!--Personal Content-->
                    </div>
                    <div class="col-lg-12">
                      <router-link to="/editJobOwner" class="main_button mb-3"
                        ><span>{{ $t("profile.editData") }}</span></router-link
                      >
                    </div>
                  </div>
                </div>
                <!--Personal Data-->
              </template>

              <template v-slot:jobs>
                <!--Applied Jobs-->
                <div class="applied_jobs">
                  <!--Job Applied Card-->
                  <div
                    class="job_applied_card"
                    v-for="job in myJobs"
                    :key="job.id"
                  >
                    <!--Details-->
                    <router-link :to="'/jobDetails/' + job.id">
                      <div class="details">
                        <!--Title-->
                        <div class="title">
                          <h4>{{ job.name }}</h4>
                        </div>
                        <!--Title-->

                        <!--City-->
                        <div class="city">
                          <h4 v-if="job.city">{{ job.city.name }}</h4>
                          <span>{{ job.created_at }}</span>
                        </div>
                        <!--City-->

                        <!--Desc-->
                        <div class="desc">
                          <p>
                            {{ job.desc }}
                          </p>
                        </div>
                        <!--Desc-->
                      </div>
                    </router-link>
                    <!--Details-->

                    <!--Image-->
                    <div class="image">
                      <img :src="job.company.image" />
                      <h4>{{ job.company.fullname }}</h4>
                    </div>
                    <!--Image-->
                  </div>
                  <!--Job Applied Card-->
                </div>
                <!--Applied Jobs-->
              </template>
            </Tabs>
          </div>
          <!--Profile Card-->
        </div>
        <!-- <div class="col-md-3">
          <div class="elan">
            <img src="@/assets/images/icons/elan.png" />
          </div>
        </div> -->
      </div>
    </div>
    <!--Profile Data--->

    <!--Newsleter-->
    <newsleter></newsleter>
    <!--Newsleter-->
  </div>
</template>

<script>
import Newsleter from "@/components/HomePage/Newsleter.vue";
import Tabs from "@/components/Global/Tabs.vue";
export default {
  components: { Newsleter, Tabs },
  data() {
    return {
      //My Data
      myData: null,
      //My Data

      //My Jobs
      myJobs: null,
      //My Jobs

      //Loading
      loading: false,
      //Loading

      // START:: TABS DATA
      tabsContent: [
        {
          tab: this.$t("profile.profileData"),
          key_name: "personal_data",
        },
        {
          tab: this.$t("profile.myAds"),
          key_name: "jobs",
        },
      ],
      // END:: TABS DATA
    };
  },
  methods: {
    getData() {
      this.loading = true;
      //Get Profile
      this.$axios({
        method: "GET",
        url: "profile",
        headers: {
          Authorization: "bearer" + localStorage.getItem("SAS_App_Token"),
        },
      }).then((res) => {
        this.loading = false;
        this.myData = res.data.data;
      });
      //Get Profile

      //Get Jobs
      this.$axios({
        method: "GET",
        url: "company/work",
        headers: {
          Authorization: "bearer" + localStorage.getItem("SAS_App_Token"),
        },
      }).then((res) => {
        this.myJobs = res.data.data;
      });
      //Get Jobs
    },
  },
  created() {
    this.getData();
  },
};
</script>

<style></style>
