<template>
  <div class="register_page">
    <!--Top Background-->
    <div class="top_background">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="title">
              <!-- <h4>{{ $t("search.title") }}</h4> -->
              <h4>{{ $t("search.allJobs") }}</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--Top Background-->

    <!--Categories-->
    <div class="search_page">
      <div class="container">
        <loader v-if="loading"></loader>
        <div class="row" v-else>
          <not-found v-if="jobs.length == 0"></not-found>
          <div class="col-lg-12" v-else>
            <job-card
              v-for="job in jobs"
              :key="job.id"
              :job="job"
              :route="`/jobDetails/${job.id}`"
            ></job-card>
          </div>
        </div>
      </div>
      <strong-cv></strong-cv>
      <newsleter></newsleter>
    </div>
    <!--Categories--->
  </div>
</template>

<script>
import Newsleter from "@/components/HomePage/Newsleter.vue";
import StrongCv from "../../../components/HomePage/StrongCv.vue";
import JobCard from "@/components/JobCard/JobCard2.vue";
export default {
  props: ["id"],
  components: {
    Newsleter,
    StrongCv,
    JobCard,
  },
  data() {
    return {
      //START:: Filter Data
      filterData: {
        category: null,
        city: null,
        date: null,
      },
      //END:: Filter Data

      //START:: CATEGORIES
      categories: [],
      //END:: CATEGORIES

      //START:: CITIES
      cities: [],
      //END:: CITIES

      //START:: JOBS
      jobs: [],
      //END:: JOBS

      //START:: LOADING
      loading: false,
      //END:: LOADING
    };
  },
  methods: {
    //=====Get Jobs
    getJobs() {
      this.loading = true;
      this.$axios({
        method: "GET",
        url: "works",
        params: {
          city_id: this.id,
        },
      }).then((res) => {
        this.jobs = res.data.data;
        this.loading = false;
      });
    },
  },
  created() {
    this.getJobs();
  },
};
</script>

<style></style>
