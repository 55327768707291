export default {
    rateStep(state) {
        return state.rateStep;
    },
    rateId(state) {
        return state.rateId;
    },
    details(state) {
        return state.details;
    },
    exp(state) {
        return state.exp;
    },
    edu(state) {
        return state.edu;
    }
};