<template>
  <!--Start Job Owner Package-->
  <section class="job_owner_package Pad_50">
    <div class="container">
      <div class="row">
        <div class="col-md-5">
          <!--Text Part-->
          <div class="text">
            <!--Title-->
            <h4>{{ $t("packages.jobOwnerPackage") }}</h4>
            <!--Title-->
            <!--Description-->
            <p>
              {{ $t("packages.jobOwner.Desc") }}
            </p>
            <!--Description-->
            <!--Link-->
            <div class="link_border">
              <router-link to="/packages" class="main_button3"
                ><span>{{ $t("packages.joinPackage") }}</span></router-link
              >
            </div>
            <!--Link-->
          </div>
          <!--Text Part-->
        </div>
      </div>
    </div>
  </section>
  <!--End Job Owner Package-->
</template>

<script>
export default {};
</script>

<style></style>
