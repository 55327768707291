<template>
  <div>
    <!-- Footer -->
    <footer class="footer Pad_50" v-if="allData">
      <div class="container">
        <div class="row">
          <div class="col-md-3">
            <!--Logo-->
            <div class="logo">
              <img src="@/assets/images/icons/foot_logo.png" />
            </div>
            <!--Logo-->
          </div>
          <div class="col-md-5">
            <!--About Us-->
            <div class="about_us">
              <h4>{{ $t("footer.moreAbout") }}</h4>
              <p v-html="allData.main_title"></p>
              <ul class="social_media">
                <li class="snap">
                  <a :href="allData.snapchat" target="_blank"
                    ><i class="fab fa-snapchat-ghost"></i
                  ></a>
                </li>
                <li class="linkedin">
                  <a :href="allData.linked_in" target="_blank"
                    ><i class="fab fa-linkedin"></i
                  ></a>
                </li>
                <li class="twitter">
                  <a :href="allData.twitter" target="_blank"
                    ><i class="fab fa-twitter"></i
                  ></a>
                </li>
                <li class="facebook">
                  <a :href="allData.facebook" target="_blank"
                    ><i class="fab fa-facebook-f"></i
                  ></a>
                </li>
                <li class="twitter">
                  <a href="https://t.me/Recruitment_sas" target="_blank"
                    ><i class="fab fa-telegram-plane"></i
                  ></a>
                </li>
                <li class="instagram">
                  <a :href="allData.instagram" target="_blank"
                    ><i class="fab fa-instagram"></i
                  ></a>
                </li>
                <li class="youtube">
                  <a :href="allData.youtube" target="_blank"
                    ><i class="fab fa-youtube"></i
                  ></a>
                </li>
              </ul>
            </div>
            <!--About Us-->
          </div>
          <div class="col-md-4">
            <!--Important Links-->
            <div class="important_links">
              <!--Title-->
              <h4>{{ $t("footer.importantLinks") }}</h4>
              <!--Title-->
              <!--All Links-->
              <div class="all_links">
                <!--links List-->
                <ul class="links_list">
                  <li>
                    <router-link to="/jobs">{{
                      $t("navbar.exploreJobs")
                    }}</router-link>
                  </li>
                  <li>
                    <router-link to="/about">{{
                      $t("navbar.about")
                    }}</router-link>
                  </li>
                  <li>
                    <router-link to="/jobSearch">{{
                      $t("navbar.jobSearch")
                    }}</router-link>
                  </li>
                  <li>
                    <router-link to="/jobOwner">{{
                      $t("navbar.jobOwner")
                    }}</router-link>
                  </li>
                  <li>
                    <router-link to="/rateCv">{{
                      $t("navbar.rateCv")
                    }}</router-link>
                  </li>
                </ul>
                <!--links List-->
                <!--links List-->
                <ul class="links_list">
                  <li>
                    <router-link to="/cvSteps">{{
                      $t("navbar.createCv")
                    }}</router-link>
                  </li>
                  <li>
                    <router-link to="/packages">{{
                      $t("navbar.packages")
                    }}</router-link>
                  </li>
                  <li>
                    <router-link to="/terms">{{
                      $t("navbar.policy")
                    }}</router-link>
                  </li>
                  <li>
                    <router-link to="/addJob">{{
                      $t("navbar.addAd")
                    }}</router-link>
                  </li>
                  <li>
                    <router-link to="/featuredCv">{{
                      $t("navbar.featCv")
                    }}</router-link>
                  </li>
                </ul>
                <!--links List-->
              </div>
              <!--All Links-->
            </div>
            <!--Important Links-->
          </div>
        </div>
      </div>
    </footer>
    <!-- Footer -->
    <!-- Start Copyright -->
    <section class="copyright">
      <div class="container">
        <div class="row">
          <div class="col-sm-12">
            <!--Text-->
            <div class="text">
              <p>
                {{ $t("footer.copy1")
                }}<a href="http://www.sas-it.net/" target="_blank">{{
                  $t("footer.copy2")
                }}</a>
                &copy;2022
              </p>
            </div>
            <!--Text-->
          </div>
        </div>
      </div>
    </section>
    <!-- End Copyright -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      //All Data
      allData: null,
      //All Data
    };
  },
  methods: {
    //Get Data
    getData() {
      this.$axios({
        method: "GET",
        url: "home",
      }).then((res) => {
        this.allData = res.data.data;
      });
    },
    //Get Data
  },
  created() {
    this.getData();
  },
};
</script>

<style lang="scss" scoped></style>
