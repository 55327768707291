<template>
  <div class="register_page">
    <!--Top Background-->
    <div class="top_background">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="title">
              <h4>
                {{ $t("register.title") }}
                <span v-if="step == 2 && userType == 'user'"
                  >- {{ $t("navbar.jobSearch") }}</span
                ><span v-if="step == 2 && userType == 'company'"
                  >- {{ $t("navbar.jobOwner") }}</span
                >
              </h4>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--Top Background-->
    <!--Register Wizard Form-->
    <div class="container">
      <div class="register_wizard_form">
        <div class="wizard_title">
          <h3>{{ $t("register.wizard_title") }}</h3>
        </div>
        <!--Progress Bar-->
        <div class="progress_bar">
          <ul>
            <li :class="{ active: step == 1 }">
              <h4>01 {{ $t("register.mainData") }}</h4>
              <p>
                <span></span>
              </p>
            </li>
            <li :class="{ active: step == 2 }">
              <h4>02 {{ $t("register.personalData") }}</h4>
              <p>
                <span></span>
              </p>
            </li>
          </ul>
        </div>
        <!--Progress Bar-->
        <!--Main Data-->
        <template v-if="step == 1">
          <!--Account Type-->
          <ul class="account_type">
            <!-- <li>{{ $t("register.accountType.type") }}</li> -->
            <li class="type_box">
              <input
                type="radio"
                v-model="userType"
                value="company"
                id="1A"
                name="d"
              />
              <label for="1A"
                ><span class="icon"><i class="fas fa-suitcase"></i></span
                >{{ $t("register.accountType.workOwner") }}</label
              >
            </li>
            <li class="type_box">
              <input
                type="radio"
                v-model="userType"
                value="user"
                id="1B"
                name="d"
              />
              <label for="1B"
                ><span class="icon"><i class="fas fa-search"></i></span
                >{{ $t("register.accountType.jobSearch") }}</label
              >
            </li>
          </ul>
          <!--Account Type-->
        </template>
        <!--Form Inputs-->
        <div class="form_inputs">
          <form>
            <div class="row">
              <template v-if="step == 1">
                <div class="col-md-6">
                  <!--Input Wrapper-->
                  <div class="input_wrapper reg_style">
                    <!--First Name-->
                    <input
                      type="text"
                      class="form-control"
                      v-model="data.firstName"
                      :placeholder="$t('register.data.firstName')"
                      @keypress="isLetter($event)"
                    />
                    <!--First Name-->
                  </div>
                  <!--Input Wrapper-->
                </div>

                <div class="col-md-6">
                  <!--Input Wrapper-->
                  <div class="input_wrapper reg_style">
                    <!--Last Name-->
                    <input
                      type="text"
                      class="form-control"
                      v-model="data.lastName"
                      :placeholder="$t('register.data.lastName')"
                      @keypress="isLetter($event)"
                    />
                    <!--Last Name-->
                  </div>
                  <!--Input Wrapper-->
                </div>

                <div class="col-md-6">
                  <!--Wrapper Flex-->
                  <div class="wrapper_flex">
                    <!--Input Wrapper-->
                    <div class="input_wrapper reg_style code">
                      <!--Code-->
                      <span>+966</span>
                      <!--Code-->
                    </div>
                    <!--Input Wrapper-->

                    <!--Input Wrapper-->
                    <div class="input_wrapper reg_style phone">
                      <!--Phone-->
                      <input
                        type="number"
                        class="form-control"
                        id="inputBox"
                        @keydown="blockChar"
                        v-model="data.phone"
                        :placeholder="$t('register.data.phone')"
                        pattern="/^-?\d+\.?\d*$/"
                        onKeyPress="if(this.value.length == 9) {
                        return false;
                      }"
                        min="0"
                      />
                      <!--Phone-->
                    </div>
                    <!--Input Wrapper-->
                  </div>
                  <!--Wrapper Flex-->
                </div>

                <div class="col-md-6">
                  <!--Input Wrapper-->
                  <div class="input_wrapper reg_style select">
                    <!--Select City-->
                    <select class="form-control" v-model="data.city">
                      <option disabled value="">
                        {{ $t("register.data.city") }}
                      </option>
                      <option
                        v-for="city in cities"
                        :key="city.id"
                        :value="city.id"
                      >
                        {{ city.name }}
                      </option>
                    </select>
                    <!--Select City-->
                  </div>
                  <!--Input Wrapper-->
                </div>

                <div class="col-md-6">
                  <!--Input Wrapper-->
                  <div class="input_wrapper reg_style select">
                    <!--Select Type-->
                    <select class="form-control" v-model="data.type">
                      <option disabled value="">
                        {{ $t("register.data.type") }}
                      </option>
                      <option
                        v-for="type in Types"
                        :key="type.id"
                        :value="type.value"
                      >
                        {{ type.name }}
                      </option>
                    </select>
                    <!--Select Type-->
                  </div>
                  <!--Input Wrapper-->
                </div>

                <div class="col-md-6">
                  <!--Input Wrapper-->
                  <div class="input_wrapper reg_style">
                    <!--Birth Date-->
                    <Datepicker
                      class="form-control"
                      :format="formatDate"
                      v-model="data.age"
                      :placeholder="$t('settings.data.birthDate')"
                    ></Datepicker>
                    <!--Birth Date-->
                  </div>
                  <!--Input Wrapper-->
                </div>

                <div class="col-md-12">
                  <!--Input Wrapper-->
                  <div class="input_wrapper reg_style">
                    <!--Email-->
                    <input
                      type="email"
                      class="form-control"
                      v-model="data.email"
                      :placeholder="$t('register.data.email')"
                    />
                    <!--Email-->
                  </div>
                  <!--Input Wrapper-->
                </div>

                <div class="col-md-6">
                  <!--Input Wrapper-->
                  <div class="input_wrapper reg_style">
                    <!--Password-->
                    <input
                      type="password"
                      class="form-control"
                      v-model="data.password"
                      :placeholder="$t('register.data.password')"
                    />
                    <!--Password-->
                  </div>
                  <!--Input Wrapper-->
                </div>

                <div class="col-md-6">
                  <!--Input Wrapper-->
                  <div class="input_wrapper reg_style">
                    <!--Password-->
                    <input
                      type="password"
                      class="form-control"
                      v-model="data.passwordConfirmation"
                      :placeholder="$t('register.data.passwordConfirmation')"
                    />
                    <!--Password-->
                  </div>
                  <!--Input Wrapper-->
                </div>
              </template>
              <!--Main Data-->

              <!--Job Searcher Data-->
              <template v-if="step == 2 && userType == 'user'">
                <div class="col-md-12">
                  <!--User Welcome-->
                  <div class="user_welcome">
                    <h4>
                      {{ $t("welcome") }} <span>{{ data.firstName }}</span>
                    </h4>
                  </div>
                  <!--User Welcome-->
                </div>
                <div class="col-md-6">
                  <!--Input Wrapper-->
                  <div class="input_wrapper reg_style select">
                    <!--Select Specialists-->
                    <select
                      class="form-control"
                      v-model="data.specialist"
                      @change="getSub(), (data.subSpecialist = '')"
                    >
                      <option disabled value="">
                        {{ $t("register.data.specialist") }}
                      </option>
                      <option
                        v-for="spe in specialists"
                        :key="spe.id"
                        :value="spe.id"
                      >
                        {{ spe.name }}
                      </option>
                    </select>
                    <!--Select Specialists-->
                  </div>
                  <!--Input Wrapper-->
                </div>

                <div class="col-md-6">
                  <!--Input Wrapper-->
                  <div class="input_wrapper reg_style select">
                    <!--Select Sub Specialists-->
                    <select class="form-control" v-model="data.subSpecialist">
                      <option disabled value="">
                        {{ $t("register.data.subSpecialist") }}
                      </option>
                      <option
                        v-for="sub in subSpecialists"
                        :key="sub.id"
                        :value="sub.id"
                      >
                        {{ sub.name }}
                      </option>
                    </select>
                    <!--Select Sub Specialists-->
                  </div>
                  <!--Input Wrapper-->
                </div>

                <div class="col-md-6">
                  <!--Input Wrapper-->
                  <div class="input_wrapper reg_style select">
                    <!--Select Region-->
                    <select class="form-control" v-model="data.educationLevel">
                      <option disabled value="">
                        {{ $t("register.data.education_level") }}
                      </option>
                      <option
                        v-for="edu in educationLevels"
                        :key="edu.id"
                        :value="edu.id"
                      >
                        {{ edu.name }}
                      </option>
                    </select>
                    <!--Select Region-->
                  </div>
                  <!--Input Wrapper-->
                </div>

                <div class="col-md-6">
                  <!--Input Wrapper-->
                  <div class="input_wrapper reg_style">
                    <!-- Experience-->
                    <input
                      type="text"
                      class="form-control"
                      v-model="data.experience"
                      :placeholder="$t('register.data.experience')"
                      pattern="/^-?\d+\.?\d*$/"
                      onKeyPress="if(this.value.length == 2) {
                        return false;
                      }"
                      min="0"
                      oninput="
                        this.value =
                          !!this.value && Math.abs(this.value) >= 0
                            ? Math.abs(this.value)
                            : null
                      "
                    />
                    <!-- Experience-->
                  </div>
                  <!--Input Wrapper-->
                </div>

                <div class="col-md-6">
                  <!--Input Wrapper-->
                  <div class="input_wrapper reg_style file_upload">
                    <!--Upload Cv-->
                    <div class="form-control">
                      <span v-if="data.beforeUpload">
                        {{ $t("register.data.uploadCv") }}</span
                      >
                      <span v-if="data.afterUpload">{{
                        data.uploadCv.name
                      }}</span>
                      <span class="icon"><i class="fal fa-file"></i></span>
                    </div>
                    <input type="file" name="chooseFile" @change="uploadFile" />
                    <!--Upload Cv-->
                  </div>
                  <!--Input Wrapper-->
                </div>
                <div class="col-md-6">
                  <!--Input Wrapper-->
                  <div class="input_wrapper reg_style file_upload">
                    <span>{{ $t("validation.cvUpload") }}</span>
                  </div>
                  <!--Input Wrapper-->
                </div>

                <div class="col-md-12">
                  <!--Input Wrapper-->
                  <div class="input_wrapper reg_style">
                    <!--About Me-->
                    <textarea
                      class="form-control"
                      v-model="data.about"
                      :placeholder="$t('register.data.about')"
                    ></textarea>
                    <!--About Me-->
                  </div>
                  <!--Input Wrapper-->
                </div>
              </template>
              <!--Job Searcher Data-->

              <!--Job Owner Data-->
              <template v-if="step == 2 && userType == 'company'">
                <!--User Welcome-->
                <div class="user_welcome">
                  <h4>
                    {{ $t("welcome") }} <span>{{ data.firstName }}</span>
                  </h4>
                </div>
                <!--User Welcome-->
                <!--                 <div class="col-md-6">
                  <!~~Input Wrapper~~>
                  <div class="input_wrapper reg_style">
                    <!~~Person Name~~>
                    <input
                      type="text"
                      class="form-control"
                      v-model="data.person"
                      :placeholder="$t('register.data.person')"
                    />
                    <!~~Person Name~~>
                  </div>
                  <!~~Input Wrapper~~>
                </div> -->

                <div class="col-md-6">
                  <!--Input Wrapper-->
                  <div class="input_wrapper reg_style">
                    <!--Company Name-->
                    <input
                      type="text"
                      class="form-control"
                      v-model="data.company"
                      @keypress="isLetter($event)"
                      :placeholder="$t('register.data.company')"
                    />
                    <!--Company Name-->
                  </div>
                  <!--Input Wrapper-->
                </div>

                <!--                 <div class="col-md-6">
                  <!~~Input Wrapper~~>
                  <div class="input_wrapper reg_style select">
                    <!~~Select Region~~>
                    <select class="form-control" v-model="data.region">
                      <option disabled value="">
                        {{ $t("register.data.region") }}
                      </option>
                      <option v-for="reg in regions" :key="reg.id">
                        {{ reg.name }}
                      </option>
                    </select>
                    <!~~Select Region~~>
                  </div>
                  <!~~Input Wrapper~~>
                </div> -->

                <div class="col-md-6">
                  <!--Input Wrapper-->
                  <div class="input_wrapper reg_style file_upload">
                    <!--Upload Cv-->
                    <div class="form-control">
                      <span v-if="data.beforeUpload2">
                        {{ $t("register.data.companyLogo") }}</span
                      >
                      <span v-if="data.afterUpload2">{{
                        data.companyLogo.name
                      }}</span>
                      <span class="icon"><i class="fal fa-file"></i></span>
                    </div>
                    <input
                      type="file"
                      name="chooseFile"
                      @change="uploadFile2"
                    />
                    <!--Upload Cv-->
                  </div>
                  <!--Input Wrapper-->
                </div>
              </template>
              <!--Job Owner Data-->

              <div class="col-sm-12 mt-5">
                <button
                  type="button"
                  class="main_button2 button register_btn"
                  @click="register()"
                  ref="register"
                >
                  <i class="fas fa-spinner"></i>
                  {{ $t("next") }}
                </button>
                <button
                  v-if="step == 2"
                  type="button"
                  class="main_button2 button register_btn"
                  @click="step = 1"
                >
                  <i class="fas fa-spinner"></i>
                  {{ $t("back") }}
                </button>
              </div>
            </div>
          </form>
        </div>
        <!--Form Inputs-->
      </div>
    </div>
    <!--Register Wizard Form-->
  </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import * as moment from "moment/moment";
export default {
  components: {
    Datepicker,
  },
  data() {
    return {
      //Progress Step
      step: 1,
      //Progress Step

      //Cities
      cities: [],
      //Cities

      //Codes
      codes: ["996", "02", "666"],
      //Codes

      //Specialists
      specialists: [],
      //Specialists

      //Sub Specialists
      subSpecialists: [],
      //Sub Specialists

      //Education Levels
      educationLevels: [],
      //Education Levels

      //Types
      Types: [
        {
          id: 1,
          name: this.$t("male"),
          value: "male",
        },
        {
          id: 2,
          name: this.$t("female"),
          value: "female",
        },
      ],
      //Types

      //Age
      ages: [
        {
          id: 1,
          age: "20",
        },
        {
          id: 2,
          age: "30",
        },
        {
          id: 3,
          age: "35",
        },
        {
          id: 4,
          age: "40",
        },
      ],
      //Age

      userType: "",

      //Experiences
      Experiences: [
        {
          id: 1,
          name: "1",
        },
        {
          id: 2,
          name: "2",
        },
        {
          id: 3,
          name: "3",
        },
      ],
      //Experiences

      //Regions
      Regions: [
        {
          id: 1,
          name: "المنطقة",
        },
        {
          id: 2,
          name: "المنطقة",
        },
        {
          id: 3,
          name: "المنطقة",
        },
      ],
      //Regions

      //Data
      data: {
        firstName: null,
        lastName: null,
        phone: null,
        type: "",
        city: "",
        age: "",
        email: null,
        password: null,
        passwordConfirmation: null,
        specialist: "",
        subSpecialist: "",
        experience: "",
        educationLevel: "",
        about: null,
        uploadCv: null,
        afterUpload: false,
        beforeUpload: true,
        person: null,
        company: null,
        region: "",
        companyLogo: null,
        afterUpload2: false,
        beforeUpload2: true,
      },
    };
  },
  methods: {
    //Upload Cv
    uploadFile(e) {
      this.data.uploadCv = e.target.files[0];
      this.data.beforeUpload = false;
      this.data.afterUpload = true;
    },
    //Upload Cv

    //Upload Logo
    uploadFile2(e) {
      this.data.companyLogo = e.target.files[0];
      this.data.beforeUpload2 = false;
      this.data.afterUpload2 = true;
    },
    //Upload Logo

    //Data Formate
    formatDate(date) {
      return moment(date).format("DD-MM-YYYY");
    },
    //Data Formate

    //Get Data
    getData() {
      //====Cities=======
      this.$axios({
        method: "GET",
        url: "cities",
      }).then((res) => {
        this.cities = res.data.data;
      });
      //====Main Category=======
      this.$axios({
        method: "GET",
        url: "categories",
      }).then((res) => {
        this.specialists = res.data.data;
      });
      //====Education Levels=======
      this.$axios({
        method: "GET",
        url: "education_levels",
      }).then((res) => {
        this.educationLevels = res.data.data;
      });
    },

    //====Sub Category=======
    getSub() {
      this.$axios({
        method: "GET",
        url: "subcategories?category_id=" + this.data.specialist,
      }).then((res) => {
        this.subSpecialists = res.data.data;
      });
    },

    //Register
    register() {
      var submitButton = this.$refs["register"];
      submitButton.classList.add("click");
      if (this.step == 1) {
        if (!this.data.firstName) {
          setTimeout(() => {
            submitButton.classList.remove("click");
            this.$iziToast.error({
              message: this.$t("register.validation.firstName"),
            });
          }, 1000);
        } else if (!this.data.lastName) {
          setTimeout(() => {
            submitButton.classList.remove("click");
            this.$iziToast.error({
              message: this.$t("register.validation.lastName"),
            });
          }, 1000);
        } else if (!this.data.phone) {
          setTimeout(() => {
            submitButton.classList.remove("click");
            this.$iziToast.error({
              message: this.$t("register.validation.phone"),
            });
          }, 1000);
        } else if (!this.data.city) {
          setTimeout(() => {
            submitButton.classList.remove("click");
            this.$iziToast.error({
              message: this.$t("register.validation.city"),
            });
          }, 1000);
        } else if (!this.data.type) {
          setTimeout(() => {
            submitButton.classList.remove("click");
            this.$iziToast.error({
              message: this.$t("register.validation.type"),
            });
          }, 1000);
        } else if (!this.data.email) {
          setTimeout(() => {
            submitButton.classList.remove("click");
            this.$iziToast.error({
              message: this.$t("register.validation.email"),
            });
          }, 1000);
        } else if (!this.data.password) {
          setTimeout(() => {
            submitButton.classList.remove("click");
            this.$iziToast.error({
              message: this.$t("register.validation.password"),
            });
          }, 1000);
        } else if (this.data.passwordConfirmation != this.data.password) {
          setTimeout(() => {
            submitButton.classList.remove("click");
            this.$iziToast.error({
              message: this.$t("register.validation.passwordConfirmation"),
            });
          }, 1000);
        } else if (!this.userType) {
          setTimeout(() => {
            submitButton.classList.remove("click");
            this.$iziToast.error({
              message: this.$t("register.validation.userType"),
            });
          }, 1000);
        } else {
          setTimeout(() => {
            submitButton.classList.remove("click");
            this.step++;
          }, 1000);
        }
      } else if (this.step == 2 && this.userType == "user") {
        if (!this.data.specialist) {
          setTimeout(() => {
            submitButton.classList.remove("click");
            this.$iziToast.error({
              message: this.$t("register.validation.specialist"),
            });
          }, 1000);
        } else if (!this.data.subSpecialist) {
          setTimeout(() => {
            submitButton.classList.remove("click");
            this.$iziToast.error({
              message: this.$t("register.validation.subSpecialist"),
            });
          }, 1000);
        } else if (!this.data.educationLevel) {
          setTimeout(() => {
            submitButton.classList.remove("click");
            this.$iziToast.error({
              message: this.$t("register.validation.education_level"),
            });
          }, 1000);
        } else if (!this.data.experience) {
          setTimeout(() => {
            submitButton.classList.remove("click");
            this.$iziToast.error({
              message: this.$t("register.validation.experience"),
            });
          }, 1000);
        }
        // else if (!this.data.uploadCv) {
        //   setTimeout(() => {
        //     submitButton.classList.remove("click");
        //     this.$iziToast.error({
        //       message: this.$t("register.validation.uploadCv"),
        //     });
        //   }, 1000);
        // } else if (!this.data.about) {
        //   setTimeout(() => {
        //     submitButton.classList.remove("click");
        //     this.$iziToast.error({
        //       message: this.$t("register.validation.about"),
        //     });
        //   }, 1000);
        // }
        else {
          let submit_data = new FormData();
          submit_data.append("first_name", this.data.firstName);
          submit_data.append("last_name", this.data.lastName);
          submit_data.append("phone", "+966" + this.data.phone);
          submit_data.append("city_id", this.data.city);
          submit_data.append("gender", this.data.type);
          submit_data.append("date_birth", this.formatDate(this.data.age));
          submit_data.append("email", this.data.email);
          submit_data.append("education_level_id", this.data.educationLevel);
          submit_data.append("password", this.data.password);
          submit_data.append(
            "password_confirmation",
            this.data.passwordConfirmation
          );
          submit_data.append("user_type", this.userType);
          submit_data.append("categories id", this.data.specialist);
          submit_data.append("sub_categories_id", this.data.subSpecialist);
          submit_data.append("experience_years", this.data.experience);
          if (this.data.uploadCv) {
            submit_data.append("cv", this.data.uploadCv);
          }
          submit_data.append("bio", this.data.about);
          this.$axios({
            method: "post",
            url: "register",
            data: submit_data,
          })
            .then((res) => {
              setTimeout(() => {
                const data = {
                  devMessage: res.data.dev_message,
                };
                this.$store.dispatch("auth_module/register", data);
                this.$router.push({ path: "/login" });
                submitButton.classList.remove("click");
                this.$iziToast.success({
                  message: res.data.message,
                });
              }, 1000);
            })
            .catch((err) => {
              setTimeout(() => {
                submitButton.classList.remove("click");
                this.$iziToast.error({
                  message: err.response.data.message,
                });
              }, 1000);
            });
        }
      } else if (this.step == 2 && this.userType == "company") {
        // if (!this.data.firstName) {
        //   setTimeout(() => {
        //     submitButton.classList.remove("click");
        //     this.$iziToast.error({
        //       message: this.$t("register.validation.person"),
        //     });
        //   }, 1000);
        // }
        if (!this.data.company) {
          setTimeout(() => {
            submitButton.classList.remove("click");
            this.$iziToast.error({
              message: this.$t("register.validation.company"),
            });
          }, 1000);
        } else if (!this.data.companyLogo) {
          setTimeout(() => {
            submitButton.classList.remove("click");
            this.$iziToast.error({
              message: this.$t("register.validation.companyLogo"),
            });
          }, 1000);
        }
        // else if (!this.data.uploadCv) {
        //   setTimeout(() => {
        //     submitButton.classList.remove("click");
        //     this.$iziToast.error({
        //       message: this.$t("register.validation.uploadCv"),
        //     });
        //   }, 1000);
        // } else if (!this.data.about) {
        //   setTimeout(() => {
        //     submitButton.classList.remove("click");
        //     this.$iziToast.error({
        //       message: this.$t("register.validation.about"),
        //     });
        //   }, 1000);
        // }
        else {
          let submit_data = new FormData();
          submit_data.append("first_name", this.data.firstName);
          submit_data.append("last_name", this.data.lastName);
          submit_data.append("phone", "+966" + this.data.phone);
          submit_data.append("city_id", this.data.city);
          submit_data.append("gender", this.data.type);
          submit_data.append("date_birth", this.formatDate(this.data.age));
          submit_data.append("email", this.data.email);
          submit_data.append("password", this.data.password);
          submit_data.append(
            "password_confirmation",
            this.data.passwordConfirmation
          );
          submit_data.append("user_type", this.userType);
          submit_data.append("fullname", this.data.company);
          submit_data.append("image", this.data.companyLogo);
          this.$axios({
            method: "post",
            url: "register",
            data: submit_data,
          })
            .then((res) => {
              setTimeout(() => {
                const data = {
                  devMessage: res.data.dev_message,
                };
                this.$store.dispatch("auth_module/register", data);
                this.$router.push({ path: "/login" });
                submitButton.classList.remove("click");
                this.$iziToast.success({
                  message: res.data.message,
                });
              }, 1000);
            })
            .catch((err) => {
              setTimeout(() => {
                submitButton.classList.remove("click");
                this.$iziToast.error({
                  message: err.response.data.message,
                });
              }, 1000);
            });
        }
      }
    },
  },
  created() {
    this.getData();
  },
};
</script>

<style></style>
