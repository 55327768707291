<template>
  <div class="auth_page">
    <div class="static_bg">
      <!--Logo-->
      <div class="logo">
        <router-link to="/"
          ><img src="@/assets/images/icons/white_logo.png"
        /></router-link>
      </div>
      <!--Logo-->
      <!--Have Account-->
      <div class="have_account">
        <h4>{{ $t("auth.login") }}</h4>
        <p>{{ $t("auth.haveAccount") }}</p>
      </div>
      <!--Have Account-->
      <!--Login Platforms-->
      <!-- <div class="login_paltforms">
        <ul class="social_media">
          <li class="google">
            <a href=""><i class="fab fa-google"></i></a>
          </li>
          <li class="apple">
            <a href=""><i class="fab fa-apple"></i></a>
          </li>
          <li class="instagram">
            <a href=""><i class="fab fa-instagram"></i></a>
          </li>
          <li class="twitter">
            <a href=""><i class="fab fa-twitter"></i></a>
          </li>
          <li class="facebook">
            <a href=""><i class="fab fa-facebook-f"></i></a>
          </li>
        </ul>
      </div> -->
      <!--Login Platforms-->
    </div>
    <!--Form Container-->

    <transition name="slither" mode="out-in">
      <div class="view_part">
        <router-view />
      </div>
    </transition>
    <!--Form Container-->
  </div>
</template>

<script>
export default {};
</script>
