<template>
  <!--Job Card2-->
  <div class="job_card2">
    <!--padge-->
    <div class="padge" v-if="job.is_trend == 1">
      <img src="@/assets/images/job_card/padge.png" />
    </div>
    <!--padge-->
    <!--Details-->
    <div class="details">
      <router-link
        v-if="userType == 'user' || userId == job.company.id || !token"
        :to="!route ? `jobDetails/${job.id}` : route"
        title="اضغط لمشاهدة تفاصيل الوظيفة"
      >
        <!--Title-->
        <div class="title">
          <h4>{{ job.name.substring(0, 30) + "...." }}</h4>
        </div>
        <!--Title-->

        <!--City-->
        <div class="city" v-if="job.city">
          <span>{{ job.created_at }}</span>
          <h4>{{ job.city.name }}</h4>
        </div>
        <!--City-->

        <!--Desc-->
        <div class="desc">
          <p>
            {{ job.desc }}
          </p>
        </div>
        <!--Desc-->
      </router-link>
      <!--------Not Link-->
      <div v-else>
        <!--Title-->
        <div class="title">
          <h4>{{ job.name }}</h4>
        </div>
        <!--Title-->

        <!--City-->
        <div class="city" v-if="job.city">
          <span>{{ job.created_at }}</span>
          <h4>{{ job.city.name }}</h4>
        </div>
        <!--City-->

        <!--Desc-->
        <div class="desc">
          <p>
            {{ job.desc }}
          </p>
        </div>
        <!--Desc-->
      </div>
      <!--Apply To Job-->
      <button
        @click="applayJob(job.id)"
        type="button"
        class="main_button"
        v-if="!job.is_join && userType != 'company'"
      >
        <span>{{ $t("applayJob") }}</span>
      </button>
      <!--Applay To Job-->
    </div>
    <!--Details-->

    <!--Image-->
    <div class="image" v-if="job.company">
      <img :src="job.company.image" />
      <h4>{{ job.company.name }}</h4>
    </div>
    <!--Image-->
  </div>
  <!--Job Card2-->
</template>

<script>
export default {
  props: ["job", "route"],
  methods: {
    //Applay Job
    applayJob(id) {
      let submit_data = new FormData();
      submit_data.append("work_id", id);
      this.$axios({
        method: "post",
        url: "client/join_work",
        data: submit_data,
        headers: {
          Authorization: "bearer" + localStorage.getItem("SAS_App_Token"),
        },
      })
        .then(() => {
          setTimeout(() => {
            this.$iziToast.success({
              message: this.$t("excuteSuccess"),
            });
          }, 1000);
        })
        .catch((err) => {
          setTimeout(() => {
            this.$iziToast.error({
              message: err.response.data.message,
            });
          }, 1000);
        });
    },
    //Applay Job
  },
  computed: {
    userType() {
      return this.$store.getters["auth_module/usertype"];
    },
    userId() {
      return this.$store.getters["auth_module/set_userId"];
    },
    token() {
      return this.$store.getters["auth_module/token"];
    },
  },
};
</script>

<style></style>
