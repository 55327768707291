<template>
  <div class="homePage">
    <main-section :item="allData"></main-section>
    <our-clients :item="allData" :loading="loading"></our-clients>
    <job-owner-package></job-owner-package>
    <!-- <featured-package></featured-package> -->
    <cv-steps></cv-steps>
    <featured-cv :item="allData2"></featured-cv>
    <employee-requests></employee-requests>
    <latest-articles :item="allData2"></latest-articles>
    <newsleter></newsleter>
  </div>
</template>

<script>
import MainSection from "@/components/HomePage/MainSectionJobOwner.vue";
import OurClients from "@/components/HomePage/OurClients.vue";
import JobOwnerPackage from "@/components/HomePage/JobOwnerPackages.vue";
import FeaturedCv from "@/components/HomePage/FeaturedCv.vue";
import LatestArticles from "@/components/HomePage/LatestArticles.vue";
import Newsleter from "@/components/HomePage/Newsleter.vue";
// import FeaturedPackage from "@/components/HomePage/FeaturedPackage.vue";
import CvSteps from "@/components/HomePage/CvSteps.vue";
import EmployeeRequests from "@/components/HomePage/EmployeeRequests.vue";
export default {
  data() {
    return {
      allData: null,
      allData2: null,
      loading: false,
    };
  },
  methods: {
    changeTheme() {
      this.$store.dispatch("theme_module/changeTheme");
      if (this.$store.getters["theme_module/current_theme"] == "light") {
        this.$vuetify.theme.dark = false;
      } else {
        this.$vuetify.theme.dark = true;
      }
    },

    changeLang() {
      this.$store.dispatch("lang_module/switchLang");
    },
    //Get Data
    getData() {
      this.loading = true;
      this.$axios({
        method: "GET",
        url: "home",
      }).then((res) => {
        this.loading = false;
        this.allData = res.data.data;
      });
    },
    //Get Data
    //Get Data
    getData2() {
      this.loading = true;
      this.$axios({
        method: "GET",
        url: "section2",
      }).then((res) => {
        this.loading = false;
        this.allData2 = res.data.data;
      });
    },
    //Get Data
  },
  components: {
    MainSection,
    OurClients,
    JobOwnerPackage,
    FeaturedCv,
    LatestArticles,
    Newsleter,
    // FeaturedPackage,
    CvSteps,
    EmployeeRequests,
  },
  created() {
    this.getData();
  },
};
</script>
