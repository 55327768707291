<template>
  <!--Start Featured Jobs-->
  <loader v-if="loading"></loader>
  <section class="featured_jobs Pad_50" v-else>
    <div class="container" v-if="item">
      <div class="row">
        <div class="col-sm-12">
          <div class="side_title">
            <h3>{{ $t("featuredJobs.title") }}</h3>
            <!-- <router-link to="">{{ $t("showAll") }}</router-link> -->
          </div>
        </div>
        <div class="col-md-4" v-for="job in item.trend_works" :key="job.id">
          <job-card :job="job"></job-card>
        </div>
      </div>
    </div>
  </section>
  <!--End Featured Jobs-->
</template>

<script>
import JobCard from "../JobCard/JobCard.vue";
export default {
  props: ["item", "loading"],
  components: { JobCard },
  data() {
    return {};
  },
};
</script>

<style></style>
