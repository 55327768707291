<template>
  <div class="register_page">
    <!--Top Background-->
    <div class="top_background">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="title">
              <h4>{{ $t("categories.title") }}</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--Top Background-->

    <!--Categories-->
    <div class="categories_page">
      <jobs-by-categories :show="true"></jobs-by-categories>
      <jobs-by-cities></jobs-by-cities>
      <featured-cv></featured-cv>
      <strong-cv></strong-cv>
      <newsleter></newsleter>
    </div>
    <!--Categories--->
  </div>
</template>

<script>
import JobsByCategories from "@/components/HomePage/JobsByCategories.vue";
import JobsByCities from "@/components/HomePage/JobsByCities.vue";
import FeaturedCv from "@/components/HomePage/FeaturedCv.vue";
import Newsleter from "@/components/HomePage/Newsleter.vue";
import StrongCv from "../../../components/HomePage/StrongCv.vue";
export default {
  components: {
    JobsByCategories,
    JobsByCities,
    FeaturedCv,
    Newsleter,
    StrongCv,
  },
  data() {
    return {};
  },
};
</script>

<style></style>
