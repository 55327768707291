<template>
  <!--Start Latest Articles-->
  <section class="latest_articles Pad_50" v-if="item">
    <div class="container">
      <div class="row">
        <div class="col-sm-12">
          <div class="side_title">
            <h3>{{ $t("blogs.title") }}</h3>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-9">
          <!--Articles Slider-->
          <owl-slider
            :items="2"
            :autoplay="true"
            :loop="true"
            :nav="true"
            :dots="true"
            :margin="10"
            dir="ltr"
            :navText="[
              `<span class='fal fa-angle-right'></span>`,
              `<span class='fal fa-angle-left'></span>`,
            ]"
            :responsive="{
              0: { items: 1, nav: false },
              700: { items: 2, nav: false },
              1000: { items: 2, nav: true },
            }"
            class="owl-articles"
          >
            <div class="item" v-for="blog in item.blog" :key="blog.id">
              <!--Article Card-->
              <router-link :to="'blogDetails/' + blog.id">
                <div class="article_card">
                  <!--Image-->
                  <div class="image">
                    <img :src="blog.image" />
                    <!--Text-->
                    <div class="text">
                      <!-- <h4>اسرار المقابلات</h4> -->
                    </div>
                    <!--Text-->
                  </div>
                  <!--Image-->
                  <!--Details-->
                  <div class="details">
                    <p class="question" v-html="blog.name"></p>
                    <p class="answer" v-html="blog.desc"></p>
                    <span>{{ blog.created_at }}</span>
                  </div>
                  <!--Details-->
                </div>
              </router-link>
              <!--Article Card-->
            </div>
            <!--             <div class="item">
              <!~~Article Card~~>
              <router-link to="">
                <div class="article_card">
                  <!~~Image~~>
                  <div class="image">
                    <img src="@/assets/images/latest_articles/img1.png" />
                    <!~~Text~~>
                    <div class="text">
                      <h4>اسرار المقابلات</h4>
                    </div>
                    <!~~Text~~>
                  </div>
                  <!~~Image~~>
                  <!~~Details~~>
                  <div class="details">
                    <p class="question">
                      ماهي اسرار المقابلات التي تعمل على خلف بيئة مريحة في
                      المقابلة والتي تعمل على إعطائك جزء كبير من الراحة النفسية
                      وتملك الشخصية؟
                    </p>
                    <p class="answer">
                      تخيّلي أن رئيسك بالعمل أرسل لك ليلا بريدا إلكترونيا بخصوص
                      مهمة غير واضحة، ثم رسا
                    </p>
                    <span>06-Oct-2020</span>
                  </div>
                  <!~~Details~~>
                </div>
              </router-link>
              <!~~Article Card~~>
            </div>
            <div class="item">
              <!~~Article Card~~>
              <div class="article_card">
                <!~~Image~~>
                <div class="image">
                  <img src="@/assets/images/latest_articles/img1.png" />
                  <!~~Text~~>
                  <div class="text">
                    <h4>اسرار المقابلات</h4>
                  </div>
                  <!~~Text~~>
                </div>
                <!~~Image~~>
                <!~~Details~~>
                <div class="details">
                  <p class="question">
                    ماهي اسرار المقابلات التي تعمل على خلف بيئة مريحة في
                    المقابلة والتي تعمل على إعطائك جزء كبير من الراحة النفسية
                    وتملك الشخصية؟
                  </p>
                  <p class="answer">
                    تخيّلي أن رئيسك بالعمل أرسل لك ليلا بريدا إلكترونيا بخصوص
                    مهمة غير واضحة، ثم رسا
                  </p>
                  <span>06-Oct-2020</span>
                </div>
                <!~~Details~~>
              </div>
              <!~~Article Card~~>
            </div> -->
          </owl-slider>
          <!--Articles Slider-->
        </div>
        <div class="col-md-3">
          <!--Image-->
          <!-- <div class="image">
            <img src="@/assets/images/icons/ads.png" />
          </div> -->
          <!--Image-->
        </div>
      </div>
    </div>
  </section>
  <!--End Latest Articles-->
</template>

<script>
export default {
  props: ["item"],
};
</script>

<style></style>
