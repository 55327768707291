<template>
  <div class="row justify-content-center" dir="ltr">
    <div class="col-md-12">
      <div
        class="cv_template_2 en"
        v-if="item"
        style="font-family: 'font_regular' !important"
      >
        <!--Right Part-->
        <div class="right_back">
          <!--Cv User Data-->
          <div class="cv_user_data">
            <!--Image-->
            <div class="user_image">
              <img :src="item.image" />
            </div>
            <!--Image-->
            <!--Back Shape-->
            <div class="back_shape"></div>
            <!--Back Shape-->
            <!--Personal Info-->
            <div class="personal_info">
              <!--Info List-->
              <ul class="info_list">
                <li>
                  <i class="fal fa-envelope"></i>
                  <span>{{ item.email }}</span>
                </li>
                <li>
                  <i class="fal fa-phone"></i>
                  <span>{{ item.phone }}</span>
                </li>
                <li>
                  <i class="fal fa-map-marker-alt"></i>
                  <span>{{ item.address_en }}</span>
                </li>
                <li>
                  <i class="fal fa-user"></i>
                  <span>{{
                    item.gender == "male" ? $t("single") : $t("married")
                  }}</span>
                </li>
                <li>
                  <i class="fab fa-twitter"></i>
                  <span>{{ item.twitter_user }}</span>
                </li>
                <li>
                  <i class="fab fa-linkedin"></i>
                  <span>{{ item.linkedin_user }}</span>
                </li>
              </ul>
              <!--Info List-->
            </div>
            <!--Personal Info-->
            <!--Langs-->
            <div class="langs">
              <h4>Languages</h4>
              <div class="lang_item">
                <span>Arabic Lang</span>
                <ul class="lang_points">
                  <li
                    class="fill"
                    v-for="lel in parseInt(item.arabic_level)"
                    :key="lel.id"
                  ></li>
                  <li
                    class="no_fill"
                    v-for="lel in 4 - parseInt(item.arabic_level)"
                    :key="lel.id"
                  ></li>
                </ul>
              </div>
              <div class="lang_item">
                <span>English Lang</span>
                <ul class="lang_points">
                  <li
                    class="fill"
                    v-for="lel in parseInt(item.english_level)"
                    :key="lel.id"
                  ></li>
                  <li
                    class="no_fill"
                    v-for="lel in 4 - parseInt(item.english_level)"
                    :key="lel.id"
                  ></li>
                </ul>
              </div>
            </div>
            <!--Langs-->
            <!--Skills-->
            <div class="skills">
              <h4>Hobbies</h4>
              <ul class="skills_list">
                <li v-for="skill in item.interests" :key="skill.id">
                  <span>{{ skill.name_en }}</span>
                </li>
              </ul>
            </div>
            <!--Skills-->
          </div>
          <!--Cv User Data-->
        </div>
        <!--Right Part-->
        <!--Left Part-->
        <div class="left_back">
          <!--User Job Title-->
          <div class="user_job_title">
            <h4>{{ item.name_en }}</h4>
            <h5>{{ item.job_title_en }}</h5>
          </div>
          <!--User Job Title-->
          <!--User Breif-->
          <div class="user_breif">
            <h4><i class="far fa-clipboard-list"></i> Introduction</h4>
            <p>
              {{ item.desc_en }}
            </p>
          </div>
          <!--User Breif-->
          <!--Work Experience-->
          <div class="work_experience">
            <h4><i class="fal fa-briefcase"></i> Work Experience</h4>
            <div
              class="work_card"
              v-for="exp in item.experiences"
              :key="exp.id"
            >
              <div class="date">
                <span>{{ exp.start_date.substring(-1, 4) }}</span>
                <span v-if="Boolean(!exp.is_still)">{{
                  exp.end_date.substring(-1, 4)
                }}</span>
                <span v-if="Boolean(exp.is_still)">Untill Now</span>
              </div>
              <div class="work">
                <span>{{ exp.job_title }}</span>
                <h4>{{ exp.name_en }}</h4>
                <p>
                  {{ exp.desc_en }}
                </p>
              </div>
            </div>
          </div>
          <!--Work Experience-->
          <!--Education-->
          <div class="work_experience">
            <h4><i class="fal fa-university"></i> Education Experience</h4>
            <div class="work_card" v-for="edu in item.education" :key="edu.id">
              <div class="date">
                <span>{{ edu.start_date.substring(-1, 4) }}</span>
                <span v-if="Boolean(!edu.is_still)">{{
                  edu.end_date.substring(-1, 4)
                }}</span>
                <span v-if="Boolean(edu.is_still)">Untill Now</span>
              </div>
              <div class="work">
                <span>{{ edu.section_title_en }}</span>
                <h4>{{ edu.name_en }}</h4>
                <p>
                  {{ edu.desc_en }}
                </p>
              </div>
            </div>
          </div>
          <!--Education-->

          <!--Personal Skills-->
          <div class="work_experience">
            <h4>
              <i class="fal fa-star"></i>
              {Personal Skills
            </h4>
            <div class="progress_container">
              <h5>Communication</h5>
              <div class="progress">
                <div
                  class="progress-bar"
                  role="progressbar"
                  :style="{ width: item.contact_level + '%' }"
                  aria-valuenow="25"
                  aria-valuemin="0"
                  aria-valuemax="100"
                >
                  {{ item.contact_level }}%
                </div>
              </div>
            </div>
            <div class="progress_container">
              <h5>Team Mangement</h5>
              <div class="progress">
                <div
                  class="progress-bar"
                  role="progressbar"
                  :style="{ width: item.leader_level + '%' }"
                  aria-valuenow="25"
                  aria-valuemin="0"
                  aria-valuemax="100"
                >
                  {{ item.leader_level }}%
                </div>
              </div>
            </div>
            <div class="progress_container">
              <h5>Enjoy</h5>
              <div class="progress">
                <div
                  class="progress-bar"
                  role="progressbar"
                  :style="{ width: item.play_level + '%' }"
                  aria-valuenow="25"
                  aria-valuemin="0"
                  aria-valuemax="100"
                >
                  {{ item.play_level }}%
                </div>
              </div>
            </div>
          </div>
          <!--Personal Skills-->

          <!--Time Mangement-->
          <div class="work_experience">
            <h4>
              <i class="fal fa-university"></i>
              Time Mangement
            </h4>
            <apexchart
              width="290"
              height="250"
              type="donut"
              :options="options"
              :series="newSer"
            ></apexchart>
          </div>
          <!--Time Mangement-->
        </div>
        <!--Left Part-->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["item"],
  data() {
    return {
      //Template Color
      templateColor: "",
      //Template Color

      options: {
        chart: {
          type: "radialBar",
        },

        legend: {
          show: true,
          horizontalAlign: "center",
          floating: false,
          fontSize: "16px",
          markers: {
            width: 20,
            height: 20,
            strokeWidth: 0,
            radius: 0,
          },
          itemMargin: {
            horizontal: 5,
            vertical: 0,
          },
        },
        dataLabels: {
          enabled: false,
        },
        plotOptions: {
          pie: {
            donut: {
              size: "85%",
              labels: {
                show: true,
                name: {
                  show: true,
                },
                value: {
                  show: false,
                },
                total: {
                  show: true,
                  label: this.$t("cvsteps.wizard.timeMangement.title"),
                  color: "#29479A",
                  // formatter: function (w) {
                  //   return w.globals.seriesTotals.reduce((a, b) => {
                  //     return a + b;
                  //   }, 0);
                  // },
                },
              },
            },
          },
        },
        labels: ["Work", "Family", "Enjoy"],
        colors: ["#7D5EE6", "#CAB6FB", "#9D9AEC"],
      },
      series: [44, 55, 41],

      //Colors
      colors: [
        {
          color: "#DFDFDF",
        },
        {
          color: "#0EB6D8",
        },
        {
          color: "#87CCAF",
        },
        {
          color: "#CEFFB1",
        },
        {
          color: "#E4A8F3",
        },
      ],
      //Colors
    };
  },
  methods: {
    //Change Color
    changeColor(color) {
      this.templateColor = color;
    },
  },
  mounted() {
    this.changeColor(this.colors[0].color);
  },
  computed: {
    newSer() {
      return [
        Number(this.item.work_level),
        Number(this.item.family_level),
        Number(this.item.play_level),
      ];
    },
  },
};
</script>

<style scoped></style>
