<template>
  <section class="cv_steps">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-sm-8">
          <!--Title-->
          <div class="center_title">
            <h3>{{ $t("cvSteps.title") }}</h3>
          </div>
          <!--Title-->
        </div>
      </div>
      <div class="row">
        <div class="col-md-4">
          <!--Step Card-->
          <div class="setp_card">
            <!--Title-->
            <div class="title">
              <span>1</span>
              <h4 v-if="lang_global == 'ar'">سجل معنا</h4>
              <h4 v-if="lang_global == 'en'">Register With Us</h4>
            </div>
            <!--Title-->
            <!--Image-->
            <div class="image">
              <img src="@/assets/images/cv_steps/sign-in.png" />
            </div>
            <!--Image-->
          </div>
          <!--Step Card-->
        </div>

        <div class="col-md-4">
          <!--Step Card-->
          <div class="setp_card">
            <!--Title-->
            <div class="title">
              <span>2</span>
              <h4 v-if="lang_global == 'ar'">أضف اعلانك وسدد رسوم الخدمة</h4>
              <h4 v-if="lang_global == 'en'">Add Your Ad And Pay For Fees</h4>
            </div>
            <!--Title-->
            <!--Image-->
            <div class="image">
              <img src="@/assets/images/cv_steps/agreement.png" />
            </div>
            <!--Image-->
          </div>
          <!--Step Card-->
        </div>

        <div class="col-md-4">
          <!--Step Card-->
          <div class="setp_card">
            <!--Title-->
            <div class="title">
              <span>3</span>
              <h4 v-if="lang_global == 'ar'">
                استمتع بقهوتك بينما نحضر لك البيانات
              </h4>
              <h4 v-if="lang_global == 'en'">
                Enjoy your coffee while we bring you the data
              </h4>
            </div>
            <!--Title-->
            <!--Image-->
            <div class="image">
              <img src="@/assets/images/cv_steps/coffee.png" />
            </div>
            <!--Image-->
          </div>
          <!--Step Card-->
        </div>
        <div class="col-sm-12">
          <router-link to="/addJob" class="main_button" v-if="token"
            ><span v-if="lang_global == 'ar'">انشر اعلانك</span
            ><span v-if="lang_global == 'en'">Post Ad</span></router-link
          >
          <router-link to="/login" class="main_button" v-if="!token"
            ><span v-if="lang_global == 'ar'">انشر اعلانك</span
            ><span v-if="lang_global == 'en'">Post Ad</span></router-link
          >
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  computed: {
    token() {
      return this.$store.getters["auth_module/token"];
    },
  },
};
</script>

<style></style>
