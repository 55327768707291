<template>
  <div class="register_page">
    <!--Top Background-->
    <div class="top_background">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="title">
              <h4>{{ $t("cvsteps.title") }}</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--Top Background-->
    <!--Cv Steps Page-->
    <div class="cv_steps_page">
      <div class="container">
        <div class="register_wizard_form">
          <div class="wizard_title">
            <h4>{{ $t("cvsteps.wizard_title") }}</h4>
            <p>{{ $t("cvsteps.wizard.desc") }}</p>
          </div>
          <!--Progress Bar-->
          <div class="progress_bar">
            <ul>
              <li :class="{ active: step == 1 }">
                <h4>01 {{ $t("cvsteps.wizard.chooseTemplate") }}</h4>
                <p>
                  <span></span>
                </p>
              </li>
              <li :class="{ active: step == 2 }">
                <h4>02 {{ $t("cvsteps.wizard.pay") }}</h4>
                <p>
                  <span></span>
                </p>
              </li>
              <li :class="{ active: step == 3 || step == 4 }">
                <h4>03 {{ $t("cvsteps.wizard.fillData") }}</h4>
                <p>
                  <span></span>
                </p>
              </li>
            </ul>
          </div>
          <!--Progress Bar-->

          <!--Choose Template-->
          <template v-if="step == 1">
            <div class="cv_templates">
              <loader v-if="loading"></loader>
              <div class="row" v-else>
                <div
                  class="col-md-4"
                  v-for="template in allData.design_cv"
                  :key="template.id"
                >
                  <cv-template
                    @chooseTemplate="chooseTemplate"
                    :template="template"
                  ></cv-template>
                </div>
              </div>
            </div>
          </template>
          <!--Choose Template-->

          <!--Fill Data-->
          <template v-if="step == 3">
            <div class="form_inputs">
              <!--Form Title-->
              <div class="form_title">
                <h4>{{ $t("cvsteps.wizard.formTitle") }}</h4>
              </div>
              <!--Form Title-->
              <!--Block Inputs Personal-->
              <div class="block_inputs">
                <!--Title-->
                <div class="block_title">
                  <h4>{{ $t("cvsteps.wizard.personalData") }} . 1</h4>
                </div>
                <!--Title-->
                <!--Inputs-->
                <div class="row">
                  <div
                    class="col-md-6"
                    v-if="tempLang == 'ar' || tempLang == 'both'"
                  >
                    <!--Input Wrapper-->
                    <div class="input_wrapper reg_style">
                      <!--First Name-->
                      <input
                        type="text"
                        class="form-control"
                        v-model="data.firstName"
                        @keypress="isLetter($event)"
                        :placeholder="$t('register.data.firstName')"
                      />
                      <!--First Name-->
                    </div>
                    <!--Input Wrapper-->
                  </div>

                  <div
                    class="col-md-6"
                    v-if="tempLang == 'en' || tempLang == 'both'"
                  >
                    <!--Input Wrapper-->
                    <div class="input_wrapper reg_style">
                      <!--First Name-->
                      <input
                        type="text"
                        class="form-control"
                        v-model="data.firstName_en"
                        :placeholder="$t('register.data.firstNameEn')"
                      />
                      <!--First Name-->
                    </div>
                    <!--Input Wrapper-->
                  </div>

                  <div
                    class="col-md-6"
                    v-if="tempLang == 'ar' || tempLang == 'both'"
                  >
                    <!--Input Wrapper-->
                    <div class="input_wrapper reg_style">
                      <!--Last Name-->
                      <input
                        type="text"
                        class="form-control"
                        v-model="data.lastName"
                        @keypress="isLetter($event)"
                        :placeholder="$t('register.data.lastName')"
                      />
                      <!--Last Name-->
                    </div>
                    <!--Input Wrapper-->
                  </div>

                  <div
                    class="col-md-6"
                    v-if="tempLang == 'en' || tempLang == 'both'"
                  >
                    <!--Input Wrapper-->
                    <div class="input_wrapper reg_style">
                      <!--Last Name-->
                      <input
                        type="text"
                        class="form-control"
                        v-model="data.lastName_en"
                        :placeholder="$t('register.data.lastNameEn')"
                      />
                      <!--Last Name-->
                    </div>
                    <!--Input Wrapper-->
                  </div>

                  <div class="col-md-6">
                    <!--Input Wrapper-->
                    <div class="wrapper_flex">
                      <vue-country-code
                        @onSelect="onSelect"
                        :preferredCountries="['vn', 'us', 'gb']"
                      >
                      </vue-country-code>
                      <div class="input_wrapper reg_style phone">
                        <!--Phone-->
                        <input
                          type="number"
                          class="form-control"
                          id="inputBox"
                          @keydown="blockChar"
                          v-model="data.phone"
                          :placeholder="$t('register.data.phone')"
                          pattern="/^-?\d+\.?\d*$/"
                          onKeyPress="if(this.value.length == 9) {
                        return false;
                      }"
                          min="0"
                        />
                        <!--Phone-->
                      </div>
                    </div>
                    <!--Input Wrapper-->
                  </div>

                  <div class="col-md-6">
                    <!--Input Wrapper-->
                    <div class="input_wrapper reg_style">
                      <!--Email-->
                      <input
                        type="email"
                        class="form-control"
                        v-model="data.email"
                        :placeholder="$t('register.data.email')"
                      />
                      <!--Email-->
                    </div>
                    <!--Input Wrapper-->
                  </div>

                  <div
                    class="col-md-6"
                    v-if="tempLang == 'ar' || tempLang == 'both'"
                  >
                    <!--Input Wrapper-->
                    <div class="input_wrapper reg_style">
                      <!--Job Title-->
                      <input
                        type="text"
                        class="form-control"
                        v-model="data.jobTitle"
                        @keypress="isLetter($event)"
                        :placeholder="$t('register.data.jobTitle')"
                      />
                      <!--Job Title-->
                    </div>
                    <!--Input Wrapper-->
                  </div>

                  <div
                    class="col-md-6"
                    v-if="tempLang == 'en' || tempLang == 'both'"
                  >
                    <!--Input Wrapper-->
                    <div class="input_wrapper reg_style">
                      <!--Job Title-->
                      <input
                        type="text"
                        class="form-control"
                        v-model="data.jobTitle_en"
                        :placeholder="$t('register.data.jobTitleEn')"
                      />
                      <!--Job Title-->
                    </div>
                    <!--Input Wrapper-->
                  </div>

                  <div
                    class="col-md-6"
                    v-if="tempLang == 'ar' || tempLang == 'both'"
                  >
                    <!--Input Wrapper-->
                    <div class="input_wrapper reg_style">
                      <!--Address-->
                      <input
                        type="text"
                        class="form-control"
                        v-model="data.address"
                        @keypress="isLetter($event)"
                        :placeholder="$t('register.data.address')"
                      />
                      <!--Address-->
                    </div>
                    <!--Input Wrapper-->
                  </div>

                  <div
                    class="col-md-6"
                    v-if="tempLang == 'en' || tempLang == 'both'"
                  >
                    <!--Input Wrapper-->
                    <div class="input_wrapper reg_style">
                      <!--Address-->
                      <input
                        type="text"
                        class="form-control"
                        v-model="data.address_en"
                        :placeholder="$t('register.data.addressEn')"
                      />
                      <!--Address-->
                    </div>
                    <!--Input Wrapper-->
                  </div>

                  <div class="col-md-6">
                    <div class="input_wrapper reg_style file_upload">
                      <!--Upload Cv-->
                      <div class="form-control">
                        <span v-if="uploadImage.beforeUploadFile">
                          {{ uploadImage.image }}</span
                        >
                        <span v-if="uploadImage.afterUploadFile">{{
                          uploadImage.image.name
                        }}</span>
                      </div>
                      <input
                        type="file"
                        name="chooseFile"
                        @change="uploadImageFile"
                        accept="image/*"
                      />
                      <!--Upload Cv-->
                    </div>
                  </div>

                  <template v-if="tempNum == 'three'">
                    <div class="col-md-6">
                      <div class="choice_list">
                        <div class="check_box">
                          <input
                            type="radio"
                            id="type5"
                            name="marital"
                            value="male"
                            v-model="data.status"
                          /><label for="type5">{{ $t("single") }}</label>
                        </div>
                        <div class="check_box">
                          <input
                            type="radio"
                            id="type6"
                            name="marital"
                            value="female"
                            v-model="data.status"
                          /><label for="type6">{{ $t("married") }}</label>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <!--Input Wrapper-->
                      <div class="input_wrapper reg_style">
                        <!--Address-->
                        <input
                          type="text"
                          class="form-control"
                          v-model="data.twitter"
                          :placeholder="$t('register.data.twitter')"
                        />
                        <!--Address-->
                      </div>
                      <!--Input Wrapper-->
                    </div>

                    <div class="col-md-6">
                      <!--Input Wrapper-->
                      <div class="input_wrapper reg_style">
                        <!--Address-->
                        <input
                          type="text"
                          class="form-control"
                          v-model="data.linkedin"
                          :placeholder="$t('register.data.linkedin')"
                        />
                        <!--Address-->
                      </div>
                      <!--Input Wrapper-->
                    </div>
                  </template>
                </div>
                <!--Inputs-->
              </div>
              <!--Block Inputs Personal-->

              <!--Block Inputs Berif-->
              <div class="block_inputs">
                <!--Title-->
                <div class="block_title">
                  <h4>{{ $t("cvsteps.wizard.breif") }} . 2</h4>
                </div>
                <!--Title-->
                <!--Inputs-->
                <div class="row">
                  <div
                    class="col-sm-12"
                    v-if="tempLang == 'ar' || tempLang == 'both'"
                  >
                    <!--Input Wrapper-->
                    <div class="input_wrapper reg_style">
                      <!--About Me-->
                      <textarea
                        class="form-control"
                        v-model="data.about"
                        @keypress="isLetter($event)"
                        :placeholder="$t('register.data.breif')"
                      ></textarea>
                      <!--About Me-->
                    </div>
                    <!--Input Wrapper-->
                  </div>

                  <div
                    class="col-sm-12"
                    v-if="tempLang == 'en' || tempLang == 'both'"
                  >
                    <!--Input Wrapper-->
                    <div class="input_wrapper reg_style">
                      <!--About Me-->
                      <textarea
                        class="form-control"
                        v-model="data.about_en"
                        @keypress="isLetter($event)"
                        :placeholder="$t('register.data.breifEn')"
                      ></textarea>
                      <!--About Me-->
                    </div>
                    <!--Input Wrapper-->
                  </div>
                </div>
                <!--Inputs-->
              </div>
              <!--Block Inputs Berif-->

              <!--Block Inputs Experience-->
              <div class="block_inputs">
                <!--Title-->
                <div class="block_title">
                  <h4>{{ $t("cvsteps.wizard.experience") }} . 3</h4>
                </div>
                <!--Title-->
                <!--Inputs-->
                <div
                  class="row"
                  v-for="(exp, index) in Experiences"
                  :key="exp.id"
                  :id="`exp_${exp.id}`"
                >
                  <div class="col-md-12">
                    <h4>{{ `${index + 1}` }}</h4>
                  </div>
                  <div
                    class="col-md-6"
                    v-if="tempLang == 'ar' || tempLang == 'both'"
                  >
                    <!--Input Wrapper-->
                    <div class="input_wrapper reg_style">
                      <!--Job Title-->
                      <input
                        type="text"
                        class="form-control"
                        v-model="Experiences[index].companyName"
                        @keypress="isLetter($event)"
                        :placeholder="$t('register.data.companyName')"
                      />
                      <!--Job Title-->
                    </div>
                    <!--Input Wrapper-->
                  </div>

                  <div
                    class="col-md-6"
                    v-if="tempLang == 'en' || tempLang == 'both'"
                  >
                    <!--Input Wrapper-->
                    <div class="input_wrapper reg_style">
                      <!--Job Title-->
                      <input
                        type="text"
                        class="form-control"
                        v-model="Experiences[index].companyNameEn"
                        :placeholder="$t('register.data.companyNameEn')"
                      />
                      <!--Job Title-->
                    </div>
                    <!--Input Wrapper-->
                  </div>

                  <div
                    class="col-md-6"
                    v-if="tempLang == 'ar' || tempLang == 'both'"
                  >
                    <!--Input Wrapper-->
                    <div class="input_wrapper reg_style">
                      <!--Job Title-->
                      <input
                        type="text"
                        class="form-control"
                        v-model="Experiences[index].jobTitle"
                        @keypress="isLetter($event)"
                        :placeholder="$t('register.data.jobTitle')"
                      />
                      <!--Job Title-->
                    </div>
                    <!--Input Wrapper-->
                  </div>

                  <div
                    class="col-md-6"
                    v-if="tempLang == 'en' || tempLang == 'both'"
                  >
                    <!--Input Wrapper-->
                    <div class="input_wrapper reg_style">
                      <!--Job Title-->
                      <input
                        type="text"
                        class="form-control"
                        v-model="Experiences[index].jobTitleEn"
                        :placeholder="$t('register.data.jobTitleEn')"
                      />
                      <!--Job Title-->
                    </div>
                    <!--Input Wrapper-->
                  </div>

                  <div class="col-md-12">
                    <!--Input Wrapper-->
                    <div class="wrapper_flex profile_wrapper mb-10">
                      <span class="profile_label">{{
                        $t("rateCv.data.stillThere")
                      }}</span>
                      <div class="choice_list">
                        <div class="check_box">
                          <input
                            type="radio"
                            :id="'still' + index"
                            name="jj"
                            v-model="Experiences[index].is_still"
                            value="true"
                          />
                          <label :for="'still' + index">{{ $t("yes") }}</label>
                        </div>
                        <div class="check_box">
                          <input
                            type="radio"
                            :id="'nostill' + index"
                            name="jj"
                            v-model="Experiences[index].is_still"
                            value="false"
                          />
                          <label :for="'nostill' + index">{{ $t("no") }}</label>
                        </div>
                      </div>
                    </div>
                    <!--Input Wrapper-->
                  </div>

                  <div class="col-md-6">
                    <!--Input Wrapper-->
                    <div class="input_wrapper reg_style">
                      <!--Start Date-->
                      <Datepicker
                        :format="formatDate"
                        class="form-control"
                        v-model="Experiences[index].startDate"
                        :placeholder="$t('register.data.startDate')"
                      ></Datepicker>
                      <!--Start Date-->
                    </div>
                    <!--Input Wrapper-->
                  </div>

                  <div class="col-md-6">
                    <!--Input Wrapper-->
                    <div class="input_wrapper reg_style">
                      <!--End Date-->
                      <Datepicker
                        :format="formatDate"
                        class="form-control"
                        v-model="Experiences[index].endDate"
                        :disabledDates="{
                          to: new Date(Experiences[index].startDate - 8640000),
                        }"
                        :placeholder="$t('register.data.endDate')"
                      ></Datepicker>
                      <!--End Date-->
                    </div>
                    <!--Input Wrapper-->
                  </div>

                  <div
                    class="col-md-12"
                    v-if="tempLang == 'ar' || tempLang == 'both'"
                  >
                    <!--Input Wrapper-->
                    <div class="input_wrapper reg_style">
                      <!--Breif Job-->
                      <input
                        type="text"
                        class="form-control"
                        v-model="Experiences[index].breifJob"
                        @keypress="isLetter($event)"
                        :placeholder="$t('register.data.breifJob')"
                      />
                      <!--Breif Job-->
                    </div>
                    <!--Input Wrapper-->
                  </div>

                  <div
                    class="col-md-12"
                    v-if="tempLang == 'en' || tempLang == 'both'"
                  >
                    <!--Input Wrapper-->
                    <div class="input_wrapper reg_style">
                      <!--Breif Job-->
                      <input
                        type="text"
                        class="form-control"
                        v-model="Experiences[index].breifJobEn"
                        @keypress="isLetter($event)"
                        :placeholder="$t('register.data.breifJob')"
                      />
                      <!--Breif Job-->
                    </div>
                    <!--Input Wrapper-->
                  </div>
                </div>

                <!--Add New Button-->
                <button class="trans_btn" @click="addExperience">
                  <i class="fal fa-plus"></i>
                  <span>{{ $t("cvsteps.data.addNewExp") }}</span>
                </button>
                <!--Add New Button-->
                <!--Inputs-->
              </div>
              <!--Block Experience-->

              <!--Block Inputs Education-->
              <div class="block_inputs">
                <!--Title-->
                <div class="block_title">
                  <h4>{{ $t("cvsteps.wizard.education") }} . 4</h4>
                </div>
                <!--Title-->
                <!--Inputs-->
                <div
                  class="row"
                  v-for="(edu, index) in Education"
                  :key="edu.id"
                  :id="`edu_${edu.id}`"
                >
                  <div class="col-md-12">
                    <h4>{{ `${index + 1}` }}</h4>
                  </div>
                  <div
                    class="col-md-6"
                    v-if="tempLang == 'ar' || tempLang == 'both'"
                  >
                    <!--Input Wrapper-->
                    <div class="input_wrapper reg_style">
                      <!--Job Title-->
                      <input
                        type="text"
                        class="form-control"
                        v-model="Education[index].universityName"
                        @keypress="isLetter($event)"
                        :placeholder="$t('register.data.universityName')"
                      />
                      <!--Job Title-->
                    </div>
                    <!--Input Wrapper-->
                  </div>

                  <div
                    class="col-md-6"
                    v-if="tempLang == 'en' || tempLang == 'both'"
                  >
                    <!--Input Wrapper-->
                    <div class="input_wrapper reg_style">
                      <!--Job Title-->
                      <input
                        type="text"
                        class="form-control"
                        v-model="Education[index].universityNameEn"
                        :placeholder="$t('register.data.universityNameEn')"
                      />
                      <!--Job Title-->
                    </div>
                    <!--Input Wrapper-->
                  </div>

                  <div
                    class="col-md-6"
                    v-if="tempLang == 'ar' || tempLang == 'both'"
                  >
                    <!--Input Wrapper-->
                    <div class="input_wrapper reg_style">
                      <!--Job Title-->
                      <input
                        type="text"
                        class="form-control"
                        v-model="Education[index].departmentName"
                        @keypress="isLetter($event)"
                        :placeholder="$t('register.data.departmentName')"
                      />
                      <!--Job Title-->
                    </div>
                    <!--Input Wrapper-->
                  </div>

                  <div
                    class="col-md-6"
                    v-if="tempLang == 'en' || tempLang == 'both'"
                  >
                    <!--Input Wrapper-->
                    <div class="input_wrapper reg_style">
                      <!--Job Title-->
                      <input
                        type="text"
                        class="form-control"
                        v-model="Education[index].departmentNameEn"
                        :placeholder="$t('register.data.departmentNameEn')"
                      />
                      <!--Job Title-->
                    </div>
                    <!--Input Wrapper-->
                  </div>

                  <div class="col-md-12">
                    <!--Input Wrapper-->
                    <div class="wrapper_flex profile_wrapper mb-10">
                      <span class="profile_label">{{
                        $t("rateCv.data.stillThere")
                      }}</span>
                      <div class="choice_list">
                        <div class="check_box">
                          <input
                            type="radio"
                            :id="'edstill' + index"
                            name="j"
                            v-model="Education[index].is_still"
                            value="true"
                          />
                          <label :for="'edstill' + index">{{
                            $t("yes")
                          }}</label>
                        </div>
                        <div class="check_box">
                          <input
                            type="radio"
                            :id="'ednostill' + index"
                            name="j"
                            v-model="Education[index].is_still"
                            value="false"
                          />
                          <label :for="'ednostill' + index">{{
                            $t("no")
                          }}</label>
                        </div>
                      </div>
                    </div>
                    <!--Input Wrapper-->
                  </div>

                  <div class="col-md-6">
                    <!--Input Wrapper-->
                    <div class="input_wrapper reg_style">
                      <!--Start Date-->
                      <Datepicker
                        :format="formatDate"
                        class="form-control"
                        v-model="Education[index].startDate"
                        :placeholder="$t('register.data.startDate')"
                      ></Datepicker>
                      <!--Start Date-->
                    </div>
                    <!--Input Wrapper-->
                  </div>

                  <div class="col-md-6">
                    <!--Input Wrapper-->
                    <div class="input_wrapper reg_style">
                      <!--End Date-->
                      <Datepicker
                        class="form-control"
                        :format="formatDate"
                        v-model="Education[index].endDate"
                        :disabledDates="{
                          to: new Date(Education[index].startDate - 8640000),
                        }"
                        :placeholder="$t('register.data.endDate')"
                      ></Datepicker>
                      <!--End Date-->
                    </div>
                    <!--Input Wrapper-->
                  </div>

                  <div
                    class="col-md-12"
                    v-if="tempLang == 'ar' || tempLang == 'both'"
                  >
                    <!--Input Wrapper-->
                    <div class="input_wrapper reg_style">
                      <!--Breif Job-->
                      <input
                        type="text"
                        class="form-control"
                        v-model="Education[index].desc"
                        @keypress="isLetter($event)"
                        :placeholder="$t('register.data.desc')"
                      />
                      <!--Breif Job-->
                    </div>
                    <!--Input Wrapper-->
                  </div>

                  <div
                    class="col-md-12"
                    v-if="tempLang == 'en' || tempLang == 'both'"
                  >
                    <!--Input Wrapper-->
                    <div class="input_wrapper reg_style">
                      <!--Breif Job-->
                      <input
                        type="text"
                        class="form-control"
                        v-model="Education[index].descEn"
                        @keypress="isLetter($event)"
                        :placeholder="$t('register.data.descEn')"
                      />
                      <!--Breif Job-->
                    </div>
                    <!--Input Wrapper-->
                  </div>

                  <div class="col-md-12">
                    <!--Input Wrapper-->
                    <div class="input_wrapper reg_style">
                      <!--Breif Job-->
                      <input
                        type="text"
                        class="form-control"
                        v-model="Education[index].descEn"
                        @keypress="isLetter($event)"
                        :placeholder="$t('register.data.descEn')"
                      />
                      <!--Breif Job-->
                    </div>
                    <!--Input Wrapper-->
                  </div>
                </div>

                <!--Add New Button-->
                <button class="trans_btn" @click="addEducation">
                  <i class="fal fa-plus"></i>
                  <span>{{ $t("cvsteps.data.addNewExp") }}</span>
                </button>
                <!--Add New Button-->
                <!--Inputs-->
              </div>
              <!--Block Education-->

              <!--Block Inputs Berif-->
              <div
                class="block_inputs"
                v-if="tempNum == 'one' || tempNum == 'two'"
              >
                <!--Title-->
                <div class="block_title">
                  <h4>{{ $t("cvsteps.wizard.skills") }} . 5</h4>
                </div>
                <!--Title-->
                <!--Inputs-->
                <div class="row">
                  <div
                    class="col-sm-12"
                    v-for="(skill, index) in Skills"
                    :key="skill.id"
                    :id="`skill_${skill.id}`"
                  >
                    <div class="col-md-12">
                      <h4>{{ `${index + 1}` }}</h4>
                    </div>

                    <!--Input Wrapper-->
                    <div
                      class="input_wrapper reg_style"
                      v-if="tempLang == 'ar' || tempLang == 'both'"
                    >
                      <!--Skills-->
                      <input
                        class="form-control"
                        type="text"
                        v-model="Skills[index].skill"
                        @keypress="isLetter($event)"
                        :placeholder="$t('register.data.skill')"
                      />
                      <!--Skills-->
                    </div>
                    <!--Input Wrapper-->

                    <!--Input Wrapper-->
                    <div
                      class="input_wrapper reg_style"
                      v-if="tempLang == 'en' || tempLang == 'both'"
                    >
                      <!--Skills-->
                      <input
                        class="form-control"
                        type="text"
                        v-model="Skills[index].skillEn"
                        @keypress="isLetter($event)"
                        :placeholder="$t('register.data.skill')"
                      />
                      <!--Skills-->
                    </div>
                    <!--Input Wrapper-->
                  </div>
                </div>
                <!--Inputs-->

                <!--Add New Button-->
                <button class="trans_btn" @click="addSkill">
                  <i class="fal fa-plus"></i>
                  <span>{{ $t("cvsteps.data.addNewSikll") }}</span>
                </button>
                <!--Add New Button-->
              </div>
              <!--Block Inputs Berif-->

              <template v-if="tempNum == 'three'">
                <!--Block Inputs Langs-->
                <div class="block_inputs">
                  <!--Title-->
                  <div class="block_title">
                    <h4>6. {{ $t("cvsteps.wizard.lang") }}</h4>
                  </div>
                  <!--Title-->

                  <!--Sub Title-->
                  <div class="sub_title">
                    <h4>{{ $t("cvsteps.wizard.subTitles.personalSkills") }}</h4>
                  </div>
                  <!--Sub Title-->

                  <!--Skill Item-->
                  <div class="skill_item">
                    <span
                      >{{ $t("cvsteps.wizard.subTitles.communicateSkill") }} ({{
                        data.skill
                      }}
                      %)</span
                    >
                    <input
                      class="range"
                      type="range"
                      v-model="data.skill"
                      min="0"
                      max="100"
                    />
                  </div>
                  <!--Skill Item-->

                  <!--Skill Item-->
                  <div class="skill_item">
                    <span
                      >{{ $t("cvsteps.wizard.subTitles.teamMangement") }} ({{
                        data.team
                      }}
                      %)</span
                    >
                    <input
                      class="range"
                      type="range"
                      v-model="data.team"
                      min="0"
                      max="100"
                    />
                  </div>
                  <!--Skill Item-->

                  <!--Skill Item-->
                  <div class="skill_item">
                    <span
                      >{{ $t("cvsteps.wizard.subTitles.timeMangement") }} ({{
                        data.time
                      }}
                      %)</span
                    >
                    <input
                      class="range"
                      type="range"
                      v-model="data.time"
                      min="0"
                      max="100"
                    />
                  </div>
                  <!--Skill Item-->

                  <div class="row">
                    <div class="col-md-6">
                      <!--Langs Level-->
                      <div class="langs_levels">
                        <!--Level Item-->
                        <div class="level_item">
                          <h5>{{ $t("cvsteps.wizard.langs.lang1") }}</h5>
                          <span>({{ $t("cvsteps.wizard.langs.desc") }})</span>
                          <div class="choice_list">
                            <div class="check_box">
                              <input
                                type="radio"
                                id="ar_level1"
                                name="ar_lang"
                                value="1"
                                v-model="data.lang"
                              />
                            </div>
                            <div class="check_box">
                              <input
                                type="radio"
                                id="ar_level2"
                                name="ar_lang"
                                value="2"
                                v-model="data.lang"
                              />
                            </div>
                            <div class="check_box">
                              <input
                                type="radio"
                                id="ar_level3"
                                name="ar_lang"
                                value="3"
                                v-model="data.lang"
                              />
                            </div>
                            <div class="check_box">
                              <input
                                type="radio"
                                id="ar_level4"
                                name="ar_lang"
                                value="4"
                                v-model="data.lang"
                              />
                            </div>
                          </div>
                        </div>
                        <!--Level Item-->
                      </div>
                      <!--Langs Level-->
                    </div>
                    <div class="col-md-6">
                      <!--Langs Level-->
                      <div class="langs_levels">
                        <!--Level Item-->
                        <div class="level_item">
                          <h5>{{ $t("cvsteps.wizard.langs.lang2") }}</h5>
                          <span>({{ $t("cvsteps.wizard.langs.desc") }})</span>
                          <div class="choice_list">
                            <div class="check_box">
                              <input
                                type="radio"
                                id="en_level1"
                                name="en_lang"
                                value="1"
                                v-model="data.lang2"
                              />
                            </div>
                            <div class="check_box">
                              <input
                                type="radio"
                                id="en_level2"
                                name="en_lang"
                                value="2"
                                v-model="data.lang2"
                              />
                            </div>
                            <div class="check_box">
                              <input
                                type="radio"
                                id="en_level3"
                                name="en_lang"
                                value="3"
                                v-model="data.lang2"
                              />
                            </div>
                            <div class="check_box">
                              <input
                                type="radio"
                                id="en_level4"
                                name="en_lang"
                                value="4"
                                v-model="data.lang2"
                              />
                            </div>
                          </div>
                        </div>
                        <!--Level Item-->
                      </div>
                      <!--Langs Level-->
                    </div>
                  </div>

                  <!--Time Mangement-->
                  <div class="time_mangement">
                    <h5>{{ $t("cvsteps.wizard.timeMangement.title") }}</h5>
                    <!--Mangement Item-->
                    <div class="mangement_item">
                      <h4>{{ $t("cvsteps.wizard.timeMangement.work") }}</h4>
                      <!--Input Wrapper-->
                      <div class="input_wrapper reg_style phone">
                        <!--Phone-->
                        <input
                          type="number"
                          class="form-control"
                          v-model="data.work"
                          :placeholder="
                            $t('cvsteps.wizard.timeMangement.workDesc')
                          "
                          pattern="/^-?\d+\.?\d*$/"
                          onKeyPress="if(this.value.length == 2) {
                        return false;
                      }"
                          min="0"
                          oninput="
                        this.value =
                          !!this.value && Math.abs(this.value) >= 0
                            ? Math.abs(this.value)
                            : null
                      "
                        />
                        <!--Phone-->
                      </div>
                      <!--Input Wrapper-->
                      <span>%</span>
                    </div>
                    <!--Mangement Item-->

                    <!--Mangement Item-->
                    <div class="mangement_item">
                      <h4>{{ $t("cvsteps.wizard.timeMangement.family") }}</h4>
                      <!--Input Wrapper-->
                      <div class="input_wrapper reg_style phone">
                        <!--Phone-->
                        <input
                          type="number"
                          class="form-control"
                          v-model="data.family"
                          :placeholder="
                            $t('cvsteps.wizard.timeMangement.familyDesc')
                          "
                          pattern="/^-?\d+\.?\d*$/"
                          onKeyPress="if(this.value.length == 2) {
                        return false;
                      }"
                          min="0"
                          oninput="
                        this.value =
                          !!this.value && Math.abs(this.value) >= 0
                            ? Math.abs(this.value)
                            : null
                      "
                        />
                        <!--Phone-->
                      </div>
                      <!--Input Wrapper-->
                      <span>%</span>
                    </div>
                    <!--Mangement Item-->

                    <!--Mangement Item-->
                    <div class="mangement_item">
                      <h4>{{ $t("cvsteps.wizard.timeMangement.enjoy") }}</h4>
                      <!--Input Wrapper-->
                      <div class="input_wrapper reg_style phone">
                        <!--Phone-->
                        <input
                          type="number"
                          class="form-control"
                          v-model="data.enjoy"
                          :placeholder="
                            $t('cvsteps.wizard.timeMangement.enjoyDesc')
                          "
                          pattern="/^-?\d+\.?\d*$/"
                          onKeyPress="if(this.value.length == 2) {
                        return false;
                      }"
                          min="0"
                          oninput="
                        this.value =
                          !!this.value && Math.abs(this.value) >= 0
                            ? Math.abs(this.value)
                            : null
                      "
                        />
                        <!--Phone-->
                      </div>
                      <!--Input Wrapper-->
                      <span>%</span>
                    </div>
                    <!--Mangement Item-->
                  </div>
                  <!--Time Mangement-->
                </div>
                <!--Block Inputs Langs-->

                <!--Block Inputs Hobbies-->
                <div class="block_inputs">
                  <!--Title-->
                  <div class="block_title">
                    <h4>7 . {{ $t("cvsteps.wizard.hobbies.title") }}</h4>
                    <p>{{ $t("cvsteps.wizard.hobbies.desc") }}</p>
                    <div class="row">
                      <div
                        class="col-md-2"
                        v-for="interest in interests"
                        :key="interest.id"
                      >
                        <div class="check_box">
                          <input
                            type="checkbox"
                            :id="'hob' + interest.id"
                            v-model="data.hobbies"
                            :value="interest.id"
                          /><label :for="'hob' + interest.id">{{
                            interest.name
                          }}</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--Title-->
                </div>
                <!--Block Inputs Hobbies-->
              </template>

              <hr class="line" />

              <!--Next Button-->
              <button
                type="button"
                class="main_button button"
                @click="nextStep()"
                ref="next"
              >
                <i class="fas fa-spinner"></i>
                <span>{{ $t("next") }}</span>
              </button>
              <!--Next Button-->
            </div>
          </template>
          <!--Fill Data-->

          <!--Cv Data-->
          <template v-if="step == 4">
            <vue-html2pdf
              :show-layout="false"
              :float-layout="true"
              :enable-download="true"
              :preview-modal="true"
              :paginate-elements-by-height="1400"
              filename="cv"
              :pdf-quality="2"
              :manual-pagination="false"
              pdf-format="a4"
              :pdf-margin="10"
              pdf-orientation="portrait"
              pdf-content-width="800px"
              @progress="onProgress($event)"
              ref="html2Pdf"
            >
              <section slot="pdf-content">
                <template-one
                  :item="cvData"
                  v-if="tempNum == 'one' && tempLang == 'ar'"
                ></template-one>

                <template-one-en
                  :item="cvData"
                  v-if="tempNum == 'one' && tempLang == 'en'"
                ></template-one-en>

                <template-four
                  :item="cvData"
                  v-if="tempNum == 'two' && tempLang == 'ar'"
                ></template-four>

                <template-four-en
                  :item="cvData"
                  v-if="tempNum == 'two' && tempLang == 'en'"
                ></template-four-en>

                <template-three
                  :item="cvData"
                  v-if="tempNum == 'three' && tempLang == 'ar'"
                ></template-three>

                <template-three-en
                  :item="cvData"
                  v-if="tempNum == 'three' && tempLang == 'en'"
                ></template-three-en>
              </section>
            </vue-html2pdf>

            <template-one
              :item="cvData"
              v-if="tempNum == 'one' && tempLang == 'ar'"
            ></template-one>
            <button
              type="button"
              class="main_button button mt-3"
              @click="generatePDF()"
              v-if="tempNum == 'one' && tempLang == 'ar'"
            >
              <span>{{ $t("download") }}</span>
            </button>

            <template-one-en
              :item="cvData"
              v-if="tempNum == 'one' && tempLang == 'en'"
            ></template-one-en>
            <button
              type="button"
              class="main_button button mt-3"
              @click="generatePDF()"
              v-if="tempNum == 'one' && tempLang == 'en'"
            >
              <span>{{ $t("download") }}</span>
            </button>

            <template-four
              :item="cvData"
              v-if="tempNum == 'two' && tempLang == 'ar'"
            ></template-four>
            <button
              type="button"
              class="main_button button mt-3"
              @click="generatePDF()"
              v-if="tempNum == 'two' && tempLang == 'ar'"
            >
              <span>{{ $t("download") }}</span>
            </button>

            <template-four-en
              :item="cvData"
              v-if="tempNum == 'two' && tempLang == 'en'"
            ></template-four-en>
            <button
              type="button"
              class="main_button button mt-3"
              @click="generatePDF()"
              v-if="tempNum == 'two' && tempLang == 'en'"
            >
              <span>{{ $t("download") }}</span>
            </button>

            <template-three
              :item="cvData"
              v-if="tempNum == 'three' && tempLang == 'ar'"
            ></template-three>

            <button
              type="button"
              class="main_button button mt-3"
              @click="generatePDF()"
              v-if="tempNum == 'three' && tempLang == 'ar'"
            >
              <span>{{ $t("download") }}</span>
            </button>

            <template-three-en
              :item="cvData"
              v-if="tempNum == 'three' && tempLang == 'en'"
            ></template-three-en>

            <button
              type="button"
              class="main_button button mt-3"
              @click="generatePDF()"
              v-if="tempNum == 'three' && tempLang == 'en'"
            >
              <span>{{ $t("download") }}</span>
            </button>

            <!--Both-->
            <vue-html2pdf
              :show-layout="false"
              :float-layout="true"
              :enable-download="true"
              :preview-modal="true"
              :paginate-elements-by-height="1400"
              filename="cv"
              :pdf-quality="2"
              :manual-pagination="false"
              pdf-format="a4"
              :pdf-margin="10"
              pdf-orientation="portrait"
              pdf-content-width="800px"
              @progress="onProgress_ar($event)"
              ref="html2Pdf_ar"
            >
              <section slot="pdf-content">
                <template-one
                  :item="cvData"
                  v-if="tempNum == 'one' && tempLang == 'both'"
                ></template-one>

                <template-four
                  :item="cvData"
                  v-if="tempNum == 'two' && tempLang == 'both'"
                ></template-four>

                <template-three
                  :item="cvData"
                  v-if="tempNum == 'three' && tempLang == 'both'"
                ></template-three>
              </section>
            </vue-html2pdf>
            <vue-html2pdf
              :show-layout="false"
              :float-layout="true"
              :enable-download="true"
              :preview-modal="true"
              :paginate-elements-by-height="1400"
              filename="cv"
              :pdf-quality="2"
              :manual-pagination="false"
              pdf-format="a4"
              :pdf-margin="10"
              pdf-orientation="portrait"
              pdf-content-width="800px"
              @progress="onProgress_en($event)"
              ref="html2Pdf_en"
            >
              <section slot="pdf-content">
                <template-one-en
                  :item="cvData"
                  v-if="tempNum == 'one' && tempLang == 'both'"
                ></template-one-en>

                <template-four-en
                  :item="cvData"
                  v-if="tempNum == 'two' && tempLang == 'both'"
                ></template-four-en>

                <template-three-en
                  :item="cvData"
                  v-if="tempNum == 'three' && tempLang == 'both'"
                ></template-three-en>
              </section>
            </vue-html2pdf>

            <template-one
              :item="cvData"
              v-if="tempNum == 'one' && tempLang == 'both'"
            ></template-one>
            <button
              type="button"
              class="main_button button mt-3"
              @click="generatePDF_ar()"
              v-if="tempNum == 'one' && tempLang == 'both'"
            >
              <span>{{ $t("download") }}</span>
            </button>

            <template-one-en
              :item="cvData"
              v-if="tempNum == 'one' && tempLang == 'both'"
            ></template-one-en>
            <button
              type="button"
              class="main_button button mt-3"
              @click="generatePDF_en()"
              v-if="tempNum == 'one' && tempLang == 'both'"
            >
              <span>{{ $t("download") }}</span>
            </button>

            <template-four
              :item="cvData"
              v-if="tempNum == 'two' && tempLang == 'both'"
            ></template-four>
            <button
              type="button"
              class="main_button button mt-3"
              @click="generatePDF_ar()"
              v-if="tempNum == 'two' && tempLang == 'both'"
            >
              <span>{{ $t("download") }}</span>
            </button>

            <template-four-en
              :item="cvData"
              v-if="tempNum == 'two' && tempLang == 'both'"
            ></template-four-en>
            <button
              type="button"
              class="main_button button mt-3"
              @click="generatePDF_en()"
              v-if="tempNum == 'two' && tempLang == 'both'"
            >
              <span>{{ $t("download") }}</span>
            </button>

            <template-three
              :item="cvData"
              v-if="tempNum == 'three' && tempLang == 'both'"
            ></template-three>

            <button
              type="button"
              class="main_button button mt-3"
              @click="generatePDF_ar()"
              v-if="tempNum == 'three' && tempLang == 'both'"
            >
              <span>{{ $t("download") }}</span>
            </button>

            <template-three-en
              :item="cvData"
              v-if="tempNum == 'three' && tempLang == 'both'"
            ></template-three-en>

            <button
              type="button"
              class="main_button button mt-3"
              @click="generatePDF_en()"
              v-if="tempNum == 'three' && tempLang == 'both'"
            >
              <span>{{ $t("download") }}</span>
            </button>
          </template>
          <!--Cv Data-->
        </div>
      </div>
    </div>
    <!--Cv Steps Page-->

    <!--Payment Model-->
    <base-model :show="show" @closeModel="closeModel">
      <div class="custom_card">
        <form id="form-container" method="post" action="/charge">
          <!-- Tap element will be here -->
          <div id="element-container"></div>
          <div id="error-handler" role="alert"></div>
          <!-- <div
            id="success"
            style="display: none; position: relative; float: left"
          >
            Success! Your token is <span id="token"></span>
          </div> -->
          <!-- Tap pay button -->
          <!-- <button id="tap-btn" @click="upload">{{ $t("pay") }}</button> -->
          <button
            class="main_button button"
            id="tap-btn"
            ref="fill"
            @click="fillData"
          >
            <i class="fas fa-spinner"></i>
            <span>{{ $t("pay") }}</span>
          </button>
        </form>
      </div>
    </base-model>
    <!--Payment Model-->
  </div>
</template>

<script src="https://cdnjs.cloudflare.com/ajax/libs/bluebird/3.3.4/bluebird.min.js"></script>
<script src="https://secure.gosell.io/js/sdk/tap.min.js"></script>
<script>
import CvTemplate from "@/components/CvCard/CvTemplate.vue";
import Datepicker from "vuejs-datepicker";
import TemplateOne from "../../../components/CvTemplate/TemplateOne.vue";
import TemplateTwo from "../../../components/CvTemplate/TemplateTwo.vue";
import TemplateTwoEn from "../../../components/CvTemplate/TemplateTwoEn.vue";
import TemplateThree from "../../../components/CvTemplate/TemplateThree.vue";
import TemplateThreeEn from "../../../components/CvTemplate/TemplateThreeEn.vue";
import TemplateFour from "../../../components/CvTemplate/TemplateFour.vue";
import TemplateFourEn from "../../../components/CvTemplate/TemplateFourEn.vue";
import VueHtml2pdf from "vue-html2pdf";
import * as moment from "moment/moment";
export default {
  props: ["tempLang", "id"],
  components: {
    CvTemplate,
    Datepicker,
    TemplateOne,
    TemplateTwo,
    TemplateTwoEn,
    TemplateThree,
    TemplateThreeEn,
    TemplateFour,
    TemplateFourEn,
    VueHtml2pdf,
  },
  data() {
    return {
      //Progress Step
      // step: 1,
      //Progress Step

      //Experiences
      Experiences: [
        {
          id: (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1),
          companyName: null,
          companyNameEn: null,
          jobTitle: null,
          jobTitleEn: null,
          startDate: null,
          is_still: false,
          endDate: null,
          breifJob: null,
          breifJobEn: null,
        },
      ],
      //Experiences

      //Education
      Education: [
        {
          id: (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1),
          universityName: null,
          universityNameEn: null,
          departmentName: null,
          departmentNameEn: null,
          is_still: false,
          startDate: null,
          endDate: null,
          desc: null,
          descEn: null,
        },
      ],
      //Education

      //Skills
      Skills: [
        {
          id: (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1),
          skill: null,
          skillEn: null,
        },
      ],
      //Skills

      cvData: null,

      //Data
      allData: null,

      //loading
      loading: false,

      //Data
      data: {
        firstName: null,
        firstName_en: null,
        lastName: null,
        lastName_en: null,
        phone: null,
        code: null,
        email: null,
        jobTitle: null,
        jobTitle_en: null,
        address: null,
        address_en: null,
        status: null,
        twitter: null,
        linkedin: null,
        about: null,
        about_en: null,
        company: null,
        skill: 50,
        team: 50,
        time: 50,
        lang: null,
        lang2: null,
        work: null,
        family: null,
        enjoy: null,
        hobbies: [],
      },
      tempNum: null,
      tempPrice: null,
      //   tempLang: null,
      //START:: JOBS
      templates: [
        {
          id: Math.random()
            .toString(36)
            .replace(/[^a-z]+/g, "")
            .substr(0, 5),
          title: "قالب شبابي حديث ومميز",
          img: require("@/assets/images/featured_cv/1.png"),
          priceAr: "200",
          priceEn: "200",
          priceBoth: "300",
          num: "one",
        },
        {
          id: Math.random()
            .toString(36)
            .replace(/[^a-z]+/g, "")
            .substr(0, 5),
          title: "قالب اخضر هادئ جذاب",
          img: require("@/assets/images/featured_cv/2.png"),
          priceAr: "200",
          priceEn: "200",
          priceBoth: "300",
          num: "two",
        },
        {
          id: Math.random()
            .toString(36)
            .replace(/[^a-z]+/g, "")
            .substr(0, 5),
          title: "قالب كلاسيكي رائع وجذاب",
          img: require("@/assets/images/featured_cv/3.png"),
          priceAr: "200",
          priceEn: "200",
          priceBoth: "300",
          num: "three",
        },
      ],
      //END:: JOBS

      //START:: INTERESTS
      interests: [],
      //END:: INTERESTS

      uploadImage: {
        image: null,
        afterUploadFile: false,
        beforeUploadFile: true,
      },

      //Show Model
      show: false,
    };
  },
  methods: {
    onProgress(event) {
      console.log(`Processed: ${event} / 100`);
    },
    onProgress_ar(event) {
      console.log(`Processed: ${event} / 100`);
    },
    onProgress_en(event) {
      console.log(`Processed: ${event} / 100`);
    },
    hasGenerated() {
      alert("PDF generated successfully!");
    },
    generatePDF() {
      this.$refs.html2Pdf.generatePdf();
    },
    generatePDF_ar() {
      this.$refs.html2Pdf_ar.generatePdf();
    },
    generatePDF_en() {
      this.$refs.html2Pdf_en.generatePdf();
    },

    openModel() {
      this.show = true;
      localStorage.removeItem("Sas_TransId");
    },

    //CloseModel
    closeModel() {
      this.show = false;
      if (!localStorage.getItem("Sas_TransId")) {
        this.$store.dispatch("cv_module/addStep", {
          step: 1,
        });
      }
      // localStorage.removeItem("Sas_TransId");
    },

    //Upload Image
    uploadImageFile(e) {
      this.uploadImage.image = e.target.files[0];
      this.uploadImage.beforeUploadFile = false;
      this.uploadImage.afterUploadFile = true;
    },
    //Upload Image
    //Get Data
    getData() {
      this.loading = true;
      this.$axios({
        method: "GET",
        url: "prices",
      }).then((res) => {
        this.loading = false;
        this.allData = res.data.data;
      });
    },

    //Get Interests
    getInterests() {
      this.$axios({
        method: "GET",
        url: "interests",
      }).then((res) => {
        this.interests = res.data.data;
      });
    },
    //Get Interests

    //Add Experience
    addExperience() {
      this.Experiences.push({
        id: (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1),
        companyName: null,
        companyNameEn: null,
        jobTitle: null,
        jobTitleEn: null,
        startDate: null,
        endDate: null,
        breifJob: null,
        breifJobEn: null,
        is_still: false,
      });
    },
    //Add Experience

    //Add Education
    addEducation() {
      this.Education.push({
        id: (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1),
        universityName: null,
        universityNameEn: null,
        departmentName: null,
        departmentNameEn: null,
        startDate: null,
        endDate: null,
        desc: null,
        descEn: null,
        is_still: false,
      });
    },
    //Add Education

    //Add Skill
    addSkill() {
      this.Skills.push({
        id: (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1),
        skill: null,
        skillEn: null,
      });
    },
    //Add Skill

    //Data Formate
    formatDate(date) {
      return moment(date).format("YYYY-MM-DD");
    },
    //Data Formate

    fillData() {
      var submitButton = this.$refs["fill"];
      submitButton.classList.add("click");
      setTimeout(() => {
        if (localStorage.getItem("Sas_TransId")) {
          setTimeout(() => {
            submitButton.classList.remove("click");
            this.$iziToast.success({
              message: this.$t("sendSuccess"),
            });
            this.$store.dispatch("cv_module/addStep", {
              step: 3,
            });
            this.closeModel();
          }, 1000);
        } else {
          setTimeout(() => {
            submitButton.classList.remove("click");
            this.$iziToast.error({
              message: this.$t("payFrist"),
            });
          }, 1000);
        }
      }, 900);
    },

    nextStep() {
      var submitButton = this.$refs["next"];
      submitButton.classList.add("click");
      if (this.step == 3) {
        if (
          !this.data.firstName &&
          (this.tempLang == "ar" || this.tempLang == "both")
        ) {
          setTimeout(() => {
            submitButton.classList.remove("click");
            this.$iziToast.error({
              message: this.$t("register.validation.firstName"),
            });
          }, 1000);
        } else if (
          !this.data.firstName_en &&
          (this.tempLang == "en" || this.tempLang == "both")
        ) {
          setTimeout(() => {
            submitButton.classList.remove("click");
            this.$iziToast.error({
              message: this.$t("register.validation.firstNameEn"),
            });
          }, 1000);
        } else if (
          !this.data.lastName &&
          (this.tempLang == "ar" || this.tempLang == "both")
        ) {
          setTimeout(() => {
            submitButton.classList.remove("click");
            this.$iziToast.error({
              message: this.$t("register.validation.lastName"),
            });
          }, 1000);
        } else if (
          !this.data.lastName_en &&
          (this.tempLang == "en" || this.tempLang == "both")
        ) {
          setTimeout(() => {
            submitButton.classList.remove("click");
            this.$iziToast.error({
              message: this.$t("register.validation.lastName"),
            });
          }, 1000);
        } else if (!this.data.phone) {
          setTimeout(() => {
            submitButton.classList.remove("click");
            this.$iziToast.error({
              message: this.$t("register.validation.phone"),
            });
          }, 1000);
        } else if (!this.data.email) {
          setTimeout(() => {
            submitButton.classList.remove("click");
            this.$iziToast.error({
              message: this.$t("register.validation.email"),
            });
          }, 1000);
        } else if (
          !this.data.jobTitle &&
          (this.tempLang == "ar" || this.tempLang == "both")
        ) {
          setTimeout(() => {
            submitButton.classList.remove("click");
            this.$iziToast.error({
              message: this.$t("register.validation.jobTitle"),
            });
          }, 1000);
        } else if (
          !this.data.jobTitle_en &&
          (this.tempLang == "en" || this.tempLang == "both")
        ) {
          setTimeout(() => {
            submitButton.classList.remove("click");
            this.$iziToast.error({
              message: this.$t("register.validation.jobTitleEn"),
            });
          }, 1000);
        } else if (
          !this.data.address &&
          (this.tempLang == "ar" || this.tempLang == "both")
        ) {
          setTimeout(() => {
            submitButton.classList.remove("click");
            this.$iziToast.error({
              message: this.$t("register.validation.address"),
            });
          }, 1000);
        } else if (
          !this.data.address_en &&
          (this.tempLang == "en" || this.tempLang == "both")
        ) {
          setTimeout(() => {
            submitButton.classList.remove("click");
            this.$iziToast.error({
              message: this.$t("register.validation.addressEn"),
            });
          }, 1000);
        } else if (!this.uploadImage.image) {
          setTimeout(() => {
            submitButton.classList.remove("click");
            this.$iziToast.error({
              message: this.$t("register.validation.image"),
            });
          }, 1000);
        }
        // else if (!this.data.twitter && this.tempNum != "one") {
        //   setTimeout(() => {
        //     submitButton.classList.remove("click");
        //     this.$iziToast.error({
        //       message: this.$t("register.validation.twitter"),
        //     });
        //   }, 1000);
        // } else if (!this.data.linkedin && this.tempNum != "one") {
        //   setTimeout(() => {
        //     submitButton.classList.remove("click");
        //     this.$iziToast.error({
        //       message: this.$t("register.validation.linkedin"),
        //     });
        //   }, 1000);
        // }
        else if (
          !this.data.about &&
          (this.tempLang == "ar" || this.tempLang == "both")
        ) {
          setTimeout(() => {
            submitButton.classList.remove("click");
            this.$iziToast.error({
              message: this.$t("register.validation.about"),
            });
          }, 1000);
        } else if (
          !this.data.about_en &&
          (this.tempLang == "en" || this.tempLang == "both")
        ) {
          setTimeout(() => {
            submitButton.classList.remove("click");
            this.$iziToast.error({
              message: this.$t("register.validation.aboutEn"),
            });
          }, 1000);
        } else if (
          !this.Experiences[0].companyName &&
          (this.tempLang == "ar" || this.tempLang == "both")
        ) {
          setTimeout(() => {
            submitButton.classList.remove("click");
            this.$iziToast.error({
              message: this.$t("register.validation.companyName"),
            });
          }, 1000);
        } else if (
          !this.Experiences[0].companyNameEn &&
          (this.tempLang == "en" || this.tempLang == "both")
        ) {
          setTimeout(() => {
            submitButton.classList.remove("click");
            this.$iziToast.error({
              message: this.$t("register.validation.companyNameEn"),
            });
          }, 1000);
        } else if (
          !this.Experiences[0].jobTitle &&
          (this.tempLang == "ar" || this.tempLang == "both")
        ) {
          setTimeout(() => {
            submitButton.classList.remove("click");
            this.$iziToast.error({
              message: this.$t("register.validation.jobTitle"),
            });
          }, 1000);
        } else if (
          !this.Experiences[0].jobTitleEn &&
          (this.tempLang == "en" || this.tempLang == "both")
        ) {
          setTimeout(() => {
            submitButton.classList.remove("click");
            this.$iziToast.error({
              message: this.$t("register.validation.jobTitleEn"),
            });
          }, 1000);
        } else if (!this.Experiences[0].startDate) {
          setTimeout(() => {
            submitButton.classList.remove("click");
            this.$iziToast.error({
              message: this.$t("register.validation.startDate"),
            });
          }, 1000);
        } else if (!this.Experiences[0].endDate) {
          setTimeout(() => {
            submitButton.classList.remove("click");
            this.$iziToast.error({
              message: this.$t("register.validation.endDate"),
            });
          }, 1000);
        } else if (
          !this.Experiences[0].breifJob &&
          (this.tempLang == "ar" || this.tempLang == "both")
        ) {
          setTimeout(() => {
            submitButton.classList.remove("click");
            this.$iziToast.error({
              message: this.$t("register.validation.breifJob"),
            });
          }, 1000);
        } else if (
          !this.Experiences[0].breifJobEn &&
          (this.tempLang == "en" || this.tempLang == "both")
        ) {
          setTimeout(() => {
            submitButton.classList.remove("click");
            this.$iziToast.error({
              message: this.$t("register.validation.breifJobEn"),
            });
          }, 1000);
        } else if (
          !this.Education[0].universityName &&
          (this.tempLang == "ar" || this.tempLang == "both")
        ) {
          setTimeout(() => {
            submitButton.classList.remove("click");
            this.$iziToast.error({
              message: this.$t("register.validation.universityName"),
            });
          }, 1000);
        } else if (
          !this.Education[0].universityNameEn &&
          (this.tempLang == "en" || this.tempLang == "both")
        ) {
          setTimeout(() => {
            submitButton.classList.remove("click");
            this.$iziToast.error({
              message: this.$t("register.validation.universityNameEn"),
            });
          }, 1000);
        } else if (
          !this.Education[0].departmentName &&
          (this.tempLang == "ar" || this.tempLang == "both")
        ) {
          setTimeout(() => {
            submitButton.classList.remove("click");
            this.$iziToast.error({
              message: this.$t("register.validation.departmentName"),
            });
          }, 1000);
        } else if (
          !this.Education[0].departmentName &&
          (this.tempLang == "en" || this.tempLang == "both")
        ) {
          setTimeout(() => {
            submitButton.classList.remove("click");
            this.$iziToast.error({
              message: this.$t("register.validation.departmentNameEn"),
            });
          }, 1000);
        } else if (!this.Education[0].startDate) {
          setTimeout(() => {
            submitButton.classList.remove("click");
            this.$iziToast.error({
              message: this.$t("register.validation.startDate"),
            });
          }, 1000);
        } else if (!this.Education[0].endDate) {
          setTimeout(() => {
            submitButton.classList.remove("click");
            this.$iziToast.error({
              message: this.$t("register.validation.endDate"),
            });
          }, 1000);
        } else if (
          (!this.Skills[0].skill && this.tempNum == "one") ||
          this.tempNum != "two"
        ) {
          setTimeout(() => {
            submitButton.classList.remove("click");
            this.$iziToast.error({
              message: this.$t("register.validation.skill"),
            });
          }, 1000);
        } else if (
          !this.data.lang &&
          this.tempNum != "one" &&
          this.tempNum != "two"
        ) {
          setTimeout(() => {
            submitButton.classList.remove("click");
            this.$iziToast.error({
              message: this.$t("register.validation.lang"),
            });
          }, 1000);
        } else if (
          !this.data.lang2 &&
          this.tempNum != "one" &&
          this.tempNum != "two"
        ) {
          setTimeout(() => {
            submitButton.classList.remove("click");
            this.$iziToast.error({
              message: this.$t("register.validation.lang2"),
            });
          }, 1000);
        } else if (
          !this.data.time &&
          this.tempNum != "one" &&
          this.tempNum != "two"
        ) {
          setTimeout(() => {
            submitButton.classList.remove("click");
            this.$iziToast.error({
              message: this.$t("register.validation.time"),
            });
          }, 1000);
        } else if (
          !this.data.family &&
          this.tempNum != "one" &&
          this.tempNum != "two"
        ) {
          setTimeout(() => {
            submitButton.classList.remove("click");
            this.$iziToast.error({
              message: this.$t("register.validation.family"),
            });
          }, 1000);
        } else if (
          !this.data.enjoy &&
          this.tempNum != "one" &&
          this.tempNum != "two"
        ) {
          setTimeout(() => {
            submitButton.classList.remove("click");
            this.$iziToast.error({
              message: this.$t("register.validation.enjoy"),
            });
          }, 1000);
        } else if (
          !this.data.hobbies &&
          this.tempNum != "one" &&
          this.tempNum != "two"
        ) {
          setTimeout(() => {
            submitButton.classList.remove("click");
            this.$iziToast.error({
              message: this.$t("register.validation.hobbies"),
            });
          }, 1000);
        } else {
          let submit_data = new FormData();
          if (this.tempLang == "ar") {
            submit_data.append("ar[name]", this.data.firstName);
            submit_data.append("en[name]", this.data.firstName);
            submit_data.append("ar[last_name]", this.data.lastName);
            submit_data.append("en[last_name]", this.data.lastName);
            submit_data.append("ar[job_title]", this.data.jobTitle);
            submit_data.append("en[job_title]", this.data.jobTitle);
            submit_data.append("ar[address]", this.data.address);
            submit_data.append("en[address]", this.data.address);
            submit_data.append("ar[desc]", this.data.about);
            submit_data.append("en[desc]", this.data.about);

            this.Experiences.forEach((item, index) => {
              submit_data.append(
                `experiences[${index}][ar][name]`,
                item.companyName
              );
              submit_data.append(
                `experiences[${index}][en][name]`,
                item.companyName
              );
              submit_data.append(
                `experiences[${index}][start_date]`,
                this.formatDate(item.startDate)
              );
              submit_data.append(
                `experiences[${index}][end_date]`,
                this.formatDate(item.endDate)
              );
              submit_data.append(
                `experiences[${index}][ar][desc]`,
                item.breifJob
              );
              submit_data.append(
                `experiences[${index}][en][desc]`,
                item.breifJob
              );
              submit_data.append(
                `experiences[${index}][ar][job_title]`,
                item.jobTitle
              );
              submit_data.append(
                `experiences[${index}][en][job_title]`,
                item.jobTitle
              );
              submit_data.append(
                `experiences[${index}][is_still]`,
                item.is_still
              );
            });

            this.Education.forEach((item, index) => {
              submit_data.append(
                `education[${index}][ar][name]`,
                item.universityName
              );
              submit_data.append(
                `education[${index}][en][name]`,
                item.universityName
              );
              submit_data.append(
                `education[${index}][start_date]`,
                this.formatDate(item.startDate)
              );
              submit_data.append(
                `education[${index}][end_date]`,
                this.formatDate(item.endDate)
              );
              submit_data.append(`education[${index}][ar][desc]`, item.desc);
              submit_data.append(`education[${index}][en][desc]`, item.desc);
              submit_data.append(
                `education[${index}][ar][section_title]`,
                item.departmentName
              );
              submit_data.append(
                `education[${index}][en][section_title]`,
                item.departmentName
              );
              submit_data.append(
                `education[${index}][is_still]`,
                item.is_still
              );
            });

            this.Skills.forEach((item, index) => {
              submit_data.append(`skills[${index}][ar][name]`, item.skill);
              submit_data.append(`skills[${index}][en][name]`, item.skill);
            });
          }
          if (this.tempLang == "en") {
            submit_data.append("ar[name]", this.data.firstName_en);
            submit_data.append("en[name]", this.data.firstName_en);
            submit_data.append("ar[last_name]", this.data.lastName_en);
            submit_data.append("en[last_name]", this.data.lastName_en);
            submit_data.append("ar[job_title]", this.data.jobTitle_en);
            submit_data.append("en[job_title]", this.data.jobTitle_en);
            submit_data.append("ar[address]", this.data.address_en);
            submit_data.append("en[address]", this.data.address_en);
            submit_data.append("ar[desc]", this.data.about_en);
            submit_data.append("en[desc]", this.data.about_en);

            this.Experiences.forEach((item, index) => {
              submit_data.append(
                `experiences[${index}][ar][name]`,
                item.companyNameEn
              );
              submit_data.append(
                `experiences[${index}][en][name]`,
                item.companyNameEn
              );
              submit_data.append(
                `experiences[${index}][start_date]`,
                this.formatDate(item.startDate)
              );
              submit_data.append(
                `experiences[${index}][end_date]`,
                this.formatDate(item.endDate)
              );
              submit_data.append(
                `experiences[${index}][ar][desc]`,
                item.breifJobEn
              );
              submit_data.append(
                `experiences[${index}][en][desc]`,
                item.breifJobEn
              );
              submit_data.append(
                `experiences[${index}][ar][job_title]`,
                item.jobTitleEn
              );
              submit_data.append(
                `experiences[${index}][en][job_title]`,
                item.jobTitleEn
              );
              submit_data.append(
                `experiences[${index}][is_still]`,
                item.is_still
              );
            });

            this.Education.forEach((item, index) => {
              submit_data.append(
                `education[${index}][ar][name]`,
                item.universityNameEn
              );
              submit_data.append(
                `education[${index}][en][name]`,
                item.universityNameEn
              );
              submit_data.append(
                `education[${index}][start_date]`,
                this.formatDate(item.startDate)
              );
              submit_data.append(
                `education[${index}][end_date]`,
                this.formatDate(item.endDate)
              );
              submit_data.append(`education[${index}][ar][desc]`, item.descEn);
              submit_data.append(`education[${index}][en][desc]`, item.descEn);
              submit_data.append(
                `education[${index}][ar][section_title]`,
                item.departmentNameEn
              );
              submit_data.append(
                `education[${index}][en][section_title]`,
                item.departmentNameEn
              );
              submit_data.append(
                `education[${index}][is_still]`,
                item.is_still
              );
            });

            this.Skills.forEach((item, index) => {
              submit_data.append(`skills[${index}][ar][name]`, item.skillEn);
              submit_data.append(`skills[${index}][en][name]`, item.skillEn);
            });
          }
          if (this.tempLang == "both") {
            submit_data.append("ar[name]", this.data.firstName);
            submit_data.append("en[name]", this.data.firstName_en);
            submit_data.append("ar[last_name]", this.data.lastName);
            submit_data.append("en[last_name]", this.data.lastName_en);
            submit_data.append("ar[job_title]", this.data.jobTitle);
            submit_data.append("en[job_title]", this.data.jobTitle_en);
            submit_data.append("ar[address]", this.data.address);
            submit_data.append("en[address]", this.data.address_en);
            submit_data.append("ar[desc]", this.data.about);
            submit_data.append("en[desc]", this.data.about_en);

            this.Experiences.forEach((item, index) => {
              submit_data.append(
                `experiences[${index}][ar][name]`,
                item.companyName
              );
              submit_data.append(
                `experiences[${index}][en][name]`,
                item.companyNameEn
              );
              submit_data.append(
                `experiences[${index}][start_date]`,
                this.formatDate(item.startDate)
              );
              submit_data.append(
                `experiences[${index}][end_date]`,
                this.formatDate(item.endDate)
              );
              submit_data.append(
                `experiences[${index}][ar][desc]`,
                item.breifJob
              );
              submit_data.append(
                `experiences[${index}][en][desc]`,
                item.breifJobEn
              );
              submit_data.append(
                `experiences[${index}][ar][job_title]`,
                item.jobTitle
              );
              submit_data.append(
                `experiences[${index}][en][job_title]`,
                item.jobTitleEn
              );
              submit_data.append(
                `experiences[${index}][is_still]`,
                item.is_still
              );
            });

            this.Education.forEach((item, index) => {
              submit_data.append(
                `education[${index}][ar][name]`,
                item.universityName
              );
              submit_data.append(
                `education[${index}][en][name]`,
                item.universityNameEn
              );
              submit_data.append(
                `education[${index}][start_date]`,
                this.formatDate(item.startDate)
              );
              submit_data.append(
                `education[${index}][end_date]`,
                this.formatDate(item.endDate)
              );
              submit_data.append(`education[${index}][ar][desc]`, item.desc);
              submit_data.append(`education[${index}][en][desc]`, item.descEn);
              submit_data.append(
                `education[${index}][ar][section_title]`,
                item.departmentName
              );
              submit_data.append(
                `education[${index}][en][section_title]`,
                item.departmentNameEn
              );
              submit_data.append(
                `education[${index}][is_still]`,
                item.is_still
              );
            });

            if (this.tempNum == "one") {
              this.Skills.forEach((item, index) => {
                submit_data.append(`skills[${index}][ar][name]`, item.skill);
                submit_data.append(`skills[${index}][en][name]`, item.skill);
              });
            }
          }
          submit_data.append("cv_id", this.id);
          submit_data.append("email", this.data.email);
          submit_data.append("phone", this.data.code + this.data.phone);
          if (this.uploadImage.afterUploadFile) {
            submit_data.append("image", this.uploadImage.image);
          }
          submit_data.append("temp_number", this.tempNum);
          if (this.tempNum != "one" || this.tempNum != "two") {
            submit_data.append("gender", this.data.status);
            submit_data.append("twitter_user", this.data.twitter);
            submit_data.append("linkedin_user", this.data.linkedin);
            submit_data.append("contact_level", this.data.skill);
            submit_data.append("leader_level", this.data.team);
            submit_data.append("time_level", this.data.time);
            submit_data.append("arabic_level", this.data.lang);
            submit_data.append("english_level", this.data.lang2);
            submit_data.append("work_level", this.data.work);
            submit_data.append("family_level", this.data.family);
            submit_data.append("play_level", this.data.enjoy);
            this.data.hobbies.forEach((item, index) => {
              submit_data.append(`interests[${index}]`, item);
            });
          }

          this.$axios({
            method: "post",
            url: "update_design",
            data: submit_data,
          })
            .then((res) => {
              setTimeout(() => {
                // this.$store.dispatch("rate_module/addId", {
                //   id
                // });
                // this.$store.dispatch("cv_module/addStep", {
                //   step: 4,
                // });
                // this.$store.dispatch("cv_module/addId", {
                //   id: res.data.data.id,
                // });
                setTimeout(() => {
                  // this.getCv(this.cv_id);
                  if (this.tempNum == "one") {
                    this.$router.push({
                      path: `/cvSteps/one/${this.tempLang}/${this.id}`,
                    });
                  }
                  if (this.tempNum == "two") {
                    this.$router.push({
                      path: `/cvSteps/two/${this.tempLang}/${this.id}`,
                    });
                  }
                  if (this.tempNum == "three") {
                    this.$router.push({
                      path: `/cvSteps/three/${this.tempLang}/${this.id}`,
                    });
                  }
                }, 500);
                submitButton.classList.remove("click");
                this.$iziToast.success({
                  message: this.$t("sendSuccess"),
                });
              }, 1000);
            })
            .catch((err) => {
              setTimeout(() => {
                submitButton.classList.remove("click");
                this.$iziToast.error({
                  message: err.response.data.message,
                });
              }, 1000);
            });
        }
      }
    },

    //Choose Template
    chooseTemplate(obj) {
      this.tempNum = obj.tempNum;
      this.tempPrice = obj.price;
      this.tempLang = obj.tempLang;
      console.log(obj);
      this.$store.dispatch("cv_module/addStep", {
        step: 3,
      });
      this.openModel();
    },

    //Cv Details
    getCv() {
      this.$axios({
        method: "GET",
        url: "cv_design_details?cv_id=" + this.id,
      }).then((res) => {
        this.cvData = res.data.data;
        this.tempNum = this.cvData.temp_number;
        this.data.firstName = this.cvData.name;
        this.data.firstName_en = this.cvData.name_en;
        this.data.lastName = this.cvData.last_name;
        this.data.lastName_en = this.cvData.last_name_en;
        this.data.phone = this.cvData.phone;
        this.data.email = this.cvData.email;
        this.data.jobTitle = this.cvData.job_title;
        this.data.jobTitle_en = this.cvData.job_title_en;
        this.data.address = this.cvData.address;
        this.data.address_en = this.cvData.address_en;
        this.data.about = this.cvData.desc;
        this.data.about_en = this.cvData.desc_en;
        this.uploadImage.image = this.cvData.image.substring(0, 30);
        this.Experiences = this.cvData.experiences.map((item) => {
          return {
            id: item.id,
            companyName: item.name,
            companyNameEn: item.name_en,
            jobTitle: item.job_title,
            jobTitleEn: item.job_title_en,
            startDate: item.start_date,
            endDate: item.end_date,
            breifJob: item.desc,
            breifJobEn: item.desc_en,
            is_still: !!item.is_still,
          };
        });
        this.Education = this.cvData.education.map((item) => {
          return {
            id: item.id,
            universityName: item.name,
            universityNameEn: item.name_en,
            departmentName: item.section_title,
            departmentNameEn: item.section_title_en,
            startDate: item.start_date,
            endDate: item.end_date,
            desc: item.desc,
            descEn: item.desc_en,
            is_still: !!item.is_still,
          };
        });
        this.Skills = this.cvData.skills.map((x) => {
          return {
            skill: x.name,
            skillEn: x.name_en,
          };
        });
        if (this.tempNum == "two" || this.tempNum == "three") {
          this.data.twitter = this.cvData.twitter_user;
          this.data.linkedin = this.cvData.linkedin_user;
          this.data.status = this.cvData.gender;
          this.data.skill = this.cvData.contact_level;
          this.data.team = this.cvData.leader_level;
          this.data.time = this.cvData.time_level;
          this.data.lang = Number(this.cvData.arabic_level);
          this.data.lang2 = Number(this.cvData.english_level);
          this.data.work = this.cvData.work_level;
          this.data.family = this.cvData.family_level;
          this.data.enjoy = this.cvData.play_level;
          this.data.hobbies = this.cvData.interests;
        }
      });
    },

    //Country Code
    onSelect({ name, iso2, dialCode }) {
      console.log(name, iso2, dialCode);
      this.data.code = dialCode;
    },
  },
  mounted() {
    this.getCv();
  },
  created() {
    this.$store.dispatch("cv_module/addStep", {
      step: 3,
    });
    this.getData();
    this.getInterests();
    // if (this.step == 3) {
    //   this.getCv(this.cv_id);
    // }
  },
  computed: {
    step() {
      return this.$store.getters["cv_module/cvStep"];
    },
    cv_id() {
      return this.$store.getters["cv_module/cvId"];
    },
  },
  beforeRouteLeave(_, _2, next) {
    localStorage.removeItem("Sas_TransId");
    next();
  },
};
</script>

<style>
.skill_item {
  margin-bottom: 15px;
}

.skill_item span {
  font-size: 15px;
  display: flex;
  margin-bottom: 4px;
}

.range {
  width: 400px;
  height: 15px;
  -webkit-appearance: none;
  background: #cecece;
  direction: ltr;
  outline: none;
  border-radius: 0px;
  overflow: hidden;
  /* box-shadow: inset 0 0 5px rgba(0, 0, 0, 1); */
}
.range::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #0eb6d8;
  cursor: pointer;
  border: 4px solid #fff;
  box-shadow: -407px 0 0 400px #0eb6d8;
}

.vue-country-select {
  margin-bottom: 15px;
  margin-inline-end: 5px;
}
</style>
